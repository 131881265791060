<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-content">
        <div class="branding">
          <div class="top-row">
            <div class="logo">
              <img
                alt="Mouse Create"
                src="/img/mouse-create-logo.png"
              >
            </div>
          </div>

          <div class="legal">
            <a
              href="https://tools.mouse.org"
              target="_blank"
            >Help &nbsp;&nbsp;|&nbsp;&nbsp;</a>
            <a
              href="https://mouse.org/privacy-policy"
              target="_blank"
            >Privacy Policy</a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a
              href="https://mouse.org/terms-of-use"
              target="_blank"
            >Terms of Use</a>
            &nbsp;&nbsp;|&nbsp;&nbsp;
            <a
              href="https://mouse.org/mouse-create-code-of-conduct"
              target="_blank"
            >Code of Conduct</a>
          </div>
        </div>

        <div class="affiliate">
          <a href="https://mouse.org"><img
            alt="Visit mouse.org"
            src="/img/gear.svg"
          >Visit mouse.org</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterNav',
};
</script>
