var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        height: "15px",
        viewBox: "0 0 10 15",
        width: "10px",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("defs"),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Designs",
            fill: "none",
            "fill-rule": "evenodd",
            stroke: "none",
            "stroke-width": "1",
          },
        },
        [
          _c(
            "g",
            {
              staticClass: "pagination__arrow",
              attrs: {
                id: "Steps-Tab---1-Prompt_no-work-yet",
                transform: "translate(-770.000000, -259.000000)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Project-Detail-Copy-2",
                    transform: "translate(49.000000, 251.000000)",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "prev/next-step-top",
                        transform: "translate(18.000000, 0.000000)",
                      },
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "right-arrow",
                            transform: "translate(691.000000, 0.000000)",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              id: "Shape-Copy-2",
                              d: "M23.648667,17.3925941 L17.6409529,11.0284305 C17.4007941,10.7872546 17.120363,10.6666667 16.8,10.6666667 C16.4733976,10.6666667 16.1961429,10.7872546 15.9681225,11.0284305 L9.95121951,17.3925941 C9.71707317,17.6401293 9.6,17.9367397 9.6,18.2821851 C9.6,18.6341099 9.71718661,18.9273606 9.95121951,19.1619371 L10.6443562,19.8950638 C10.8662507,20.1427191 11.1435054,20.2666667 11.4762337,20.2666667 C11.808962,20.2666667 12.0862167,20.1425991 12.3081112,19.8950638 L16.8,15.153738 L21.2918888,19.8949438 C21.5136699,20.1424791 21.7909246,20.2665467 22.1238798,20.2665467 C22.4502552,20.2665467 22.7307998,20.1424791 22.9648327,19.8949438 L23.6578559,19.1618172 C23.8858763,18.9206413 24,18.6273906 24,18.2820651 C24,17.9366197 23.8829268,17.6400093 23.648667,17.3925941 L23.648667,17.3925941 Z",
                              transform:
                                "translate(16.800000, 15.466667) scale(-1, 1) rotate(-90.000000) translate(-16.800000, -15.466667) ",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }