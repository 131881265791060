<template>
  <div>
    <div
      v-if="!isLoading && project.hasPackAccess"
      class="evidence-prompt-content"
      :class="{'project__add-work': !hideExtra }"
    >
      <div class="prompt__response">
        <div
          v-if="prompts.length"
          class="evidence__prompts"
        >
          <div class="highlight__title">
            <h2>Requirements</h2>
            <span v-if="!hideExtra">These required tasks should be reflected in your project work.</span>
          </div>
          <div
            v-for="prompt in prompts"
            :key="prompt.stepId"
            class="prompt__row"
          >
            <div
              v-if="!hideExtra"
              class="prompt__step"
            >
              <a
                href="#"
                class="button button-step"
                @click.stop.prevent="promptStep(prompt.order)"
              >
                Step {{ prompt.order }}
              </a>
            </div>
            <!-- eslint-disable -->
            <div
              class="prompt__description"
              v-html="prompt.text"
            />
            <!-- eslint-enable -->
          </div>
        </div>

        <div
          v-if="canSubmitEvidence"
          class="evidence__response"
        >
          <div class="highlight__title">
            <h2 v-if="hasWork && !hideExtra">
              Your Project Work
            </h2>
            <h2 v-else>
              Add Your Response
            </h2>
            <span v-if="isAddWork && !hideExtra">
              Add text, images or file attachments before submitting work.
            </span>
          </div>

          <div class="evidence__buttons">
            <a
              href="#"
              class="button step__button"
              @click.stop.prevent="evidenceModal('text')"
            >
              <span class="step__button-text">Text</span>
              <span class="step__button-icon">
                <text-icon />
              </span>
            </a>
            <a
              href="#"
              class="button step__button"
              @click.stop.prevent="evidenceModal('image')"
            >
              <span class="step__button-text">Image</span>
              <span class="step__button-icon">
                <image-icon />
              </span>
            </a>
            <a
              href="#"
              class="button step__button"
              @click.stop.prevent="evidenceModal('file')"
            >
              <span class="step__button-text">File</span>
              <span class="step__button-icon">
                <file-icon />
              </span>
            </a>
          </div>
        </div>
      </div>

      <div
        v-if="!assets || assets.length < 1"
        class="project__assets-none"
      >
        <template v-if="!forStep">
          <div class="no-asset-icon">
            <no-asset-icon />
          </div>
          <span class="no-asset-text">You have not shared any work yet. Show us what you got!</span>
        </template>
      </div>

      <div
        v-else
        :class="{ 'evidence__assets': !forStep, 'step-footer': !!forStep }"
      >
        <project-evidences
          :assets="assets"
          :evidence-id="evidenceId"
          :scoped-step="forStep ? forStep.stepId : false"
        />
      </div>

      <project-collaborators
        v-if="!hideExtra"
        :evidence-id="evidenceId"
      />

      <div
        v-if="!hideExtra"
        class="evidence-submit"
      >
        <div class="review-title">
          <div class="review-icon">
            <review-icon />
          </div>
          <h2>Review</h2>
          <span v-if="assets && assets.length > 0">Is your project complete?</span>
          <span v-if="!assets || assets.length < 1">You <strong>must add work</strong> to submit your project.</span>
        </div>
        <button
          class="button__submit-work"
          :disabled="!assets || assets.length < 1"
          @click.stop.prevent="submitModal"
        >
          Yes! Submit My Work
        </button>

        <div class="clearfix" />
      </div>
    </div>

    <div v-show="!isLoading && !project.hasPackAccess">
      <p>You do not have access to this project</p>
    </div>

    <div class="modal modal__global">
      <label for="evidenceModal" />
      <input
        id="evidenceModal"
        class="modal-state"
        type="checkbox"
      >
      <div class="modal-fade-screen">
        <div class="modal-inner">
          <div class="modal__header">
            <button
              id="close-button-1"
              class="modal__close"
              for="evidenceModal"
              aria-label="Close this dialog window"
              @click="closeEvidenceModal"
            >
              <close-modal-icon />
            </button>
            <h2 v-if="currentType.name === 'text'">
              Add Text
            </h2>
            <h2 v-if="currentType.name === 'image'">
              Upload Image
            </h2>
            <h2 v-if="currentType.name === 'file'">
              Upload File
            </h2>
          </div>

          <div
            class="modal__content"
            :class="{ 'modal__content-text': currentType.name === 'text' }"
          >
            <upload-manager
              :type="currentType.value"
              :error-clear-fn="() => errors.clear()"
            >
              <template #default="umProps">
                <form>
                  <div v-if="currentType.name === 'text'">
                    <label for="textarea-content" />
                    <textarea
                      id="textarea-content"
                      v-model="asset.content"
                      v-validate="'required|min:10'"
                      type="text"
                      name="content_text"
                      placeholder="Your text here"
                      :class="{ 'input-error' : errors.has('content_text') }"
                    />
                    <div
                      v-show="errors.has('content_text')"
                      class="form-error"
                    >
                      {{ errors.first('content_text') }}
                    </div>
                  </div>
                  <div
                    v-if="currentType.name === 'image'"
                    class="modal__file-input"
                  >
                    <label>
                      <image-label />
                      <input
                        ref="fileInputRef"
                        v-validate="'ext:jpeg,jpg,bmp,png,gif,heic'"
                        type="file"
                        name="content_image"
                        :class="{ 'input-error' : errors.has('content_image') || errors.has('filesize') }"
                        @change="umProps.handleFileChange"
                      >
                    </label>
                    <div
                      v-show="errors.has('filesize')"
                      class="form-error"
                    >
                      {{ errors.first('filesize') }}
                    </div>
                    <div
                      v-show="errors.has('content_image')"
                      class="form-error"
                    >
                      {{ errors.first('content_image') }}
                    </div>
                    <div
                      v-if="umProps.upload.active && !errors.has('filesize')"
                      class="asset-processing"
                    >
                      Uploading file, please wait...{{ umProps.upload.percent }}%
                    </div>
                    <hr>
                  </div>
                  <div
                    v-if="currentType.name === 'file'"
                    class="modal__file-input"
                  >
                    <label>
                      <file-label />
                      <input
                        ref="fileInputRef"
                        v-validate="'required|ext:pdf,docx,xlsx,pptx,doc,xls,ppt,txt,zip,webm'"
                        type="file"
                        name="content_file"
                        :class="{ 'input-error' : errors.has('content_file') || errors.has('filesize') }"
                        @change="umProps.handleFileChange"
                      >
                    </label>

                    <div
                      v-show="errors.has('filesize')"
                      class="form-error"
                    >
                      {{ errors.first('filesize') }}
                    </div>

                    <div
                      v-show="errors.has('content_file')"
                      class="form-error"
                    >
                      {{ errors.first('content_file') }}
                    </div>

                    <div
                      v-if="umProps.upload.active && !errors.has('filesize')"
                      class="asset-processing"
                    >
                      Uploading file, please wait...{{ umProps.upload.percent }}%
                    </div>
                    <hr>
                  </div>
                  <div class="clearfix" />
                  <div class="modal__actions">
                    <span class="action__proceed">
                      <button
                        type="button"
                        class="button modal__button"
                        for="evidenceModal"
                        :disabled="!umProps.isValid ? true : umProps.upload.active || errors.items.length > 0"
                        @click.stop.prevent="() => sendEvidence(umProps.handleUpload)"
                      >
                        Save
                      </button>
                    </span>
                  </div>
                </form>
              </template>
            </upload-manager>
            <div class="clearfix" />
          </div>
        </div>
      </div>
    </div>

    <div class="modal modal__global">
      <label for="submit-work-modal" />
      <input
        id="submit-work-modal"
        class="modal-state"
        type="checkbox"
      >
      <div class="modal-fade-screen">
        <div class="modal-inner">
          <div class="modal__header">
            <button
              id="close-button"
              class="modal__close"
              for="delete-group-modal"
              aria-label="Close this dialog window"
              @click="closeSubmitModal"
            >
              <close-modal-icon />
            </button>
            <h2>Submit Your Work</h2>
          </div>

          <div class="modal__content">
            <div class="modal__disclaimer">
              <div class="modal__icon">
                <announce-icon />
              </div>
              <div class="modal__disclaimer-copy">
                <div class="modal__sure-copy">
                  <strong>Are you sure?</strong> You will not be able to edit or add to this work after you submit.
                </div>
              </div>
            </div>

            <div class="clearfix" />
            <hr>

            <div class="modal__actions">
              <span class="action__cancel cancel">
                <a
                  href="#"
                  @click.prevent.stop="closeSubmitModal"
                >Cancel</a>
              </span>
              <span class="action__proceed">
                <a
                  href="#"
                  @click.prevent.stop="submitProject"
                >Confirm and Submit</a>
              </span>
            </div>

            <div class="clearfix" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import modalMixin from '../../../mixins/modal';
import ProjectEvidences from '../ProjectEvidences.vue';
import ProjectCollaborators from '../ProjectCollaborators.vue';
import UploadManager from '../../upload/UploadManager.vue';
import ReviewIcon from '../icons/ReviewIcon.vue';
import TextIcon from '../icons/TextIcon.vue';
import ImageIcon from '../icons/ImageIcon.vue';
import FileIcon from '../icons/FileIcon.vue';
import NoAssetIcon from '../icons/NoAssetIcon.vue';
import CloseModalIcon from '../icons/CloseModalIcon.vue';
import FileLabel from '../labels/FileLabel.vue';
import ImageLabel from '../labels/ImageLabel.vue';
import AnnounceIcon from '../icons/AnnounceIcon.vue';
import CheckboxLabel from '../labels/CheckboxLabel.vue';
import { AssetTypes } from '../../../common/AssetTypes';

export default {
  name: 'ProjectEvidenceManager',
  components: {
    CheckboxLabel,
    AnnounceIcon,
    ImageLabel,
    FileLabel,
    CloseModalIcon,
    NoAssetIcon,
    FileIcon,
    ImageIcon,
    TextIcon,
    ReviewIcon,
    UploadManager,
    ProjectEvidences,
    ProjectCollaborators,
  },
  mixins: [modalMixin],
  props: {
    hideExtra: {
      default: false,
      type: Boolean,
    },
    project: {
      type: [Object, null],
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    getProject: {
      type: Function,
      required: true,
    },
    prompts: {
      type: Array,
      required: true,
    },
    forStep: {
      type: [Boolean, Object],
      default: false,
    },
  },
  data() {
    return {
      canSubmitEvidence: true,
      asset: {
        content: '',
      },
      currentType: {},
      assets: {},
      evidenceId: null,
      fileIsUploading: false,
      isEdit: true,
      hasWork: false,
      isAddWork: true,
      types: [
        {
          name: 'text',
          value: 1,
        },
        {
          name: 'image',
          value: 2,
        },
        {
          name: 'file',
          value: 4,
        },
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.users.current;
    },
    postEvidenceRoute() {
      return laroute.route('group.project.evidence.save', {
        group: this.currentUser.defaultGroupId,
        project: this.$route.params.id,
      });
    },
    postProjectRoute() {
      return laroute.route('group.project.evidence.submit', {
        group: this.currentUser.defaultGroupId,
        project: this.$route.params.id,
      });
    },
    getEvidenceRoute() {
      return laroute.route('api.evidences.project', {
        project: this.$route.params.id,
        include: 'assets',
      });
    },
  },
  watch: {},
  mounted() {
    this.getEvidence();

    bus.$on('update-project-evidence', this.getEvidence);
    bus.$on('close:modal', this.modalIsClosed);
  },
  methods: {
    submitModal() {
      this.openModal('#submit-work-modal');
    },

    closeSubmitModal() {
      this.closeModal('#submit-work-modal');
    },

    submitProject() {
      const $this = this;

      axios.post(this.postProjectRoute, {
        prompts: this.prompts,
      }).then((response) => {
        if (response.data.status === 'success') {
          $this.closeSubmitModal();

          this.$store.dispatch('loadMessage', {
            content: response.data.message,
            type: 'success',
            icon: '',
            autoclose: true,
          });

          $this.$router.push({
            name: 'project-steps',
            params: {
              id: this.$route.params.id,
              step: '1',
            },
          });
        }
      });
    },

    getEvidence() {
      axios.get(this.getEvidenceRoute).then((response) => {
        this.evidenceId = response.data.id;
        if (response.data.assets) {
          this.assets = response.data.assets.data;
        }

        if (this.assets && this.assets.length > 0) {
          this.hasWork = true;
        }

        bus.$emit('enable:tabs');
      });
    },

    promptStep(order) {
      this.$router.push({ name: 'project-steps', params: { step: order } });
    },

    async sendEvidence(uploadFn) {
      const umData = { status: null };

      this.fileIsUploading = true;

      const query = {
        type: this.currentType.value,
        content: this.asset.content,
      };

      if ([AssetTypes.File.name, AssetTypes.Image.name].includes(this.currentType.name)) {
        umData.status = await uploadFn();
        if (!umData.status.upload.direct_upload_id) {
          this.errors.add({
            field: 'filesize',
            msg: umData.status.errors.file_size[0],
          });
          return;
        }
        query.direct_upload_id = umData.status.upload.direct_upload_id;
      }

      if (this.forStep) {
        query.step_id = this.forStep.stepId;
        query.step_order = this.forStep.stepOrder;
      }

      axios.post(this.postEvidenceRoute, query)
        .then((response) => {
          if (response.data.status === 'success') {
            if (response.data.newEvidence) {
              this.openModal('#verify-collaborators');
            }
            this.$store.dispatch('loadMessage', {
              content: response.data.message,
              type: 'success',
              icon: '',
              autoclose: true,
            });

            this.getEvidence();
            this.closeModal('#evidenceModal');
            this.asset.content = '';
            this.currentType = {};
            this.fileIsUploading = false;
          }
        })
        .catch((error) => {
          this.errors.add('filesize', error.data.status);
        });
    },

    evidenceModal(type) {
      this.currentType = _.find(this.types, (t) => t.name === type);
      this.errors.clear();
      this.openModal('#evidenceModal');
    },

    modalIsClosed() {
      this.getEvidence();
      this.errors.clear();
      this.fileIsUploading = false;
      this.asset.content = '';
    },

    closeEvidenceModal() {
      if (this.$refs.fileInputRef && this.$refs.fileInputRef.value) {
        this.$refs.fileInputRef.value = '';
      }
      this.modalIsClosed();
      this.closeModal('#evidenceModal');
    },
  },
};
</script>
