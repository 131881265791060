<template>
  <div class="page-wrap">
    <main>
      <router-link
        to="/"
        class="back-to__button"
      >
        <span class="back-to__icon">

          <svg
            width="9px"
            height="17px"
            viewBox="0 0 9 17"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Shape</title>
            <desc>Created with Sketch.</desc>
            <g
              id="Page-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Project-Gallery-Browser---med/tablet"
                transform="translate(-23.000000, -204.000000)"
                fill="#00A886"
              >
                <g
                  id="back-to-profile-copy"
                  transform="translate(23.000000, 203.000000)"
                >
                  <path
                    id="Shape"
                    d="M12.4801154,11.237282 L5.5086642,5.27180751 C5.22997992,5.04574032 4.90456303,4.93270673 4.53280847,4.93270673 C4.15381364,4.93270673 3.83208271,5.04574032 3.56748402,5.27180751 L-3.41463012,11.237282 C-3.68633743,11.4693101 -3.82219108,11.747339 -3.82219108,12.0711437 C-3.82219108,12.4010219 -3.68620578,12.6759016 -3.41463012,12.8957828 L-2.61030279,13.5829821 C-2.35281273,13.8151227 -2.0310818,13.9313055 -1.64497835,13.9313055 C-1.2588749,13.9313055 -0.937143967,13.8150103 -0.679653907,13.5829821 L4.53280847,9.13868114 L9.74527084,13.5828696 C10.0026293,13.8148978 10.3243602,13.9311931 10.7107269,13.9311931 C11.0894585,13.9311931 11.415007,13.8148978 11.6865827,13.5828696 L12.4907783,12.8956704 C12.755377,12.6696032 12.887808,12.3947235 12.887808,12.0710313 C12.887808,11.7472266 12.7519544,11.4691977 12.4801154,11.237282 L12.4801154,11.237282 Z"
                    transform="translate(4.532808, 9.432006) rotate(-90.000000) translate(-4.532808, -9.432006) "
                  />
                </g>
              </g>
            </g>
          </svg>

        </span>
        <span class="back-to__text">Back to Home</span>
      </router-link>

      <section class="primary-content">
        <div class="content-box">
          <h1 class="page-title">
            Registration
          </h1>

          <form
            v-show="formReady"
            id="registrationForm"
            class="form-horizontal form-registration registration"
            method="post"
          >
            <div class="form-block">
              <div class="form-row">
                <div class="legend">
                  What kind of account do you want to make?
                </div>
                <input
                  id="account_educator"
                  v-model="registration.type"
                  type="radio"
                  name="registration.type"
                  value="adult"
                  @change="resetForm"
                >
                <label
                  for="account_educator"
                  class="options"
                >Educator</label><br>
                <input
                  id="account_youth"
                  v-model="registration.type"
                  type="radio"
                  name="registration.type"
                  value="youth"
                  @change="resetForm"
                >
                <label
                  for="account_youth"
                  class="options"
                >Student</label>
              </div>
            </div>

            <div
              v-show="isAdult"
              class="form-block"
            >
              <div class="form-row">
                <div class="legend">
                  Is your site a current Mouse Member?
                </div>
                <input
                  id="code_yes"
                  v-model="facilitator.status"
                  type="radio"
                  name="facilitator.status"
                  value="yes"
                >
                <label
                  for="code_yes"
                  class="options"
                >My site is a Mouse Member and I have my site’s {{ labels.facilitator }} Code.</label><br>

                <input
                  id="code_maybe"
                  v-model="facilitator.status"
                  type="radio"
                  name="facilitator.status"
                  value="maybe"
                >
                <label
                  for="code_maybe"
                  class="options"
                >I think my site is a Mouse Member, but I don’t have my site’s {{ labels.facilitator }} Code.</label><br>

                <input
                  id="code_no"
                  v-model="facilitator.status"
                  type="radio"
                  name="facilitator.status"
                  value="no"
                >
                <label
                  for="code_no"
                  class="options"
                > I want to learn about becoming a Mouse Member.</label>
              </div>
            </div>

            <div
              v-show="isNotFacilitator"
              class="form-block"
            >
              <div class="form-row">
                <p>
                  <strong>Become a part of the national Mouse learning network!</strong>
                </p>
                <p>
                  Visit <a
                    href="https://mouse.org/join"
                    target="_new"
                  >mouse.org/join</a> to find a Mouse Membership option that fits your learning environment and budget.
                </p>
              </div>
            </div>

            <div
              v-show="isMaybeFacilitator"
              class="form-block"
            >
              <div class="form-row">
                <p>
                  If you are a Mouse member and do not have your site's Educator Code, email <a href="mailto:create@mouse.org">create@mouse.org</a> with your name and the name of your school or organization.
                </p>
              </div>
            </div>

            <div
              v-show="showGeneral"
              class="form-block"
            >
              <h3>General Information</h3>

              <div class="form-row">
                <label for="username">Username <span>* required</span></label>
                <span
                  v-show="errors.has('username')"
                  class="help-block error"
                >
                  <strong>{{ errors.first('username') }}</strong>
                </span>

                <input
                  id="username"
                  v-model="general.username"
                  type="text"
                  name="username"
                  placeholder="Your Username"
                  @keyup="checkUsername"
                >

                <div
                  v-if="registrationErrors.username"
                  class="form-error"
                >
                  <span><strong v-text="registrationErrors.username" /></span>
                </div>

                <div
                  v-if="usernameError"
                  class="form-error"
                >
                  <span v-text="usernameError" />
                </div>

                <div
                  v-if="usernameSuccess"
                  class="form-success"
                >
                  <span v-text="usernameSuccess" />
                </div>
              </div>

              <div
                v-if="!isGoogleRegistration"
                class="form-row"
              >
                <div class="form-1-2">
                  <label for="password">Password <span>* required</span></label>
                  <input
                    id="password"
                    v-model="general.password"
                    type="password"
                    name="password"
                    placeholder="Your Secret Password"
                  >
                  <div
                    v-if="registrationErrors.password"
                    class="form-error"
                  >
                    <span><strong>{{ registrationErrors.password }}</strong></span>
                  </div>
                </div>

                <div class="form-1-2">
                  <label for="password_confirm">
                    Confirm Password <span>* required</span>
                  </label>

                  <span
                    v-if="registrationErrors.password_confirmation"
                    class="help-block error"
                  >
                    <strong>{{ registrationErrors.password_confirmation[0] }}</strong>
                  </span>

                  <input
                    id="password_confirm"
                    v-model="general.password_confirmation"
                    type="password"
                    name="password_confirmation"
                    placeholder="Confirm Password"
                  >
                </div>
              </div>

              <div class="form-row">
                <div class="form-1-2">
                  <label for="first_name">
                    First Name <span>* required</span>
                  </label>
                  <input
                    id="first_name"
                    v-model="general.first_name"
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                  >
                  <div
                    v-if="registrationErrors.first_name"
                    class="form-error"
                  >
                    <span><strong>{{ registrationErrors.first_name }}</strong></span>
                  </div>
                </div>

                <div class="form-1-2">
                  <label for="last_name">
                    Last Name <span>* required</span>
                  </label>
                  <input
                    id="last_name"
                    v-model="general.last_name"
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                  >
                  <div
                    v-if="registrationErrors.last_name"
                    class="form-error"
                  >
                    <span><strong>{{ registrationErrors.last_name }}</strong></span>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div class="form-1">
                  <label for="email">
                    Email <span v-show="isFacilitator">* required</span>
                  </label>

                  <input
                    id="email"
                    v-model="general.email"
                    type="text"
                    name="email"
                    placeholder="Your Email"
                  >
                  <div class="form-error">
                    <span><strong v-html="registrationErrors.email" /></span>
                  </div>
                </div>
              </div>

              <div class="form-row">
                <div
                  id="birth"
                  class="legend"
                >
                  Birthday
                </div>

                <div class="form-1-3">
                  <label for="birth_year">
                    Year <span>* required</span>
                  </label>

                  <select
                    id="birth_year"
                    v-model="general.birth.year"
                    name="birth[year]"
                  >
                    <option
                      v-for="year in birthYears"
                      :key="year"
                      :value="year"
                    >
                      {{ year }}
                    </option>
                  </select>
                  <div
                    v-if="registrationErrors.birth && registrationErrors.birth.year"
                    class="form-error"
                  >
                    <span><strong>{{ registrationErrors.birth.year }}</strong></span>
                  </div>
                </div>

                <div class="form-1-3">
                  <label for="birth_month">
                    Month
                  </label>

                  <select
                    id="birth_month"
                    v-model="general.birth.month"
                    name="birth[month]"
                  >
                    <option value="01">
                      January
                    </option>
                    <option value="02">
                      February
                    </option>
                    <option value="03">
                      March
                    </option>
                    <option value="04">
                      April
                    </option>
                    <option value="05">
                      May
                    </option>
                    <option value="06">
                      June
                    </option>
                    <option value="07">
                      July
                    </option>
                    <option value="08">
                      August
                    </option>
                    <option value="09">
                      September
                    </option>
                    <option value="10">
                      October
                    </option>
                    <option value="11">
                      November
                    </option>
                    <option value="12">
                      December
                    </option>
                  </select>
                </div>

                <div class="form-1-3">
                  <label for="birth_day">
                    Day
                  </label>

                  <select
                    id="birth_day"
                    v-model="general.birth.day"
                    name="birth[day]"
                  >
                    <option value="01">
                      01
                    </option>
                    <option value="02">
                      02
                    </option>
                    <option value="03">
                      03
                    </option>
                    <option value="04">
                      04
                    </option>
                    <option value="05">
                      05
                    </option>
                    <option value="06">
                      06
                    </option>
                    <option value="07">
                      07
                    </option>
                    <option value="08">
                      08
                    </option>
                    <option value="09">
                      09
                    </option>
                    <option value="10">
                      10
                    </option>
                    <option value="11">
                      11
                    </option>
                    <option value="12">
                      12
                    </option>
                    <option value="13">
                      13
                    </option>
                    <option value="14">
                      14
                    </option>
                    <option value="15">
                      15
                    </option>
                    <option value="16">
                      16
                    </option>
                    <option value="17">
                      17
                    </option>
                    <option value="18">
                      18
                    </option>
                    <option value="19">
                      19
                    </option>
                    <option value="20">
                      20
                    </option>
                    <option value="21">
                      21
                    </option>
                    <option value="22">
                      22
                    </option>
                    <option value="23">
                      23
                    </option>
                    <option value="24">
                      24
                    </option>
                    <option value="25">
                      25
                    </option>
                    <option value="26">
                      26
                    </option>
                    <option value="27">
                      27
                    </option>
                    <option value="28">
                      28
                    </option>
                    <option value="29">
                      29
                    </option>
                    <option value="30">
                      30
                    </option>
                    <option value="31">
                      31
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-row">
                <div class="form-1-2">
                  <label for="ethnicity">Ethnicity</label>

                  <select
                    id="ethnicity"
                    v-model="general.ethnicity"
                    name="ethnicity"
                  >
                    <option
                      v-for="ethnicity in ethnicities"
                      :key="ethnicity.id"
                      :value="ethnicity.id"
                    >
                      {{ ethnicity.name }}
                    </option>
                  </select>
                </div>

                <div class="form-1-2">
                  <label for="gender">Gender</label>

                  <select
                    id="gender"
                    v-model="general.gender"
                    name="gender"
                  >
                    <option value="" />
                    <option
                      v-for="gender in genders"
                      :key="gender.id"
                      :value="gender.id"
                    >
                      {{ gender.name }}
                    </option>
                  </select>
                </div>
              </div>
              <h3>Terms of Service</h3>

              <div class="form-row">
                <div
                  v-if="registrationErrors.tos"
                  class="form-error"
                >
                  <span><strong>{{ registrationErrors.tos }}</strong></span>
                </div>

                <label
                  for="tos"
                  class="required"
                >
                  <input
                    id="tos"
                    v-model="tos"
                    type="checkbox"
                    name="tos"
                    value="yes"
                  > Please mark here that you have read and agree to the <a
                    href="https://mouse.org/terms-of-use"
                    target="_blank"
                  >Terms of Use</a> and <a
                    href="https://mouse.org/privacy-policy"
                    target="_blank"
                  >Privacy Policy</a>.
                </label>
              </div>

              <div
                v-if="!isGoogleRegistration"
                class="form-block"
              >
                <div
                  v-if="registrationErrors.recaptcha"
                  id="recaptcha"
                  class="form-error form-text-center"
                >
                  <span><strong>{{ registrationErrors.recaptcha }}</strong></span>
                </div>

                <div
                  id="captcha"
                  class="captcha form-row"
                >
                  <vue-recaptcha
                    :sitekey="captchaSiteKey"
                    :load-recaptcha-script="true"
                  />
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="form-1-1 register">
                <button
                  type="button"
                  class="button"
                  @click.stop.prevent="submit"
                >
                  Register <i
                    class="fa fa-chevron-right"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VueRecaptcha from 'vue-recaptcha';
import _ from 'lodash';

export default {
  name: 'Registration',
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      captchaSiteKey: process.env.MIX_CAPTCHA_SITEKEY,
      checkUsernameUrl: laroute.route('auth.check.username'),
      configUrl: laroute.route('api.config.index'),
      facilitator: {
        status: null,
      },
      error: null,
      ethnicities: [],
      ethnicityUrl: laroute.route('api.ethnicity.index'),
      formReady: false,
      // general variables
      general: {
        birth: {
          month: null,
          day: null,
          year: null,
        },
        email: null,
        ethnicity: null,
        first_name: null,
        gender: null,
        last_name: null,
        password: null,
        username: null,
      },
      genders: [],
      genderUrl: laroute.route('api.gender.index'),
      labels: {},
      registration: {
        type: null,
      },
      registrationErrors: {
        birth: {
          month: null,
          day: null,
          year: null,
        },
        email: null,
        ethnicity: null,
        first_name: null,
        gender: null,
        last_name: null,
        password: null,
        recaptcha: null,
        username: null,
      },
      tos: false,
      username: '',
      usernameError: '',
      usernameSuccess: '',
    };
  },
  computed: {
    ...mapGetters([
      'current',
    ]),

    isAdult() {
      return this.registration.type === 'adult';
    },
    isYouth() {
      return this.registration.type === 'youth';
    },

    // facilitator methods
    isFacilitator() {
      return this.facilitator.status === 'yes';
    },
    isNotFacilitator() {
      return this.facilitator.status === 'no';
    },
    isMaybeFacilitator() {
      return this.facilitator.status === 'maybe';
    },

    yearNow() {
      return new Date().getFullYear();
    },

    birthYearHigh() {
      return this.yearNow - 6;
    },

    birthYearLow() {
      return this.yearNow - 75;
    },

    birthYears() {
      const years = [];

      for (let i = this.birthYearLow; i <= this.birthYearHigh; i += 1) {
        years.push(i);
      }

      return years.reverse();
    },

    // general methods
    showGeneral() {
      return this.isFacilitator || this.isYouth;
    },

    isGoogleRegistration() {
      return this.current && this.current.oauth_provider_id;
    },

    currentUser() {
      return this.$store.state.users.current;
    },

    isRegistered() {
      return Boolean(this.currentUser && !_.isEmpty(this.currentUser.profile));
    },
  },
  watch: {
    current(user) {
      this.general.email = user.email;
    },
  },
  mounted() {
    this.formReady = true;
    this.getEthnicities();
    this.getGenders();
    this.getConfigs();

    document.body.className = 'js language-markup single-column';

    this.$store.dispatch('getMe');
  },
  methods: {
    checkUsername: _.debounce(function checkUsernameMethod() {
      this.usernameError = null;
      this.usernameSuccess = null;

      if (this.general.username.length <= 2) return;

      axios
        .post(this.checkUsernameUrl, {
          username: this.general.username,
        })
        .then((response) => {
          if (response.data.status) {
            this.usernameSuccess = 'This username is available!';
            this.usernameError = null;
          }
        }).catch((error) => {
          const [unError] = error.response.data.errors.username;
          this.usernameError = unError;
          this.usernameSuccess = null;
        });
    }, 250),
    resetForm() {
      this.$set(this.facilitator, 'status', null);
    },

    // get formatted birthday
    getBirthday() {
      return (
        `${this.general.birth.month
        }/${
          this.general.birth.day
        }/${
          this.general.birth.year}`
      );
    },

    getEthnicities() {
      axios.get(this.ethnicityUrl).then((response) => {
        this.ethnicities = response.data;
      });
    },

    getGenders() {
      axios.get(this.genderUrl).then((response) => {
        this.genders = response.data;
      });
    },

    getConfigs() {
      axios.get(this.configUrl).then((response) => {
        this.labels = response.data.labels;
      });
    },

    submit() {
      this.registrationErrors = {};

      const postdata = {
        birth: {
          month: this.general.birth.month,
          day: this.general.birth.day,
          year: this.general.birth.year,
        },
        email: this.general.email,
        ethnicity: this.general.ethnicity,
        first_name: this.general.first_name,
        gender: this.general.gender,
        last_name: this.general.last_name,
        password: this.general.password,
        password_confirmation: this.general.password_confirmation,
        recaptcha: $('#g-recaptcha-response').val(),
        registration_type: this.registration.type,
        tos: this.tos,
        username: this.general.username,
      };

      if (this.isGoogleRegistration) {
        postdata.id = this.current.id;
      }

      axios.post(laroute.route('auth.register'), postdata).then((response) => {
        if (response.data && response.data.errors) {
          this.registrationErrors = response.data.errors;
          // get first element that has an error so we can scroll to it
          const first_error = `#${Object.keys(this.registrationErrors)[0]}`;
          $('html,body').animate(
            { scrollTop: $(first_error).offset().top - 175 },
            750,
          );

          return;
        }

        const user = response.data;

        this.$store.dispatch('loginUser', user);

        axios
          .get(laroute.route('switchboard'))
          .then((res) => {
            if (res.data.groupId) {
              this.$store.dispatch(
                'setActiveGroup',
                res.data.groupId,
              );
            }

            this.$router.push(res.data.route).catch(() => {});
          });
      });
    },
  },
};
</script>
