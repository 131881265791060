<template>
  <div>
    <div class="tab-background">
      <div class="tab-container">
        <ul>
          <li
            v-for="nav in visibleTabs"
            :key="nav.title"
            class="button"
            :class="tabClass(nav)"
            @click.stop.prevent="setActiveTab(nav.component)"
          >
            <a
              href="#"
              tabindex="0"
              v-text="nav.title"
            />
          </li>
        </ul>
      </div>
    </div>

    <div class="tab-content">
      <component
        :is="activeComponent"
        :options="componentOptions"
      />
    </div>
    <div style="clear:both;" />
  </div>
</template>

<script>

import Playlist from '../groups/Playlist.vue';
import GroupWork from '../groups/GroupWork.vue';
import Badges from '../groups/Badges.vue';
import Members from '../groups/Members.vue';
import GroupEdit from '../groups/GroupEdit.vue';
import GroupNotifications from '../groups/GroupNotifications.vue';
import ManagePlaylist from '../groups/ManagePlaylist.vue';
import MembersArchived from '../groups/MembersArchived.vue';

import ProjectFinder from '../projects/ProjectFinder.vue';
import CourseModule from '../courses/CourseModule.vue';

import ProfileView from '../profile/ProfileView.vue';
import ProfileEdit from '../profile/ProfileEdit.vue';
import Works from '../work/Works.vue';

import ProjectSteps from '../projects/ProjectSteps.vue';
import ProjectPlan from '../projects/ProjectPlan.vue';
import ProjectAddWork from '../projects/ProjectAddWork.vue';

export default {
  name: 'Tabs',
  components: {
    Playlist,
    GroupWork,
    Badges,
    Members,
    MembersArchived,
    ProjectFinder,
    CourseModule,
    ProfileView,
    ProfileEdit,
    Works,
    ProjectSteps,
    ProjectPlan,
    ProjectAddWork,
    GroupEdit,
    GroupNotifications,
    ManagePlaylist,
  },
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    navTabs: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      activeComponent: null,
      currentNav: {},
      token: this.token,
      message: false,
      componentOptions: {
        token: this.options.token,
        groupId: this.options.groupId,
        user: this.options.user,
      },
    };
  },
  computed: {
    activeTab() {
      return _.find(this.navTabs, (tab) => tab.active);
    },
    visibleTabs() {
      return this.navTabs.filter((tab) => tab.visible);
    },
    currentUser() {
      return (this.$store.state.users.current) ? this.$store.state.users.current : null;
    },
  },
  watch: {},
  created() {
    bus.$on('set-active-tab', this.setActiveTab);
    bus.$on('tab-message', this.sendTabMessage);
    bus.$on('clear-alert-message', this.clearMessage);
  },
  mounted() {
    // activate initial tab component
    let active = null;

    _.each(this.navTabs, (tab) => {
      if (tab.active) {
        active = tab.component;
      }
    });
    this.setActiveTab(active);
  },
  methods: {
    setActiveTabItem() {
      _.each(this.navTabs, (tab) => {
        tab.active = false;
      });
      this.currentNav.active = true;
    },
    setActiveTab(component) {
      this.currentNav = _.find(this.navTabs, (tab) => tab.component === component);

      this.activeComponent = this.currentNav.component;
      this.setActiveTabItem();
    },
    setActiveTabComponent(params) {
      this.setActiveTab(params.component);
      $('body').scrollTop(0);
    },
    findComponent() {
      return _.find(this.navTabs, (tab) => tab.active);
    },
    tabClass(nav) {
      let className = (nav.active) ? 'active' : '';
      className = `${className} ${nav.component}-tab`;
      return className;
    },
    sendTabMessage(params) {
      this.message = params.message;
      setTimeout(() => {
        this.message = false;
      });
    },
    clearMessage() {
      this.message = false;
    },
  },
  events: {
    setActiveTabComponent(params) {
      this.setActiveTab(params.component);
      $('body').scrollTop(0);
    },
    updateNavTabs(params) {
      // TODO: Fix this or remove it depending on if it actually is being used or not
      // eslint-disable-next-line vue/no-mutating-props
      this.navTabs = params.tabs;
    },
  },
};
</script>
