var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "supporting__slider" }, [
    _c("h1", [_vm._v(_vm._s(_vm.sliderHeader))]),
    _vm._v(" "),
    _vm.loading ? _c("h4", [_vm._v("\n    Loading...\n  ")]) : _vm._e(),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "supporting__slider-wrapper" },
      [
        _c(
          "slick",
          { ref: "slick", attrs: { options: _vm.slickOptions } },
          _vm._l(_vm.slides, function (slide, index) {
            return _c("home-slide", { key: index, attrs: { slide: slide } })
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", { staticClass: "home__arrows home__prev" }),
      _vm._v(" "),
      _c("li", { staticClass: "home__arrows home__next" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }