<template>
  <div>
    <router-link :to="'/group/' + groupId"  aria-label="Group">{{ groupName }}</router-link>
  </div>
</template>

<script>
export default {
  name: 'GroupName',
  props: {
    groupId: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    groupName() {
      return (this.$store.state.groups && this.$store.state.groups.active) ? this.$store.state.groups.active.name : 'No active groups';
    },
  },
};
</script>
