var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "21px",
        height: "21px",
        viewBox: "-3 -3 21 21",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "close-X",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
            "stroke-linecap": "square",
          },
        },
        [
          _c("path", {
            attrs: {
              id: "Line-1",
              d: "M0.5,0.5 L14.6421356,14.6421356",
              stroke: "#028E6D",
              "stroke-width": "4",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              id: "Line-2",
              d: "M0.5,0.5 L14.6421356,14.6421356",
              stroke: "#028E6D",
              "stroke-width": "4",
              transform:
                "translate(7.500000, 7.500000) scale(-1, 1) translate(-7.500000, -7.500000) ",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }