<template>
  <div class="modal__callout">
    Accepted file types: pdf, docx, xlsx, pptx, doc, xls, ppt, txt, zip, webm
  </div>
</template>

<script>
export default {
  name: 'FileLabel',
};
</script>

<style scoped>

</style>
