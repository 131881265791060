<template>
  <div class="full-wrap">
    <div class="page-wrap">
      <main>
        <section class="primary-content">
          <div class="content-box">
            <img
              src="/img/oops.gif"
              alt=""
            >

            <p>Oh no! Looks like something went wrong. Email <a href="mailto:help@mouse.org">help@mouse.org</a> to let us know you found a bug. Tell us your username, what browser you are using and how you got to this error.</p>
            <p><a href="/">Take me back to the home page</a>.</p>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  components: {},
  props: {},
  data: () => ({

  }),
  computed: {},
  watch: {},
  mounted() {},
  created() {
    document.body.className = 'no-js language-markup single-column';
  },
  methods: {},
};
</script>
