<template>
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Make Manager Icon</title>
    <g
      id="Member-Table-Permissions"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Layout-F---Member-Table"
        transform="translate(-1057.000000, -504.000000)"
        fill="#00A886"
        fill-rule="nonzero"
      >
        <g
          id="Table"
          transform="translate(23.000000, 332.000000)"
        >
          <path
            id="Make-Manager-Icon"
            d="M1049.82706,174.916766 C1049.77328,174.737021 1049.60783,174.702979 1049.47438,174.836426 L1047.0703,177.241191 L1044.75813,174.929021 L1047.16289,172.524255 C1047.29566,172.391489 1047.2623,172.226043 1047.08187,172.172255 C1046.71285,172.061277 1046.32204,172 1045.91489,172 C1043.65923,172 1041.82979,173.829447 1041.82979,176.085106 C1041.82979,176.505191 1041.89379,176.910298 1042.01157,177.292255 L1037.39813,181.905702 C1037.28647,181.893447 1037.17821,181.87234 1037.06383,181.87234 C1035.37328,181.87234 1034,183.246298 1034,184.93617 C1034,186.626043 1035.37328,188 1037.06383,188 C1038.75438,188 1040.12766,186.626043 1040.12766,184.93617 C1040.12766,184.821787 1040.10655,184.713532 1040.0943,184.601872 L1044.70706,179.988426 C1045.0897,180.106213 1045.49481,180.170213 1045.91489,180.170213 C1048.17055,180.170213 1050,178.340766 1050,176.085106 C1050,175.678638 1049.93872,175.287149 1049.82706,174.916766 Z M1038.32699,185.738667 L1036.91554,186.117647 L1035.88235,185.084863 L1036.26125,183.673098 L1037.6727,183.294118 L1038.70588,184.326902 L1038.32699,185.738667 Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'WrenchIcon',
};
</script>

<style scoped>

</style>
