var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "58px",
        height: "68px",
        viewBox: "0 0 58 68",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("defs", [
        _c("polygon", {
          attrs: {
            id: "path-1",
            points: "0.2707 0.479474972 26.795 0.479474972 26.795 27 0.2707 27",
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Designs",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Work-Tab---no-evidence-yet-A",
                transform: "translate(-77.000000, -1008.000000)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Review-and-Submit---GRAY",
                    transform: "translate(47.000000, 987.000000)",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "mag-glass-icon",
                        transform: "translate(30.000000, 21.000000)",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          id: "Fill-1",
                          d: "M23.8822,45.2827 C17.4932,45.2837 12.3172,40.1047 12.3172,33.7177 C12.3162,27.3277 17.4912,22.1487 23.8802,22.1487 C30.2672,22.1477 35.4462,27.3257 35.4472,33.7147 C35.4482,40.1027 30.2692,45.2827 23.8822,45.2827 M23.8792,14.3047 C13.1612,14.3067 4.4722,22.9987 4.4742,33.7187 C4.4752,44.4377 13.1652,53.1287 23.8832,53.1277 C34.6042,53.1267 43.2912,44.4337 43.2902,33.7147 C43.2892,22.9947 34.6002,14.3037 23.8792,14.3047",
                          fill: "#00A886",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          id: "Fill-3",
                          d: "M23.8802,27.9047 L23.8812,33.7147 L18.0722,33.7157 C18.0722,36.9257 20.6742,39.5297 23.8812,39.5287 C27.0902,39.5287 29.6912,36.9247 29.6912,33.7147 C29.6902,30.5067 27.0892,27.9037 23.8802,27.9047",
                          fill: "#00A886",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          id: "Fill-5",
                          d: "M23.8783,4.9877 C22.9713,4.9867 22.2313,4.2507 22.2313,3.3407 C22.2313,2.4297 22.9703,1.6927 23.8783,1.6897 C24.7883,1.6927 25.5263,2.4277 25.5263,3.3407 C25.5263,4.2507 24.7903,4.9867 23.8783,4.9877 M27.2173,3.3407 C27.2173,1.4947 25.7233,-0.0003 23.8773,0.0007 C22.0323,0.0007 20.5403,1.4957 20.5403,3.3407 C20.5403,4.8917 21.5993,6.1927 23.0323,6.5667 L23.0333,14.9517 C22.0743,15.2997 21.3853,16.2097 21.3853,17.2877 C21.3853,18.6667 22.5033,19.7837 23.8793,19.7837 C25.2583,19.7837 26.3743,18.6677 26.3743,17.2877 C26.3743,16.2097 25.6853,15.2997 24.7253,14.9517 L24.7243,6.5687 C26.1593,6.1917 27.2173,4.8917 27.2173,3.3407",
                          fill: "#00A886",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          id: "Fill-7",
                          d: "M8.9789,8.0434 C8.0899,7.8464 7.5299,6.9674 7.7229,6.0794 C7.9209,5.1904 8.7989,4.6294 9.6879,4.8254 C10.5769,5.0214 11.1369,5.8994 10.9429,6.7894 C10.7459,7.6784 9.8679,8.2374 8.9789,8.0434 M18.8509,16.3634 C18.8519,15.9904 18.8539,13.0904 18.5909,10.9064 C18.4269,9.1534 17.2659,7.2494 14.7279,6.7334 L12.6669,6.2794 C12.5989,4.8074 11.5599,3.5044 10.0519,3.1724 C8.2509,2.7754 6.4689,3.9134 6.0719,5.7154 C5.6739,7.5174 6.8129,9.2994 8.6149,9.6964 C10.1379,10.0324 11.6439,9.2654 12.3139,7.9334 L14.3649,8.3854 C16.2279,8.8384 16.7069,9.8054 16.9129,11.1074 C17.1539,13.0804 17.1609,15.8404 17.1619,16.3154 C16.3529,16.5814 15.6999,17.2464 15.5029,18.1374 C15.2069,19.4834 16.0569,20.8144 17.4019,21.1104 C18.7469,21.4074 20.0779,20.5594 20.3749,19.2114 C20.6419,17.9984 19.9719,16.8044 18.8509,16.3634",
                          fill: "#00A886",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          id: "Fill-9",
                          d: "M11.0335,25.7475 C10.1215,25.7475 9.3875,25.0085 9.3855,24.1005 C9.3875,23.1875 10.1215,22.4515 11.0335,22.4485 C11.9455,22.4505 12.6815,23.1865 12.6825,24.0995 C12.6815,25.0085 11.9455,25.7475 11.0335,25.7475 M11.8105,20.8535 C11.8305,20.0485 11.8585,18.7635 11.8585,17.4945 C11.8645,16.1785 11.4605,15.1355 10.7215,14.4795 C9.9905,13.8235 9.0595,13.6185 8.1935,13.6185 L4.8875,13.6185 L4.8875,13.9675 C4.5965,12.9115 3.6405,12.1335 2.4945,12.1335 C1.1185,12.1335 0.0005,13.2515 0.0005,14.6295 C0.0005,16.0085 1.1175,17.1235 2.4945,17.1235 C3.6405,17.1235 4.5965,16.3445 4.8875,15.2905 L4.8875,15.3105 C4.8875,15.3095 5.2725,15.3095 5.8805,15.3105 C6.4875,15.3095 7.3145,15.3095 8.1945,15.3105 C8.8085,15.3105 9.2885,15.4585 9.5955,15.7345 C9.8955,16.0135 10.1605,16.4815 10.1675,17.4945 C10.1675,18.7855 10.1395,20.1015 10.1175,20.8885 C8.7205,21.2875 7.6945,22.5715 7.6945,24.1005 C7.6945,25.9425 9.1885,27.4375 11.0345,27.4375 C12.8795,27.4365 14.3735,25.9415 14.3735,24.0995 C14.3725,22.5205 13.2785,21.2035 11.8105,20.8535",
                          fill: "#00A886",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "Group-13",
                            transform: "translate(31.000000, 40.622800)",
                          },
                        },
                        [
                          _c(
                            "mask",
                            { attrs: { id: "mask-2", fill: "white" } },
                            [_c("use", { attrs: { "xlink:href": "#path-1" } })]
                          ),
                          _vm._v(" "),
                          _c("g", { attrs: { id: "Clip-12" } }),
                          _vm._v(" "),
                          _c("path", {
                            attrs: {
                              id: "Fill-11",
                              d: "M20.4242,23.4226 C19.6532,22.6516 19.6522,21.4006 20.4232,20.6276 C21.1952,19.8566 22.4452,19.8586 23.2172,20.6286 C23.9882,21.4006 23.9892,22.6506 23.2182,23.4216 C22.4462,24.1936 21.1952,24.1936 20.4242,23.4226 M3.8502,6.8516 C3.0772,6.0786 3.0772,4.8286 3.8492,4.0576 C4.6202,3.2856 5.8692,3.2846 6.6432,4.0586 C7.4132,4.8286 7.4142,6.0786 6.6432,6.8506 C5.8712,7.6216 4.6212,7.6216 3.8502,6.8516 M25.5212,19.5766 L7.6942,1.7526 C5.9952,0.0546 3.2422,0.0556 1.5442,1.7526 C-0.1538,3.4516 -0.1538,6.2056 1.5442,7.9026 L19.3712,25.7276 C21.0702,27.4246 23.8232,27.4246 25.5222,25.7256 C27.2202,24.0276 27.2192,21.2746 25.5212,19.5766",
                              fill: "#00A886",
                              mask: "url(#mask-2)",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }