var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "full-wrap" }, [
    _c("div", { staticClass: "page-wrap" }, [
      _c("main", [
        _c("section", { staticClass: "primary-content" }, [
          _c("div", { staticClass: "content-box" }, [_c("profile-edit")], 1),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }