var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "course__row course__bottom-border",
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeBadgeModal($event)
        },
      },
    },
    [
      _c("h2", { staticClass: "row__title" }, [_vm._v("\n    Badges\n  ")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "badge__items" },
        [
          _vm.badgeVisible
            ? _c("div", { staticClass: "badge__modal" }, [
                _c(
                  "a",
                  {
                    staticClass: "badge__exit",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.closeBadgeModal($event)
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          width: "20px",
                          height: "19px",
                          viewBox: "0 0 20 19",
                          xmlns: "http://www.w3.org/2000/svg",
                        },
                      },
                      [
                        _c("title", [_vm._v("X")]),
                        _vm._v(" "),
                        _c("desc", [_vm._v("Created with Sketch.")]),
                        _vm._v(" "),
                        _c(
                          "g",
                          {
                            attrs: {
                              id: "Page-1",
                              stroke: "none",
                              "stroke-width": "1",
                              fill: "none",
                              "fill-rule": "evenodd",
                              "stroke-linecap": "square",
                            },
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  id: "Courses-Page---Open-v2",
                                  transform:
                                    "translate(-926.000000, -1288.000000)",
                                  "fill-rule": "nonzero",
                                  stroke: "#00A886",
                                  "stroke-width": "3.2",
                                },
                              },
                              [
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      id: "Badge-info-Modal-/-drawer?",
                                      transform:
                                        "translate(103.000000, 1267.000000)",
                                    },
                                  },
                                  [
                                    _c(
                                      "g",
                                      {
                                        attrs: {
                                          id: "X",
                                          transform:
                                            "translate(833.142136, 30.142136) rotate(-315.000000) translate(-833.142136, -30.142136) translate(823.142136, 20.142136)",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            id: "Line-2",
                                            d: "M0.434782609,10 L20,10",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("path", {
                                          attrs: {
                                            id: "Line-2-Copy",
                                            d: "M0.217391304,10.2173913 L19.7826087,10.2173913",
                                            transform:
                                              "translate(10.000000, 10.217391) rotate(-270.000000) translate(-10.000000, -10.217391) ",
                                          },
                                        }),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "badge__modal-body" }, [
                  _c("div", { staticClass: "badge__modal-image" }, [
                    _c("img", {
                      attrs: {
                        src: _vm.activeBadge.image,
                        alt: _vm.activeBadge.label,
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "badge__modal-content" }, [
                    _c("h2", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.activeBadge.label) +
                          "\n            "
                      ),
                      _vm.activeBadge.shortDescription
                        ? _c("span", { staticClass: "sub-heading" }, [
                            _vm._v(_vm._s(_vm.activeBadge.shortDescription)),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(_vm.activeBadge.description),
                      },
                    }),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.badges, function (badge) {
            return _c("badge-item", { key: badge.id, attrs: { badge: badge } })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "clearfix" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }