<template>
  <div
    :id="commentElId"
    class="comment-thread"
    :class="commentClass"
  >
    <div class="comment-thread__user-avatar">
      <a
        tabindex="-1"
        :href="comment.user.links.profile"
        target="_blank"
        v-html="comment.user.avatar"
      />

      <div
        v-if="comment.type.id"
        class="comment-thread__type"
      >
        <svg
          v-if="commentType === 'love'"
          width="40px"
          height="40px"
          viewBox="0 0 40 40"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby="navHeartOnTitle navHeartOnDesc"
        >
          <title id="navHeartOnTitle">Heart Selected</title><desc id="navHeartOnDesc">A Heart</desc>
          <desc>Created with Sketch.</desc>
          <defs />
          <g
            id="Symbols"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g id="comment-icons-ON">
              <g id="Star--SELECTED-Copy">
                <g>
                  <circle
                    id="star-Oval-5"
                    fill="#2C328C"
                    cx="20"
                    cy="20"
                    r="20"
                  />
                  <path
                    id="Fill-1"
                    d="M23.2111061,29.7771814 L27.27883,31.9081167 C27.8534854,32.2092536 28.5249588,31.7226445 28.4150493,31.0854182 L27.0329259,23.0525698 C26.9887667,22.7994318 27.073568,22.5414258 27.2577274,22.3618925 L33.114093,16.6731996 C33.5793274,16.2215429 33.3228721,15.4347705 32.6805125,15.341791 L24.58727,14.1699566 C24.3322801,14.1334463 24.1114842,13.9733852 23.9975692,13.7433217 L20.3785689,6.43512966 C20.0909481,5.85495678 19.2608144,5.85495678 18.9737798,6.43512966 L15.354291,13.7433217 C15.2402783,13.973872 15.0194824,14.1334463 14.7645902,14.1704434 L6.6713477,15.3422778 C6.0288904,15.4353547 5.77243505,16.2220298 6.23766949,16.6736864 L12.0935466,22.3623793 C12.2782922,22.5414258 12.3626049,22.800016 12.3189343,23.0530566 L10.9362247,31.0854182 C10.8263152,31.7231313 11.4983748,32.2097404 12.0730302,31.9086035 L16.1411449,29.777084 L17.5961504,29.0148465 L19.3115192,28.1160118 C19.5393493,27.9965501 19.8119247,27.9965501 20.0402432,28.1160118 L21.7557097,29.0147491 L23.2111061,29.7771814 Z"
                    fill="#FFFFFF"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
        <svg
          v-if="commentType === 'question'"
          width="41px"
          height="40px"
          viewBox="0 0 41 40"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby="navQuestionOnTitle navQuestionOnDesc"
        >
          <title id="navQuestionOnTitle">Question Mark Selected</title><desc id="navQuestionOnDesc">A Question Mark</desc>
          <g
            id="Symbols"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="comment-icons-ON"
              transform="translate(-48.000000, 0.000000)"
            >
              <g id="Q-SELECTED-Copy">
                <g transform="translate(48.000000, 0.000000)">
                  <circle
                    id="question-Oval-5"
                    fill="#017362"
                    cx="20.5"
                    cy="20"
                    r="20"
                  />
                  <path
                    id="question"
                    d="M21.6934245,20.567581 C21.6614006,20.567581 21.5813408,20.6213826 21.5813408,20.6687919 L21.5813408,21.6329584 C21.5370411,22.3057442 20.9547396,22.8144619 20.2811701,22.7697161 C19.6695134,22.7297644 19.1827499,22.2434196 19.1421863,21.6329584 L19.1421863,20.6741187 C19.1544621,19.5757147 19.8675279,18.6067539 20.914176,18.2668985 C21.6293767,18.0266559 22.1027968,17.3469452 22.0777115,16.5937234 C22.0296756,15.6743027 21.2760462,14.9498461 20.3537576,14.9375942 C19.7010035,14.941323 19.1074937,15.3184666 18.8272845,15.9065549 C18.5385356,16.5159508 17.8099916,16.7759029 17.1994024,16.4871856 C16.5893469,16.199001 16.3288857,15.471881 16.6176346,14.8630178 C16.6213708,14.8555602 16.6245732,14.8486352 16.6283093,14.8411776 L16.6336466,14.8683447 C17.3205595,13.4316833 18.7749788,12.5191875 20.3697695,12.5239817 C22.5991675,12.5394297 24.4255979,14.296237 24.5222033,16.5191469 C24.5819813,18.3452038 23.4307217,19.9928099 21.6934245,20.567581 M20.3751069,26.4543237 L20.3537576,26.4543237 C19.6759181,26.4543237 19.1261743,25.9056544 19.1261743,25.2286071 C19.1261743,24.5520924 19.6759181,24.0034231 20.3537576,24.0034231 C21.031597,24.0034231 21.5813408,24.5520924 21.5813408,25.2286071 C21.5813408,25.8971314 21.0449403,26.4420719 20.3751069,26.4543237 M20.502135,7 C13.5983136,6.99893462 8.00106768,12.5836429 8.00000022,19.4734384 C7.99946649,22.3259864 8.9783307,25.0922388 10.7732709,27.3114199 C10.4071309,28.5467249 9.80935118,29.7021267 9.0119558,30.7158333 C8.74295495,31.0658098 8.81020516,31.5670698 9.16140072,31.8355449 C9.31084564,31.950073 9.49605059,32.0081361 9.68445793,31.9990804 C11.5824084,31.9591287 13.4536722,31.5409681 15.1872333,30.7685695 C16.8514092,31.5505565 18.6687661,31.9543345 20.5085398,31.9511384 C27.407024,31.9463442 32.9989325,26.3642994 33,19.4776999 C33.0010675,12.5879044 27.4054228,7.00106538 20.502135,7"
                    fill="#FFFFFF"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
        <svg
          v-if="commentType === 'intrigued'"
          width="41px"
          height="40px"
          viewBox="0 0 41 40"
          xmlns="http://www.w3.org/2000/svg"
          aria-labelledby="navBulbOnTitle navBulbOnDesc"
        >
          <title id="navBulbOnTitle">Light Bulb Selected</title><desc id="navBulbOnDesc">A Light Bulb</desc>
          <g
            id="Symbols"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
          >
            <g
              id="comment-icons-ON"
              transform="translate(-96.000000, 0.000000)"
            >
              <g id="lighbulb-SELECTED-Copy-2">
                <g transform="translate(96.000000, 0.000000)">
                  <path
                    id="bulb-Oval-5"
                    d="M20.5,40 C31.545695,40 40.5,31.045695 40.5,20 C40.5,8.954305 31.545695,0 20.5,0 C9.454305,0 0.5,8.954305 0.5,20 C0.5,31.045695 9.454305,40 20.5,40 Z"
                    fill="#FF3333"
                  />
                  <path
                    id="bulb"
                    d="M27.0911502,22.7444984 L27.0875716,22.7444984 C27.0947289,22.7369461 27.1042719,22.7322985 27.1114292,22.7247461 C27.113815,22.7235842 27.1150079,22.7212604 27.1167972,22.7200985 C28.7182413,21.1590872 29.7083333,19.0049265 29.7083333,16.6241954 C29.7083333,11.8615714 25.74379,8 20.8541667,8 C15.9639469,8 12,11.8615714 12,16.6241954 C12,19.0182883 13.0032138,21.1817442 14.6207618,22.7444984 L14.6177796,22.7444984 C14.6177796,22.7444984 15.6478332,24.3699951 15.9752793,25.2420003 C16.4112776,26.4027374 16.5585986,27.5448842 16.4762897,28.5295936 L16.5120762,28.6417168 L16.5120762,30.307299 C16.5120762,30.860363 16.9725286,31.308275 17.539744,31.308275 L17.9023802,31.308275 C17.9023802,32.3226129 19.2908948,33 20.5058451,33 C21.7225848,33 23.1099065,32.3226129 23.1099065,31.308275 L23.4725427,31.308275 C24.0403545,31.308275 24.4984212,30.860363 24.4984212,30.307299 L24.4984212,28.6417168 L24.5067714,28.6237074 C24.5043856,27.7557688 24.6695998,26.7925546 25.0352181,25.8188832 C25.5242997,24.5175563 26.2710438,23.451514 27.0911502,22.7444984"
                    fill="#FFFFFF"
                  />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>

    <div
      v-if="isDeleting"
      class="comment-thread__delete-confirmation"
    >
      <div>Are you sure you want to delete this comment?</div>
      <div>
        <a
          href="#"
          class="confirm"
          @click.stop.prevent="deleteComment"
        >Yes</a>
        <a
          href="#"
          class=""
          @click.stop.prevent="toggleDelete"
        >No</a>
      </div>
    </div>

    <div class="comment-thread__body">
      <transition name="fade">
        <div
          v-if="commentSaved"
          class="comment-thread__saved-notice animated"
        >
          <i class="fa fa-check" /> Updated!
        </div>
      </transition>

      <div
        v-if="!isEditing"
        class="comment-thread__content"
      >
        <a
          class="comment-thread__user-link"
          :href="comment.user.links.profile"
          target="_blank"
        >{{ comment.user.username }}</a> <span
          v-if="isCreator(comment.user)"
          class="comment-thread__creator-label"
        >Creator</span>
        <span
          class="comment-thread__text"
          v-html="currentComment"
        />
        <a
          v-if="comment.manageable && currentUser.isInActiveGroup"
          href="#"
          class="comment-thread__manage-action"
          @click.stop.prevent="toggleEdit"
        >
          Edit
        </a>
        <a
          v-if="comment.manageable && !isDeleting"
          href="#"
          class="comment-thread__manage-action"
          @click.stop.prevent="toggleDelete"
        >
          Delete
        </a>
        <span class="comment-thread__time">{{ comment.createdAt | timeago }}</span>
      </div>

      <div
        v-if="isEditing"
        class="comment-thread__content"
      >
        <comment-form
          :on-submit="updateComment"
          :comment="comment"
          :owner="currentUser"
          :with-actions="false"
        >
          :replaceHolder="placeholder"
        </comment-form>
      </div>

      <div
        v-if="repliesEnabled"
        class="comment-thread__actions"
      >
        <span>
          <a
            v-if="!moreRepliesAvailable"
            href="#"
            @click.prevent.stop="toggleReplies"
          >
            <span v-if="!comment.replyCount && !repliesVisible && currentUser.isInActiveGroup">Reply</span>
            <span v-if="comment.replyCount && !repliesVisible">View {{ comment.replyCount }} {{ comment.replyCount > 1 ? 'replies' : 'reply' }}</span>
            <span v-if="repliesVisible">Hide Replies</span>
          </a>
          <a
            v-if="moreRepliesAvailable"
            href="#"
            @click.prevent.stop="loadNextPage"
          >
            <span>Load More</span>
          </a>
        </span>
        <span v-if="replies && comment.replyCount && repliesVisible">{{ replies.data.length }} of {{ comment.replyCount }}</span>
      </div>

      <div
        v-if="repliesVisible && repliesEnabled"
        class="comment-thread__replies"
      >
        <CommentThread
          v-for="reply in replies.data"
          :key="reply.id"
          :on-delete="onReplyDeleted"
          :cursor-id="cursorId"
          :comment="reply"
          :creators="creators"
          :replies-enabled="false"
          :current-user="currentUser"
        />

        <a
          v-if="hasPreviousReplies"
          href="#"
          @click.prevent.stop="loadPrevious"
        >
          <span>Load More</span>
        </a>

        <div
          v-if="currentUser.isInActiveGroup"
          class="comment-thread__reply-form"
        >
          <div
            v-if="currentUser"
            class="comment-thread__user-avatar"
          >
            <div v-html="currentUser.avatar" />
          </div>
          <comment-form
            :on-submit="addReply"
            replace-holder="Add a reply..."
            :owner="currentUser"
            :with-actions="false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import resourceMixin from '../../mixins/resource';
import CommentForm from './CommentForm.vue';

export default {
  name: 'CommentThread',
  components: {
    CommentForm,
  },
  mixins: [resourceMixin],
  props: {
    comment: {
      type: Object,
      required: true,
    },
    cursorId: {
      type: Number,
      default: 0,
    },
    currentUser: {
      type: Object,
      required: true,
    },
    onDelete: {
      type: Function,
      required: true,
    },
    owner: {
      type: Object,
      default() {
        return null;
      },
    },
    repliesEnabled: {
      type: Boolean,
      default: true,
    },
    creators: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      isEditing: false,
      isDeleting: false,
      repliesVisible: false,
      commentSaved: false,
      previousPage: false,
      page: 0,
      replies: false,
      currentComment: '',
      placeholder: 'Add a reply...',
    };
  },
  computed: {
    commentElId() {
      return `comment-${this.comment.id}`;
    },
    commentReplyRoute() {
      return laroute.route('comments.index.for-type', {
        type: 'comment',
        id: this.comment.id,
      });
    },
    commentClass() {
      return {
        'comment-thread--is-deleting': this.isDeleting,
        'comment-thread--was-saved': this.commentSaved,
        'comment-thread--highlight': this.cursorId === this.comment.id,
      };
    },
    commentTypeClass() {
      const typeClass = {};
      typeClass[`comment-thread__type--${this.comment.type.value.toLowerCase()}`] = true;
      return typeClass;
    },
    commentType() {
      return this.comment.type.value.toLowerCase();
    },
    moreRepliesAvailable() {
      if (!this.replies) {
        return false;
      }
      return this.replies.data.length < this.replies.meta.pagination.total;
    },
    hasPreviousReplies() {
      if (!this.replies) {
        return false;
      }
      return this.previousPage > this.replies.meta.pagination.total_pages;
    },
  },
  watch: {},
  mounted() {
    if (this.comment.isCursor) {
      this.showReplies();
    }
    this.currentComment = this.comment.body;
  },
  methods: {
    isCreator(user) {
      return _.findIndex(this.creators, { id: user.id }) > -1;
    },
    toggleEdit() {
      this.isEditing = !this.isEditing;
    },
    commentRoute(commentId) {
      return laroute.route('comment.show', {
        id: commentId,
      });
    },
    deleteComment() {
      const $this = this;
      return axios.delete(this.commentRoute(this.comment.id)).then(() => {
        $this.onDelete($this.comment);
      });
    },
    getReplyIndex(reply) {
      return _.findIndex(this.replies.data, (item) => item.id === reply.id);
    },
    toggleReplies() {
      if (!this.replies) {
        this.fetchReplies();
      }
      this.repliesVisible = !this.repliesVisible;
    },
    showReplies() {
      if (!this.replies && this.repliesEnabled) {
        this.fetchReplies(false, true);
      }
      this.repliesVisible = true;
    },
    hideReplies() {
      this.repliesVisible = false;
    },
    onReplyDeleted(reply) {
      const index = this.getReplyIndex(reply);

      // Remove comment from list
      this.replies.data.splice(index, 1);

      if (this.replies.data.length === 0) {
        return;
      }

      // Decrement counts
      this.$set(this.replies.meta.pagination, 'total', this.replies.meta.pagination.total - 1);
      // TODO: Fix this and determine if neccessary
      // eslint-disable-next-line vue/no-mutating-props
      this.comment.replyCount -= 1;

      bus.$emit('decrement-count-with-replies');

      // If we've deleted all the active comments and have some left in pagination, retrieve those.
      if (!this.replies.data.length && this.replies.meta.pagination.total) {
        this.page = Math.max(this.page - 1, 0);
        this.loadNextPage();
      }
    },
    addReply(reply) {
      const request = axios.post(this.commentReplyRoute, reply);

      request.then((response) => {
        // TODO: determine if this change is even needed, and fix this
        // eslint-disable-next-line vue/no-mutating-props
        this.comment.replyCount += 1;
        this.replies.data.push(response.data);
        this.replies.meta.pagination.total += 1;
        bus.$emit('increment-count-with-replies');
      });

      return request;
    },
    updateComment(comment) {
      const request = axios.put(this.commentRoute(comment.id), comment);

      request.then((response) => {
        this.isEditing = false;
        this.showCommentUpdated();
        this.currentComment = response.data.body;
      });

      return request;
    },
    fetchReplies(loadingNext, useCursor) {
      const params = {
        perPage: 5,
      };
      if (this.page >= 1) {
        params.page = this.page;
      }
      if (this.cursorId > 0 && !loadingNext && useCursor) {
        params.commentId = this.cursorId;
      }

      axios.get(this.commentReplyRoute, { params }).then((response) => {
        if (this.page >= 1) {
          this.replies.data = _.concat(response.data.data, this.replies.data);
          this.replies.meta = response.data.meta;
        } else {
          this.replies = response.data;

          // Check if we had a cursor comment supplied
          if (this.replies.meta.pagination.current_page !== 1) {
            this.page = this.replies.meta.pagination.current_page;
            this.previousPage = this.page - 1;
          }
        }

        this.replyCount = this.replies.meta.pagination.total;

        // Scroll to cursor if available
        if (params.commentId) {
          this.$nextTick(() => {
            const commentEl = $(`#comment-${params.commentId}`);
            if (!commentEl.length) {
              return;
            }
            const parentEl = commentEl.parent().closest('.comment-thread');
            const vueModal = $('.vue-modal');
            vueModal.scrollTop(vueModal.scrollTop() + parentEl.position().top + commentEl.position().top);
          });
        }
      });
    },
    loadPrevious() {
      if (!this.previousPage || this.previousPage < 1) {
        return;
      }
      axios.get(this.commentReplyRoute, {
        params: {
          page: this.previousPage,
          perPage: 5,
        },
      }).then((response) => {
        this.replies.data = _.concat(this.replies.data, response.data.data);
        this.previousPage += 1;
      });
    },
    loadNextPage() {
      this.page -= 1;
      this.fetchReplies(true);
    },
    showCommentUpdated() {
      const $this = this;
      $this.commentSaved = true;
      setTimeout(() => {
        $this.commentSaved = false;
      }, 2000);
    },
    toggleDelete() {
      this.isDeleting = !this.isDeleting;
    },
  },
};
</script>
