var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoggedIn && _vm.isRegistered
    ? _c("nav", { staticClass: "menu-actions", class: _vm.navCls }, [
        _c(
          "ul",
          { attrs: { role: "menu" } },
          [
            _vm.hasDefaultGroup &&
            !_vm.navMinified &&
            _vm.currentUser &&
            _vm.currentUser.hasAnyLicensedSites
              ? [
                  _c(
                    "li",
                    { staticClass: "primary group" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: "/group/" + _vm.currentUser.defaultGroupId,
                            "aria-label": "Group",
                          },
                        },
                        [_c("NavGroupIcon")],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    { staticClass: "primary projects" },
                    [
                      _c(
                        "router-link",
                        {
                          attrs: { "aria-label": "projects", to: "/projects" },
                        },
                        [_c("PaperAirplaneIcon")],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.isRegistered
              ? _c(
                  "li",
                  { staticClass: "primary profile" },
                  [_c("ProfileImage")],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.currentUser.hasAnyLicensedSites &&
            _vm.hasDefaultGroup &&
            !_vm.navMinified
              ? _c(
                  "li",
                  {
                    staticClass: "primary menu-notifications",
                    attrs: { id: "notification-menu" },
                  },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: { "aria-label": "Inbox", to: "/notifications" },
                      },
                      [
                        _c("NavNotificationIcon"),
                        _vm._v(" "),
                        _vm.notificationCount
                          ? _c("span", { staticClass: "notification-count" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.notificationCount) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("li", { staticClass: "primary main-dropdown no-group" }, [
              _c("div", { staticClass: "dropdown" }, [
                _c("div", { staticClass: "dropdown-container" }, [
                  _c(
                    "div",
                    {
                      staticClass: "dropdown-button",
                      attrs: { id: "menu-dropdown", tabindex: "0" },
                      on: { click: _vm.toggleMenu },
                    },
                    [_c("MenuIcon")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isToggled,
                          expression: "isToggled",
                        },
                      ],
                      staticClass: "dropdown-menu dropdown-select",
                    },
                    [
                      _vm.canSeeMenu
                        ? [
                            _vm._m(0),
                            _vm._v(" "),
                            _vm._m(1),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: "/group/switch" } },
                                  [
                                    _vm._v(
                                      "\n                  My Groups\n                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.logoutUser($event)
                              },
                            },
                          },
                          [_vm._v("Logout")]
                        ),
                      ]),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "https://tools.mouse.org",
            tabindex: "0",
            target: "_blank",
          },
        },
        [_vm._v("Help")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        { attrs: { href: "https://mouse.org/news/wire", target: "_blank" } },
        [_vm._v("The Wire")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }