var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    !_vm.isLoading && _vm.project.hasPackAccess
      ? _c(
          "div",
          {
            staticClass: "evidence-prompt-content",
            class: { "project__add-work": !_vm.hideExtra },
          },
          [
            _c("div", { staticClass: "prompt__response" }, [
              _vm.prompts.length
                ? _c(
                    "div",
                    { staticClass: "evidence__prompts" },
                    [
                      _c("div", { staticClass: "highlight__title" }, [
                        _c("h2", [_vm._v("Requirements")]),
                        _vm._v(" "),
                        !_vm.hideExtra
                          ? _c("span", [
                              _vm._v(
                                "These required tasks should be reflected in your project work."
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.prompts, function (prompt) {
                        return _c(
                          "div",
                          { key: prompt.stepId, staticClass: "prompt__row" },
                          [
                            !_vm.hideExtra
                              ? _c("div", { staticClass: "prompt__step" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "button button-step",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.promptStep(prompt.order)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              Step " +
                                          _vm._s(prompt.order) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", {
                              staticClass: "prompt__description",
                              domProps: { innerHTML: _vm._s(prompt.text) },
                            }),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.canSubmitEvidence
                ? _c("div", { staticClass: "evidence__response" }, [
                    _c("div", { staticClass: "highlight__title" }, [
                      _vm.hasWork && !_vm.hideExtra
                        ? _c("h2", [
                            _vm._v(
                              "\n            Your Project Work\n          "
                            ),
                          ])
                        : _c("h2", [
                            _vm._v(
                              "\n            Add Your Response\n          "
                            ),
                          ]),
                      _vm._v(" "),
                      _vm.isAddWork && !_vm.hideExtra
                        ? _c("span", [
                            _vm._v(
                              "\n            Add text, images or file attachments before submitting work.\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "evidence__buttons" }, [
                      _c(
                        "a",
                        {
                          staticClass: "button step__button",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.evidenceModal("text")
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "step__button-text" }, [
                            _vm._v("Text"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "step__button-icon" },
                            [_c("text-icon")],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "button step__button",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.evidenceModal("image")
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "step__button-text" }, [
                            _vm._v("Image"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "step__button-icon" },
                            [_c("image-icon")],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "button step__button",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.evidenceModal("file")
                            },
                          },
                        },
                        [
                          _c("span", { staticClass: "step__button-text" }, [
                            _vm._v("File"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "step__button-icon" },
                            [_c("file-icon")],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            !_vm.assets || _vm.assets.length < 1
              ? _c(
                  "div",
                  { staticClass: "project__assets-none" },
                  [
                    !_vm.forStep
                      ? [
                          _c(
                            "div",
                            { staticClass: "no-asset-icon" },
                            [_c("no-asset-icon")],
                            1
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "no-asset-text" }, [
                            _vm._v(
                              "You have not shared any work yet. Show us what you got!"
                            ),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                )
              : _c(
                  "div",
                  {
                    class: {
                      evidence__assets: !_vm.forStep,
                      "step-footer": !!_vm.forStep,
                    },
                  },
                  [
                    _c("project-evidences", {
                      attrs: {
                        assets: _vm.assets,
                        "evidence-id": _vm.evidenceId,
                        "scoped-step": _vm.forStep ? _vm.forStep.stepId : false,
                      },
                    }),
                  ],
                  1
                ),
            _vm._v(" "),
            !_vm.hideExtra
              ? _c("project-collaborators", {
                  attrs: { "evidence-id": _vm.evidenceId },
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.hideExtra
              ? _c("div", { staticClass: "evidence-submit" }, [
                  _c("div", { staticClass: "review-title" }, [
                    _c(
                      "div",
                      { staticClass: "review-icon" },
                      [_c("review-icon")],
                      1
                    ),
                    _vm._v(" "),
                    _c("h2", [_vm._v("Review")]),
                    _vm._v(" "),
                    _vm.assets && _vm.assets.length > 0
                      ? _c("span", [_vm._v("Is your project complete?")])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.assets || _vm.assets.length < 1
                      ? _c("span", [
                          _vm._v("You "),
                          _c("strong", [_vm._v("must add work")]),
                          _vm._v(" to submit your project."),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button__submit-work",
                      attrs: { disabled: !_vm.assets || _vm.assets.length < 1 },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.submitModal($event)
                        },
                      },
                    },
                    [_vm._v("\n        Yes! Submit My Work\n      ")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "clearfix" }),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading && !_vm.project.hasPackAccess,
            expression: "!isLoading && !project.hasPackAccess",
          },
        ],
      },
      [_c("p", [_vm._v("You do not have access to this project")])]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "modal modal__global" }, [
      _c("label", { attrs: { for: "evidenceModal" } }),
      _vm._v(" "),
      _c("input", {
        staticClass: "modal-state",
        attrs: { id: "evidenceModal", type: "checkbox" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "modal-fade-screen" }, [
        _c("div", { staticClass: "modal-inner" }, [
          _c("div", { staticClass: "modal__header" }, [
            _c(
              "button",
              {
                staticClass: "modal__close",
                attrs: {
                  id: "close-button-1",
                  for: "evidenceModal",
                  "aria-label": "Close this dialog window",
                },
                on: { click: _vm.closeEvidenceModal },
              },
              [_c("close-modal-icon")],
              1
            ),
            _vm._v(" "),
            _vm.currentType.name === "text"
              ? _c("h2", [_vm._v("\n            Add Text\n          ")])
              : _vm._e(),
            _vm._v(" "),
            _vm.currentType.name === "image"
              ? _c("h2", [_vm._v("\n            Upload Image\n          ")])
              : _vm._e(),
            _vm._v(" "),
            _vm.currentType.name === "file"
              ? _c("h2", [_vm._v("\n            Upload File\n          ")])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "modal__content",
              class: { "modal__content-text": _vm.currentType.name === "text" },
            },
            [
              _c("upload-manager", {
                attrs: {
                  type: _vm.currentType.value,
                  "error-clear-fn": function () {
                    return _vm.errors.clear()
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (umProps) {
                      return [
                        _c("form", [
                          _vm.currentType.name === "text"
                            ? _c("div", [
                                _c("label", {
                                  attrs: { for: "textarea-content" },
                                }),
                                _vm._v(" "),
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.asset.content,
                                      expression: "asset.content",
                                    },
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: "required|min:10",
                                      expression: "'required|min:10'",
                                    },
                                  ],
                                  class: {
                                    "input-error":
                                      _vm.errors.has("content_text"),
                                  },
                                  attrs: {
                                    id: "textarea-content",
                                    type: "text",
                                    name: "content_text",
                                    placeholder: "Your text here",
                                  },
                                  domProps: { value: _vm.asset.content },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.asset,
                                        "content",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("content_text"),
                                        expression:
                                          "errors.has('content_text')",
                                      },
                                    ],
                                    staticClass: "form-error",
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.errors.first("content_text")
                                        ) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.currentType.name === "image"
                            ? _c("div", { staticClass: "modal__file-input" }, [
                                _c(
                                  "label",
                                  [
                                    _c("image-label"),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value:
                                            "ext:jpeg,jpg,bmp,png,gif,heic",
                                          expression:
                                            "'ext:jpeg,jpg,bmp,png,gif,heic'",
                                        },
                                      ],
                                      ref: "fileInputRef",
                                      class: {
                                        "input-error":
                                          _vm.errors.has("content_image") ||
                                          _vm.errors.has("filesize"),
                                      },
                                      attrs: {
                                        type: "file",
                                        name: "content_image",
                                      },
                                      on: { change: umProps.handleFileChange },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("filesize"),
                                        expression: "errors.has('filesize')",
                                      },
                                    ],
                                    staticClass: "form-error",
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.errors.first("filesize")) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("content_image"),
                                        expression:
                                          "errors.has('content_image')",
                                      },
                                    ],
                                    staticClass: "form-error",
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.errors.first("content_image")
                                        ) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                umProps.upload.active &&
                                !_vm.errors.has("filesize")
                                  ? _c(
                                      "div",
                                      { staticClass: "asset-processing" },
                                      [
                                        _vm._v(
                                          "\n                    Uploading file, please wait..." +
                                            _vm._s(umProps.upload.percent) +
                                            "%\n                  "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("hr"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.currentType.name === "file"
                            ? _c("div", { staticClass: "modal__file-input" }, [
                                _c(
                                  "label",
                                  [
                                    _c("file-label"),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value:
                                            "required|ext:pdf,docx,xlsx,pptx,doc,xls,ppt,txt,zip,webm",
                                          expression:
                                            "'required|ext:pdf,docx,xlsx,pptx,doc,xls,ppt,txt,zip,webm'",
                                        },
                                      ],
                                      ref: "fileInputRef",
                                      class: {
                                        "input-error":
                                          _vm.errors.has("content_file") ||
                                          _vm.errors.has("filesize"),
                                      },
                                      attrs: {
                                        type: "file",
                                        name: "content_file",
                                      },
                                      on: { change: umProps.handleFileChange },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("filesize"),
                                        expression: "errors.has('filesize')",
                                      },
                                    ],
                                    staticClass: "form-error",
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(_vm.errors.first("filesize")) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.errors.has("content_file"),
                                        expression:
                                          "errors.has('content_file')",
                                      },
                                    ],
                                    staticClass: "form-error",
                                  },
                                  [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.errors.first("content_file")
                                        ) +
                                        "\n                  "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                umProps.upload.active &&
                                !_vm.errors.has("filesize")
                                  ? _c(
                                      "div",
                                      { staticClass: "asset-processing" },
                                      [
                                        _vm._v(
                                          "\n                    Uploading file, please wait..." +
                                            _vm._s(umProps.upload.percent) +
                                            "%\n                  "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("hr"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "clearfix" }),
                          _vm._v(" "),
                          _c("div", { staticClass: "modal__actions" }, [
                            _c("span", { staticClass: "action__proceed" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "button modal__button",
                                  attrs: {
                                    type: "button",
                                    for: "evidenceModal",
                                    disabled: !umProps.isValid
                                      ? true
                                      : umProps.upload.active ||
                                        _vm.errors.items.length > 0,
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return (function () {
                                        return _vm.sendEvidence(
                                          umProps.handleUpload
                                        )
                                      })($event)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                      Save\n                    "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("div", { staticClass: "clearfix" }),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal modal__global" }, [
      _c("label", { attrs: { for: "submit-work-modal" } }),
      _vm._v(" "),
      _c("input", {
        staticClass: "modal-state",
        attrs: { id: "submit-work-modal", type: "checkbox" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "modal-fade-screen" }, [
        _c("div", { staticClass: "modal-inner" }, [
          _c("div", { staticClass: "modal__header" }, [
            _c(
              "button",
              {
                staticClass: "modal__close",
                attrs: {
                  id: "close-button",
                  for: "delete-group-modal",
                  "aria-label": "Close this dialog window",
                },
                on: { click: _vm.closeSubmitModal },
              },
              [_c("close-modal-icon")],
              1
            ),
            _vm._v(" "),
            _c("h2", [_vm._v("Submit Your Work")]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal__content" }, [
            _c("div", { staticClass: "modal__disclaimer" }, [
              _c(
                "div",
                { staticClass: "modal__icon" },
                [_c("announce-icon")],
                1
              ),
              _vm._v(" "),
              _vm._m(0),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "clearfix" }),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "modal__actions" }, [
              _c("span", { staticClass: "action__cancel cancel" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.closeSubmitModal($event)
                      },
                    },
                  },
                  [_vm._v("Cancel")]
                ),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "action__proceed" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.submitProject($event)
                      },
                    },
                  },
                  [_vm._v("Confirm and Submit")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "clearfix" }),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal__disclaimer-copy" }, [
      _c("div", { staticClass: "modal__sure-copy" }, [
        _c("strong", [_vm._v("Are you sure?")]),
        _vm._v(
          " You will not be able to edit or add to this work after you submit.\n              "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }