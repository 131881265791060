<template>
  <div class="filter--form">
    <div class="filter--form--label">
      Filter by:
    </div>
    <div class="filter--form--content">
      <form>
        <slot />
      </form>
      <a
        v-if="isFilterSelected"
        href="#"
        class="filter--clear--filter"
        @click.stop.prevent="clearFilter"
      >
        Clear Filters
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupWorkFilterLayout',
  props: {
    isFilterSelected: {
      type: Boolean,
      required: true,
    },
    clearFilter: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
