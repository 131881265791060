var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c(
      "td",
      {
        staticClass: "table__cell__valign-top",
        attrs: { "data-label": "Submitted" },
      },
      [
        _vm._v(
          "\n    " +
            _vm._s(_vm._f("formatDate")(_vm.evidence.submitted_at, "M/D/YY")) +
            "\n  "
        ),
      ]
    ),
    _vm._v(" "),
    _c("td", { attrs: { "data-label": "Project Name" } }, [
      _c("a", { attrs: { href: "#" }, on: { click: _vm.viewEvidenceModal } }, [
        _c(
          "div",
          { staticClass: "icon__group__icon-top-small" },
          [_c("ProjectIcon")],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "icon__group__text-top-small" }, [
          _vm._v(
            "\n        " + _vm._s(_vm.evidence.project.title) + "\n      "
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "td",
      { attrs: { "data-label": "Created by" } },
      [
        _c(
          "router-link",
          { attrs: { to: "/profile/" + _vm.evidence.user.token } },
          [
            _c("div", { staticClass: "icon__group" }, [
              _c("div", {
                staticClass: "profile__pic-member",
                domProps: { innerHTML: _vm._s(_vm.evidence.user.avatar) },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "icon__group__text" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.evidence.user.profile
                        ? _vm.evidence.user.profile.full_name
                        : ""
                    ) +
                    "\n          "
                ),
                _vm.evidence.membership &&
                _vm.evidence.membership.status === "archived"
                  ? _c("span", [_vm._v("\n            (Archived)\n          ")])
                  : _vm._e(),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("td", { attrs: { "data-label": "Collaborators" } }, [
      _c("div", { staticClass: "icon__group collaborators" }, [
        _c(
          "div",
          { staticClass: "icon__group__icon" },
          _vm._l(
            _vm.evidence.collaborators.slice(0, 3),
            function (collaborator) {
              return _c(
                "span",
                { key: collaborator.id, staticClass: "profile__pic-member" },
                [
                  _c("router-link", {
                    attrs: { to: "/profile/" + collaborator.token },
                    domProps: { innerHTML: _vm._s(collaborator.avatar) },
                  }),
                ],
                1
              )
            }
          ),
          0
        ),
        _vm._v(" "),
        _vm.evidence.collaborators.length > 3
          ? _c("div", { staticClass: "icon__group__text" }, [
              _vm._v("\n        +"),
              _c("span", [
                _vm._v(_vm._s(_vm.evidence.collaborators.length - 3)),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c("td", { attrs: { "data-label": "Comments" } }, [
      _c("div", { staticClass: "icon__group" }, [
        _c("a", { attrs: { href: "#" }, on: { click: _vm.launchComments } }, [
          _c("div", { staticClass: "icon__group__icon" }, [
            _c(
              "svg",
              {
                attrs: {
                  width: "25",
                  height: "25",
                  viewBox: "0 0 25 25",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("title", [_vm._v("Comment")]),
                _c("path", {
                  attrs: {
                    d: "M2.564 23.7c1.42-.13 2.81-.492 4.115-1.073l.52-.233.52.243c1.496.704 3.13 1.067 4.788 1.064 6.21-.003 11.24-5.027 11.242-11.222 0-6.2-5.035-11.227-11.248-11.228-6.214 0-11.25 5.025-11.252 11.224 0 2.566.88 5.055 2.495 7.05l.414.512-.188.63c-.32 1.078-.796 2.1-1.408 3.033z",
                    stroke: "#00A886",
                    "stroke-width": "2.5",
                    fill: "none",
                    "fill-rule": "evenodd",
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "comment__count" }, [
              _vm._v("+" + _vm._s(_vm.repliesCount)),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("td", { attrs: { "data-label": "Reviewed" } }, [
      !_vm.evidence.reviewed && !_vm.$groupUser().can("review_evidence")
        ? _c("span", [_vm._v("\n      No\n    ")])
        : _vm._e(),
      _vm._v(" "),
      !_vm.evidence.reviewed && _vm.$groupUser().can("review_evidence")
        ? _c(
            "a",
            {
              staticClass: "status--needs-review",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.viewEvidenceModal(_vm.evidence)
                },
              },
            },
            [_vm._v("\n      Needs Review\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.evidence.reviewed
        ? _c("span", [
            _vm._v(
              "\n      Reviewed " +
                _vm._s(
                  _vm._f("formatDate")(_vm.evidence.reviewed_at, "M/D/YY")
                ) +
                "\n    "
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }