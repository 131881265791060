var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "tab-background" }, [
      _c("div", { staticClass: "tab-container" }, [
        _c(
          "ul",
          _vm._l(_vm.visibleTabs, function (nav) {
            return _c(
              "li",
              {
                key: nav.title,
                staticClass: "button",
                class: _vm.tabClass(nav),
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.setActiveTab(nav.component)
                  },
                },
              },
              [
                _c("a", {
                  attrs: { href: "#", tabindex: "0" },
                  domProps: { textContent: _vm._s(nav.title) },
                }),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tab-content" },
      [
        _c(_vm.activeComponent, {
          tag: "component",
          attrs: { options: _vm.componentOptions },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticStyle: { clear: "both" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }