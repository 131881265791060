<template>
  <div class="project-tab">
    <h1>{{ project.title }}</h1>
    <project-evidence-manager
      :prompts="prompts"
      :get-project="getProject"
      :is-loading="isLoading"
      :project="project"
    />
  </div>
</template>

<script>
import ProjectEvidenceManager from './steps/ProjectEvidenceManager.vue';

export default {
  name: 'ProjectAddWork',
  components: {
    ProjectEvidenceManager,
  },
  data() {
    return {
      pageTitle: 'Submit Work | Mouse Create',
      project: {
        title: '',
        hasPackAccess: false,
      },
      prompts: [],
      isLoading: true,
    };
  },
  computed: {
    getProjectRoute() {
      return laroute.route('api.projects.show', {
        project: this.$route.params.id,
        include: 'prompts',
      });
    },
  },
  metaInfo() {
    return {
      title: this.getTitle(),
    };
  },
  mounted() {
    this.getProject();
    if (this.$route.name !== 'project-add-work') {
      this.$router.push({ name: 'project-add-work' });
    }
  },
  methods: {
    getTitle() {
      return `${this.pageTitle} - Submit Work`;
    },
    getProject() {
      axios.get(this.getProjectRoute)
        .then((response) => {
          this.project = response.data;
          this.pageTitle = this.project.title;
          this.prompts = response.data.prompts.data;
          this.isLoading = false;
        });
    },
  },
};
</script>
