var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-wrap" }, [
    _c("div", { staticClass: "notifications__header" }, [
      _c("h1", { staticClass: "notifications__title" }, [
        _vm._v("\n      Your Notifications\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "notifications__read-toggle",
          on: { click: _vm.toggleOnlyUnread },
        },
        [_vm._v("\n      " + _vm._s(_vm.toggleUnreadText) + "\n    ")]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "notifications__background" }, [
      _c(
        "svg",
        {
          attrs: {
            width: "347px",
            height: "641px",
            viewBox: "0 222 347 641",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              id: "Fill-8",
              d: "M208.736449,428.534798 C199.961263,428.534798 193.827436,419.909593 196.68891,411.621769 L256.178219,239.763094 C260.829948,226.32655 243.308923,216.527848 234.269601,227.514716 L2.91576852,508.787343 C-3.90774613,517.104504 2.00596656,529.60225 12.7768478,529.60225 L137.845268,529.60225 C146.60578,529.60225 152.754281,538.242124 149.892807,546.51528 L90.4034983,718.373954 C85.751769,731.810498 103.287468,741.6092 112.297442,730.622332 L146.972636,688.464443 L146.972636,821.215258 L108.966393,821.215258 L108.966393,862.551699 L180.459217,862.551699 L180.459217,835.747368 C180.459217,791.897777 180.459217,656.912035 180.459217,656.912035 C180.459217,654.007629 180.048339,651.425935 179.373325,649.064272 L221.884555,597.386386 L221.884555,862.551699 L293.392053,862.551699 L293.392053,818.838926 L255.371136,818.838926 L255.371136,556.666031 L274.667742,533.210754 L343.6366,449.349706 C350.489463,441.047213 344.546402,428.534798 333.790195,428.534798 L208.736449,428.534798 Z",
              stroke: "none",
              fill: "#00A886",
              "fill-rule": "evenodd",
            },
          }),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "notifications" },
      [
        _vm._l(_vm.notifications.data, function (notification) {
          return _c("notification", {
            key: notification.id,
            attrs: {
              notification: notification,
              "show-if-read": !_vm.onlyUnread,
            },
          })
        }),
        _vm._v(" "),
        _vm.moreResultsAvailable
          ? _c(
              "div",
              {
                staticClass: "notifications__view-more",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.loadMore($event)
                  },
                },
              },
              [_vm._v("\n      View more notifications\n    ")]
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }