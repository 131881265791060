export default {
  data() {
    return {
      pagination: {
        currentPage: 1,
        limit: 10,
        totalPages: 0,
        start: 0,
        count: 0,
        end: 0,
      },
    };
  },
  methods: {
    setupPagination(data) {
      this.pagination.count = data.length;
      this.pagination.totalPages = Math.ceil(this.pagination.count / this.pagination.limit);
    },

    pageSelected(page) {
      this.pagination.currentPage = page;
    },

    previous() {
      if (this.pagination.currentPage === 1) return;
      this.pagination.currentPage -= 1;
    },

    next() {
      if (this.pagination.currentPage === this.pagination.totalPages) return;
      this.pagination.currentPage += 1;
    },
  },
};
