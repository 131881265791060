var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card" },
    [
      _c("router-link", {
        staticClass: "project-image",
        attrs: { tabindex: "-1", to: _vm.projectUrl },
        domProps: { innerHTML: _vm._s(_vm.project.imgix) },
      }),
      _vm._v(" "),
      _c(
        "h2",
        [
          _vm.sortable
            ? _c("span", { staticClass: "course__project-sort" }, [
                _vm._v("\n      " + _vm._s(_vm.project.sort) + "\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("router-link", { attrs: { to: _vm.projectUrl } }, [
            _vm._v("\n      " + _vm._s(_vm.project.title) + "\n    "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.project && _vm.project.teaser.length > 0
        ? _c("span", { staticClass: "teaser" }, [
            _vm._v(_vm._s(_vm.project.teaser)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.project && _vm.project.curriculum.length > 0
        ? _c("h3", [
            _vm._v("\n    Course: " + _vm._s(_vm.project.curriculum) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.project && _vm.project.categories.length < 0
        ? _c("span", { staticClass: "teaser" }, [
            _vm._v("There are no tags for this project."),
          ])
        : _c(
            "ul",
            { staticClass: "card-tags" },
            _vm._l(_vm.categories, function (category) {
              return _c("li", { key: category.categoryId }, [
                _vm._v("\n      " + _vm._s(category.categoryName) + "\n    "),
              ])
            }),
            0
          ),
      _vm._v(" "),
      _vm.$groupUser().can("manage_group")
        ? _c("span", [
            !_vm.addedToCurrentGroup
              ? _c(
                  "a",
                  {
                    staticClass: "add-to-group",
                    attrs: { href: "#", role: "button", tabindex: "0" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.addToGroup($event)
                      },
                    },
                  },
                  [
                    _vm._v("\n      Add this Project to Playlist "),
                    _c("i", {
                      staticClass: "fa fa-plus",
                      attrs: { "aria-hidden": "true" },
                    }),
                  ]
                )
              : _c("span", { staticClass: "inactive-group-button" }, [
                  _vm._v("\n      In Playlist\n    "),
                ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }