<template>
  <modal
    :tabs="true"
    :is-visible.sync="visible"
  >
    <div slot="tabs">
      <div
        v-for="tab in tabs"
        :key="tab.component"
      >
        <a
          v-if="tab.visible"
          href="#"
          :class="{ active: tab.active }"
          @click.stop.prevent="activateTab(tab)"
        >
          {{ tab.title }}
          <span
            v-if="tab.count !== false"
            class="tab-count"
          >{{ tab.count }}</span>
        </a>
      </div>
    </div>
    <div
      v-if="evidenceId"
      class="tab-content"
    >
      <evidence-review
        v-show="activeTab.title === 'Project Work'"
        :is-editing="isEditing"
        :is-exportable="isExportable"
        :evidence="evidence"
        :group-id="groupId"
      />
      <comments
        v-show="activeTab.title === 'Discussion'"
        commentable-type="evidence"
        :creators="allCreators"
        :cursor-id="commentId"
        :commentable-id="evidenceId"
        :evidence="evidence"
      />
      <work-manage
        v-show="activeTab.title === 'Manage' && manageIsVisible"
        :evidence="evidenceId"
        :wrapper="wrapper"
      />
    </div>
  </modal>
</template>

<script>

import Modal from './Modal.vue';
import EvidenceReview from '../evidence/EvidenceReview.vue';
import Comments from '../comments/Comments.vue';
import WorkManage from '../work/WorkManage.vue';

export default {
  name: 'ProjectReviewModal',
  components: {
    Modal,
    EvidenceReview,
    Comments,
    WorkManage,
  },
  props: {},
  data() {
    return {
      closeFn: null,
      badges: [],
      evidence: {
        assets: {
          data: [],
        },
        collaborators: {
          data: [],
        },
        group: {
          name: '',
        },
        user: {
          links: {
            profile: '',
          },
        },
      },
      visible: false,
      isEditing: false,
      isOwner: false,
      isViewable: true,
      commentId: false,
      groupId: null,
      evidenceId: null,
      manageIsVisible: true,
      isExportable: false,
      wrapper: '',
      allCreators: null,
      tabs: [
        {
          title: 'Project Work',
          active: true,
          component: 'evidence-review',
          count: false,
          visible: true,
        },
        {
          title: 'Discussion',
          active: false,
          component: 'comments',
          count: false,
          visible: true,
        },
        {
          title: 'Manage',
          active: false,
          component: 'work-manage',
          count: false,
          visible: true,
        },
      ],
      comments: {},
    };
  },
  computed: {
    activeTab() {
      return _.find(this.tabs, (tab) => tab.active);
    },
    endpoint() {
      if (this.isEditing) {
        return laroute.route('group.evidence.edit', {
          group: this.groupId,
          evidence: this.evidenceId,
        });
      }
      return laroute.route('api.evidence.get', {
        id: this.evidenceId,
        include: 'user,collaborators,assets,prompts',
      });
    },
    currentUser() {
      return this.$store.state.users.current;
    },
  },
  watch: {},
  created() {
    bus.$on('open-project-review-modal', this.openProjectReviewModal);
    bus.$on('modal-clear', this.closeModal);
    bus.$on('comment-added', this.updateCommentCount);
  },
  mounted() {
    this.detectUrlParams();
  },
  methods: {
    activateTab(tab) {
      _.each(this.tabs, (_tab) => {
        _tab.active = false;
      });

      tab.active = true;
    },
    canView() {
      const $this = this;

      const creators = this.allCreators;

      // Return all ids of creators
      const ids = creators.map((obj) => obj.id);

      const user = this.$store.state.users.current;

      if (ids.indexOf(user.id) < 0) {
        $this.manageIsVisible = false;
        $this.tabs[2].visible = false;
      } else {
        $this.manageIsVisible = true;
        $this.tabs[2].visible = true;
      }
    },
    getCreators() {
      const creators = [this.evidence.user];
      return creators.concat(this.evidence.collaborators.data);
    },
    getEvidence() {
      axios.get(this.endpoint).then((response) => {
        this.evidence = response.data;
        this.visible = true;
        this.allCreators = this.getCreators();
        this.canView();
      });
    },
    getUrlParam(tempName, tempUrl) {
      const url = !tempUrl ? window.location.href : tempUrl;
      let name = tempName;

      name = name.replace(/[[\]]/g, '\\$&');
      const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
      const results = regex.exec(url);
      if (!results) {
        return null;
      }
      if (!results[2]) {
        return null;
      }
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    detectUrlParams() {
      const params = {
        groupId: this.getUrlParam('group') || this.groupId,
        evidenceId: this.getUrlParam('evidence') || this.evidenceId,
        commentId: parseInt(this.getUrlParam('comment'), 10) || this.commentId,
      };

      if (params.groupId && params.evidenceId) {
        this.groupId = parseInt(params.groupId, 10);
        this.evidenceId = parseInt(params.evidenceId, 10);
      } else {
        return;
      }

      if (params.groupId && params.evidenceId && params.commentId) {
        this.commentId = params.commentId;
        this.activateTab(this.tabs[1]);
      }

      this.getEvidence();
    },
    closeModal() {
      if (this.closeFn) {
        this.closeFn();
      }
      this.closeFn = null;
      this.groupId = false;
      this.evidenceId = false;
      this.commentId = false;
      this.badges = false;
      this.visible = false;
    },
    openProjectReviewModal(params) {
      // Fetch new comments
      bus.$emit('fetch-new-comments');

      if (params.closeFn) {
        this.closeFn = params.closeFn;
      }

      this.groupId = params.groupId;
      this.evidenceId = params.evidenceId;
      this.commentId = params.commentId;
      this.wrapper = params.wrapper;
      this.isEditing = _.isNil(params.isEditing) ? false : params.isEditing;
      this.visible = true;

      if (params.openDiscussion) {
        this.tabs[0].active = false;
        this.tabs[1].active = true;
      }

      this.detectUrlParams();
      bus.$emit('modal-open');
    },
    updateCommentCount() {
      // this.tabs[1].count = newValue.meta.pagination.countWithReplies
    },
  },
};
</script>
