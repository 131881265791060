var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "project-tab" }, [
    !_vm.isLoading && _vm.project.hasPackAccess
      ? _c(
          "div",
          [
            _c("div", { staticClass: "main-menu mobile-project-steps" }, [
              _c("div", { staticClass: "mobile-dropdown mobile-filter" }, [
                _c("div", { staticClass: "dropdown" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "dropdown-container project__steps-nav steps__nav-mobile",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "dropdown-button-delay",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.filterSteps($event)
                            },
                          },
                        },
                        [_vm._v("\n              See all steps\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "ol",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isFilteredActive,
                              expression: "isFilteredActive",
                            },
                          ],
                          staticClass: "steps dropdown-menu",
                          class: { "show-menu": _vm.isFilteredActive },
                        },
                        _vm._l(_vm.steps, function (step, o) {
                          return _c(
                            "li",
                            { key: o },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "project-steps",
                                      params: {
                                        id: _vm.$route.params.id,
                                        step: step.start.stepOrder,
                                      },
                                    },
                                  },
                                },
                                [
                                  _c("span", { staticClass: "step" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(step.start.stepOrder) +
                                        "\n                  "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "step__title" }, [
                                    _vm._v(
                                      _vm._s(step.start.title) +
                                        "\n                    "
                                    ),
                                    step.prompt
                                      ? _c(
                                          "span",
                                          { staticClass: "prompt__required" },
                                          [
                                            _c("br"),
                                            _vm._v(
                                              "This step has required tasks.\n                    "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "project-tab__heading" }, [
              _c("h1", [_vm._v(_vm._s(_vm.project.title))]),
              _vm._v(" "),
              _c("div", { staticClass: "switch switch__desktop" }, [
                _c("span", [_vm._v("Facilitation Notes")]),
                _vm._v(" "),
                _c(
                  "label",
                  { staticClass: "label-switch" },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.facilitatorCheck,
                          expression: "facilitatorCheck",
                        },
                      ],
                      attrs: {
                        id: "facilitator_note_toggle",
                        type: "checkbox",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.facilitatorCheck)
                          ? _vm._i(_vm.facilitatorCheck, null) > -1
                          : _vm.facilitatorCheck,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.facilitatorCheck,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.facilitatorCheck = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.facilitatorCheck = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.facilitatorCheck = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("checkbox-label"),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "project__content" },
              [
                _vm._l(_vm.steps, function (step) {
                  return [
                    parseInt(_vm.currentStep) === parseInt(step.start.stepOrder)
                      ? _c(
                          "div",
                          {
                            key: step.start.stepOrder,
                            staticClass: "steps__content",
                          },
                          [
                            _c("div", { staticClass: "project__step-header" }, [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "step-pagination step-pagination__previous",
                                  class: {
                                    "step-pagination--hidden": _vm.isFirstPage,
                                  },
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.previous($event)
                                    },
                                  },
                                },
                                [_c("prev-page-icon")],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "step-header__title" }, [
                                _c(
                                  "span",
                                  { staticClass: "title__step-number" },
                                  [
                                    _vm._v(
                                      "Step " + _vm._s(_vm.currentStep) + ":"
                                    ),
                                  ]
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(step.start.title) +
                                    "\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "step-pagination step-pagination__next",
                                  class: {
                                    "step-pagination--hidden": _vm.isLastPage,
                                  },
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.next($event)
                                    },
                                  },
                                },
                                [_c("next-page-icon")],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "switch__mobile" }, [
                              _c("hr"),
                              _vm._v(" "),
                              _c("div", { staticClass: "switch" }, [
                                _c("span", [_vm._v("Facilitation Notes")]),
                                _vm._v(" "),
                                _c(
                                  "label",
                                  { staticClass: "label-switch" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.facilitatorCheck,
                                          expression: "facilitatorCheck",
                                        },
                                      ],
                                      attrs: {
                                        id: "facilitator_note_toggle",
                                        type: "checkbox",
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.facilitatorCheck
                                        )
                                          ? _vm._i(_vm.facilitatorCheck, null) >
                                            -1
                                          : _vm.facilitatorCheck,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = _vm.facilitatorCheck,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.facilitatorCheck =
                                                  $$a.concat([$$v]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.facilitatorCheck = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.facilitatorCheck = $$c
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("checkbox-label"),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _vm._l(step.content, function (stepContent, index) {
                              return _c("evidence-step", {
                                key: index,
                                attrs: {
                                  "ie-class": _vm.ieClass,
                                  index: index,
                                  "show-note": _vm.facilitatorCheck,
                                  step: stepContent,
                                },
                              })
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "clearfix" }),
                            _vm._v(" "),
                            step.prompt
                              ? _c("project-evidence-manager", {
                                  attrs: {
                                    "for-step": {
                                      stepId: _vm.stepId,
                                      stepOrder: _vm.currentStep,
                                    },
                                    "get-project": _vm.getProject,
                                    "hide-extra": true,
                                    "is-loading": _vm.isLoading,
                                    project: _vm.project,
                                    prompts: [step.prompt],
                                  },
                                })
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                }),
                _vm._v(" "),
                _c("div", { staticClass: "step-footer" }, [
                  _c("hr", { staticClass: "step-footer__hr" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "step-footer__actions" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "step-pagination step-pagination__previous",
                        class: { "step-pagination--hidden": _vm.isFirstPage },
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.previous($event)
                          },
                        },
                      },
                      [_c("prev-page-icon")],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "step-pagination step-pagination__next",
                        class: { "step-pagination--hidden": _vm.isLastPage },
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.next($event)
                          },
                        },
                      },
                      [_c("next-page-icon")],
                      1
                    ),
                    _vm._v(" "),
                    _vm.isLastPage
                      ? _c(
                          "a",
                          {
                            staticClass: "button button__review",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.reviewWork($event)
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "review-text" }, [
                              _vm._v("Review My Work"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "review-icon" },
                              [_c("review-next-icon")],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "project__steps-nav steps__nav-desktop" },
              [
                _c(
                  "ol",
                  { staticClass: "steps" },
                  _vm._l(_vm.steps, function (step, o) {
                    return _c(
                      "li",
                      { key: o },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "project-steps",
                                params: {
                                  id: _vm.$route.params.id,
                                  step: step.start.stepOrder,
                                },
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "step" }, [
                              _vm._v(_vm._s(step.start.stepOrder)),
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(step.start.title) + " "),
                              step.prompt
                                ? _c(
                                    "span",
                                    { staticClass: "prompt__required" },
                                    [
                                      _c("br"),
                                      _vm._v("This step has required tasks."),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]
            ),
            _vm._v(" "),
            _c("collaborator-modal", {
              attrs: { "group-name": _vm.groupName },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading && !_vm.project.hasPackAccess,
            expression: "!isLoading && !project.hasPackAccess",
          },
        ],
      },
      [
        _c("div", { staticClass: "card no-access__card" }, [
          _c(
            "div",
            { staticClass: "no-access__overlay" },
            [
              _c("hidden-eye-icon"),
              _vm._v(" "),
              _c("span", { staticClass: "overlay__text" }, [
                _vm._v("Project Hidden"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", {
            staticClass: "no-access__image",
            domProps: { innerHTML: _vm._s(_vm.project.imgix) },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "no-access__content" }, [
            _c("h1", [_vm._v(_vm._s(_vm.project.title))]),
            _vm._v(" "),
            _c("p", { domProps: { textContent: _vm._s(_vm.project.teaser) } }),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "no-access__message" },
          [
            _c("hidden-eye-filled-icon"),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Your current membership does not include access to this project."
              ),
            ]),
            _vm._v(" "),
            _vm._m(0),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        "\n        To learn about adding more projects to your Mouse Create membership, please visit\n        "
      ),
      _c("a", { attrs: { href: "https://mouse.org/join", target: "_blank" } }, [
        _vm._v("mouse.org/join"),
      ]),
      _vm._v(" or email us at\n        "),
      _c("a", { attrs: { href: "mailto:membership@mouse.org" } }, [
        _vm._v("membership@mouse.org."),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }