<template>
  <div class="course-description">
    <vue-editor
      id="course-editor"
      v-model="description"
      :editor-toolbar="customToolbar"
    />
    <input
      v-model="description"
      type="hidden"
      name="description"
    >
  </div>
</template>
<script>

import { VueEditor } from 'vue2-editor';

export default {
  name: 'CourseDescription',
  components: { VueEditor },
  mixins: [],
  props: {
    currentDescription: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    description: '',
    customToolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  }),
  computed: {},
  watch: {},
  mounted() {
    this.description = this.currentDescription;
  },
  methods: {
  },
};
</script>
