var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", [
    _c(
      "section",
      { staticClass: "group-tab primary-content" },
      [
        _c("group-header", { attrs: { group: _vm.group } }),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header__intro" }, [
      _c("div", { staticClass: "header__intro__title" }, [
        _vm._v("\n        Group Work\n      "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "header__intro__summary" }, [
        _vm._v(
          "\n        This is all of the work submitted by members of your group. "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://tools.mouse.org/group-work",
              target: "_blank",
            },
          },
          [_vm._v("Learn more >")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }