<template>
  <div>
    <home-header
      v-if="home"
      :home="home"
    />

    <main>
      <home-slider
        :slider-header="home.programSliderHeader"
        :slides="home.programSlider"
      />
      <home-news :news-articles="home.news" />
    </main>

    <home-footer />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import HomeHeader from './HomeHeader.vue';
import HomeSlider from './HomeSlider.vue';
import HomeNews from './HomeNews.vue';
import HomeFooter from './HomeFooter.vue';

const HOME_QUERY = gql`{
      entry (section: "home") {
        ...on home_home_Entry {
          title,
          tagline,
          video {
            id
            filename
            url
            mimeType
          }
          programSliderHeader
          description
          programSlider {
            ...on programSlider_slide_BlockType {
              id
              slideTitle
              slideDescription
              slideImage {
                ...on homeSlideshow_Asset {
                  title
                  altTag
                  ioSlideshow {
                    src
                    srcset
                  }
                }
              }
              slideCtaHeader
              slideCtaButtonText
              slideCtaUrl
            }
          }
          news {
            ...on news_article_BlockType {
              id
              articleImage {
                ...on homeNews_Asset {
                  title
                  altTag
                  ioNews {
                    ...on ioNews_OptimizedImages {
                      src
                      srcset
                    }
                  }
                }
              }
              articleTitle
              articleDate @formatDateTime(format:"F j, Y")
              articleSummary
              articleUrl
              articleFeatured
            }
          }
        }
      }
    }`;

export default {
  name: 'Home',
  components: {
    HomeHeader, HomeSlider, HomeNews, HomeFooter,
  },
  mixins: [],
  apollo: {
    home: {
      query: HOME_QUERY,
      update(data) {
        return data.entry;
      },
    },
  },
  data() {
    return {
      home: {},
    };
  },
};
</script>
