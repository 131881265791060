<template>
  <div class="page-wrap">
    <div class="notifications__header">
      <h1 class="notifications__title">
        Your Notifications
      </h1>
      <div
        class="notifications__read-toggle"
        @click="toggleOnlyUnread"
      >
        {{ toggleUnreadText }}
      </div>
    </div>
    <div class="notifications__background">
      <svg
        width="347px"
        height="641px"
        viewBox="0 222 347 641"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Fill-8"
          d="M208.736449,428.534798 C199.961263,428.534798 193.827436,419.909593 196.68891,411.621769 L256.178219,239.763094 C260.829948,226.32655 243.308923,216.527848 234.269601,227.514716 L2.91576852,508.787343 C-3.90774613,517.104504 2.00596656,529.60225 12.7768478,529.60225 L137.845268,529.60225 C146.60578,529.60225 152.754281,538.242124 149.892807,546.51528 L90.4034983,718.373954 C85.751769,731.810498 103.287468,741.6092 112.297442,730.622332 L146.972636,688.464443 L146.972636,821.215258 L108.966393,821.215258 L108.966393,862.551699 L180.459217,862.551699 L180.459217,835.747368 C180.459217,791.897777 180.459217,656.912035 180.459217,656.912035 C180.459217,654.007629 180.048339,651.425935 179.373325,649.064272 L221.884555,597.386386 L221.884555,862.551699 L293.392053,862.551699 L293.392053,818.838926 L255.371136,818.838926 L255.371136,556.666031 L274.667742,533.210754 L343.6366,449.349706 C350.489463,441.047213 344.546402,428.534798 333.790195,428.534798 L208.736449,428.534798 Z"
          stroke="none"
          fill="#00A886"
          fill-rule="evenodd"
        />
      </svg>
    </div>
    <div class="notifications">
      <notification
        v-for="notification in notifications.data"
        :key="notification.id"
        :notification="notification"
        :show-if-read="!onlyUnread"
      />

      <div
        v-if="moreResultsAvailable"
        class="notifications__view-more"
        @click.prevent.stop="loadMore"
      >
        View more notifications
      </div>
    </div>
  </div>
</template>

<script>

import Notification from './Notification.vue';

export default {
  name: 'Notifications',
  components: {
    Notification,
  },
  props: {},
  data() {
    return {
      page: 1,
      notifications: false,
      onlyUnread: false,
      moreResultsAvailable: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.users.current;
    },
    total() {
      return this.notifications.length;
    },
    notificationUrl() {
      return laroute.route('api.notifications.index');
    },
    toggleUnreadText() {
      return this.onlyUnread ? 'See all' : 'See only unread';
    },
  },
  watch: {
    notifications() {
      this.moreResultsAvailable = this.notifications.meta.pagination.total_pages > this.page;
    },
    currentUser() {
      this.getNotifications();
    },
  },
  mounted() {
    this.getNotifications();
    document.body.className = 'no-js language-markup single-column';
  },
  methods: {
    toggleOnlyUnread() {
      this.onlyUnread = !this.onlyUnread;
      this.page = 1;
      this.getNotifications(true);
    },
    getNotifications(force) {
      axios.get(this.notificationUrl, {
        params: {
          page: this.page,
          only_unread: this.onlyUnread ? 1 : 0,
        },
      }).then((response) => {
        if (this.page > 1 && !force) {
          this.notifications.data = _.concat(this.notifications.data, response.data.data);
          this.notifications.meta = response.data.meta;
        } else {
          this.notifications = response.data;
        }
      });
    },
    loadMore() {
      this.page += 1;
      this.getNotifications();
    },
  },
};
</script>
