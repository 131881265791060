var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lastPage > 1
    ? _c(
        "nav",
        {
          staticClass: "table__pagination",
          attrs: { role: "navigation", "aria-label": "Pagination Navigation" },
        },
        [
          _c("div", { staticClass: "table__pagination__prev" }, [
            _c(
              "a",
              {
                staticClass: "table__pagination__link",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.previous($event)
                  },
                },
              },
              [
                _vm.value === 1 || _vm.lastPage === 1
                  ? _c("span", [_vm._v(" ")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.value !== 1 ? _c("span", [_vm._v("< previous")]) : _vm._e(),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table__pagination__numbers" }, [
            _vm.lastPage > 1
              ? _c(
                  "ul",
                  { staticClass: "table__pagination__row" },
                  _vm._l(_vm.lastPage, function (page) {
                    return _c(
                      "li",
                      { key: page, staticClass: "table__pagination__list" },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "table__pagination__link",
                            class: {
                              "table__pagination__link--active":
                                _vm.value === page,
                            },
                            attrs: {
                              href: "#",
                              "aria-label": "Current Page, Page " + page,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.changePage(page)
                              },
                            },
                          },
                          [_vm._v("\n          " + _vm._s(page) + "\n        ")]
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "table__pagination__next" }, [
            _c(
              "a",
              {
                staticClass: "table__pagination__link",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.next($event)
                  },
                },
              },
              [
                _vm.value === _vm.lastPage || _vm.lastPage === 1
                  ? _c("span", [_vm._v(" ")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.value !== _vm.lastPage
                  ? _c("span", [_vm._v("next >")])
                  : _vm._e(),
              ]
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }