var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "full-wrap" }, [
    _c("div", { staticClass: "page-wrap" }, [
      _c("main", [
        _c("section", { staticClass: "primary-content" }, [
          _vm.currentUser.isEducator
            ? _c("div", { staticClass: "content-box" }, [
                _c(
                  "div",
                  { staticClass: "back_link" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: { name: "group-switch" } } },
                      [
                        _c("i", {
                          staticClass: "fa fa-chevron-left",
                          attrs: { "aria-hidden": "true" },
                        }),
                        _vm._v(
                          " Back to\n              My Groups\n            "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "group-title--area" }, [
                  _c("h1", [
                    _vm._v("\n              Create a Group\n            "),
                  ]),
                  _vm._v(" "),
                  _vm.currentUser.isEducator
                    ? _c(
                        "div",
                        { staticClass: "group-switch-buttons" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "button__secondary",
                              attrs: { to: "/join/group" },
                            },
                            [
                              _vm._v(
                                "\n                Join a Group\n                "
                              ),
                              _c(
                                "svg",
                                {
                                  staticStyle: {
                                    "enable-background": "new 0 0 45.5 35",
                                  },
                                  attrs: {
                                    version: "1.1",
                                    id: "Layer_1",
                                    focusable: "false",
                                    xmlns: "http://www.w3.org/2000/svg",
                                    "xmlns:xlink":
                                      "http://www.w3.org/1999/xlink",
                                    x: "0px",
                                    y: "0px",
                                    viewBox: "0 0 45.5 35",
                                    "xml:space": "preserve",
                                  },
                                },
                                [
                                  _c(
                                    "desc",
                                    {
                                      attrs: {
                                        "data-v-908c7996": "",
                                        id: "navGroupDesc",
                                      },
                                    },
                                    [_vm._v("A group of people")]
                                  ),
                                  _c("g", [
                                    _c("path", {
                                      staticClass: "st0",
                                      attrs: {
                                        d: "M15.5,4c0.3-0.4,0.7-0.7,1.1-0.9l-1.5-2.6C14.9,0.2,14.6,0,14.2,0H7.9C7.5,0,7.2,0.2,7,0.5L3.8,6\n                    c-0.2,0.3-0.2,0.7,0,1.1L7,12.5c0.2,0.4,0.5,0.6,0.9,0.6h4.8c-0.1-0.7-0.2-1.3-0.2-1.9C12.5,8.4,13.7,5.8,15.5,4",
                                      },
                                    }),
                                    _c("path", {
                                      staticClass: "st0",
                                      attrs: {
                                        d: "M10.9,22.4c1.5-1.5,3.5-2.6,5.7-3.1c-0.4-0.3-0.8-0.6-1.1-0.9c-1-1-1.7-2.2-2.3-3.5H8.3C3.7,14.9,0,18.6,0,23\n                    v3.9h8c0.1-0.3,0.2-0.6,0.3-0.9C8.9,24.7,9.8,23.4,10.9,22.4",
                                      },
                                    }),
                                    _c("path", {
                                      staticClass: "st0",
                                      attrs: {
                                        d: "M45.5,23c0-4.4-3.8-8.1-8.4-8.1h-4.9c-0.5,1.3-1.3,2.5-2.2,3.5c-0.4,0.3-0.7,0.6-1.1,0.9\n                    c1.5,0.3,2.8,0.9,4,1.7c1.9,1.2,3.4,3,4.3,5c0.2,0.3,0.3,0.6,0.4,0.9h7.9V23z",
                                      },
                                    }),
                                    _c("path", {
                                      staticClass: "st0",
                                      attrs: {
                                        d: "M31.7,30.6H13.9v-0.1c0-2.8,2.4-5,5.3-5h7.2C29.3,25.5,31.7,27.7,31.7,30.6L31.7,30.6z M26.4,21h-7.2\n                    c-5.4,0-9.8,4.3-9.8,9.5V35h26.7v-4.5C36.1,25.3,31.7,21,26.4,21L26.4,21z",
                                      },
                                    }),
                                    _c("path", {
                                      staticClass: "st0",
                                      attrs: {
                                        d: "M22.7,18.9c4.3,0,7.8-3.5,7.8-7.7c0-4.3-3.5-7.8-7.8-7.8S15,6.9,15,11.2C15,15.4,18.4,18.9,22.7,18.9",
                                      },
                                    }),
                                    _c("path", {
                                      staticClass: "st0",
                                      attrs: {
                                        d: "M38.3,0h-8.1c-1.4,0-2.4,1.1-2.4,2.4v0.1c3.1,1.8,5.2,5.1,5.2,8.8c0,0.6-0.1,1.1-0.2,1.6h5.5\n                    c1.3,0,2.4-1.1,2.4-2.4V2.4C40.7,1.1,39.6,0,38.3,0",
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "clearfix" }),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "instructional" }, [
                  _vm._v(
                    "\n            Make a new group for you and your students below. If you are a member of more than one Mouse member site, make sure to select the correct location. Note: Group names can only be used once, so choose a unique name.\n          "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  { staticClass: "simple-edit", attrs: { method: "post" } },
                  [
                    _c("div", { staticClass: "form-row" }, [
                      _c("label", { attrs: { for: "site" } }, [
                        _vm.errors.first("site_id")
                          ? _c("span", { staticClass: "help-block error" }, [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.errors.first("site_id"))),
                              ]),
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n                  Site\n                "
                              ),
                            ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.site_id,
                              expression: "site_id",
                            },
                          ],
                          attrs: { id: "site", name: "site_id" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.site_id = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        _vm._l(_vm.licensedSites, function (site) {
                          return _c(
                            "option",
                            { key: site.id, domProps: { value: site.id } },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(site.name) +
                                  "\n                "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "field-instructions" },
                        [
                          _vm._v(
                            "\n                Create a group at a different site?\n                "
                          ),
                          _c("router-link", { attrs: { to: "/join/site" } }, [
                            _vm._v(
                              "\n                  Click here to enter a new Educator Code.\n                "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.name,
                            expression: "name",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          id: "groupName",
                          type: "text",
                          name: "name",
                          placeholder: "Group Name",
                        },
                        domProps: { value: _vm.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.name = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.first("name")
                        ? _c("div", { staticClass: "form-error" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.errors.first("name")) +
                                "\n              "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _vm._m(2),
                      _vm._v(" "),
                      _c("div", { staticClass: "associated-field" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.tos,
                              expression: "tos",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            id: "tos",
                            type: "checkbox",
                            name: "tos",
                            value: "1",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.tos)
                              ? _vm._i(_vm.tos, "1") > -1
                              : _vm.tos,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.tos,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = "1",
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.tos = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.tos = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.tos = $$c
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("label", { attrs: { for: "tos" } }, [
                          _vm._v(
                            "Please mark that you have read and agree to the terms here"
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.errors.first("tos")
                        ? _c("div", { staticClass: "form-error" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.errors.first("tos")) +
                                "\n              "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button",
                          attrs: { type: "submit" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.createGroup($event)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                Create Group\n              "
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "groupName" } }, [
      _vm._v("Group Name "),
      _c("span", [_vm._v("* Required")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v("\n                Parent/Guardian Permission Agreement: "),
      _c("span", [_vm._v("* Required")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "terms small" }, [
      _vm._v(
        "\n                As the educator in charge of this group, it is my responsibility to collect\n                Mouse Event and Media Consent forms signed by parent/guardian for each student."
      ),
      _c("br"),
      _c("br"),
      _vm._v(
        "I will keep\n                these forms on file at my school/site. "
      ),
      _c(
        "a",
        { attrs: { href: "/forms/MouseConsentForm.pdf", target: "_blank" } },
        [_vm._v("Download\n                  the Mouse consent form")]
      ),
      _vm._v(".\n              "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }