var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        "aria-labelledby": "navMenuTitle navMenuDesc",
        focusable: "false",
        height: "59",
        viewBox: "0 0 34 54",
        width: "34",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("title", { attrs: { id: "navMenuTitle" } }, [_vm._v("Menu")]),
      _vm._v(" "),
      _c("desc", { attrs: { id: "navMenuDesc" } }, [_vm._v("Menu")]),
      _vm._v(" "),
      _c("g", { attrs: { fill: "none" } }, [
        _c("path", {
          staticClass: "text",
          attrs: {
            d: "M2.4 42.9L0.9 42.9 0.9 53 2.2 53 2.2 45.1 5.1 50.3 6.2 50.3 9.3 45.2 9.3 53 10.7 53 10.7 42.9 9.1 42.9 5.7 48.6 2.4 42.9ZM12.3 49.2C12.3 46.9 14 45.3 16.2 45.3 18.4 45.3 20.1 46.9 20.1 49.2 20.1 51.4 18.4 53.2 16.2 53.2 14 53.2 12.3 51.5 12.3 49.2ZM13.6 49.2C13.6 50.8 14.7 51.9 16.2 51.9 17.6 51.9 18.7 50.7 18.7 49.2 18.7 47.7 17.6 46.5 16.2 46.5 14.7 46.5 13.6 47.7 13.6 49.2ZM25.6 45.3C25.4 45.3 25.3 45.3 25.1 45.3 24.1 45.3 23.3 45.9 22.9 46.9L22.9 45.4 21.6 45.4 21.6 53 22.9 53 22.9 49.6C22.9 48.9 23 48.4 23 48.2 23.3 47.2 23.9 46.6 25 46.6 25.2 46.6 25.4 46.6 25.6 46.7L25.6 45.3ZM27.7 48.5L32.3 48.5C32.1 47.3 31.3 46.5 30 46.5 28.8 46.5 27.9 47.4 27.7 48.5ZM33.6 49.6L27.7 49.6C27.8 51 28.7 51.9 30.2 51.9 31.2 51.9 31.8 51.4 32.1 50.7L33.4 50.9C33 52.2 31.8 53.2 30.2 53.2 27.9 53.2 26.3 51.6 26.3 49.2 26.3 46.9 28.1 45.3 30 45.3 32.2 45.3 33.6 47 33.6 49 33.6 49.2 33.6 49.4 33.6 49.6Z",
            fill: "#E7E7E7",
          },
        }),
        _vm._v(" "),
        _c(
          "g",
          { staticClass: "lines", staticStyle: { "stroke-width": "3" } },
          [
            _c("path", { attrs: { d: "M2.5 1.5L33 1.5" } }),
            _vm._v(" "),
            _c("path", { attrs: { d: "M2.5 11.5L33 11.5" } }),
            _vm._v(" "),
            _c("path", { attrs: { d: "M2.5 21.5L33 21.5" } }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }