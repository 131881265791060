<template>
  <svg
    height="15px"
    viewBox="0 0 10 15"
    width="10px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <g
      id="Designs"
      fill="none"
      fill-rule="evenodd"
      stroke="none"
      stroke-width="1"
    >
      <g
        id="Steps-Tab---1-Prompt_w_work---last-step"
        fill="#FFFFFF"
        transform="translate(-766.000000, -2180.000000)"
      >
        <g
          id="Project-Detail-Copy-2"
          transform="translate(49.000000, 251.000000)"
        >
          <g
            id="PAGINATION---BOTTOM"
            transform="translate(17.000000, 1895.000000)"
          >
            <g
              id="submit"
              transform="translate(468.000000, 21.000000)"
            >
              <path
                id="Shape-Copy-2"
                d="M237.640953,16.0284305 C237.400794,15.7872546 237.120363,15.6666667 236.8,15.6666667 C236.473398,15.6666667 236.196143,15.7872546 235.968123,16.0284305 L229.95122,22.3925941 C229.717073,22.6401293 229.6,22.9367397 229.6,23.2821851 C229.6,23.6341099 229.717187,23.9273606 229.95122,24.1619371 L230.644356,24.8950638 C230.866251,25.1427191 231.143505,25.2666667 231.476234,25.2666667 C231.808962,25.2666667 232.086217,25.1425991 232.308111,24.8950638 L236.8,20.153738 L241.291889,24.8949438 C241.51367,25.1424791 241.790925,25.2665467 242.12388,25.2665467 C242.450255,25.2665467 242.7308,25.1424791 242.964833,24.8949438 L243.657856,24.1618172 C243.885876,23.9206413 244,23.6273906 244,23.2820651 C244,22.9366197 243.882927,22.6400093 243.648667,22.3925941 L237.640953,16.0284305 Z"
                transform="translate(236.800000, 20.466667) scale(-1, 1) rotate(-90.000000) translate(-236.800000, -20.466667) "
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ReviewNextIcon',
};
</script>

<style scoped>

</style>
