<template>
  <div class="full-wrap">
    <div class="page-wrap">
      <main>
        <section class="primary-content">
          <div
            v-if="currentUser.isEducator"
            class="content-box"
          >
            <div class="back_link">
              <router-link :to="{ name: 'group-switch' }">
                <i
                  class="fa fa-chevron-left"
                  aria-hidden="true"
                /> Back to
                My Groups
              </router-link>
            </div>

            <div class="group-title--area">
              <h1>
                Create a Group
              </h1>

              <div class="group-switch-buttons" v-if="currentUser.isEducator">
                <router-link
                  to="/join/group"
                  class="button__secondary"
                >
                  Join a Group
                  <svg version="1.1" id="Layer_1" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                     x="0px" y="0px" viewBox="0 0 45.5 35" style="enable-background:new 0 0 45.5 35;" xml:space="preserve"><desc  data-v-908c7996="" id="navGroupDesc">A group of people</desc><g><path class="st0" d="M15.5,4c0.3-0.4,0.7-0.7,1.1-0.9l-1.5-2.6C14.9,0.2,14.6,0,14.2,0H7.9C7.5,0,7.2,0.2,7,0.5L3.8,6
                      c-0.2,0.3-0.2,0.7,0,1.1L7,12.5c0.2,0.4,0.5,0.6,0.9,0.6h4.8c-0.1-0.7-0.2-1.3-0.2-1.9C12.5,8.4,13.7,5.8,15.5,4"/><path class="st0" d="M10.9,22.4c1.5-1.5,3.5-2.6,5.7-3.1c-0.4-0.3-0.8-0.6-1.1-0.9c-1-1-1.7-2.2-2.3-3.5H8.3C3.7,14.9,0,18.6,0,23
                      v3.9h8c0.1-0.3,0.2-0.6,0.3-0.9C8.9,24.7,9.8,23.4,10.9,22.4"/><path class="st0" d="M45.5,23c0-4.4-3.8-8.1-8.4-8.1h-4.9c-0.5,1.3-1.3,2.5-2.2,3.5c-0.4,0.3-0.7,0.6-1.1,0.9
                      c1.5,0.3,2.8,0.9,4,1.7c1.9,1.2,3.4,3,4.3,5c0.2,0.3,0.3,0.6,0.4,0.9h7.9V23z"/><path class="st0" d="M31.7,30.6H13.9v-0.1c0-2.8,2.4-5,5.3-5h7.2C29.3,25.5,31.7,27.7,31.7,30.6L31.7,30.6z M26.4,21h-7.2
                      c-5.4,0-9.8,4.3-9.8,9.5V35h26.7v-4.5C36.1,25.3,31.7,21,26.4,21L26.4,21z"/><path class="st0" d="M22.7,18.9c4.3,0,7.8-3.5,7.8-7.7c0-4.3-3.5-7.8-7.8-7.8S15,6.9,15,11.2C15,15.4,18.4,18.9,22.7,18.9"/><path class="st0" d="M38.3,0h-8.1c-1.4,0-2.4,1.1-2.4,2.4v0.1c3.1,1.8,5.2,5.1,5.2,8.8c0,0.6-0.1,1.1-0.2,1.6h5.5
                      c1.3,0,2.4-1.1,2.4-2.4V2.4C40.7,1.1,39.6,0,38.3,0"/></g></svg>
                </router-link>
              </div>

              <div class="clearfix"></div>
            </div>

            <p class="instructional">
              Make a new group for you and your students below. If you are a member of more than one Mouse member site, make sure to select the correct location. Note: Group names can only be used once, so choose a unique name.
            </p>

            <form
              class="simple-edit"
              method="post"
            >
              <div class="form-row">
                <label for="site">
                  <span
                    v-if="errors.first('site_id')"
                    class="help-block error"
                  >
                    <strong>{{ errors.first('site_id') }}</strong>
                  </span>
                  <span v-else>
                    Site
                  </span>
                </label>

                <select
                  id="site"
                  v-model="site_id"
                  name="site_id"
                >
                  <option
                    v-for="site in licensedSites"
                    :key="site.id"
                    :value="site.id"
                  >
                    {{ site.name }}
                  </option>
                </select>
                <p class="field-instructions">
                  Create a group at a different site?
                  <router-link to="/join/site">
                    Click here to enter a new Educator Code.
                  </router-link>
                </p>
              </div>

              <div class="form-row">
                <label for="groupName">Group Name <span>* Required</span></label>
                <input
                  id="groupName"
                  v-model="name"
                  v-validate="'required'"
                  type="text"
                  name="name"
                  placeholder="Group Name"
                >
                <div
                  v-if="errors.first('name')"
                  class="form-error"
                >
                  {{ errors.first('name') }}
                </div>
              </div>

              <div class="form-row">
                <label>
                  Parent/Guardian Permission Agreement: <span>* Required</span>
                </label>

                <div class="terms small">
                  As the educator in charge of this group, it is my responsibility to collect
                  Mouse Event and Media Consent forms signed by parent/guardian for each student.<br><br>I will keep
                  these forms on file at my school/site. <a
                    href="/forms/MouseConsentForm.pdf"
                    target="_blank"
                  >Download
                    the Mouse consent form</a>.
                </div>

                <div class="associated-field">
                  <input
                    id="tos"
                    v-model="tos"
                    v-validate="'required'"
                    type="checkbox"
                    name="tos"
                    value="1"
                  >
                  <label for="tos">Please mark that you have read and agree to the terms here</label>
                </div>
                <div
                  v-if="errors.first('tos')"
                  class="form-error"
                >
                  {{ errors.first('tos') }}
                </div>
              </div>

              <div class="form-row">
                <button
                  type="submit"
                  class="button"
                  @click.stop.prevent="createGroup"
                >
                  Create Group
                </button>
              </div>
            </form>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>

import messages from '../../mixins/messages';

export default {
  name: 'CreateGroup',
  components: {},
  mixins: [messages],
  props: {},
  data: () => ({
    sites: [],
    site_id: '',
    name: '',
    tos: false,
  }),
  computed: {
    licensedSites() {
      return this.sites.filter((s) => s.isLicensed);
    },
    currentUser() {
      return this.$store.state.users.current;
    }
  },
  watch: {
    currentUser() {
      this.getSites();
    },
  },
  mounted() {
    document.body.className = 'no-js language-markup single-column';
    this.getSites();
    window.scrollTo(0, 0);
  },
  methods: {

    getSites() {
      axios.get('/api/sites').then((response) => {
        this.sites = response.data;
        this.site_id = _.head(this.sites).id;
      }).catch(() => {
        // console.log(error)
      });
    },

    createGroup() {
      const data = {
        site_id: this.site_id,
        name: this.name,
        tos: this.tos,
      };

      axios.post('/api/groups', data).then((response) => {
        const group = response.data;
        this.$store.dispatch('getMe');
        this.$store.dispatch('setGroupByResponse', group);
        this.callNotification(`The Group "${group.name}" has been created`, 'success');
        this.$router.push(`/group/${group.id}`);
      }).catch((error) => {
        _.forEach(error.response.data.errors, (message, id) => {
          const field = this.$validator.fields.find({
            name: id,
            scope: this.$options.scope,
          });

          this.$validator.errors.add({
            id: field.id,
            field: id,
            msg: message[0],
            scope: this.$options.scope,
          });
        });
      });
    },

  },
};
</script>
