var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-wrap" }, [
    _c(
      "main",
      [
        _c(
          "router-link",
          { staticClass: "back-to__button", attrs: { to: "/" } },
          [
            _c("span", { staticClass: "back-to__icon" }, [
              _c(
                "svg",
                {
                  attrs: {
                    width: "9px",
                    height: "17px",
                    viewBox: "0 0 9 17",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("title", [_vm._v("Shape")]),
                  _vm._v(" "),
                  _c("desc", [_vm._v("Created with Sketch.")]),
                  _vm._v(" "),
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Page-1",
                        stroke: "none",
                        "stroke-width": "1",
                        fill: "none",
                        "fill-rule": "evenodd",
                      },
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "Project-Gallery-Browser---med/tablet",
                            transform: "translate(-23.000000, -204.000000)",
                            fill: "#00A886",
                          },
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "back-to-profile-copy",
                                transform: "translate(23.000000, 203.000000)",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  id: "Shape",
                                  d: "M12.4801154,11.237282 L5.5086642,5.27180751 C5.22997992,5.04574032 4.90456303,4.93270673 4.53280847,4.93270673 C4.15381364,4.93270673 3.83208271,5.04574032 3.56748402,5.27180751 L-3.41463012,11.237282 C-3.68633743,11.4693101 -3.82219108,11.747339 -3.82219108,12.0711437 C-3.82219108,12.4010219 -3.68620578,12.6759016 -3.41463012,12.8957828 L-2.61030279,13.5829821 C-2.35281273,13.8151227 -2.0310818,13.9313055 -1.64497835,13.9313055 C-1.2588749,13.9313055 -0.937143967,13.8150103 -0.679653907,13.5829821 L4.53280847,9.13868114 L9.74527084,13.5828696 C10.0026293,13.8148978 10.3243602,13.9311931 10.7107269,13.9311931 C11.0894585,13.9311931 11.415007,13.8148978 11.6865827,13.5828696 L12.4907783,12.8956704 C12.755377,12.6696032 12.887808,12.3947235 12.887808,12.0710313 C12.887808,11.7472266 12.7519544,11.4691977 12.4801154,11.237282 L12.4801154,11.237282 Z",
                                  transform:
                                    "translate(4.532808, 9.432006) rotate(-90.000000) translate(-4.532808, -9.432006) ",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "back-to__text" }, [
              _vm._v("Back to Home"),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("section", { staticClass: "primary-content" }, [
          _c("div", { staticClass: "content-box" }, [
            _c("h1", { staticClass: "page-title" }, [
              _vm._v("\n          Registration\n        "),
            ]),
            _vm._v(" "),
            _c(
              "form",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.formReady,
                    expression: "formReady",
                  },
                ],
                staticClass: "form-horizontal form-registration registration",
                attrs: { id: "registrationForm", method: "post" },
              },
              [
                _c("div", { staticClass: "form-block" }, [
                  _c("div", { staticClass: "form-row" }, [
                    _c("div", { staticClass: "legend" }, [
                      _vm._v(
                        "\n                What kind of account do you want to make?\n              "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.registration.type,
                          expression: "registration.type",
                        },
                      ],
                      attrs: {
                        id: "account_educator",
                        type: "radio",
                        name: "registration.type",
                        value: "adult",
                      },
                      domProps: {
                        checked: _vm._q(_vm.registration.type, "adult"),
                      },
                      on: {
                        change: [
                          function ($event) {
                            return _vm.$set(_vm.registration, "type", "adult")
                          },
                          _vm.resetForm,
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "options",
                        attrs: { for: "account_educator" },
                      },
                      [_vm._v("Educator")]
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.registration.type,
                          expression: "registration.type",
                        },
                      ],
                      attrs: {
                        id: "account_youth",
                        type: "radio",
                        name: "registration.type",
                        value: "youth",
                      },
                      domProps: {
                        checked: _vm._q(_vm.registration.type, "youth"),
                      },
                      on: {
                        change: [
                          function ($event) {
                            return _vm.$set(_vm.registration, "type", "youth")
                          },
                          _vm.resetForm,
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "options",
                        attrs: { for: "account_youth" },
                      },
                      [_vm._v("Student")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isAdult,
                        expression: "isAdult",
                      },
                    ],
                    staticClass: "form-block",
                  },
                  [
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "legend" }, [
                        _vm._v(
                          "\n                Is your site a current Mouse Member?\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.facilitator.status,
                            expression: "facilitator.status",
                          },
                        ],
                        attrs: {
                          id: "code_yes",
                          type: "radio",
                          name: "facilitator.status",
                          value: "yes",
                        },
                        domProps: {
                          checked: _vm._q(_vm.facilitator.status, "yes"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.facilitator, "status", "yes")
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        { staticClass: "options", attrs: { for: "code_yes" } },
                        [
                          _vm._v(
                            "My site is a Mouse Member and I have my site’s " +
                              _vm._s(_vm.labels.facilitator) +
                              " Code."
                          ),
                        ]
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.facilitator.status,
                            expression: "facilitator.status",
                          },
                        ],
                        attrs: {
                          id: "code_maybe",
                          type: "radio",
                          name: "facilitator.status",
                          value: "maybe",
                        },
                        domProps: {
                          checked: _vm._q(_vm.facilitator.status, "maybe"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.facilitator, "status", "maybe")
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "options",
                          attrs: { for: "code_maybe" },
                        },
                        [
                          _vm._v(
                            "I think my site is a Mouse Member, but I don’t have my site’s " +
                              _vm._s(_vm.labels.facilitator) +
                              " Code."
                          ),
                        ]
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.facilitator.status,
                            expression: "facilitator.status",
                          },
                        ],
                        attrs: {
                          id: "code_no",
                          type: "radio",
                          name: "facilitator.status",
                          value: "no",
                        },
                        domProps: {
                          checked: _vm._q(_vm.facilitator.status, "no"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.facilitator, "status", "no")
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        { staticClass: "options", attrs: { for: "code_no" } },
                        [
                          _vm._v(
                            " I want to learn about becoming a Mouse Member."
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isNotFacilitator,
                        expression: "isNotFacilitator",
                      },
                    ],
                    staticClass: "form-block",
                  },
                  [_vm._m(0)]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isMaybeFacilitator,
                        expression: "isMaybeFacilitator",
                      },
                    ],
                    staticClass: "form-block",
                  },
                  [_vm._m(1)]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showGeneral,
                        expression: "showGeneral",
                      },
                    ],
                    staticClass: "form-block",
                  },
                  [
                    _c("h3", [_vm._v("General Information")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _vm._m(2),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("username"),
                              expression: "errors.has('username')",
                            },
                          ],
                          staticClass: "help-block error",
                        },
                        [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.errors.first("username"))),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.general.username,
                            expression: "general.username",
                          },
                        ],
                        attrs: {
                          id: "username",
                          type: "text",
                          name: "username",
                          placeholder: "Your Username",
                        },
                        domProps: { value: _vm.general.username },
                        on: {
                          keyup: _vm.checkUsername,
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.general,
                              "username",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.registrationErrors.username
                        ? _c("div", { staticClass: "form-error" }, [
                            _c("span", [
                              _c("strong", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.registrationErrors.username
                                  ),
                                },
                              }),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.usernameError
                        ? _c("div", { staticClass: "form-error" }, [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(_vm.usernameError),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.usernameSuccess
                        ? _c("div", { staticClass: "form-success" }, [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(_vm.usernameSuccess),
                              },
                            }),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    !_vm.isGoogleRegistration
                      ? _c("div", { staticClass: "form-row" }, [
                          _c("div", { staticClass: "form-1-2" }, [
                            _vm._m(3),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.general.password,
                                  expression: "general.password",
                                },
                              ],
                              attrs: {
                                id: "password",
                                type: "password",
                                name: "password",
                                placeholder: "Your Secret Password",
                              },
                              domProps: { value: _vm.general.password },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.general,
                                    "password",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.registrationErrors.password
                              ? _c("div", { staticClass: "form-error" }, [
                                  _c("span", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.registrationErrors.password)
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-1-2" }, [
                            _vm._m(4),
                            _vm._v(" "),
                            _vm.registrationErrors.password_confirmation
                              ? _c(
                                  "span",
                                  { staticClass: "help-block error" },
                                  [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.registrationErrors
                                            .password_confirmation[0]
                                        )
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.general.password_confirmation,
                                  expression: "general.password_confirmation",
                                },
                              ],
                              attrs: {
                                id: "password_confirm",
                                type: "password",
                                name: "password_confirmation",
                                placeholder: "Confirm Password",
                              },
                              domProps: {
                                value: _vm.general.password_confirmation,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.general,
                                    "password_confirmation",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-1-2" }, [
                        _vm._m(5),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.general.first_name,
                              expression: "general.first_name",
                            },
                          ],
                          attrs: {
                            id: "first_name",
                            type: "text",
                            name: "first_name",
                            placeholder: "First Name",
                          },
                          domProps: { value: _vm.general.first_name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.general,
                                "first_name",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.registrationErrors.first_name
                          ? _c("div", { staticClass: "form-error" }, [
                              _c("span", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.registrationErrors.first_name)
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-1-2" }, [
                        _vm._m(6),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.general.last_name,
                              expression: "general.last_name",
                            },
                          ],
                          attrs: {
                            id: "last_name",
                            type: "text",
                            name: "last_name",
                            placeholder: "Last Name",
                          },
                          domProps: { value: _vm.general.last_name },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.general,
                                "last_name",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _vm.registrationErrors.last_name
                          ? _c("div", { staticClass: "form-error" }, [
                              _c("span", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.registrationErrors.last_name)
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-1" }, [
                        _c("label", { attrs: { for: "email" } }, [
                          _vm._v("\n                  Email "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isFacilitator,
                                  expression: "isFacilitator",
                                },
                              ],
                            },
                            [_vm._v("* required")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.general.email,
                              expression: "general.email",
                            },
                          ],
                          attrs: {
                            id: "email",
                            type: "text",
                            name: "email",
                            placeholder: "Your Email",
                          },
                          domProps: { value: _vm.general.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.general,
                                "email",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-error" }, [
                          _c("span", [
                            _c("strong", {
                              domProps: {
                                innerHTML: _vm._s(_vm.registrationErrors.email),
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c(
                        "div",
                        { staticClass: "legend", attrs: { id: "birth" } },
                        [_vm._v("\n                Birthday\n              ")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-1-3" }, [
                        _vm._m(7),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.general.birth.year,
                                expression: "general.birth.year",
                              },
                            ],
                            attrs: { id: "birth_year", name: "birth[year]" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.general.birth,
                                  "year",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.birthYears, function (year) {
                            return _c(
                              "option",
                              { key: year, domProps: { value: year } },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(year) +
                                    "\n                  "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _vm.registrationErrors.birth &&
                        _vm.registrationErrors.birth.year
                          ? _c("div", { staticClass: "form-error" }, [
                              _c("span", [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(_vm.registrationErrors.birth.year)
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-1-3" }, [
                        _c("label", { attrs: { for: "birth_month" } }, [
                          _vm._v("\n                  Month\n                "),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.general.birth.month,
                                expression: "general.birth.month",
                              },
                            ],
                            attrs: { id: "birth_month", name: "birth[month]" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.general.birth,
                                  "month",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "01" } }, [
                              _vm._v(
                                "\n                    January\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "02" } }, [
                              _vm._v(
                                "\n                    February\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "03" } }, [
                              _vm._v(
                                "\n                    March\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "04" } }, [
                              _vm._v(
                                "\n                    April\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "05" } }, [
                              _vm._v(
                                "\n                    May\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "06" } }, [
                              _vm._v(
                                "\n                    June\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "07" } }, [
                              _vm._v(
                                "\n                    July\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "08" } }, [
                              _vm._v(
                                "\n                    August\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "09" } }, [
                              _vm._v(
                                "\n                    September\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "10" } }, [
                              _vm._v(
                                "\n                    October\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "11" } }, [
                              _vm._v(
                                "\n                    November\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "12" } }, [
                              _vm._v(
                                "\n                    December\n                  "
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-1-3" }, [
                        _c("label", { attrs: { for: "birth_day" } }, [
                          _vm._v("\n                  Day\n                "),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.general.birth.day,
                                expression: "general.birth.day",
                              },
                            ],
                            attrs: { id: "birth_day", name: "birth[day]" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.general.birth,
                                  "day",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "01" } }, [
                              _vm._v(
                                "\n                    01\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "02" } }, [
                              _vm._v(
                                "\n                    02\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "03" } }, [
                              _vm._v(
                                "\n                    03\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "04" } }, [
                              _vm._v(
                                "\n                    04\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "05" } }, [
                              _vm._v(
                                "\n                    05\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "06" } }, [
                              _vm._v(
                                "\n                    06\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "07" } }, [
                              _vm._v(
                                "\n                    07\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "08" } }, [
                              _vm._v(
                                "\n                    08\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "09" } }, [
                              _vm._v(
                                "\n                    09\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "10" } }, [
                              _vm._v(
                                "\n                    10\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "11" } }, [
                              _vm._v(
                                "\n                    11\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "12" } }, [
                              _vm._v(
                                "\n                    12\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "13" } }, [
                              _vm._v(
                                "\n                    13\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "14" } }, [
                              _vm._v(
                                "\n                    14\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "15" } }, [
                              _vm._v(
                                "\n                    15\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "16" } }, [
                              _vm._v(
                                "\n                    16\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "17" } }, [
                              _vm._v(
                                "\n                    17\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "18" } }, [
                              _vm._v(
                                "\n                    18\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "19" } }, [
                              _vm._v(
                                "\n                    19\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "20" } }, [
                              _vm._v(
                                "\n                    20\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "21" } }, [
                              _vm._v(
                                "\n                    21\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "22" } }, [
                              _vm._v(
                                "\n                    22\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "23" } }, [
                              _vm._v(
                                "\n                    23\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "24" } }, [
                              _vm._v(
                                "\n                    24\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "25" } }, [
                              _vm._v(
                                "\n                    25\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "26" } }, [
                              _vm._v(
                                "\n                    26\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "27" } }, [
                              _vm._v(
                                "\n                    27\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "28" } }, [
                              _vm._v(
                                "\n                    28\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "29" } }, [
                              _vm._v(
                                "\n                    29\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "30" } }, [
                              _vm._v(
                                "\n                    30\n                  "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "31" } }, [
                              _vm._v(
                                "\n                    31\n                  "
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _c("div", { staticClass: "form-1-2" }, [
                        _c("label", { attrs: { for: "ethnicity" } }, [
                          _vm._v("Ethnicity"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.general.ethnicity,
                                expression: "general.ethnicity",
                              },
                            ],
                            attrs: { id: "ethnicity", name: "ethnicity" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.general,
                                  "ethnicity",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.ethnicities, function (ethnicity) {
                            return _c(
                              "option",
                              {
                                key: ethnicity.id,
                                domProps: { value: ethnicity.id },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(ethnicity.name) +
                                    "\n                  "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-1-2" }, [
                        _c("label", { attrs: { for: "gender" } }, [
                          _vm._v("Gender"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.general.gender,
                                expression: "general.gender",
                              },
                            ],
                            attrs: { id: "gender", name: "gender" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.general,
                                  "gender",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }),
                            _vm._v(" "),
                            _vm._l(_vm.genders, function (gender) {
                              return _c(
                                "option",
                                {
                                  key: gender.id,
                                  domProps: { value: gender.id },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(gender.name) +
                                      "\n                  "
                                  ),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("h3", [_vm._v("Terms of Service")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _vm.registrationErrors.tos
                        ? _c("div", { staticClass: "form-error" }, [
                            _c("span", [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.registrationErrors.tos)),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "label",
                        { staticClass: "required", attrs: { for: "tos" } },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.tos,
                                expression: "tos",
                              },
                            ],
                            attrs: {
                              id: "tos",
                              type: "checkbox",
                              name: "tos",
                              value: "yes",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.tos)
                                ? _vm._i(_vm.tos, "yes") > -1
                                : _vm.tos,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.tos,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "yes",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 && (_vm.tos = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.tos = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.tos = $$c
                                }
                              },
                            },
                          }),
                          _vm._v(
                            " Please mark here that you have read and agree to the "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://mouse.org/terms-of-use",
                                target: "_blank",
                              },
                            },
                            [_vm._v("Terms of Use")]
                          ),
                          _vm._v(" and "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://mouse.org/privacy-policy",
                                target: "_blank",
                              },
                            },
                            [_vm._v("Privacy Policy")]
                          ),
                          _vm._v(".\n              "),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    !_vm.isGoogleRegistration
                      ? _c("div", { staticClass: "form-block" }, [
                          _vm.registrationErrors.recaptcha
                            ? _c(
                                "div",
                                {
                                  staticClass: "form-error form-text-center",
                                  attrs: { id: "recaptcha" },
                                },
                                [
                                  _c("span", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.registrationErrors.recaptcha)
                                      ),
                                    ]),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "captcha form-row",
                              attrs: { id: "captcha" },
                            },
                            [
                              _c("vue-recaptcha", {
                                attrs: {
                                  sitekey: _vm.captchaSiteKey,
                                  "load-recaptcha-script": true,
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "form-1-1 register" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.submit($event)
                          },
                        },
                      },
                      [
                        _vm._v("\n                Register "),
                        _c("i", {
                          staticClass: "fa fa-chevron-right",
                          attrs: { "aria-hidden": "true" },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row" }, [
      _c("p", [
        _c("strong", [
          _vm._v("Become a part of the national Mouse learning network!"),
        ]),
      ]),
      _vm._v(" "),
      _c("p", [
        _vm._v("\n                Visit "),
        _c("a", { attrs: { href: "https://mouse.org/join", target: "_new" } }, [
          _vm._v("mouse.org/join"),
        ]),
        _vm._v(
          " to find a Mouse Membership option that fits your learning environment and budget.\n              "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row" }, [
      _c("p", [
        _vm._v(
          "\n                If you are a Mouse member and do not have your site's Educator Code, email "
        ),
        _c("a", { attrs: { href: "mailto:create@mouse.org" } }, [
          _vm._v("create@mouse.org"),
        ]),
        _vm._v(
          " with your name and the name of your school or organization.\n              "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "username" } }, [
      _vm._v("Username "),
      _c("span", [_vm._v("* required")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "password" } }, [
      _vm._v("Password "),
      _c("span", [_vm._v("* required")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "password_confirm" } }, [
      _vm._v("\n                  Confirm Password "),
      _c("span", [_vm._v("* required")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "first_name" } }, [
      _vm._v("\n                  First Name "),
      _c("span", [_vm._v("* required")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "last_name" } }, [
      _vm._v("\n                  Last Name "),
      _c("span", [_vm._v("* required")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "birth_year" } }, [
      _vm._v("\n                  Year "),
      _c("span", [_vm._v("* required")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }