<template>
  <section class="course__row course__bottom-row">
    <div class="course__wrapper">
      <h2 class="row__title">
        Related Projects
      </h2>

      <div class="related_course-description">
        <p>{{ description }}</p>
      </div>
      <div class="clearfix" />
      <div class="card__list course__project-cards">
        <project-card
          v-for="project in projects"
          :key="project.id"
          :project="project"
          :is-in-current-group="checkProject(project.id)"
        />
      </div>
    </div>
  </section>
</template>

<script>

import ProjectCard from '../projects/ProjectCard.vue';

export default {
  name: 'CourseRelatedProjects',
  components: { ProjectCard },
  props: {
    projects: {
      type: Array,
      required: true,
    },
    groupProjectIds: {
      type: Array,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  data: () => ({

  }),
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    checkProject(id) {
      return this.groupProjectIds.indexOf(id) > -1;
    },
  },
};
</script>
