<template>
  <div class="modal modal__global">
    <input
      id="verify-collaborators"
      class="modal-state"
      type="checkbox"
    >
    <div class="modal-fade-screen">
      <div class="modal-inner">
        <div class="modal__header">
          <button
            id="close-button"
            class="modal__close"
            for="verify-collaborators"
            aria-label="Close this dialog window"
            @click.stop.prevent="closeCollaboratorModal"
          >
            <svg
              width="21px"
              height="21px"
              viewBox="0 0 21 21"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Close X</title>
              <defs />
              <g
                id="Designs"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                stroke-linecap="square"
              >
                <g
                  id="Work-Tab----Edit-Collaborators-Modal-"
                  transform="translate(-1032.000000, -61.000000)"
                  stroke="#FFFFFF"
                  stroke-width="4"
                >
                  <g
                    id="Edit-Collaborators-Modal-Copy"
                    transform="translate(210.000000, 49.000000)"
                  >
                    <g id="Green-top-area">
                      <g
                        id="close-X"
                        transform="translate(825.000000, 15.000000)"
                      >
                        <path
                          id="Line"
                          d="M0.5,0.50472973 L14.6421356,14.7806423"
                        />
                        <path
                          id="Line"
                          d="M0.5,0.50472973 L14.6421356,14.7806423"
                          transform="translate(7.500000, 7.570946) scale(-1, 1) translate(-7.500000, -7.570946) "
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </button>
          <h2>Who is working on this?</h2>
        </div>

        <div class="modal__content">
          <div class="modal__instructions">
            You are active in {{ groupName }}. Select the group members who are collaborating with you on this work, or just click close if you are working alone.
          </div>

          <div class="modal__all-collaborators">
            <span
              v-if="currentUser"
              href="#"
              class="modal-collaborator active"
            >
              <!--  eslint-disable  -->
              <!--suppress HtmlUnknownTag -->
              <div
                class="modal-collaborator__pic"
                v-html="currentUser.avatar"
              />
              <!--  eslint-enable  -->
              <!--suppress HtmlUnknownTag -->
              <div class="modal-collaborator__name">
                {{ currentUser.username }}<br>(You!)
              </div>
            </span>

            <a
              v-for="user in allCollaborators"
              :key="user.id"
              href="#"
              class="modal-collaborator"
              :class="{ active: collaborators.indexOf(user.id) > -1 }"
              @click.stop.prevent="selectUser(user.id)"
            >
              <!--  eslint-disable  -->
              <div
                class="modal-collaborator__pic"
                v-html="user.avatar"
              />
              <!--  eslint-enable  -->
              <div class="modal-collaborator__name">
                {{ user.username }}
                <br>
                <span v-if="user.shortName && user.shortName.length > 1">({{ user.shortName }}.)</span>
              </div>
            </a>

            <div class="clearifx" />

            <br>

            <div
              v-if="availableCollaborators.length > limit && isViewMore"
              class="modal-collaborator__pagination"
            >
              <a
                href="#"
                @click.prevent.stop="addMoreCollaborators"
              >View all Group Members <span class="pagination__sign">+</span></a>
            </div>

            <div
              v-if="!isViewMore"
              class="modal-collaborator__pagination"
            >
              <a
                href="#"
                @click.prevent.stop="removeMoreCollaborators"
              >View Fewer Group Members <span class="pagination__sign">-</span></a>
            </div>
          </div>

          <hr>

          <div class="modal__actions">
            <span class="action__proceed">
              <button
                type="button"
                class="button modal__button"
                for="verify-collaborators"
                @click.stop.prevent="closeCollaboratorModal"
              >Close</button>
            </span>
          </div>

          <div class="clearfix" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import modalMixin from '../../mixins/modal';

export default {
  name: 'CollaboratorModal',
  components: {},
  mixins: [modalMixin],
  props: {
    evidenceCollaborators: {
      type: Array,
      default() {
        return [];
      },
    },
    groupName: {
      type: String,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      originalLimit: 13, // take account for creator
      limit: 13,
      pageBy: 41,
      collaborators: [],
      availableCollaborators: [],
      isViewMore: true,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.users.current;
    },
    getGroupCollaboratorsRoute() {
      return laroute.route('api.collaborators.group', {
        group: this.currentUser.defaultGroupId,
      });
    },
    selectedCollaboratorIds() {
      if (!this.evidenceCollaborators) {
        return [];
      }
      return _.map(this.evidenceCollaborators, 'id');
    },
    allCollaborators() {
      return _.slice(this.availableCollaborators, 0, this.limit);
    },
    postCollaboratorsRoute() {
      return laroute.route('group.project.evidence.collaborators', {
        group: this.currentUser.defaultGroupId,
        project: this.$route.params.id,
      });
    },
  },
  watch: {
    currentUser() {
      this.getGroupCollaborators();
    },
    selectedCollaboratorIds(newValue) {
      this.collaborators = newValue;
    },
    $route(to) {
      if (to.name === 'project-add-work') {
        this.getGroupCollaborators();
      }
    },
  },
  mounted() {
    if (this.currentUser) {
      this.getGroupCollaborators();
    }
  },
  methods: {
    addMoreCollaborators() {
      this.limit += this.pageBy;
      this.isViewMore = false;
    },

    removeMoreCollaborators() {
      this.limit = this.originalLimit;
      this.isViewMore = true;
    },

    getGroupCollaborators() {
      axios.get(this.getGroupCollaboratorsRoute).then((response) => {
        this.availableCollaborators = response.data.data;
      });
    },

    selectUser(id) {
      const index = this.collaborators.indexOf(id);

      if (index > -1) {
        this.collaborators.splice(index, 1);
      } else {
        this.collaborators.push(id);
      }

      this.saveCollaborators();
    },

    saveCollaborators() {
      axios.post(this.postCollaboratorsRoute, {
        collaborators: this.collaborators,
      }).then((response) => {
        if (response.data.status === 'success') {
          bus.$emit('update:collaborators', { id: response.data.id });
        }
      });
    },

    closeCollaboratorModal() {
      this.closeModal('#verify-collaborators');
      this.removeMoreCollaborators();
    },
  },
};
</script>
