<template>
  <div class="project-tab">
    <div v-if="!isLoading && project.hasPackAccess">
      <div class="main-menu mobile-project-steps">
        <div class="mobile-dropdown mobile-filter">
          <div class="dropdown">
            <div class="dropdown-container project__steps-nav steps__nav-mobile">
              <p
                class="dropdown-button-delay"
                @click.stop.prevent="filterSteps"
              >
                See all steps
              </p>

              <ol
                v-show="isFilteredActive"
                :class="{'show-menu': isFilteredActive}"
                class="steps dropdown-menu"
              >
                <li
                  v-for="(step, o) in steps"
                  :key="o"
                >
                  <router-link :to="{ name: 'project-steps', params: { id: $route.params.id, step: step.start.stepOrder }}">
                    <span class="step">
                      {{ step.start.stepOrder }}
                    </span>
                    <span class="step__title">{{ step.start.title }}
                      <span
                        v-if="step.prompt"
                        class="prompt__required"
                      >
                        <br>This step has required tasks.
                      </span>
                    </span>
                  </router-link>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div class="project-tab__heading">
        <h1>{{ project.title }}</h1>

        <div class="switch switch__desktop">
          <span>Facilitation Notes</span>
          <label class="label-switch">
            <input
              id="facilitator_note_toggle"
              v-model="facilitatorCheck"
              type="checkbox"
            >
            <checkbox-label />
          </label>
        </div>
      </div>

      <div class="project__content">
        <template v-for="step in steps">
          <div
            v-if="parseInt(currentStep) === parseInt(step.start.stepOrder)"
            :key="step.start.stepOrder"
            class="steps__content"
          >
            <div class="project__step-header">
              <a
                class="step-pagination step-pagination__previous"
                href="#"
                :class="{'step-pagination--hidden': isFirstPage}"
                @click.stop.prevent="previous"
              >
                <prev-page-icon />
              </a>

              <div class="step-header__title">
                <span class="title__step-number">Step {{ currentStep }}:</span> {{ step.start.title }}
              </div>

              <a
                class="step-pagination step-pagination__next"
                href="#"
                :class="{'step-pagination--hidden': isLastPage}"
                @click.stop.prevent="next"
              >
                <next-page-icon />
              </a>
            </div>

            <div class="switch__mobile">
              <hr>
              <div class="switch">
                <span>Facilitation Notes</span>
                <label class="label-switch">
                  <input
                    :id="'facilitator_note_toggle'"
                    v-model="facilitatorCheck"
                    type="checkbox"
                  >
                  <checkbox-label />
                </label>
              </div>
            </div>

            <evidence-step
              v-for="(stepContent, index) in step.content"
              :key="index"
              :ie-class="ieClass"
              :index="index"
              :show-note="facilitatorCheck"
              :step="stepContent"
            />

            <div class="clearfix" />

            <project-evidence-manager
              v-if="step.prompt"
              :for-step="{ stepId, stepOrder: currentStep }"
              :get-project="getProject"
              :hide-extra="true"
              :is-loading="isLoading"
              :project="project"
              :prompts="[step.prompt]"
            />
          </div>
        </template>

        <div class="step-footer">
          <hr class="step-footer__hr">

          <div class="step-footer__actions">
            <a
              class="step-pagination step-pagination__previous"
              href="#"
              :class="{'step-pagination--hidden': isFirstPage}"
              @click.stop.prevent="previous"
            >
              <prev-page-icon />
            </a>

            <a
              class="step-pagination step-pagination__next"
              href="#"
              :class="{'step-pagination--hidden': isLastPage}"
              @click.stop.prevent="next"
            >
              <next-page-icon />
            </a>

            <a
              v-if="isLastPage"
              class="button button__review"
              href="#"
              @click.stop.prevent="reviewWork"
            >
              <span class="review-text">Review My Work</span>

              <span class="review-icon">
                <review-next-icon />
              </span>
            </a>
          </div>
        </div>
      </div>

      <div class="project__steps-nav steps__nav-desktop">
        <ol class="steps">
          <li
            v-for="(step, o) in steps"
            :key="o"
          >
            <router-link :to="{ name: 'project-steps', params: { id: $route.params.id, step: step.start.stepOrder }}">
              <span class="step">{{ step.start.stepOrder }}</span>
              <span>{{ step.start.title }} <span
                v-if="step.prompt"
                class="prompt__required"
              ><br>This step has required tasks.</span></span>
            </router-link>
          </li>
        </ol>
      </div>

      <!-- Modal award start -->
      <collaborator-modal :group-name="groupName" />
    </div>
    <div v-show="!isLoading && !project.hasPackAccess">
      <div class="card no-access__card">
        <div class="no-access__overlay">
          <hidden-eye-icon />
          <span class="overlay__text">Project Hidden</span>
        </div>
        <!--   eslint-disable     -->
        <div
          class="no-access__image"
          v-html="project.imgix"
        />
        <!--   eslint-enable     -->

        <div class="no-access__content">
          <h1>{{ project.title }}</h1>
          <p v-text="project.teaser" />
        </div>
      </div>
      <div class="no-access__message">
        <hidden-eye-filled-icon />
        <p>Your current membership does not include access to this project.</p>
        <p>
          To learn about adding more projects to your Mouse Create membership, please visit
          <a
            href="https://mouse.org/join"
            target="_blank"
          >mouse.org/join</a> or email us at
          <a href="mailto:membership@mouse.org">membership@mouse.org.</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import modalMixin from '../../mixins/modal';
import CollaboratorModal from '../modals/CollaboratorModal.vue';
import EvidenceStep from './steps/EvidenceStep.vue';
import ProjectEvidenceManager from './steps/ProjectEvidenceManager.vue';
import CheckboxLabel from './labels/CheckboxLabel.vue';
import ReviewNextIcon from './icons/ReviewNextIcon.vue';
import HiddenEyeIcon from './icons/HiddenEyeIcon.vue';
import HiddenEyeFilledIcon from './icons/HiddenEyeFilledIcon.vue';
import NextPageIcon from './icons/NextPageIcon.vue';
import PrevPageIcon from './icons/PrevPageIcon.vue';

export default {
  name: 'ProjectSteps',
  components: {
    PrevPageIcon,
    NextPageIcon,
    HiddenEyeFilledIcon,
    HiddenEyeIcon,
    ReviewNextIcon,
    CheckboxLabel,
    ProjectEvidenceManager,
    CollaboratorModal,
    EvidenceStep,
  },
  mixins: [modalMixin],
  props: {},
  data() {
    return {
      project: {
        title: '',
      },
      pageTitle: 'Mouse Create',
      steps: {},
      currentStep: '1',
      isLoading: true,
      ieClass: false,
      isFilteredActive: false,
    };
  },
  metaInfo() {
    return {
      title: this.getTitle(),
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.users.current;
    },
    activeGroup() {
      return this.$store.state.groups.active;
    },
    groupName() {
      return this.activeGroup.name;
    },
    getProjectRoute() {
      return laroute.route('api.projects.show', {
        project: this.$route.params.id,
        include: 'details',
      });
    },
    stepId() {
      const stepObject = _.find(this.steps, (s) => s.start.stepOrder === parseInt(this.currentStep, 10));
      return (stepObject) ? stepObject.start.stepId : null;
    },
    isFirstPage() {
      return parseInt(this.currentStep, 10) === 1;
    },
    isLastPage() {
      return parseInt(this.currentStep, 10) === this.totalSteps;
    },
    totalSteps() {
      return Object.keys(this.steps).length;
    },
    canSubmitEvidence() {
      return _.has(this.steps[this.currentStep], 'prompt');
    },
    showGroupRoute() {
      return laroute.route('api.group.show', { group: this.currentUser.defaultGroupId });
    },
    facilitatorCheck: {
      get() {
        return this.$store.state.steps.facilitationNotes;
      },
      set(value) {
        this.$store.dispatch('setFacilitationNotes', value);
      },
    },
  },
  watch: {
    $route(to) {
      this.currentStep = to.params.step;
      this.isFilteredActive = false;

      window.scrollTo(0, 0);
    },
    currentUser() {
      if (this.currentUser.isStudent) {
        this.$store.dispatch('setFacilitationNotes', false);
      }
    },
  },
  mounted() {
    this.getProject();
    this.currentStep = (this.$route.params.step) ? this.$route.params.step : 1;

    if (this.currentUser.isStudent) {
      this.$store.dispatch('setFacilitationNotes', false);
    }

    if (this.$route.name !== 'project-steps') {
      this.$router.push({
        name: 'project-steps',
        params: {
          id: this.$route.params.id,
          step: `${this.currentStep}`,
        },
      });
    }
    this.checkBrowser();
  },
  methods: {
    getProject() {
      axios.get(this.getProjectRoute)
        .then((response) => {
          this.project = response.data;
          this.pageTitle = this.project.title;

          if (this.project.hasPackAccess) {
            this.steps = this.project.details.steps;
            bus.$emit('enable:tabs');
          } else {
            bus.$emit('disable:tabs');
          }
          this.isLoading = false;
        });
    },
    getTitle() {
      return `${this.pageTitle} - Step - ${this.currentStep}`;
    },
    next() {
      this.currentStep = parseInt(this.currentStep, 10) + 1;
      this.updateRoute();
    },
    previous() {
      this.currentStep -= 1;
      this.updateRoute();
    },
    updateRoute() {
      this.$router.push({
        name: 'project-steps',
        params: {
          id: this.$route.params.id,
          step: `${this.currentStep}`,
        },
      });
    },
    reviewWork() {
      this.$router.push({ name: 'project-add-work' });
      window.scrollTo(0, 0);
    },
    filterSteps() {
      this.isFilteredActive = !this.isFilteredActive;
    },
    checkBrowser() {
      const browser = detection.detect();

      if (browser.name === 'ie') {
        this.ieClass = true;
      }
    },
  },
};
</script>
