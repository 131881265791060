import { groupRolesHelper } from '../helpers/groupRolesHelper';

const GroupUserRolePlugin = {
  install: function installGroupUserRolePlugin(Vue) {
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$groupUser = function pluginGroupRolesHelper() {
      return groupRolesHelper(this.$store.state.groups.active.userRole);
    };
  },
};

export default GroupUserRolePlugin;
