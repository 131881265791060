var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "evidence-creators" }, [
        _c("div", { staticClass: "step-heading" }, [
          _c("h2", [_vm._v("Creators")]),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              "Add all group members who collaborated with you on this work.\n        "
            ),
            _c(
              "a",
              {
                staticClass: "creators__edit",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.addCreator($event)
                  },
                },
              },
              [
                _c("span", { staticClass: "creators__edit-text" }, [
                  _vm._v("Edit"),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "creators__edit-icon" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "13px",
                        height: "13px",
                        viewBox: "0 0 13 13",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("defs"),
                      _vm._v(" "),
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "Symbols",
                            stroke: "none",
                            "stroke-width": "1",
                            fill: "none",
                            "fill-rule": "evenodd",
                          },
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "Creators-block",
                                transform: "translate(-643.000000, -25.000000)",
                                fill: "#00A886",
                              },
                            },
                            [
                              _c("g", { attrs: { id: "Creators" } }, [
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      id: "edit-on-white",
                                      transform:
                                        "translate(611.000000, 0.000000)",
                                    },
                                  },
                                  [
                                    _c(
                                      "g",
                                      {
                                        attrs: {
                                          id: "pencil30",
                                          transform:
                                            "translate(32.000000, 25.000000)",
                                        },
                                      },
                                      [
                                        _c("g", { attrs: { id: "Group" } }, [
                                          _c("path", {
                                            attrs: {
                                              id: "Shape",
                                              d: "M0.0287110698,9.20044633 L0.0287110698,12.6720597 L3.45513462,12.6720597 L10.3079817,5.72883296 L6.88155817,2.2572196 L0.0287110698,9.20044633 L0.0287110698,9.20044633 Z M3.01860505,11.6038316 L3.01860505,11.6038316 L2.13725608,11.603934 L2.13725608,10.5357059 L1.08303412,10.5357059 L1.08303412,9.6426307 L1.8325548,8.88322488 L3.76812572,10.8443233 L3.01860505,11.6038316 L3.01860505,11.6038316 Z M7.14521476,3.67595847 C7.26592257,3.67595847 7.32627647,3.73721079 7.32627647,3.85951057 C7.32627647,3.91523174 7.30716946,3.96245133 7.26865214,4.00147664 L2.80438407,8.52462227 C2.76596785,8.56344271 2.71926182,8.58290415 2.66436706,8.58290415 C2.54365925,8.58290415 2.48320425,8.52154941 2.48320425,8.39935206 C2.48320425,8.34363089 2.50241236,8.29641129 2.54082858,8.25738598 L7.00509665,3.73434279 C7.04351287,3.69541991 7.09021891,3.67595847 7.14521476,3.67595847 L7.14521476,3.67595847 Z",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("path", {
                                            attrs: {
                                              id: "Shape",
                                              d: "M12.2023058,2.29880562 L10.2667348,0.346106322 C10.0581752,0.13479607 9.80826758,0.0289873007 9.51721416,0.0289873007 C9.2206005,0.0289873007 8.97362465,0.13479607 8.7759833,0.346106322 L7.40877025,1.72305432 L10.8351938,5.19466769 L12.202508,3.80942297 C12.4057095,3.60354143 12.5072092,3.35320588 12.5072092,3.05831387 C12.5071081,2.76905543 12.4056084,2.51585187 12.2023058,2.29880562 L12.2023058,2.29880562 Z",
                                            },
                                          }),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "clearfix" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "creators__all" },
          [
            _vm.currentUser
              ? _c("div", { staticClass: "evidence-creator" }, [
                  _c("div", {
                    staticClass: "evidence-creator__profile",
                    domProps: { innerHTML: _vm._s(_vm.currentUser.avatar) },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "evidence-creator__name" }, [
                    _vm._v("\n          You!\n        "),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.evidenceCollaborators, function (user) {
              return _c(
                "div",
                { key: user.id, staticClass: "evidence-creator" },
                [
                  _c("div", {
                    staticClass: "evidence-creator__profile",
                    domProps: { innerHTML: _vm._s(user.avatar) },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "evidence-creator__name" }, [
                    _vm._v(
                      "\n          " + _vm._s(user.username) + "\n        "
                    ),
                  ]),
                ]
              )
            }),
            _vm._v(" "),
            _c("div", { staticClass: "evidence-creator" }, [
              _c(
                "a",
                {
                  staticClass: "evidence__creator--add",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.addCreator($event)
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "17px",
                        height: "18px",
                        viewBox: "0 0 17 18",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("defs"),
                      _vm._v(" "),
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "Symbols",
                            stroke: "none",
                            "stroke-width": "1",
                            fill: "none",
                            "fill-rule": "evenodd",
                            "font-family": "Basetica-Medium, Basetica",
                            "font-size": "36",
                            "font-weight": "400",
                          },
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "Creators-block",
                                transform: "translate(-174.000000, -76.000000)",
                                fill: "#FFFFFF",
                              },
                            },
                            [
                              _c("g", { attrs: { id: "Creators" } }, [
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      id: "Green-top-area",
                                      transform:
                                        "translate(0.000000, 51.000000)",
                                    },
                                  },
                                  [
                                    _c(
                                      "g",
                                      { attrs: { id: "creators---images" } },
                                      [
                                        _c("g", { attrs: { id: "row-1" } }, [
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                id: "Add-collaborator----ICON-Copy",
                                                transform:
                                                  "translate(141.000000, 0.000000)",
                                              },
                                            },
                                            [
                                              _c(
                                                "g",
                                                {
                                                  attrs: {
                                                    id: "Group-5",
                                                    transform:
                                                      "translate(19.000000, 12.000000)",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "text",
                                                    { attrs: { id: "+" } },
                                                    [
                                                      _c(
                                                        "tspan",
                                                        {
                                                          attrs: {
                                                            x: "12.376699",
                                                            y: "35",
                                                          },
                                                        },
                                                        [_vm._v("+")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "evidence-creator__name evidence-add__more" },
                [_vm._v("\n          Add More Creators\n        ")]
              ),
            ]),
          ],
          2
        ),
        _vm._v(" "),
        _c("hr"),
      ]),
      _vm._v(" "),
      _c("collaborator-modal", {
        attrs: {
          "evidence-collaborators": _vm.evidenceCollaborators,
          "group-name": _vm.groupName,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }