<template>
  <main>
    <section
      id="group"
      class="group-tab primary-content"
    >
      <div class="wrap-12">
        <div class="back_link tab__back-button">
          <a
            href="#"
            class="gallery__back-to"
            @click="backToPlaylist"
          >
            <span class="gallery__back-to-icon">
              <svg
                width="9px"
                height="17px"
                viewBox="0 0 9 17"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Shape</title>
                <desc>Created with Sketch.</desc>
                <g
                  id="Page-1"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="Project-Gallery-Browser---med/tablet"
                    transform="translate(-23.000000, -204.000000)"
                    fill="#00A886"
                  >
                    <g
                      id="back-to-profile-copy"
                      transform="translate(23.000000, 203.000000)"
                    >
                      <path
                        id="Shape"
                        d="M12.4801154,11.237282 L5.5086642,5.27180751 C5.22997992,5.04574032 4.90456303,4.93270673 4.53280847,4.93270673 C4.15381364,4.93270673 3.83208271,5.04574032 3.56748402,5.27180751 L-3.41463012,11.237282 C-3.68633743,11.4693101 -3.82219108,11.747339 -3.82219108,12.0711437 C-3.82219108,12.4010219 -3.68620578,12.6759016 -3.41463012,12.8957828 L-2.61030279,13.5829821 C-2.35281273,13.8151227 -2.0310818,13.9313055 -1.64497835,13.9313055 C-1.2588749,13.9313055 -0.937143967,13.8150103 -0.679653907,13.5829821 L4.53280847,9.13868114 L9.74527084,13.5828696 C10.0026293,13.8148978 10.3243602,13.9311931 10.7107269,13.9311931 C11.0894585,13.9311931 11.415007,13.8148978 11.6865827,13.5828696 L12.4907783,12.8956704 C12.755377,12.6696032 12.887808,12.3947235 12.887808,12.0710313 C12.887808,11.7472266 12.7519544,11.4691977 12.4801154,11.237282 L12.4801154,11.237282 Z"
                        transform="translate(4.532808, 9.432006) rotate(-90.000000) translate(-4.532808, -9.432006) "
                      />
                    </g>
                  </g>
                </g>
              </svg>

            </span>
            <span class="gallery__back-to-text">Back to Playlist</span>
          </a>
        </div>

        <div v-if="$groupUser().can('manage_group')">
          <div class="header__intro">
            <h1 class="header__admin__text__title title--no-margin">
              Manage Playlist
            </h1>
          </div>

          <div class="">
            <p>Select "Clear Playlist" to remove all projects at once, or you can change the playlist order and remove individual projects below.</p>
            <a href="#" @click.stop.preven="clearPlaylist" class="button">Clear Playlist</a>
          </div>

          <div id="playlist_update_message" />

          <form>
            <input
              type="hidden"
              name="_method"
              value="PUT"
            >

            <div
              id="playlist_card_management"
              class="items-container"
            >
              <template v-if="projects && projects.length > 0">
                <div
                  v-for="(project, index) in projects"
                  :key="project.id"
                  class="card playlist-card"
                >
                  <i
                    class="fa fa-arrows"
                    aria-hidden="true"
                  />
                  <span v-html="project.imgix" />
                  <h2 class="small-card">
                    {{ project.title }}
                  </h2>
                  <input
                    type="hidden"
                    :name="'projects[' + project.id + ']'"
                    class="project_order"
                    :value="index"
                  >
                  <a
                    class="remove-from-group"
                    @click.stop.prevent="removeFromGroup(project.id)"
                  >Remove from Group <i
                    class="fa fa-minus-square"
                    aria-hidden="true"
                  /></a>
                </div>
              </template>
              <span v-else>
                There are no projects in this playlist.
              </span>
            </div>
          </form>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: 'ManagePlaylist',
  data: () => ({
    projects: [],
    newProjectOrder: [],
  }),
  computed: {
    currentUser() {
      return this.$store.state.users.current;
    },
    group() {
      return this.$store.state.groups.active;
    },
    getGroupProjectsRoute() {
      return laroute.route('api.group.projects', { group: this.group.id });
    },

  },
  watch: {
    group() {
      this.managePlaylist();
      this.getAllProjectsByGroup();
    },
  },
  mounted() {
    this.managePlaylist();
    this.getAllProjectsByGroup();

    document.body.className += ' single-column-12';
  },
  methods: {
    managePlaylist() {
      const drake = dragula([document.querySelector('#playlist_card_management')], {
        // revertOnSpill: true,
      });

      drake.on('drop', (el, target, source) => {
        const cards = $(source).find('.project_order');
        const data = {
          _method: 'PUT',
        };

        _.forEach(cards, (value, order) => {
          // eslint-disable-next-line no-param-reassign
          order += 1;
          const input = $(value);
          data[input.attr('name')] = order;
        });

        axios.post(`/api/group/${this.group.id}/manage-playlist`, data).then(() => {});
      });
    },

    clearPlaylist() {
      let route = laroute.route('api.playlist.clear', { group: this.group.id })
      axios.put(route)
        .then((response) => {
          this.getAllProjectsByGroup();

          this.$store.dispatch('loadMessage', {
            content: response.data.message,
            type: 'success',
            icon: '',
            autoclose: true,
          });
        });
    },

    backToPlaylist() {
      bus.$emit('set-active-tab', 'playlist');
    },

    getAllProjectsByGroup() {
      axios.get(this.getGroupProjectsRoute)
        .then((response) => {
          this.projects = response.data.data;
          $('.card').matchHeight();
          this.managePlaylist();
        });
    },

    removeFromGroup(projectId) {
      let route = laroute.route('api.playlist.remove', { group: this.group.id, project: projectId })
      axios.put(route)
        .then((response) => {
          this.getAllProjectsByGroup();

          this.$store.dispatch('loadMessage', {
            content: response.data.message,
            type: 'success',
            icon: '',
            autoclose: true,
          });
        }).catch((error) => {
          console.log(error)
          console.log(error.response)
        });
    },
  },
};
</script>
