<template>
  <div class="full-wrap">
    <div class="page-wrap">
      <main>
        <section class="primary-content">
          <div class="content-box">
            <profile-edit />
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>

import ProfileEdit from './ProfileEdit.vue';

export default {
  name: 'ProfileEducatorEdit',
  components: {
    ProfileEdit,
  },
  props: {},
  data: () => ({

  }),
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>
