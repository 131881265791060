var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.badges.length > 0
    ? _c("div", { staticClass: "modal modal__global" }, [
        _c("input", {
          staticClass: "modal-state",
          attrs: { id: "badge-award-modal", type: "checkbox" },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "modal-fade-screen" }, [
          _c("div", { staticClass: "modal-inner" }, [
            _c("div", { staticClass: "modal__header" }, [
              _c("button", {
                staticClass: "modal-close",
                attrs: {
                  id: "close-button",
                  for: "badge-award-modal",
                  "aria-label": "Close this dialog window",
                },
                on: { click: _vm.closeAwardModal },
              }),
              _vm._v(" "),
              _c("h2", [_vm._v("Award Badge")]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal__content" },
              [
                !_vm.award.user.of_age
                  ? [
                      _c("h3", [
                        _vm._v(
                          "This user is under 13 and is not able to earn badges yet."
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._m(0),
                    ]
                  : !_vm.award.user.can_award
                  ? [
                      _c("h3", [
                        _vm._v(
                          "This badge is pending. The user must opt-in to earn badges first."
                        ),
                      ]),
                      _vm._v(" "),
                      _vm._m(1),
                    ]
                  : [
                      _vm.awardComplete
                        ? _c("div", [
                            _c("h3", [
                              _vm._v("Badge "),
                              _c("strong", [
                                _vm._v(_vm._s(_vm.award.badge.title)),
                              ]),
                              _vm._v(
                                " has been awarded to " +
                                  _vm._s(_vm.award.user.profile.short_name)
                              ),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.awardError
                        ? _c("div", [
                            _c("h3", {
                              domProps: {
                                innerHTML: _vm._s(_vm.awardErrorText),
                              },
                            }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.awardComplete || !_vm.awardError
                        ? _c("div", [
                            _c("p", [
                              _c("strong", [_vm._v("User:")]),
                              _vm._v(
                                " " + _vm._s(_vm.award.user.profile.short_name)
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _c("strong", [_vm._v("Badge:")]),
                              _vm._v(" " + _vm._s(_vm.award.badge.title)),
                            ]),
                            _vm._v(" "),
                            _c("label", { staticClass: "terms__label" }, [
                              _vm._v("Terms"),
                            ]),
                            _vm._v(" "),
                            _vm._m(2),
                            _vm._v(" "),
                            _c("p", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.agreeToTerms,
                                    expression: "agreeToTerms",
                                  },
                                ],
                                attrs: {
                                  id: "badge-award-terms-check",
                                  type: "checkbox",
                                  value: "Y",
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.agreeToTerms)
                                    ? _vm._i(_vm.agreeToTerms, "Y") > -1
                                    : _vm.agreeToTerms,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.agreeToTerms,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = "Y",
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.agreeToTerms = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.agreeToTerms = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.agreeToTerms = $$c
                                    }
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm._m(3),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-row short-row" }, [
                        !_vm.awardComplete
                          ? _c("div", [
                              _c(
                                "button",
                                {
                                  staticClass: "button",
                                  attrs: {
                                    disabled: !_vm.agreeToTerms,
                                    type: "button",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.awardBadge($event)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                Award Badge\n              "
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ]),
                    ],
              ],
              2
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n            Mouse badges are shared via "),
      _c("a", { attrs: { href: "https://credly.com", target: "_blank" } }, [
        _c("span", { staticClass: "sr-only" }, [
          _vm._v("(opens in a new tab)"),
        ]),
        _vm._v("credly.com"),
      ]),
      _vm._v(
        ", a service that gives users the option to display their\n            badges publicly. COPPA regulations require that users be at least 13 to share information publicly.\n            "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://tools.mouse.org/help/badges",
            target: "_blank",
          },
        },
        [
          _c("span", { staticClass: "sr-only" }, [
            _vm._v("(opens in a new tab)"),
          ]),
          _vm._v("Learn more about Badges here"),
        ]
      ),
      _vm._v(".\n          "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(
        '\n            Users who are 13 or older must go to their Profile page and select "Edit Account Settings" to opt-in\n            to earn badges. '
      ),
      _c("a", { attrs: { href: "https://tools.mouse.org/badge-directory/" } }, [
        _vm._v("Learn more about Badges here"),
      ]),
      _vm._v(".\n          "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal__terms small full" }, [
      _vm._v(
        "\n              By awarding a badge to this student, the badge information will be added to the student's account\n              on "
      ),
      _c("a", { attrs: { href: "https://credly.com", target: "_blank" } }, [
        _c("span", { staticClass: "sr-only" }, [
          _vm._v("(opens in a new tab)"),
        ]),
        _vm._v("Credly"),
      ]),
      _vm._v(
        ".\n              Mouse has no control of that website, and your use of "
      ),
      _c("a", { attrs: { href: "https://credly.com", target: "_blank" } }, [
        _c("span", { staticClass: "sr-only" }, [
          _vm._v("(opens in a new tab)"),
        ]),
        _vm._v("Credly's"),
      ]),
      _vm._v(
        " website is\n              subject to the terms of use and privacy policy posted on "
      ),
      _c("a", { attrs: { href: "https://credly.com", target: "_blank" } }, [
        _c("span", { staticClass: "sr-only" }, [
          _vm._v("(opens in a new tab)"),
        ]),
        _vm._v("Credly"),
      ]),
      _vm._v(
        ".\n              You represent that those students to be awarded badges through "
      ),
      _c("a", { attrs: { href: "https://credly.com", target: "_blank" } }, [
        _c("span", { staticClass: "sr-only" }, [
          _vm._v("(opens in a new tab)"),
        ]),
        _vm._v("Credly"),
      ]),
      _vm._v(
        "\n              have satisfactorily achieved the requirements for the badge to be awarded, and that the appropriate\n              permissions and consents have been obtained from the student and his or her parent or guardian to\n              participate in the badge program and use the "
      ),
      _c("a", { attrs: { href: "https://credly.com", target: "_blank" } }, [
        _c("span", { staticClass: "sr-only" }, [
          _vm._v("(opens in a new tab)"),
        ]),
        _vm._v("Credly"),
      ]),
      _vm._v(
        " website, including any\n              requirements of the Children's Online Protection Act (COPPA) and the Family Educational Rights and Privacy Act (FERPA).\n            "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "badge-award-terms-check" } }, [
      _c("strong", [_vm._v("Agree To Terms")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }