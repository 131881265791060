<template>
  <div class="project-tab">
    <h1>{{ project.title }}</h1>

    <div
      v-if="!isLoading && project.hasPackAccess"
      class="project__plan"
    >
      <evidence-step
        v-for="(step, index) in plans"
        :key="index"
        :step="step"
      />
    </div>

    <div v-show="!isLoading && !project.hasPackAccess">
      <p>You do not have access to this project</p>
    </div>
  </div>
</template>

<script>

import EvidenceStep from './steps/EvidenceStep.vue';

export default {
  name: 'ProjectPlan',
  components: {
    EvidenceStep,
  },
  props: {},
  data() {
    return {
      project: {
        title: '',
      },
      plans: {},
      pageTitle: 'Mouse Create',
      isLoading: true,
    };
  },
  metaInfo() {
    return {
      title: this.getTitle(),
    };
  },
  computed: {
    getShowPlanRoute() {
      return laroute.route('api.projects.show.plan', {
        project: this.$route.params.id,
      });
    },
    getProjectRoute() {
      return laroute.route('api.projects.show', {
        project: this.$route.params.id,
      });
    },
  },
  watch: {},
  mounted() {
    this.getShowPlan();
    this.getProject();

    if (this.$route.name !== 'project-plan') {
      this.$router.push({ name: 'project-plan' });
    }
  },
  methods: {
    getShowPlan() {
      axios.get(this.getShowPlanRoute)
        .then((response) => {
          this.plans = response.data.data;
          bus.$emit('enable:tabs');
        });
    },
    getProject() {
      axios.get(this.getProjectRoute)
        .then((response) => {
          this.project = response.data;
          this.pageTitle = this.project.title;
          this.isLoading = false;
        });
    },
    getTitle() {
      return `${this.pageTitle} - Plan`;
    },
  },
};
</script>
