export default {
  methods: {
    isImage(asset) {
      return asset.type.name === 'Image';
    },

    isFile(asset) {
      return asset.type.name === 'File';
    },

    isText(asset) {
      return asset.type.name === 'Text';
    },
  },
};
