<template>
  <svg
    width="21px"
    height="21px"
    viewBox="-3 -3 21 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="close-X"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="square"
    >
      <path
        id="Line-1"
        d="M0.5,0.5 L14.6421356,14.6421356"
        stroke="#028E6D"
        stroke-width="4"
      />
      <path
        id="Line-2"
        d="M0.5,0.5 L14.6421356,14.6421356"
        stroke="#028E6D"
        stroke-width="4"
        transform="translate(7.500000, 7.500000) scale(-1, 1) translate(-7.500000, -7.500000) "
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ProjectReviewCloseModalIcon',
};
</script>
