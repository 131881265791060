var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "section",
        { staticClass: "course__page" },
        [
          _c(
            "div",
            {
              staticClass: "course__top-header",
              class: { "course__top-header--fixed": _vm.passedScroll },
            },
            [
              _c("div", { staticClass: "course__heading" }, [
                _c("div", { staticClass: "course__icon" }, [
                  _c("img", {
                    attrs: { src: _vm.course.icon, alt: "course icon" },
                  }),
                ]),
                _vm._v(" "),
                _c("h1", { staticClass: "course__title" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.course.title) + "\n        "
                  ),
                ]),
                _vm._v(" "),
                _vm.currentUser.isEducator && _vm.$groupUser().can("add_course")
                  ? _c("div", { staticClass: "course__add-to-course" }, [
                      _c(
                        "button",
                        {
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.addToPlaylist($event)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            Add Course to Playlist\n            "
                          ),
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "15px",
                                height: "15px",
                                viewBox: "0 0 15 15",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("title", [_vm._v("+")]),
                              _vm._v(" "),
                              _c("desc", [_vm._v("Created with Sketch.")]),
                              _vm._v(" "),
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "Page-1",
                                    stroke: "none",
                                    "stroke-width": "1",
                                    fill: "none",
                                    "fill-rule": "evenodd",
                                    "font-family": "Basetica-Medium, Basetica",
                                    "font-size": "31",
                                    "font-weight": "400",
                                  },
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        id: "Courses-Page----Default-v2",
                                        transform:
                                          "translate(-1298.000000, -243.000000)",
                                        fill: "#FFFFFF",
                                      },
                                    },
                                    [
                                      _c(
                                        "g",
                                        {
                                          attrs: {
                                            id: "Course-Description.---CLOSED",
                                            transform:
                                              "translate(103.000000, 230.000000)",
                                          },
                                        },
                                        [
                                          _c(
                                            "g",
                                            {
                                              attrs: {
                                                id: "Add-new-btn",
                                                transform:
                                                  "translate(976.000000, 0.000000)",
                                              },
                                            },
                                            [
                                              _c(
                                                "text",
                                                { attrs: { id: "+" } },
                                                [
                                                  _c(
                                                    "tspan",
                                                    {
                                                      attrs: {
                                                        x: "218.258",
                                                        y: "32",
                                                      },
                                                    },
                                                    [_vm._v("+")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "section",
            {
              staticClass:
                "course__row course__bottom-border content__has-lists",
            },
            [
              _c("div", {
                staticClass: "course__description",
                class: { "full-height": _vm.descriptionExpanded },
                domProps: { innerHTML: _vm._s(_vm.course.description) },
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "course__more-button",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.toggleDescription($event)
                    },
                  },
                },
                [
                  !_vm.descriptionExpanded
                    ? _c("span", [_vm._v("Show More")])
                    : _c("span", [_vm._v("Show Less")]),
                  _vm._v(" "),
                  _vm.descriptionExpanded
                    ? _c("span", [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "27px",
                              height: "4px",
                              viewBox: "0 0 27 4",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("title", [_vm._v("Line 2")]),
                            _vm._v(" "),
                            _c("desc", [_vm._v("Created with Sketch.")]),
                            _vm._v(" "),
                            _c(
                              "g",
                              {
                                attrs: {
                                  id: "Page-1",
                                  stroke: "none",
                                  "stroke-width": "1",
                                  fill: "none",
                                  "fill-rule": "evenodd",
                                  "stroke-linecap": "square",
                                },
                              },
                              [
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      id: "Courses-Page---Open-v2",
                                      transform:
                                        "translate(-201.000000, -1136.000000)",
                                      "fill-rule": "nonzero",
                                      stroke: "#00A886",
                                      "stroke-width": "4",
                                    },
                                  },
                                  [
                                    _c(
                                      "g",
                                      {
                                        attrs: {
                                          id: "Course-Description.---OPEN",
                                          transform:
                                            "translate(103.000000, 230.000000)",
                                        },
                                      },
                                      [
                                        _c(
                                          "g",
                                          {
                                            attrs: {
                                              id: "View-Less",
                                              transform:
                                                "translate(2.000000, 898.000000)",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                id: "Line-2",
                                                d: "M98.5,10 L121,10",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    : _c("span", [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "20px",
                              height: "20px",
                              viewBox: "0 0 20 20",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c("title", [_vm._v("Group 4")]),
                            _vm._v(" "),
                            _c("desc", [_vm._v("Created with Sketch.")]),
                            _vm._v(" "),
                            _c(
                              "g",
                              {
                                attrs: {
                                  id: "Page-1",
                                  stroke: "none",
                                  "stroke-width": "1",
                                  fill: "none",
                                  "fill-rule": "evenodd",
                                  "stroke-linecap": "square",
                                },
                              },
                              [
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      id: "Courses-Page----Default-v2",
                                      transform:
                                        "translate(-206.000000, -450.000000)",
                                      "fill-rule": "nonzero",
                                      stroke: "#00A886",
                                      "stroke-width": "3.2",
                                    },
                                  },
                                  [
                                    _c(
                                      "g",
                                      {
                                        attrs: {
                                          id: "View-More",
                                          transform:
                                            "translate(105.000000, 451.000000)",
                                        },
                                      },
                                      [
                                        _c(
                                          "g",
                                          {
                                            attrs: {
                                              id: "Group-4",
                                              transform:
                                                "translate(103.000000, 1.000000)",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                id: "Line-2",
                                                d: "M0.347826087,8 L16,8",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              attrs: {
                                                id: "Line-2-Copy",
                                                d: "M0.173913043,8.17391304 L15.826087,8.17391304",
                                                transform:
                                                  "translate(8.000000, 8.173913) rotate(-270.000000) translate(-8.000000, -8.173913) ",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _vm.course.badges
            ? _c("course-badges", { attrs: { badges: _vm.course.badges.data } })
            : _vm._e(),
          _vm._v(" "),
          _vm.course.projects
            ? _c("course-projects", {
                attrs: {
                  projects: _vm.course.projects.data,
                  "group-project-ids": _vm.groupProjectIds,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.course.projects
        ? _c("course-related-projects", {
            attrs: {
              projects: _vm.course.relatedProjects.data,
              description: _vm.course.related_description,
              "group-project-ids": _vm.groupProjectIds,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }