<template>
  <div class="project-filter-list">
    <div
      v-if="courses.length > 0"
      class="filter-block"
    >
      <h3>CHOOSE A COURSE</h3>
      <ul class="who-categories">
        <li
          v-for="course in courses"
          :key="course.id"
        >
          <router-link
            :to="'/projects/courses/' + course.slug"
            class="filter tag"
          >
            {{ course.title }}
          </router-link>
        </li>
      </ul>
    </div>

    <div class="filter-block">
      <h3>CHOOSE A SKILL</h3>
      <ul class="what-categories">
        <project-filter-link
          v-for="category in categories.what"
          :key="category.categoryId"
          :data-id="category.categoryId"
          :data-label="category.categoryName"
          :category="category"
          :category-counts="categoryCounts"
          :active-categories="activeCategories"
        />
      </ul>
    </div>

    <div class="filter-block">
      <h3>CHOOSE A SETTING</h3>
      <ul class="when-categories">
        <project-filter-link
          v-for="category in categories.when"
          :key="category.categoryId"
          :data-id="category.categoryId"
          :data-label="category.categoryName"
          :category="category"
          :category-counts="categoryCounts"
          :active-categories="activeCategories"
        />
      </ul>
    </div>
  </div>
</template>

<script>

import ProjectFilterLink from './ProjectFilterLink.vue';

export default {
  name: 'ProjectFinderFilters',
  components: { ProjectFilterLink },
  props: {
    courses: {
      type: Array,
      default() {
        return [];
      },
    },
    categories: {
      type: Object,
      default() {
        return {};
      },
    },
    categoryCounts: {
      type: Object,
      default() {
        return {};
      },
    },
    activeCategories: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: () => ({

  }),
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>
