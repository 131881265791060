var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    [
      _c("group-delete-modal", {
        attrs: { "group-name": _vm.groupName, group: _vm.group },
      }),
      _vm._v(" "),
      _c("section", { staticClass: "group-tab primary-content" }, [
        _c("div", { staticClass: "group-fixed-container" }, [
          _c("div", { staticClass: "header__admin header__admin--no-rule" }, [
            _c("div", { staticClass: "header__admin__text" }, [
              _c("h1", { staticClass: "header__admin__text__title" }, [
                _vm._v(
                  "\n            Edit Group: " +
                    _vm._s(_vm.groupName) +
                    "\n          "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.editMode
            ? _c("div", [
                _c(
                  "form",
                  { staticClass: "simple-edit", attrs: { method: "post" } },
                  [
                    _c("input", {
                      attrs: { type: "hidden", name: "_method", value: "PUT" },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row" }, [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.groupName,
                            expression: "groupName",
                          },
                        ],
                        attrs: {
                          id: "name",
                          type: "text",
                          name: "name",
                          placeholder: "Group Name",
                        },
                        domProps: { value: _vm.groupName },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.groupName = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.first("name")
                        ? _c("div", { staticClass: "form-error form-row" }, [
                            _c("span", [
                              _c("strong", [
                                _vm._v(_vm._s(_vm.errors.first("name"))),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row align-right" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button",
                          attrs: { type: "submit" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.editGroup($event)
                            },
                          },
                        },
                        [_vm._v("\n              Save Changes\n            ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "cancel" },
                        [
                          _vm._v("or "),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "group-playlist",
                                  params: { id: _vm.group.id },
                                },
                              },
                            },
                            [_vm._v("cancel")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c("div", [
            _vm._m(2),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n          Student work that was submitted to a deleted group is still available on their profile and in the Project Gallery (if they added it), however, unreviewed work is no longer available to be reviewed. Pending badges in a deleted group are not awardable.\n        "
              ),
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "button",
                {
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.openDeleteModal($event)
                    },
                  },
                },
                [_vm._v("\n            Delete\n          ")]
              ),
            ]),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { attrs: { for: "name" } }, [
      _vm._v("\n              Change your Group's name "),
      _c("span", [_vm._v("* required")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header__intro" }, [
      _c("h2", { staticClass: "header__intro__title" }, [
        _vm._v("\n          Delete this Group\n        "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Warning: ")]),
      _vm._v(
        "Before deleting a group it is a good idea to review any work and award any pending badges. Deleting a group is permanent. Student connections to that group will also be deleted, and any students who are only in that group will no longer have full access to Mouse Create until they join another group.\n        "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }