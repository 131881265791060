<template>
  <main class="tab-content--padding main--overflow-visible">
    <section class="group-tab">
      <div class="wrap-12">
        <h1 class="page-title table-title">
          Manage Archvied Members
        </h1>

        <table>
          <thead>
            <tr>
              <th>Name/Email</th>
              <th>Role</th>
              <th>Last Login</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="member in members"
              :key="member.id"
            >
              <td data-label="Name/Email">
                <router-link :to="profileView(member.token)">
                  {{ member.short_name }} ({{ member.username }})
                </router-link>
              </td>
              <td data-label="Role">
                {{ member.roleName }}
              </td>

              <td data-label="Last Login">
                {{ member.lastLogin | formatDate('MMM DD, YYYY') }}
              </td>

              <td
                data-label="Actions"
                class="text-right"
              >
                <a
                  href="#"
                  class="member-change-status modal-trigger"
                  @click.stop.prevent="changeStatus(member.id)"
                >Change Status</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        id="update-status"
        class="modal"
        :class="{'active-modal': isModalVisible}"
        role="dialog"
        :aria-hidden="!isModalVisible"
      >
        <input
          id="modal-1"
          v-model="isModalVisible"
          class="modal-state"
          type="checkbox"
        >
        <div class="modal-fade-screen">
          <div class="modal-inner">
            <button
              id="close-button"
              data-close-modal
              class="modal-close"
              for="modal-1"
              aria-label="Close this dialog window"
              @click.stop.prevent="isModalVisible = false"
            />
            <div class="row-row">
              <h3 id="name-placeholder" />
              <br>
              <p>Archiving a user will prevent them from accessing your group. The work they submitted and collaborated on will still be available on your group page and on their profile</p>
            </div>
            <div class="form-row">
              <input
                id="status-active"
                v-model="status"
                type="radio"
                name="status"
                value="active"
              >
              <label for="status-active">Active</label>
              <br>
              <input
                id="status-archived"
                v-model="status"
                type="radio"
                name="status"
                value="archived"
              >
              <label for="status-archived">Archived</label>
            </div>
            <div class="form-row short-row align-right">
              <button
                class="do-update button float-none"
                data-close
                @click.stop.prevent="handleStatus"
              >
                Update Status
              </button>
              <span class="cancel">or <a
                class="modal-close-text"
                href="#"
                @click.stop.prevent="isModalVisible = true"
              >Cancel</a></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
export default {
  name: 'MembersArchived',
  components: {},
  mixins: [],
  props: {},
  data: () => ({
    members: {},
    isModalVisible: false,
    status: 'archived',
    activeId: null,
  }),
  computed: {
    getArchivedMembersRoute() {
      const id = (this.$route.params.id) ? this.$route.params.id : this.group.id;
      return laroute.route('api.group.members.archived', { group: id });
    },
    group() {
      return this.$store.state.groups.active;
    },
  },
  watch: {},
  mounted() {
    this.getArchivedMembers();
  },
  methods: {
    getArchivedMembers() {
      axios.get(this.getArchivedMembersRoute)
        .then((response) => {
          this.members = response.data.data;
        })
        .then(() => {
          this.loaded = true;
        });
    },
    profileView(memberToken) {
      return laroute.url('profile', [memberToken]);
    },
    changeStatus(id) {
      this.isModalVisible = true;
      this.activeId = id;
    },
    handleStatus() {
      axios.get(`/api/group/${this.group.id}/manage-members/${this.activeId}/${this.status}`)
        .then(() => {
          this.getArchivedMembers();
          this.isModalVisible = false;
          this.status = 'archived';
          this.activeId = null;
        });
    },
  },
};
</script>
