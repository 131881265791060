var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "current-step__content content__has-lists",
      class: { "has-heading": _vm.step.type === "header" },
    },
    [
      _vm.step.type === "image"
        ? _c(
            "figure",
            {
              staticClass: "step__item-image",
              class: _vm.stepClassSize + " " + _vm.step.position,
            },
            [
              _c(
                "div",
                {
                  staticClass: "step__item-image-wrap",
                  class: { ie__image: _vm.ieClass },
                },
                [
                  _vm.step.url && _vm.step.url.length > 0
                    ? _c(
                        "a",
                        {
                          attrs: { href: _vm.step.url, target: _vm.hrefTarget },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.step.imgix_url,
                              alt: _vm.step.alt,
                            },
                          }),
                        ]
                      )
                    : _c("figure", [
                        _c("img", {
                          attrs: { src: _vm.step.imgix_url, alt: _vm.step.alt },
                        }),
                        _vm._v(" "),
                        _vm.step.caption
                          ? _c("figcaption", { staticClass: "caption" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.step.caption) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.step.type === "text"
        ? _c("span", {
            staticClass: "step__item-text",
            domProps: { innerHTML: _vm._s(_vm.step.text) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.step.type === "header"
        ? _c("span", {
            staticClass: "step__item-header",
            domProps: { innerHTML: _vm._s(_vm.stepHeader(_vm.step)) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.step.type === "code"
        ? _c("div", { staticClass: "cbCode" }, [
            _vm.step.syntax === "html"
              ? _c("pre", [
                  _vm._v("      "),
                  _c("code", [_vm._v(_vm._s(_vm.step.text))]),
                  _vm._v("\n    "),
                ])
              : _c("pre", { class: "language-" + _vm.step.syntax }, [
                  _vm._v("      "),
                  _c("code", [_vm._v(_vm._s(_vm.step.text))]),
                  _vm._v("\n    "),
                ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.step.type === "facilitator_note"
        ? _c("div", { staticClass: "step__item-note" }, [
            _vm.showNote
              ? _c("div", { staticClass: "facilitator_note" }, [
                  _c("div", { staticClass: "flash-success" }, [
                    _c("h3", [_vm._v("Facilitation Notes")]),
                    _vm._v(" "),
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.step.text) },
                    }),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.step.type === "video"
        ? _c("div", { staticClass: "step__item-video" }, [
            _c(
              "video",
              {
                staticClass: "video-js vjs-default-skin vjs-big-play-centered",
                attrs: {
                  id: _vm.randomVideoString,
                  controls: "",
                  preload: "auto",
                  height: "240",
                  width: "600",
                  "data-setup": '{"fluid":true}',
                },
              },
              [
                _vm._l(_vm.step.videos, function (video) {
                  return _c("source", {
                    key: video.path,
                    attrs: { src: _vm.videoURL(video.path), type: video.mime },
                  })
                }),
                _vm._v(" "),
                _vm._m(0),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.step.type === "embed"
        ? _c("div", {
            staticClass: "step__item-embed",
            attrs: { id: "embed-" + _vm.index },
            domProps: {
              innerHTML: _vm._s(_vm.$options.filters.script2Tag(_vm.step.code)),
            },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "vjs-no-js" }, [
      _vm._v(
        "\n        To view this video please enable JavaScript, and consider upgrading to a web browser that \n        "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://videojs.com/html5-video-support/",
            target: "_blank",
          },
        },
        [_vm._v("supports HTML5 video")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }