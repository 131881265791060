var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "20px",
        height: "15px",
        viewBox: "0 0 20 15",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("defs"),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Designs",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              staticClass: "step__icon-state",
              attrs: {
                id: "Steps-Tab-with-Prompt-Large",
                transform: "translate(-311.000000, -1200.000000)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Project-Detail-Copy-2",
                    transform: "translate(49.000000, 247.000000)",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Evidence-Prompt-Steps-Tab---Large",
                        transform: "translate(0.000000, 675.000000)",
                      },
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "Add-response",
                            transform: "translate(21.000000, 206.000000)",
                          },
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "BUTTON-ICON---DEFAULT",
                                transform: "translate(0.000000, 63.000000)",
                              },
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "button--image-ON",
                                    transform:
                                      "translate(151.000000, 0.000000)",
                                  },
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        id: "picture13",
                                        transform:
                                          "translate(90.869843, 9.025641)",
                                      },
                                    },
                                    [
                                      _c("g", { attrs: { id: "Group" } }, [
                                        _c("path", {
                                          attrs: {
                                            id: "Shape",
                                            d: "M18.2402757,0.501104936 C17.9364112,0.200653808 17.5713693,0.0504870873 17.1445546,0.0504870873 L1.62914014,0.0504870873 C1.20244452,0.0504870873 0.83728353,0.200653808 0.533300095,0.501104936 C0.229435683,0.801556064 0.0775629881,1.16261523 0.0775629881,1.58451782 L0.0775629881,13.2437398 C0.0775629881,13.6656424 0.229435683,14.0268192 0.533300095,14.3272703 C0.83728353,14.6277215 1.20244452,14.7778882 1.62914014,14.7778882 L17.1447927,14.7778882 C17.5713693,14.7778882 17.9365303,14.6277215 18.2403947,14.3272703 C18.5442591,14.0268192 18.6962508,13.6656424 18.6962508,13.2437398 L18.6962508,1.58451782 C18.6962508,1.16261523 18.5442591,0.801556064 18.2402757,0.501104936 L18.2402757,0.501104936 Z M17.4547272,13.2436221 C17.4547272,13.3268258 17.4241385,13.3986139 17.3627229,13.4594573 C17.3013072,13.5200654 17.2285845,13.5503106 17.1445546,13.5503106 L1.62914014,13.5503106 C1.54511027,13.5503106 1.47238753,13.5200654 1.41097192,13.4594573 C1.34955632,13.3986139 1.31884852,13.3268258 1.31884852,13.2436221 L1.31884852,1.58440013 C1.31884852,1.50131415 1.3494373,1.42952599 1.41097192,1.36868258 C1.47238753,1.30807453 1.54511027,1.27771167 1.62914014,1.27771167 L17.1447927,1.27771167 C17.2287035,1.27771167 17.3014263,1.30807453 17.3627229,1.36868258 C17.4240194,1.42952599 17.4547272,1.50131415 17.4547272,1.58440013 L17.4547272,13.2436221 L17.4547272,13.2436221 Z",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("path", {
                                          attrs: {
                                            id: "Shape",
                                            d: "M4.42200283,6.18684537 C4.93927456,6.18684537 5.37882461,6.00796339 5.74089102,5.64996404 C6.10283841,5.29196469 6.28387161,4.85735208 6.28387161,4.3460085 C6.28387161,3.83454724 6.10271938,3.39993462 5.74089102,3.04193527 C5.37882461,2.68405361 4.93927456,2.50505394 4.42200283,2.50505394 C3.90485012,2.50505394 3.46530007,2.68405361 3.10323366,3.04193527 C2.74128627,3.39993462 2.56025307,3.83454724 2.56025307,4.3460085 C2.56025307,4.85735208 2.74116725,5.29208238 3.10323366,5.64996404 C3.46530007,6.0078457 3.90485012,6.18684537 4.42200283,6.18684537 L4.42200283,6.18684537 Z",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("polygon", {
                                          attrs: {
                                            id: "Shape",
                                            points:
                                              "7.21474649 8.94821837 5.66316933 7.41418764 2.56013405 10.4823668 2.56013405 12.3233213 16.2136798 12.3233213 16.2136798 8.02779993 12.1797696 4.03908467",
                                          },
                                        }),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }