<template>
  <div class="full-wrap">
    <div class="page-wrap">
      <main>
        <section class="primary-content">
          <div class="content-box">
            <div
              v-if="currentUser.isInActiveGroup"
              class="back_link"
            >
              <router-link to="/join/group">
                <i
                  class="fa fa-chevron-left"
                  aria-hidden="true"
                /> Back to Add Group
              </router-link>
            </div>

            <div v-if="!currentUser.hasAnyLicensedSites && !currentUser.isNewUser">
              <h1 class="page-title">
                Time to renew your site’s Mouse membership!
              </h1>

              <div>
                <strong>Unfortunately, your site’s membership has expired.</strong>
              </div>

              <ul>
                <li>Don’t worry, all of your own work is still available in your Profile.</li>
                <li>You can export your work anytime in the My Work area of your Profile.</li>
                <li>We will reactivate your groups after your site’s membership is renewed.</li>
              </ul>

              <br>

              <div>
                <strong>Ready to Renew?</strong>
              </div>

              <p>
                <a href="https://www.mouse.org/renew">Click here</a> to learn more about renewing your membership or to let us know if you think this message is an error.  Students: Talk to your teacher about renewing your site’s membership.
              </p>

              <div>
                <strong>At a new site?</strong>
              </div>

              <p>
                If you are now at a new Mouse site and want to use this account, enter your new site’s Educator Code or Group Code below.
              </p>
            </div>

            <h1 class="page-title">
              Educator Code
            </h1>

            <form class="simple-join-group">
              <p class="instructional">
                Please confirm your Mouse Create membership by entering your site’s Educator Code below. If you don’t have your site’s code or want to learn more about membership, email <a href="mailto:membership@mouse.org">membership@mouse.org</a>.
              </p>

              <div class="form-row short-row">
                <label
                  for="educator_code"
                  class="visuallyhidden"
                >Educator Code</label>
                <input
                  id="educator_code"
                  v-model="code"
                  type="text"
                  name="code"
                  placeholder="Enter Educator Code"
                  value=""
                  autocomplete="off"
                >
              </div>
              <div class="submit-button-centered">
                <button
                  type="submit"
                  class="button"
                  @click.stop.prevent="joinGroup"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>

import messages from '../../mixins/messages';

export default {
  name: 'JoinSite',
  mixins: [messages],
  data: () => ({
    code: '',
  }),
  computed: {
    currentUser() {
      return this.$store.state.users.current;
    },
  },
  mounted() {
    document.body.className = 'no-js language-markup single-column';
  },
  methods: {
    joinGroup() {
      const data = {
        code: this.code,
      };

      let siteCount = this.currentUser.siteCount;

      axios.post('/api/join/site', data).then((response) => {
        if (response.data.error) {
          this.callNotification(response.data.message, 'error');
        } else {
          this.callNotification(response.data.message, 'success');
          this.$store.dispatch('getMe');
          this.$router.push(response.data.route).catch(() => {});
        }
      }).catch((error) => {
        this.callNotification(error.data.message, 'error');
      });
    },

  },
};
</script>
