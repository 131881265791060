/**
 * Quick Method to get a defined link from resource
 *
 */
export default {
  methods: {
    getResourceLink(resource, rel) {
      return _.find(resource._links, (link) => link.rel === rel);
    },
  },
};
