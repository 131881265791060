import { render, staticRenderFns } from "./HomeHeader.vue?vue&type=template&id=33be83be"
import script from "./HomeHeader.vue?vue&type=script&lang=js"
export * from "./HomeHeader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/hotfix-2o9b5/hotfix-create.mouse.org/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('33be83be')) {
      api.createRecord('33be83be', component.options)
    } else {
      api.reload('33be83be', component.options)
    }
    module.hot.accept("./HomeHeader.vue?vue&type=template&id=33be83be", function () {
      api.rerender('33be83be', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/home/HomeHeader.vue"
export default component.exports