<template>
  <section class="course__row">
    <h2 class="row__title">
      Projects ({{ projectCount }})
    </h2>

    <div class="card__list course__project-cards">
      <project-card
        v-for="project in projects"
        :key="project.id"
        :project="project"
        :sortable="true"
        :is-in-current-group="checkProject(project.id)"
      />
    </div>
  </section>
</template>

<script>

import ProjectCard from '../projects/ProjectCard.vue';

export default {
  name: 'CourseProjects',
  components: {
    ProjectCard,
  },
  props: {
    projects: {
      type: Array,
      required: true,
    },
    groupProjectIds: {
      type: Array,
      required: true,
    },
  },
  data: () => ({

  }),
  computed: {
    projectCount() {
      return this.projects.length;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    checkProject(id) {
      return this.groupProjectIds.indexOf(id) > -1;
    },
  },
};
</script>
