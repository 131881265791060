<template>
  <main class="tab-content--padding main--overflow-visible">
    <section class="group-tab">
      <div class=" group-fixed-container">
        <div class="header__admin header__admin--no-rule">
          <div class="header__admin__text">
            <h1 class="header__admin__text__title">
              {{ group.name }} Notifications
            </h1>
          </div>
        </div>

        <notification-form
          context="group"
          :contextid="group.id"
        />
      </div>
    </section>
  </main>
</template>

<script>

import NotificationForm from '../notifications/NotificationForm.vue';

export default {
  name: 'GroupNotifications',
  components: {
    NotificationForm,
  },
  props: {},
  data: () => ({

  }),
  computed: {
    group() {
      return this.$store.state.groups.active;
    },
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>
