var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "project-filter-list" }, [
    _vm.courses.length > 0
      ? _c("div", { staticClass: "filter-block" }, [
          _c("h3", [_vm._v("CHOOSE A COURSE")]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "who-categories" },
            _vm._l(_vm.courses, function (course) {
              return _c(
                "li",
                { key: course.id },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "filter tag",
                      attrs: { to: "/projects/courses/" + course.slug },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(course.title) + "\n        "
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "filter-block" }, [
      _c("h3", [_vm._v("CHOOSE A SKILL")]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "what-categories" },
        _vm._l(_vm.categories.what, function (category) {
          return _c("project-filter-link", {
            key: category.categoryId,
            attrs: {
              "data-id": category.categoryId,
              "data-label": category.categoryName,
              category: category,
              "category-counts": _vm.categoryCounts,
              "active-categories": _vm.activeCategories,
            },
          })
        }),
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "filter-block" }, [
      _c("h3", [_vm._v("CHOOSE A SETTING")]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "when-categories" },
        _vm._l(_vm.categories.when, function (category) {
          return _c("project-filter-link", {
            key: category.categoryId,
            attrs: {
              "data-id": category.categoryId,
              "data-label": category.categoryName,
              category: category,
              "category-counts": _vm.categoryCounts,
              "active-categories": _vm.activeCategories,
            },
          })
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }