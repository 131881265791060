<template>
  <svg
    width="13px"
    height="13px"
    viewBox="0 0 13 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs />
    <g
      id="Designs"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Work-Tab--1-prompt"
        transform="translate(-971.000000, -561.000000)"
        fill="#00A886"
      >
        <g
          id="content-area-1030-wide"
          transform="translate(50.000000, 537.000000)"
        >
          <g
            id="text-asset"
            transform="translate(31.000000, 22.000000)"
          >
            <g id="text-Asset">
              <g
                id="edit-/-delete"
                transform="translate(862.000000, 0.000000)"
              >
                <g
                  id="edit-btn"
                  transform="translate(0.000000, 2.000000)"
                >
                  <g
                    id="pencil30"
                    transform="translate(28.471671, 0.150862)"
                  >
                    <g id="Group">
                      <path
                        id="Shape"
                        d="M0.0283955635,9.28891216 L0.0283955635,12.7939064 L3.41716611,12.7939064 L10.1947072,5.78391789 L6.80593666,2.27892363 L0.0283955635,9.28891216 L0.0283955635,9.28891216 Z M2.98543356,11.7154069 L2.98543356,11.7154069 L2.11376975,11.7155103 L2.11376975,10.6370107 L1.07113265,10.6370107 L1.07113265,9.7353483 L1.81241683,8.9686405 L3.72671775,10.9485957 L2.98543356,11.7154069 L2.98543356,11.7154069 Z M7.06669592,3.71130423 C7.18607726,3.71130423 7.24576794,3.77314551 7.24576794,3.89662125 C7.24576794,3.9528782 7.22687089,4.00055183 7.18877684,4.03995238 L2.77356666,8.60658979 C2.7355726,8.64578351 2.68937982,8.66543208 2.6350883,8.66543208 C2.51570695,8.66543208 2.45591629,8.60348738 2.45591629,8.48011506 C2.45591629,8.42385811 2.47491333,8.37618448 2.51290739,8.33678393 L6.92811757,3.77024993 C6.96611163,3.73095279 7.01230441,3.71130423 7.06669592,3.71130423 L7.06669592,3.71130423 Z"
                      />
                      <path
                        id="Shape"
                        d="M12.0682145,2.32090952 L10.1539136,0.349434267 C9.9476458,0.136092186 9.70048442,0.0292660247 9.41262939,0.0292660247 C9.11927522,0.0292660247 8.87501339,0.136092186 8.67954393,0.349434267 L7.32735519,1.73962215 L10.7161257,5.24461641 L12.0684145,3.84605204 C12.2693831,3.63819087 12.3697674,3.38544824 12.3697674,3.08772073 C12.3696674,2.79568096 12.2692831,2.54004276 12.0682145,2.32090952 L12.0682145,2.32090952 Z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'EditIcon',
};
</script>

<style scoped>

</style>
