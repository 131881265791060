<template>
  <nav
    v-if="lastPage > 1"
    class="table__pagination"
    role="navigation"
    aria-label="Pagination Navigation"
  >
    <div class="table__pagination__prev">
      <a
        href="#"
        class="table__pagination__link"
        @click.stop.prevent="previous"
      >
        <span v-if="value === 1 || lastPage === 1">&nbsp;</span>
        <span v-if="value !== 1">&lt; previous</span>
      </a>
    </div>

    <div class="table__pagination__numbers">
      <ul
        v-if="lastPage > 1"
        class="table__pagination__row"
      >
        <li
          v-for="page in lastPage"
          :key="page"
          class="table__pagination__list"
        >
          <a
            href="#"
            class="table__pagination__link"
            :aria-label="'Current Page, Page ' + page"
            :class="{ 'table__pagination__link--active': value === page }"
            @click.stop.prevent="changePage(page)"
          >
            {{ page }}
          </a>
        </li>
      </ul>
    </div>
    <div class="table__pagination__next">
      <a
        href="#"
        class="table__pagination__link"
        @click.stop.prevent="next"
      >
        <span v-if="value === lastPage || lastPage === 1">&nbsp;</span>
        <span v-if="value !== lastPage">next &gt;</span>
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'GroupWorkPagination',
  props: {
    value: {
      type: Number,
      required: true,
    },
    lastPage: {
      type: Number,
      required: true,
    },
  },
  methods: {
    previous() {
      this.changePage(this.value - 1);
    },
    next() {
      this.changePage(this.value + 1);
    },
    changePage(page) {
      this.$emit('input', page);
    },
  },
};
</script>

<style scoped>

</style>
