var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "profile-view" },
    [
      _c("div", { staticClass: "profile-header" }, [
        _c("div", {
          staticClass: "profile-pic",
          domProps: { innerHTML: _vm._s(_vm.user.avatar) },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "username" }, [
          _c("h1", { staticClass: "page-title" }, [
            _vm._v("\n        " + _vm._s(_vm.user.username) + "\n      "),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.editable,
                expression: "editable",
              },
            ],
            staticClass: "profile-edit",
          },
          [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.loadEditComponent($event)
                  },
                },
              },
              [
                _vm._v("Edit Account Settings "),
                _c("i", {
                  staticClass: "fa fa-pencil",
                  attrs: { "aria-hidden": "true" },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "bio" }, [
          _c("p", {
            staticClass: "bio",
            domProps: { textContent: _vm._s(_vm.user.profile.bio) },
          }),
        ]),
      ]),
      _vm._v(" "),
      _vm.showGoogleConnect
        ? [
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "login-grid-google profile" }, [
              _c("p", [
                _vm._v(
                  'Connect your Google account to log in to Mouse Create using the "Login with Google" option instead of your username and password.'
                ),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button",
                  attrs: { type: "submit" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.connectGoogle($event)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "text" }, [
                    _vm._v("Log In With Google"),
                  ]),
                  _vm._v(" "),
                  _vm._m(0),
                ]
              ),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("h2", { staticClass: "section-heading" }, [
        _vm._v("\n    Groups\n  "),
      ]),
      _vm._v(" "),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.groups,
              expression: "groups",
            },
          ],
          staticClass: "profile-group-list",
        },
        _vm._l(_vm.groups, function (group) {
          return _c("li", { key: group.id }, [
            _vm._v("\n      " + _vm._s(group.name) + " \n      "),
            group.status === "archived"
              ? _c("span", { staticClass: "archived" }, [_vm._v("(archived)")])
              : _vm._e(),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("hr", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.groups,
            expression: "groups",
          },
        ],
      }),
      _vm._v(" "),
      _c("h2", { staticClass: "section-heading" }, [
        _vm._v("\n    Badges\n  "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.badges,
              expression: "badges",
            },
          ],
          staticClass: "badge-block",
        },
        _vm._l(_vm.badges, function (badge) {
          return _c("div", { key: badge.id, staticClass: "badge" }, [
            _c("a", { attrs: { href: badge.url, target: "new" } }, [
              _c("img", { attrs: { src: badge.image, alt: badge.title } }),
            ]),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.badges.length,
              expression: "!badges.length",
            },
          ],
          staticClass: "clearfix",
        },
        [
          _c("p", [
            _vm._v(
              '\n      If you are 13 or older, you can sign up to earn badges on Mouse Create and Credly. Go to "Edit Account Settings" and check the badges option. Then, start working on projects.\n    '
            ),
          ]),
          _vm._v(" "),
          _vm._m(1),
        ]
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.evidence,
              expression: "evidence",
            },
          ],
          attrs: { id: "profileEvidenceHandler" },
        },
        [
          _c("table", [
            _c("caption", { staticClass: "large-table-caption" }, [
              _vm._v("\n        Recent Work Log\n      "),
            ]),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.evidence, function (e) {
                return _c("tr", { key: e.id }, [
                  _c("td", { attrs: { "data-label": "Project Title" } }, [
                    _c("a", {
                      attrs: { href: "#" },
                      domProps: { textContent: _vm._s(e.title) },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.showProjectReviewModal(e, e.group.id)
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("td", {
                    attrs: { "data-label": "Group" },
                    domProps: { textContent: _vm._s(e.group.name) },
                  }),
                  _vm._v(" "),
                  _c("td", { attrs: { "data-label": "Date" } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm._f("formatDate")(e.submitted)) +
                        "\n          "
                    ),
                  ]),
                ])
              }),
              0
            ),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("img", { attrs: { src: "/img/icon-google.png", alt: "Google" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n      For more badges help, go to "),
      _c(
        "a",
        { attrs: { href: "https://tools.mouse.org", target: "_blank" } },
        [
          _c("span", { staticClass: "sr-only" }, [
            _vm._v("(opens in a new tab) "),
          ]),
          _vm._v("Tools.mouse.org"),
        ]
      ),
      _vm._v(".\n    "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("\n            Project Title\n          "),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("\n            Group\n          "),
        ]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [
          _vm._v("\n            Date\n          "),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }