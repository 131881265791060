var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { staticClass: "home__header" }, [
    _c("div", { staticClass: "header__brand" }, [_c("MouseHeaderLogoIcon")], 1),
    _vm._v(" "),
    _c("div", {
      staticClass: "header__description",
      domProps: { innerHTML: _vm._s(_vm.home.tagline) },
    }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "header__actions" },
      [
        _vm.isLoggedIn && !_vm.isRegistered
          ? _c(
              "router-link",
              {
                staticClass: "button home__button",
                attrs: { to: "/register" },
              },
              [_c("span", [_vm._v("Continue Registration")])]
            )
          : _c(
              "router-link",
              { staticClass: "button home__button", attrs: { to: "/login" } },
              [
                _vm.isLoggedIn
                  ? _c("span", [_vm._v("\n        Back to Group\n      ")])
                  : _c("span", [
                      _vm._v("\n        Login or Create an Account\n      "),
                    ]),
              ]
            ),
        _vm._v(" "),
        _vm.isLoggedIn
          ? _c(
              "a",
              {
                staticClass: "button home__button",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.logoutUser($event)
                  },
                },
              },
              [_c("span", [_vm._v("Logout")])]
            )
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "header__video" }, [
      _vm.video
        ? _c("div", { staticClass: "video__player" }, [
            _c(
              "video",
              {
                ref: "videoPlayer",
                staticClass: "video-js vjs-default-skin vjs-big-play-centered",
                attrs: { height: "240", width: "600" },
              },
              [_vm._m(0)]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        staticClass: "video__content",
        domProps: { innerHTML: _vm._s(_vm.videoContent) },
      }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "vjs-no-js" }, [
      _vm._v(
        "\n          To view this video please enable JavaScript, and consider upgrading to a web browser that "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://videojs.com/html5-video-support/",
            target: "_blank",
          },
        },
        [_vm._v("supports HTML5 video")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }