<template>
  <div
    v-if="isExpired"
    class="no-active-group"
  >
    <p>
      Your site’s membership has expired! <a
        href="https://mouse.org/renew"
        target="_blank"
      >Click here for help renewing your membership</a>.
    </p>
  </div>
</template>
<script>
export default {
  name: 'LicenseExpired',
  components: {},
  mixins: [],
  props: {},
  data: () => ({
  }),
  computed: {
    currentUser() {
      return (this.$store.state.users.current) ? this.$store.state.users.current : null;
    },
    isExpired() {
      if (_.isEmpty(this.currentUser)) {
        return false;
      }

      if (this.currentUser && !this.currentUser.isStaffAdmin) {
        return !this.currentUser.hasAnyLicensedSites && !this.currentUser.isNewUser;
      }

      return false;
    },
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>
