var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "message-fade" } }, [
    _vm.message.loaded
      ? _c(
          "div",
          { staticClass: "alert__message", class: _vm.messageTypeClass },
          [
            _c(
              "button",
              {
                staticClass: "message__icon-close",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.closeMessage($event)
                  },
                },
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "21px",
                      height: "21px",
                      viewBox: "0 0 21 21",
                      version: "1.1",
                      xmlns: "http://www.w3.org/2000/svg",
                      "xmlns:xlink": "http://www.w3.org/1999/xlink",
                    },
                  },
                  [
                    _c("title", [_vm._v("close X")]),
                    _vm._v(" "),
                    _c("desc", [_vm._v("Created with Sketch.")]),
                    _vm._v(" "),
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "Page-3",
                          stroke: "none",
                          "stroke-width": "1",
                          fill: "none",
                          "fill-rule": "evenodd",
                          "stroke-linecap": "square",
                        },
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              id: "Notify-Message-Set",
                              transform: "translate(-1124.000000, -67.000000)",
                              stroke: "#FFFFFF",
                              "stroke-width": "4",
                            },
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  id: "Notify-Message-Green",
                                  transform: "translate(672.000000, 52.000000)",
                                },
                              },
                              [
                                _c("g", { attrs: { id: "Notification-box" } }, [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        id: "close-X",
                                        transform:
                                          "translate(455.000000, 18.000000)",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          id: "Line",
                                          d: "M0.5,0.50472973 L14.6421356,14.7806423",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          id: "Line",
                                          d: "M0.5,0.50472973 L14.6421356,14.7806423",
                                          transform:
                                            "translate(7.500000, 7.570946) scale(-1, 1) translate(-7.500000, -7.570946) ",
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "message__content" }, [
              _vm.message.icon
                ? _c("div", { staticClass: "message__icon" }, [
                    _c("img", {
                      attrs: {
                        src: "/img/alerts/" + _vm.message.icon + ".svg",
                      },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", {
                staticClass: "message__text",
                domProps: { innerHTML: _vm._s(_vm.message.content) },
              }),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }