<template>
  <div class="full-wrap">
    <div class="page-wrap">
      <main>
        <section class="primary-content">
          <div class="content-box">
            <div class="group-title--area">

              <h1 v-if="currentUser.isNewEducator">Getting Started</h1>
              <h1 v-else>My Groups</h1>

              <div class="group-switch-buttons">
                <router-link
                  to="/create-group"
                  class="button__secondary"
                  v-if="currentUser.isEducator"
                >
                  Create New Group <i class="fa fa-plus"></i>
                </router-link>
                <router-link
                  to="/join/group"
                  class="button__secondary"
                >
                  Join a Group
                  <svg version="1.1" id="Layer_1" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                     x="0px" y="0px" viewBox="0 0 45.5 35" style="enable-background:new 0 0 45.5 35;" xml:space="preserve"><desc  data-v-908c7996="" id="navGroupDesc">A group of people</desc><g><path class="st0" d="M15.5,4c0.3-0.4,0.7-0.7,1.1-0.9l-1.5-2.6C14.9,0.2,14.6,0,14.2,0H7.9C7.5,0,7.2,0.2,7,0.5L3.8,6
                      c-0.2,0.3-0.2,0.7,0,1.1L7,12.5c0.2,0.4,0.5,0.6,0.9,0.6h4.8c-0.1-0.7-0.2-1.3-0.2-1.9C12.5,8.4,13.7,5.8,15.5,4"/><path class="st0" d="M10.9,22.4c1.5-1.5,3.5-2.6,5.7-3.1c-0.4-0.3-0.8-0.6-1.1-0.9c-1-1-1.7-2.2-2.3-3.5H8.3C3.7,14.9,0,18.6,0,23
                      v3.9h8c0.1-0.3,0.2-0.6,0.3-0.9C8.9,24.7,9.8,23.4,10.9,22.4"/><path class="st0" d="M45.5,23c0-4.4-3.8-8.1-8.4-8.1h-4.9c-0.5,1.3-1.3,2.5-2.2,3.5c-0.4,0.3-0.7,0.6-1.1,0.9
                      c1.5,0.3,2.8,0.9,4,1.7c1.9,1.2,3.4,3,4.3,5c0.2,0.3,0.3,0.6,0.4,0.9h7.9V23z"/><path class="st0" d="M31.7,30.6H13.9v-0.1c0-2.8,2.4-5,5.3-5h7.2C29.3,25.5,31.7,27.7,31.7,30.6L31.7,30.6z M26.4,21h-7.2
                      c-5.4,0-9.8,4.3-9.8,9.5V35h26.7v-4.5C36.1,25.3,31.7,21,26.4,21L26.4,21z"/><path class="st0" d="M22.7,18.9c4.3,0,7.8-3.5,7.8-7.7c0-4.3-3.5-7.8-7.8-7.8S15,6.9,15,11.2C15,15.4,18.4,18.9,22.7,18.9"/><path class="st0" d="M38.3,0h-8.1c-1.4,0-2.4,1.1-2.4,2.4v0.1c3.1,1.8,5.2,5.1,5.2,8.8c0,0.6-0.1,1.1-0.2,1.6h5.5
                      c1.3,0,2.4-1.1,2.4-2.4V2.4C40.7,1.1,39.6,0,38.3,0"/></g></svg>
                </router-link>
              </div>
            </div>

            <div class="clearfix" />

            <div  v-if="currentUser.isNewEducator">
              <p class="instructional">
                Your educator account is now confirmed – Welcome to Mouse Create!
              </p>
              <p class="instructional">Your next step is to join an existing group or create your own group. Select one of these options above to get started.
              </p>
            </div>
            <div v-else>
              <p class="instructional" v-if="hasGroups">
                Select a group to get started. You can switch your active group anytime by going to “More > My Groups” in the top menu.
              </p>
              <p class="instructional" v-else>
                Looks like you aren’t in a group yet! Join a group to get started using Mouse Create.
              </p>
            </div>

            <div
              v-for="group in activeGroups"
              :key="group.id"
              class="group-selector"
            >
              <a
                href=""
                class="button"
                @click.stop.prevent="selectGroup(group.id)"
              >{{ group.name }}</a>
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupSwitch',
  components: {},
  props: {},
  data: () => ({
    groups: [],
  }),
  computed: {
    currentUser() {
      return this.$store.state.users.current;
    },
    activeGroups() {
      return Object.values(this.groups).filter((g) => g.status === 'active');
    },
    groupCount() {
      return this.groups.length
    },
    hasGroups() {
      return this.groupCount > 0
    }
  },
  watch: {
    currentUser() {
      this.getGroups();
    },
  },
  mounted() {
    document.body.className = 'no-js language-markup single-column';
    this.getGroups();
    console.log(this.groups.length)
  },
  methods: {
    getGroups() {
      axios.get('/api/groups').then((response) => {
        this.groups = response.data.groups;
      }).catch(() => {});
    },
    selectGroup(id) {
      this.$store.dispatch('setActiveGroup', id).then((response) => {
        this.$store.dispatch('getMe');
        this.$router.push({ name: 'group-playlist', params: { id: response.data.id } });
      });
    },
  },
};
</script>
