<template>
  <svg
    width="360px"
    height="40px"
    viewBox="0 0 360 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Mouse Logo</title>
    <defs />
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      font-family="Basetica-Regular, Basetica"
      font-size="57.6"
      font-weight="normal"
    >
      <g
        id="Create-HOME-final"
        transform="translate(-459.000000, -58.000000)"
      >
        <text id="mouse-create">
          <tspan
            x="456"
            y="97"
            fill="#FFFFFF"
          >mouse</tspan>
          <tspan
            x="648.4416"
            y="97"
            fill="#FF3333"
          >create</tspan>
        </text>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'MouseHeaderLogoIcon',
};
</script>

<style scoped>

</style>
