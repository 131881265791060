<template>
  <div class="badge__item-wrapper">
    <div class="badge__item">
      <div class="badge__image">
        <img
          :src="badge.image"
          :alt="badge.label"
        >
      </div>
      <div class="badge__learn-more">
        <a
          href="#"
          class="badge__learn-link"
          @click.stop.prevent="badgeLearnMore"
        >Learn More
          <svg
            width="14px"
            height="14px"
            viewBox="0 0 14 14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>+</title>
            <desc>Created with Sketch.</desc>
            <g
              id="Page-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="square"
            >
              <g
                id="Courses-Page----Default-v2"
                transform="translate(-221.000000, -758.000000)"
                fill-rule="nonzero"
                stroke="#00A886"
                stroke-width="3.2"
              >
                <g
                  id="Badges"
                  transform="translate(105.000000, 532.000000)"
                >
                  <g
                    id="teamwork-badge-block"
                    transform="translate(0.000000, 58.000000)"
                  >
                    <g
                      id="Learn-More"
                      transform="translate(31.000000, 165.000000)"
                    >
                      <g
                        id="+"
                        transform="translate(87.000000, 5.000000)"
                      >
                        <path
                          id="Line-2"
                          d="M0.217391304,5 L10,5"
                        />
                        <path
                          id="Line-2-Copy"
                          d="M0.108695652,5.10869565 L9.89130435,5.10869565"
                          transform="translate(5.000000, 5.108696) rotate(-270.000000) translate(-5.000000, -5.108696) "
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BadgeItem',
  components: {},
  props: {
    badge: {
      type: Object,
      required: true,
    },
  },
  methods: {
    badgeLearnMore() {
      bus.$emit('open:badge-modal', {
        badge: this.badge,
      });
    },
  },
};
</script>
