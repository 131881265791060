var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "comments-form" },
    [
      _vm.prompt
        ? _c(
            "label",
            {
              staticClass: "comments-form__prompt",
              attrs: { for: "comment-input" },
            },
            [_vm._v("${prompt}")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.comment.body,
            expression: "comment.body",
          },
        ],
        staticClass: "comment-form__input",
        attrs: {
          id: "comment-input",
          placeholder: _vm.placeholder,
          rows: "1",
          maxlength: _vm.charLimit,
          disabled: !_vm.owner.isInActiveGroup,
        },
        domProps: { value: _vm.comment.body },
        on: {
          focus: _vm.inputFocus,
          blur: _vm.inputBlur,
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.submit($event)
          },
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            $event.preventDefault()
          },
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.comment, "body", $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "comment-form__submit",
          class: { "comment-form__submit": !_vm.comment.body.length },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.submit($event)
            },
          },
        },
        [
          !_vm.submitInProgress
            ? _c("span", [
                _vm._v("Submit "),
                _c("i", { staticClass: "fa fa-chevron-right" }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.submitInProgress
            ? _c("span", [
                _vm._v("\n      Posting\n      "),
                _c("i", {
                  staticClass: "fa fa-spinner fa-pulse fa-fw fa-spin",
                }),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.withActions
        ? _c("comment-type-selection", {
            attrs: {
              visible: _vm.typesVisible,
              prompt: _vm.prompt,
              "comment-type-id": _vm.comment.comment_type_id,
            },
            on: {
              "update:visible": function ($event) {
                _vm.typesVisible = $event
              },
              "update:prompt": function ($event) {
                _vm.prompt = $event
              },
              "update:commentTypeId": function ($event) {
                return _vm.$set(_vm.comment, "comment_type_id", $event)
              },
              "update:comment-type-id": function ($event) {
                return _vm.$set(_vm.comment, "comment_type_id", $event)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }