<template>
  <svg
    aria-labelledby="navProjectsTitle navProjectsDesc"
    focusable="false"
    height="59"
    viewBox="0 0 53 59"
    width="53"
    xmlns="http://www.w3.org/2000/svg"
  ><title id="navProjectsTitle">Projects</title>
    <desc id="navProjectsDesc">A Paper Airplane</desc>
    <g fill="#636366">
      <path
        class="icon a"
        d="M25.9 29L19.5 25.3C19.2 25.2 19 25.3 19 25.6L19 33.9 19 33.9C19 36 20.1 36.4 21.5 34.8L26 29.6C26.2 29.4 26.1 29.1 25.9 29L25.9 29Z"
      />
      <path
        class="text"
        d="M4.1 47.2L1.8 47.2 1.8 50.6 4 50.6C5.3 50.6 6 49.9 6 48.9 6 47.9 5.3 47.2 4.1 47.2ZM4 51.9L1.8 51.9 1.8 56 0.4 56 0.4 45.9 4.1 45.9C6.3 45.9 7.4 47.2 7.4 48.9 7.4 50.7 6.3 51.9 4 51.9ZM12.8 48.3C12.7 48.3 12.5 48.3 12.4 48.3 11.4 48.3 10.6 48.9 10.2 49.9L10.2 48.4 8.9 48.4 8.9 56 10.2 56 10.2 52.6C10.2 51.9 10.3 51.4 10.3 51.2 10.6 50.2 11.2 49.6 12.3 49.6 12.5 49.6 12.7 49.6 12.8 49.7L12.8 48.3ZM13.6 52.2C13.6 49.9 15.3 48.3 17.5 48.3 19.7 48.3 21.4 49.9 21.4 52.2 21.4 54.4 19.7 56.2 17.5 56.2 15.3 56.2 13.6 54.5 13.6 52.2ZM14.9 52.2C14.9 53.8 16 54.9 17.5 54.9 19 54.9 20 53.7 20 52.2 20 50.7 19 49.5 17.5 49.5 16 49.5 14.9 50.7 14.9 52.2ZM24.3 48.4L22.9 48.4 22.9 58.4 24.3 58.1 24.3 48.4ZM22.8 46.6C22.8 47.1 23.1 47.4 23.6 47.4 24.1 47.4 24.4 47.1 24.4 46.6 24.4 46.1 24.1 45.8 23.6 45.8 23.1 45.8 22.8 46.1 22.8 46.6ZM27.2 51.5L31.7 51.5C31.6 50.3 30.7 49.5 29.5 49.5 28.3 49.5 27.4 50.4 27.2 51.5ZM33 52.6L27.1 52.6C27.2 54 28.2 54.9 29.6 54.9 30.7 54.9 31.3 54.4 31.6 53.7L32.8 53.9C32.4 55.2 31.3 56.2 29.6 56.2 27.4 56.2 25.8 54.6 25.8 52.2 25.8 49.9 27.5 48.3 29.5 48.3 31.7 48.3 33.1 50 33.1 52 33.1 52.2 33.1 52.4 33 52.6ZM38.1 49.5C39.1 49.5 39.8 50.1 40.2 50.9L41.3 50.2C40.8 49.1 39.8 48.3 38.1 48.3 35.9 48.3 34.2 49.9 34.2 52.2 34.2 54.5 35.9 56.2 38.1 56.2 39.9 56.2 41 55.2 41.5 53.9L40.3 53.3C39.9 54.3 39.2 54.9 38.1 54.9 36.6 54.9 35.5 53.8 35.5 52.2 35.5 50.7 36.6 49.5 38.1 49.5ZM46.2 49.6L46.2 48.4 44.8 48.4 44.8 46.6 43.5 46.9 43.5 48.4 42 48.4 42 49.6 43.5 49.6 43.5 56 44.8 56 44.8 49.6 46.2 49.6ZM49.8 55C48.9 55 48.3 54.5 47.9 53.7L46.8 54.4C47.3 55.4 48.2 56.2 49.8 56.2 51.2 56.2 52.3 55.3 52.3 54.1 52.3 53.1 51.7 52.4 50.7 51.9L49.1 51.2C48.6 51 48.4 50.7 48.4 50.3 48.4 49.8 48.8 49.5 49.5 49.5 50.1 49.5 50.6 49.9 50.9 50.4L51.9 49.6C51.5 48.9 50.8 48.3 49.5 48.3 48.1 48.3 47.2 49.3 47.2 50.4 47.2 51.3 47.7 51.9 48.7 52.4L50.1 53.1C50.7 53.4 51 53.7 51 54.1 51 54.6 50.6 55 49.8 55Z"
        fill="#E7E7E7"
      />
      <path
        class="icon"
        d="M44.6 0.3L6.6 12.9C4.6 13.6 4.4 15.1 6.3 16.1L16.4 21.9C16.7 22 17 22 17.3 21.9L37.4 7.9C38.5 7.1 38.6 7.2 37.6 8.1L20.6 23.6C20.3 23.8 20.3 24.1 20.6 24.3L35.4 32.7C36.6 33.3 37.8 32.8 38.2 31.5L46.6 2.3C47 0.7 46.1-0.2 44.6 0.3L44.6 0.3Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PaperAirplaneIcon',
};
</script>

<style scoped>

</style>
