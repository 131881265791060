var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    [
      _c("badge-award-modal", {
        attrs: { award: _vm.award, badges: _vm.badges },
      }),
      _vm._v(" "),
      _c("badge-student-modal", { attrs: { award: _vm.award } }),
      _vm._v(" "),
      !_vm.loaded
        ? _c("div", { staticClass: "response-loading" }, [
            _c("h2", [_vm._v("Badges loading now...")]),
            _vm._v(" "),
            _vm._m(0),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "section",
            { staticClass: "group-tab primary-content" },
            [
              _c("group-header", { attrs: { group: _vm.group } }),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _vm.loaded && _vm.badges.length > 0
                ? _c("table", { staticClass: "table__paginated" }, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", [
                          _c(
                            "span",
                            {
                              staticClass: "sort",
                              class: _vm.sortClasses("eligibility_dt"),
                              on: {
                                click: function ($event) {
                                  return _vm.sortMe("eligibility_dt")
                                },
                              },
                            },
                            [_vm._v("Date Eligible")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _c(
                            "span",
                            {
                              staticClass: "sort",
                              class: _vm.sortClasses("title"),
                              on: {
                                click: function ($event) {
                                  return _vm.sortMe("title")
                                },
                              },
                            },
                            [_vm._v("Badge Name")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _c(
                            "span",
                            {
                              staticClass: "sort",
                              class: _vm.sortClasses("user.full_name"),
                              on: {
                                click: function ($event) {
                                  return _vm.sortMe("user.full_name")
                                },
                              },
                            },
                            [_vm._v("Member Name")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("th", [
                          _c(
                            "span",
                            {
                              staticClass: "sort",
                              class: _vm.sortClasses("awardingWeight"),
                              on: {
                                click: function ($event) {
                                  return _vm.sortMe("awardingWeight")
                                },
                              },
                            },
                            [_vm._v("Awarded?")]
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.filteredBadges, function (badge) {
                        return _c("tr", { key: badge.id }, [
                          _c("td", { attrs: { "data-label": "Date Earned" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm._f("formatDate")(
                                    badge.eligibility_dt,
                                    "M/D/YY"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            { attrs: { "data-label": "Badge Name" } },
                            [
                              _vm.$groupUser().can("award_badges") &&
                              !badge.awarded
                                ? _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.showAwardModal(
                                            badge.id,
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(badge.title))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.$groupUser().can("award_badges") &&
                              !badge.awarded
                                ? _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.showStudentModal(
                                            badge.id,
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(badge.title))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              badge.awarded
                                ? _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: "/badge-evidence/" + badge.code,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(badge.title) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            { attrs: { "data-label": "Earned By" } },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: _vm.getProfileRoute(badge.user.token),
                                  },
                                },
                                [
                                  _c("div", { staticClass: "icon__group" }, [
                                    _c("div", {
                                      staticClass: "profile__pic-member",
                                      domProps: {
                                        innerHTML: _vm._s(badge.user.avatar),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "icon__group__text" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(badge.user.full_name) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("td", { attrs: { "data-label": "Awarded" } }, [
                            _vm.$groupUser().can("award_badges") &&
                            badge.user.canAward &&
                            !badge.awarded &&
                            badge.acclaim_badge_id.length > 0
                              ? _c("div", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "status--needs-review",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.showAwardModal(badge.id)
                                        },
                                      },
                                    },
                                    [_vm._v("Award Badge")]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.$groupUser().can("award_badges") &&
                            badge.user.canAward &&
                            badge.acclaim_badge_id.length < 1
                              ? _c("div", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.showAwardModal(badge.id)
                                        },
                                      },
                                    },
                                    [_vm._v("Not Available")]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.$groupUser().can("award_badges") &&
                            !badge.awarded
                              ? _c("div", [
                                  _vm._v("\n              No\n            "),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            badge.awarded
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          href: "#",
                                          to: "/badge-evidence/" + badge.code,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                Awarded " +
                                            _vm._s(
                                              _vm._f("formatDate")(
                                                badge.updated_at,
                                                "M/D/YY"
                                              )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.loaded && _vm.badges.length === 0
                ? _c("div", { staticClass: "group_info" }, [
                    _c("p", [
                      _vm._v(
                        "Your group has not started earning badges yet.  To earn a badge:"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("ol", { staticClass: "list-number-style" }, [
                      _c("li", [
                        _vm._v("Accept ability to accept badges in your "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: "/profile/" + _vm.currentUser.token,
                            },
                          },
                          [_vm._v("Mouse Create Profile")]
                        ),
                        _vm._v(". You must be 13 or older to accept badges."),
                      ]),
                      _vm._v(" "),
                      _vm._m(2),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v(
                          "Your educator must review each project related to the badge in the Group Work tab."
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v(
                          "Your badge will then appear here, ready for your educator to award it to you!"
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.pagination.totalPages > 1
                ? _c(
                    "nav",
                    {
                      staticClass: "table__pagination",
                      attrs: {
                        role: "navigation",
                        "aria-label": "Pagination Navigation",
                      },
                    },
                    [
                      _c("div", { staticClass: "table__pagination__prev" }, [
                        _c(
                          "a",
                          {
                            staticClass: "table__pagination__link",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.previous($event)
                              },
                            },
                          },
                          [
                            _vm.pagination.currentPage === 1 ||
                            _vm.pagination.totalPages === 1
                              ? _c("span", [_vm._v(" ")])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.pagination.currentPage !== 1
                              ? _c("span", [_vm._v("< previous")])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "table__pagination__numbers" }, [
                        _vm.pagination.totalPages > 1
                          ? _c(
                              "ul",
                              { staticClass: "table__pagination__row" },
                              _vm._l(
                                _vm.pagination.totalPages,
                                function (page) {
                                  return _c(
                                    "li",
                                    {
                                      key: page,
                                      staticClass: "table__pagination__list",
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "table__pagination__link",
                                          class: {
                                            "table__pagination__link--active":
                                              _vm.pagination.currentPage ===
                                              page,
                                          },
                                          attrs: {
                                            href: "#",
                                            "aria-label":
                                              "Current Page, Page " + page,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.pageSelected(page)
                                            },
                                          },
                                        },
                                        [_vm._v(_vm._s(page))]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "table__pagination__next" }, [
                        _c(
                          "a",
                          {
                            staticClass: "table__pagination__link",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.next($event)
                              },
                            },
                          },
                          [
                            _vm.pagination.currentPage ===
                              _vm.pagination.totalPages ||
                            _vm.pagination.totalPages === 1
                              ? _c("span", [_vm._v(" ")])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.pagination.currentPage !==
                            _vm.pagination.totalPages
                              ? _c("span", [_vm._v("next >")])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("img", {
        attrs: {
          src: "/img/gear-animation-115.gif",
          alt: "Badges loading gear icon",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header__intro" }, [
      _c("div", { staticClass: "header__intro__title" }, [
        _vm._v("\n        Badges\n      "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "header__intro__summary" }, [
        _vm._v(
          "\n        This is every badge earned by members of your group. "
        ),
        _c(
          "a",
          {
            attrs: { href: "https://tools.mouse.org/badges", target: "_blank" },
          },
          [_vm._v("Learn more >")]
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _vm._v(
        "\n          Submit every project related to a badge.  Go to the "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://tools.mouse.org/badge-directory",
            target: "_blank",
          },
        },
        [_vm._v("Badge Directory")]
      ),
      _vm._v(" to see all available badges.\n        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }