<template>
  <div v-show="initialLoadFinished">
    <header v-if="showHeader">
      <div
        :class="{
          'has-group': hasDefaultGroup && ! navMinified,
          'has-no-active-groups': currentUser && ! currentUser.hasAnyLicensedSites && ! navMinified
        }"
        class="container"
      >
        <div class="branding">
          <div class="logo">
            <img
              alt="Mouse Create"
              src="/img/mouse-create-logo.png"
            >
          </div>
        </div>

        <div
          v-if="!navMinified"
          class="grouping"
        >
          <group-name
            v-if="isLoggedIn && hasDefaultGroup"
            :group-id="currentUser.defaultGroupId"
          />
        </div>

        <AppNav v-bind="{ isLoggedIn, navMinified, toggleMenu, notificationCount, isToggled }" />
      </div>
    </header>

    <router-view
      :key="$route.fullPath"
      :tab-options="tabOptions"
    />

    <footer-nav v-if="!isHome" />
    <project-review-modal />
    <alert-message />
  </div>
</template>

<script>
import _ from 'lodash';
import AppNav from './nav/AppNav.vue';
import GroupName from './groups/GroupName.vue';
import ProjectReviewModal from './modals/ProjectReviewModal.vue';
import AlertMessage from './alerts/AlertMessage.vue';
import FooterNav from './nav/FooterNav.vue';

const timerRef = { current: null };

export default {
  name: 'AppRoot',
  components: {
    FooterNav, AppNav, GroupName, ProjectReviewModal, AlertMessage,
  },
  data: () => ({
    notificationCount: 0,
    navMinified: false,
    isToggled: false,
  }),
  computed: {
    currentUser() {
      return this.$store.state.users.current ? this.$store.state.users.current : null;
    },
    initialLoadFinished() {
      return this.$store.state.users.initialLoadFinished;
    },
    hasDefaultGroup() {
      return !!(this.currentUser && this.currentUser.defaultGroupId);
    },
    isRegistered() {
      return this.currentUser && !_.isEmpty(this.currentUser.profile);
    },
    isHome() {
      return this.$route.name === 'home';
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    notificationCountUrl() {
      return laroute.route('api.notifications.count');
    },
    showHeader() {
      return this.$route.name !== 'home';
    },
    tabOptions() {
      return {
        groupId: this.currentUser ? this.currentUser.defaultGroupId : null,
      };
    },
  },
  watch: {
    $route() {
      this.handleNotificationManager();
    },
    currentUser(user) {
      if (user && this.hasDefaultGroup) {
        this.$store.dispatch('setActiveGroup', this.currentUser.defaultGroupId);
      }
    },
    isLoggedIn() {
      if (this.isLoggedIn) {
        this.handleNotificationManager();
      }
    },
  },
  created() {
    if (this.isLoggedIn) {
      this.handleNotificationManager();
    }
  },
  methods: {
    handleNotificationManager() {
      const { name } = this.$route;

      switch (name) {
        case 'group-switch':
        case 'create-group':
        case 'new-educator':
        case 'join-group':
        case 'join-site':
          this.navMinified = true;
          break;
        default:
          this.navMinified = false;
      }

      if (name !== 'login' && name !== 'register' && !timerRef.current) {
        this.startNotificationChecker();
      } else {
        this.checkJs();
      }
    },
    toggleMenu() {
      this.isToggled = !this.isToggled;

      if (this.isToggled) {
        document.addEventListener('click', this.outsideListener);
      } else {
        document.removeEventListener('click', this.outsideListener);
      }
    },
    outsideListener(event) {
      const element = window.document.getElementById('menu-dropdown');
      if (!element.contains(event.target) && this.isToggled) {
        this.isToggled = false;
      }
    },
    startNotificationChecker() {
      if (!this.isLoggedIn) {
        timerRef.current = null;
        return;
      }

      axios.get(this.notificationCountUrl).then((response) => {
        // If total is not defined from api we are not logged in
        if (typeof response.data.total === 'undefined') {
          timerRef.current = null;
          return;
        }

        this.notificationCount = response.data.total;

        timerRef.current = setTimeout(() => {
          this.startNotificationChecker();
        }, 5000);
      }).catch(() => {
        this.$store.dispatch('logoutClient');
      });
    },
    checkJs() {
      // handle the case where we don't detect the browser
      document.body.className = document.body.className.replace('no-js', 'js');
    },
  },
};
</script>
