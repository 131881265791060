var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filter--form" }, [
    _c("div", { staticClass: "filter--form--label" }, [
      _vm._v("\n    Filter by:\n  "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "filter--form--content" }, [
      _c("form", [_vm._t("default")], 2),
      _vm._v(" "),
      _vm.isFilterSelected
        ? _c(
            "a",
            {
              staticClass: "filter--clear--filter",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.clearFilter($event)
                },
              },
            },
            [_vm._v("\n      Clear Filters\n    ")]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }