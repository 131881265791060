<template>
  <tabs
    :nav-tabs="tabs"
    :options="user"
  />
</template>

<script>

import Tabs from '../tabs/Tabs.vue';

export default {
  name: 'ProfileTabs',
  components: { Tabs },
  props: {},
  data() {
    const route = this.$route;

    return {
      tabs: [
        {
          title: 'My Profile',
          component: 'profile-view',
          active: (route.name === 'profile-view'),
          visible: true,
          checkOwnership: true,
        },
        {
          title: 'Edit Profile',
          component: 'profile-edit',
          active: !!((route.name === 'profile-settings' || route.name === 'profile-educator-edit')),
          visible: false,
        },
        {
          title: 'My Work',
          component: 'works',
          active: (route.name === 'profile-work'),
          visible: true,
          checkOwnership: true,
        },
      ],
      owner: false,
    };
  },
  computed: {
    currentUser() {
      return (this.$store.state.users.current) ? this.$store.state.users.current : null;
    },
    user() {
      return {
        user: this.currentUser,
      };
    },
  },
  watch: {
    currentUser() {
      this.loadProfile();
    },
  },
  mounted() {
    this.loadProfile();
  },
  methods: {
    // get profile to see if this user is the owner
    loadProfile() {
      const token = this.$route.params.id;
      const url = laroute.route('api.user', { token });

      axios
        .get(url)
        .then((response) => {
          this.owner = response.data.owner;
          this.processNav();
        },
        () => {
          // this.$router.push({name: 'page-not-found'})
        });
    },

    processNav() {
      const $this = this;
      _.each(this.tabs, (tab) => {
        if (tab.checkOwnership) {
          tab.visible = $this.owner;
        }
      });
      this.updateTabs();
    },

    updateTabs() {
      $('body').trigger('vue.global.event', {
        _event: 'updateNavTabs',
        tabs: this.tabs,
      });
    },
  },
};
</script>
