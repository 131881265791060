export const SET_MESSAGE = 'SET_MESSAGE';

// initial state
const state = {
  message: {
    loaded: false,
    content: '',
    type: 'success', // success or error
    icon: '',
    autoclose: true,
  },
};

// getters
const getters = {
  message: (state) => state.message,
};

// actions
const actions = {
  loadMessage: ({ commit }, message) => {
    const data = {
      content: message.content,
      type: message.type,
      icon: message.icon,
      loaded: true,
      autoclose: message.autoclose,
    };

    if (message.type === 'error') {
      data.autoclose = false;
    }

    commit(SET_MESSAGE, data);
  },
  clearMessage: ({ commit }) => {
    const data = {
      content: '',
      type: 'success',
      icon: '',
      loaded: false,
    };

    commit(SET_MESSAGE, data);
  },
};

// mutations
const mutations = {

  SET_MESSAGE: (state, message) => {
    state.message = message;
  },
};

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
