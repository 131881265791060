var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "tab-content--padding main--overflow-visible" },
    [
      _c("section", { staticClass: "group-tab" }, [
        _c(
          "div",
          { staticClass: " group-fixed-container" },
          [
            _c("div", { staticClass: "header__admin header__admin--no-rule" }, [
              _c("div", { staticClass: "header__admin__text" }, [
                _c("h1", { staticClass: "header__admin__text__title" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.group.name) +
                      " Notifications\n          "
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("notification-form", {
              attrs: { context: "group", contextid: _vm.group.id },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }