var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header__admin" }, [
    _c("div", { staticClass: "header__admin__text" }, [
      _c("h1", { staticClass: "header__admin__text__title" }, [
        _vm._v("\n      " + _vm._s(_vm.groupName) + "\n    "),
      ]),
      _vm._v(" "),
      _vm.$groupUser().can("manage_group")
        ? _c("h2", { staticClass: "header__admin__text__subtitle" }, [
            _vm._v("\n      Group Code: " + _vm._s(_vm.groupCode) + "\n    "),
          ])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "header__admin__actions" }, [
      _vm.$groupUser().can("manage_group")
        ? _c("span", [
            _c(
              "a",
              {
                staticClass: "header__admin__actions--notification",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.loadNotifications($event)
                  },
                },
              },
              [_vm._v("Send Notification")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "header__admin__actions--edit",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.loadEdit($event)
                  },
                },
              },
              [_vm._v("Edit Group")]
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }