<template>
  <transition name="message-fade">
    <div
      v-if="message.loaded"
      class="alert__message"
      :class="messageTypeClass"
    >
      <button
        class="message__icon-close"
        @click.stop.prevent="closeMessage"
      >
        <svg
          width="21px"
          height="21px"
          viewBox="0 0 21 21"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>close X</title>
          <desc>Created with Sketch.</desc>
          <g
            id="Page-3"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
            stroke-linecap="square"
          >
            <g
              id="Notify-Message-Set"
              transform="translate(-1124.000000, -67.000000)"
              stroke="#FFFFFF"
              stroke-width="4"
            >
              <g
                id="Notify-Message-Green"
                transform="translate(672.000000, 52.000000)"
              >
                <g id="Notification-box">
                  <g
                    id="close-X"
                    transform="translate(455.000000, 18.000000)"
                  >
                    <path
                      id="Line"
                      d="M0.5,0.50472973 L14.6421356,14.7806423"
                    />
                    <path
                      id="Line"
                      d="M0.5,0.50472973 L14.6421356,14.7806423"
                      transform="translate(7.500000, 7.570946) scale(-1, 1) translate(-7.500000, -7.570946) "
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </button>
      <div class="message__content">
        <div
          v-if="message.icon"
          class="message__icon"
        >
          <img :src="'/img/alerts/' + message.icon + '.svg'">
        </div>
        <div
          class="message__text"
          v-html="message.content"
        />
      </div>
    </div>
  </transition>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: 'AlertMessage',
  components: {},
  props: {},
  data() {
    return {
      isOpen: true,
    };
  },
  computed: {
    ...mapGetters([
      'message',
    ]),
    messageTypeClass() {
      return {
        message__success: this.message.type === 'success',
        message__error: this.message.type === 'error',
        icon__disabled: this.message.icon === '',
      };
    },
  },
  watch: {
    message(value) {
      const { autoclose } = this.message;

      if (value.loaded && autoclose) {
        const self = this;
        setTimeout(() => {
          self.$store.dispatch('clearMessage');
        }, 3500);
      }
    },
  },
  mounted() {},
  methods: {
    closeMessage() {
      this.$store.dispatch('clearMessage');
    },
  },
};
</script>
