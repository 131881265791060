<template>
  <main>
    <badge-award-modal
      :award="award"
      :badges="badges"
    />
    <badge-student-modal :award="award" />

    <div
      v-if="!loaded"
      class="response-loading"
    >
      <h2>Badges loading now...</h2>
      <p>
        <img
          src="/img/gear-animation-115.gif"
          alt="Badges loading gear icon"
        >
      </p>
    </div>

    <section
      v-if="loaded"
      class="group-tab primary-content"
    >
      <group-header :group="group" />

      <div class="header__intro">
        <div class="header__intro__title">
          Badges
        </div>
        <div class="header__intro__summary">
          This is every badge earned by members of your group. <a
            href="https://tools.mouse.org/badges"
            target="_blank"
          >Learn more ></a>
        </div>
      </div>

      <table
        v-if="loaded && badges.length > 0"
        class="table__paginated"
      >
        <thead>
          <tr>
            <th>
              <span
                class="sort"
                :class="sortClasses('eligibility_dt')"
                @click="sortMe('eligibility_dt')"
              >Date Eligible</span>
            </th>
            <th>
              <span
                class="sort"
                :class="sortClasses('title')"
                @click="sortMe('title')"
              >Badge Name</span>
            </th>
            <th>
              <span
                class="sort"
                :class="sortClasses('user.full_name')"
                @click="sortMe('user.full_name')"
              >Member Name</span>
            </th>
            <th>
              <span
                class="sort"
                :class="sortClasses('awardingWeight')"
                @click="sortMe('awardingWeight')"
              >Awarded?</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="badge in filteredBadges"
            :key="badge.id"
          >
            <td data-label="Date Earned">
              {{ badge.eligibility_dt | formatDate('M/D/YY') }}
            </td>
            <td data-label="Badge Name">
              <a
                v-if="$groupUser().can('award_badges') && !badge.awarded"
                href="#"
                @click.stop.prevent="showAwardModal( badge.id , $event )"
              >{{ badge.title }}</a>
              <a
                v-if="!$groupUser().can('award_badges') && !badge.awarded"
                href="#"
                @click.stop.prevent="showStudentModal( badge.id , $event )"
              >{{ badge.title }}</a>
              <router-link
                v-if="badge.awarded"
                :to="'/badge-evidence/' + badge.code"
              >
                {{ badge.title }}
              </router-link>
            </td>
            <td data-label="Earned By">
              <router-link :to="getProfileRoute( badge.user.token )">
                <div class="icon__group">
                  <div
                    class="profile__pic-member"
                    v-html="badge.user.avatar"
                  />
                  <div class="icon__group__text">
                    {{ badge.user.full_name }}
                  </div>
                </div>
              </router-link>
            </td>
            <td data-label="Awarded">
              <div v-if="$groupUser().can('award_badges') && badge.user.canAward && !badge.awarded && badge.acclaim_badge_id.length > 0">
                <a
                  class="status--needs-review"
                  href="#"
                  @click.stop.prevent="showAwardModal( badge.id )"
                >Award Badge</a>
              </div>
              <div v-if="$groupUser().can('award_badges') && badge.user.canAward && badge.acclaim_badge_id.length < 1">
                <a
                  href="#"
                  @click.stop.prevent="showAwardModal( badge.id )"
                >Not Available</a>
              </div>
              <div v-if="!$groupUser().can('award_badges') && !badge.awarded">
                No
              </div>
              <div v-if="badge.awarded">
                <router-link
                  href="#"
                  :to="'/badge-evidence/' + badge.code"
                >
                  Awarded {{ badge.updated_at | formatDate('M/D/YY') }}
                </router-link>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="loaded && badges.length === 0"
        class="group_info"
      >
        <p>Your group has not started earning badges yet.  To earn a badge:</p>
        <ol class="list-number-style">
          <li>Accept ability to accept badges in your <a :href="'/profile/' + currentUser.token">Mouse Create Profile</a>. You must be 13 or older to accept badges.</li>
          <li>
            Submit every project related to a badge.  Go to the <a
              href="https://tools.mouse.org/badge-directory"
              target="_blank"
            >Badge Directory</a> to see all available badges.
          </li>
          <li>Your educator must review each project related to the badge in the Group Work tab.</li>
          <li>Your badge will then appear here, ready for your educator to award it to you!</li>
        </ol>
      </div>

      <nav
        v-if="pagination.totalPages > 1"
        class="table__pagination"
        role="navigation"
        aria-label="Pagination Navigation"
      >
        <div class="table__pagination__prev">
          <a
            href="#"
            class="table__pagination__link"
            @click.stop.prevent="previous"
          >
            <span v-if="pagination.currentPage === 1 || pagination.totalPages === 1">&nbsp;</span>
            <span v-if="pagination.currentPage !== 1">&lt; previous</span>
          </a>
        </div>
        <div class="table__pagination__numbers">
          <ul
            v-if="pagination.totalPages > 1"
            class="table__pagination__row"
          >
            <li
              v-for="page in pagination.totalPages"
              :key="page"
              class="table__pagination__list"
            >
              <a
                href="#"
                class="table__pagination__link"
                :aria-label="'Current Page, Page ' + page"
                :class="{ 'table__pagination__link--active': pagination.currentPage === page }"
                @click.stop.prevent="pageSelected(page)"
              >{{ page }}</a>
            </li>
          </ul>
        </div>
        <div class="table__pagination__next">
          <a
            href="#"
            class="table__pagination__link"
            @click.stop.prevent="next"
          >
            <span v-if="pagination.currentPage === pagination.totalPages || pagination.totalPages === 1">&nbsp;</span>
            <span v-if="pagination.currentPage !== pagination.totalPages">next &gt;</span>
          </a>
        </div>
      </nav>
    </section>
  </main>
</template>

<script>

import GroupHeader from './GroupHeader.vue';
import BadgeAwardModal from './BadgeAwardModal.vue';
import BadgeStudentModal from './BadgeStudentModal.vue';
import modalMixin from '../../mixins/modal';
import paginationMixin from '../../mixins/pagination';
import sortingMixin from '../../mixins/sorting';

export default {
  name: 'Badges',
  components: { GroupHeader, BadgeAwardModal, BadgeStudentModal },
  mixins: [modalMixin, paginationMixin, sortingMixin],
  data() {
    return {
      badges: [],
      loaded: false,
      editModalAward: '#badge-award-modal',
      badgeDetailModal: '#badge-details-modal',
      badgeStudentModal: '#badge-student-modal',
      editModalStatusMessage: '',
      evidences: [],
      award_complete: false,
      award_error: false,
      award_error_text: null,
      award_terms_agreement: false,
      award: {
        user: {},
        of_age: '',
        badge: {
          image_url: '',
        },
        issuer: {
          name: '',
        },
      },
      pagination: {
        limit: 25,
      },
      groupName: '',
      groupCode: '',
      field: 'eligibility_dt',
      order: 'desc',
      groupId: '',
    };
  },
  computed: {
    group() {
      return this.$store.state.groups.active;
    },

    getBadgeRoute() {
      return laroute.route('api.group.badges', { group: this.groupId });
    },

    currentUser() {
      return this.$store.state.users.current;
    },

    filteredBadges() {
      // // Order Evidences
      return _.orderBy(this.badges, (badge) => {
        const byField = _.get(badge, this.field);

        if (byField == null || byField === false) {
          return '';
        }

        return byField.toString().toLowerCase();
      }, [this.order]);
    },
    showGroupRoute() {
      return laroute.route('api.group.show', { group: this.groupId });
    },
    deleteGroupRoute() {
      return laroute.route('group.delete', { group: this.groupId });
    },
  },
  mounted() {
    this.groupId = this.$route.params.id;

    this.getEvidence();

    bus.$on('get-badge-evidence', this.getEvidence);

    if (this.$route.name !== 'group-badges') {
      this.$router.push({ name: 'group-badges', params: { id: this.groupId } }).catch(() => {});
    }
  },
  methods: {
    getProfileRoute(token) {
      return laroute.url('profile', [token]);
    },

    showStudentModal() {
      this.openModal(this.badgeStudentModal);
    },

    // show modal for editing of evidence
    showAwardModal(award) {
      this.award_error = false;
      this.award_complete = false;
      this.award_terms_agreement = false;

      axios.get(this.getAwardRoute(award))
        .then((response) => {
          this.award = response.data;

          // mark badge as complete
          if (response.data.awarded) {
            this.award_complete = true;
          }

          this.openModal(this.editModalAward);
        });
    },

    // close modal window
    closeAwardModal() {
      this.closeModal(this.editModalAward);
    },

    // perform udpate on evidence record
    awardBadge() {
      axios.post(this.getAwardRoute(this.award.id), {
        agreetoterms: this.award_terms_agreement,
      }).then((response) => {
        this.getEvidence();

        if (response.data.status) {
          this.award_complete = true;
          this.closeAwardModal();
        } else {
          this.award_error = true;
          this.award_error_text = response.data.message;
        }
      });
    },

    // perform udpate on evidence record
    updateEvidence() {
      axios.put(this.getEvidenceUpdateRoute(), {
        reviewed: this.reviewedStatus,
      }).then((response) => {
        this.editModalStatusMessage = response.data.message;

        this.reviewedStatus = false;

        // refresh evidence list
        this.getEvidence();

        /*
                if( this.keepReviewing )
                  this.showNext();
                else
                */
        this.closeModal(this.editModal);
        this.openModal(this.editModalStatus);
      });
    },

    getEvidence() {
      axios.get(this.getBadgeRoute)
        .then((response) => {
          this.badges = response.data.data;
          this.loaded = true;
        });
    },

    getAwardRoute(award_id) {
      return laroute.route('badgeaward.get', { award: award_id });
    },
  },
};
</script>
