<template>
  <div>
    <section class="course__page">
      <div
        class="course__top-header"
        :class="{ 'course__top-header--fixed': passedScroll }"
      >
        <div class="course__heading">
          <div class="course__icon">
            <img
              :src="course.icon"
              alt="course icon"
            >
          </div>
          <h1 class="course__title">
            {{ course.title }}
          </h1>
          <div
            v-if="currentUser.isEducator && $groupUser().can('add_course')"
            class="course__add-to-course"
          >
            <button @click.stop.prevent="addToPlaylist">
              Add Course to Playlist
              <svg
                width="15px"
                height="15px"
                viewBox="0 0 15 15"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>+</title>
                <desc>Created with Sketch.</desc>
                <g
                  id="Page-1"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                  font-family="Basetica-Medium, Basetica"
                  font-size="31"
                  font-weight="400"
                >
                  <g
                    id="Courses-Page----Default-v2"
                    transform="translate(-1298.000000, -243.000000)"
                    fill="#FFFFFF"
                  >
                    <g
                      id="Course-Description.---CLOSED"
                      transform="translate(103.000000, 230.000000)"
                    >
                      <g
                        id="Add-new-btn"
                        transform="translate(976.000000, 0.000000)"
                      >
                        <text id="+">
                          <tspan
                            x="218.258"
                            y="32"
                          >+</tspan>
                        </text>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>

      <section class="course__row course__bottom-border content__has-lists">
        <div
          class="course__description"
          :class="{ 'full-height': descriptionExpanded }"
          v-html="course.description"
        />
        <a
          href="#"
          class="course__more-button"
          @click.stop.prevent="toggleDescription"
        >
          <span v-if="!descriptionExpanded">Show More</span>
          <span v-else>Show Less</span>
          <span v-if="descriptionExpanded">
            <svg
              width="27px"
              height="4px"
              viewBox="0 0 27 4"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Line 2</title>
              <desc>Created with Sketch.</desc>
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                stroke-linecap="square"
              >
                <g
                  id="Courses-Page---Open-v2"
                  transform="translate(-201.000000, -1136.000000)"
                  fill-rule="nonzero"
                  stroke="#00A886"
                  stroke-width="4"
                >
                  <g
                    id="Course-Description.---OPEN"
                    transform="translate(103.000000, 230.000000)"
                  >
                    <g
                      id="View-Less"
                      transform="translate(2.000000, 898.000000)"
                    >
                      <path
                        id="Line-2"
                        d="M98.5,10 L121,10"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
          <span v-else>
            <svg
              width="20px"
              height="20px"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Group 4</title>
              <desc>Created with Sketch.</desc>
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                stroke-linecap="square"
              >
                <g
                  id="Courses-Page----Default-v2"
                  transform="translate(-206.000000, -450.000000)"
                  fill-rule="nonzero"
                  stroke="#00A886"
                  stroke-width="3.2"
                >
                  <g
                    id="View-More"
                    transform="translate(105.000000, 451.000000)"
                  >
                    <g
                      id="Group-4"
                      transform="translate(103.000000, 1.000000)"
                    >
                      <path
                        id="Line-2"
                        d="M0.347826087,8 L16,8"
                      />
                      <path
                        id="Line-2-Copy"
                        d="M0.173913043,8.17391304 L15.826087,8.17391304"
                        transform="translate(8.000000, 8.173913) rotate(-270.000000) translate(-8.000000, -8.173913) "
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
        </a>
      </section>

      <course-badges
        v-if="course.badges"
        :badges="course.badges.data"
      />

      <course-projects
        v-if="course.projects"
        :projects="course.projects.data"
        :group-project-ids="groupProjectIds"
      />
    </section>

    <course-related-projects
      v-if="course.projects"
      :projects="course.relatedProjects.data"
      :description="course.related_description"
      :group-project-ids="groupProjectIds"
    />
  </div>
</template>

<script>

import CourseBadges from './CourseBadges.vue';
import CourseProjects from './CourseProjects.vue';
import CourseRelatedProjects from './CourseRelatedProjects.vue';

export default {
  name: 'CourseModule',
  components: {
    CourseBadges,
    CourseProjects,
    CourseRelatedProjects,
  },
  data() {
    return {
      course: {},
      descriptionExpanded: false,
      groupProjectIds: [],
      scrollY: null,
      passedScroll: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.users.current;
    },
    courseRoute() {
      return laroute.route('api.courses.show', {
        course: this.$route.params.slug,
        include: 'projects,relatedProjects,badges',
      });
    },
    addCourseToPlaylistRoute() {
      return laroute.route('api.course.add.projects');
    },
    defaultGroupId() {
      return this.currentUser.defaultGroupId;
    },
    showGroupRoute() {
      return laroute.route('api.group.show', { group: this.defaultGroupId });
    },
    getGroupProjectsRoute() {
      return laroute.route('api.group.projects', { group: this.defaultGroupId });
    },
  },
  watch: {
    defaultGroupId() {
      this.getGroupProjects();
    },
    scrollY(ycoord) {
      this.passedScroll = ycoord >= 114;
    },
  },
  mounted() {
    this.getCourse();

    const $this = this;

    window.addEventListener('scroll', () => {
      $this.scrollY = Math.round(window.scrollY);
    });
  },
  methods: {
    getCourse() {
      const $this = this;

      axios.get(this.courseRoute)
        .then((response) => {
          $this.course = response.data;
        });
    },
    toggleDescription() {
      this.descriptionExpanded = !this.descriptionExpanded;

      if (!this.descriptionExpanded) {
        window.scrollTo(0, 0);
      }
    },
    addToPlaylist() {
      let projects = this.course.projects.data;
      projects = _.map(projects, 'id');

      const data = {
        projects,
        group: this.defaultGroupId,
      };

      axios.post(this.addCourseToPlaylistRoute, data)
        .then((response) => {
          const { message } = response.data;

          this.$store.dispatch('loadMessage', {
            content: message,
            type: 'success',
            icon: 'plane',
            autoclose: true,
          });

          this.getGroupProjects();
        }).catch((err) => {
          const { message } = err.response.data;

          this.$store.dispatch('loadMessage', {
            content: message,
            type: 'error',
            icon: 'warning',
            autoclose: true,
          });
        });
    },
    getGroupProjects() {
      axios.get(this.getGroupProjectsRoute).then((response) => {
        const projects = response.data.data;
        this.groupProjectIds = _.map(projects, 'id');
      });
    },
  },
};
</script>
