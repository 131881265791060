var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "16px",
        height: "16px",
        viewBox: "0 0 16 16",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("title", [_vm._v("X icon")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Symbols",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "row/MEMBER-Gray",
                transform: "translate(-1059.000000, -29.000000)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Group",
                    transform: "translate(17.000000, 11.000000)",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Archive",
                        transform: "translate(881.000000, 18.000000)",
                      },
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "X-icon",
                            transform: "translate(161.000000, 0.000000)",
                          },
                        },
                        [
                          _c("circle", {
                            attrs: {
                              id: "Oval",
                              fill: "#00A886",
                              cx: "8",
                              cy: "8",
                              r: "8",
                            },
                          }),
                          _vm._v(" "),
                          _c("polygon", {
                            attrs: {
                              id: "X",
                              fill: "#FFFFFF",
                              "fill-rule": "nonzero",
                              points:
                                "6.23809524 12 7.88095238 9.18888889 9.69047619 12 12 12 9.14285714 7.88888889 11.7619048 4 9.63095238 4 8.08333333 6.55555556 6.44047619 4 4.14285714 4 6.80952381 7.76666667 4 12",
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }