var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "16px",
        height: "17px",
        viewBox: "0 0 16 17",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("defs"),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Designs",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              staticClass: "step__icon-state",
              attrs: {
                id: "Steps-Tab-with-Prompt-Large",
                transform: "translate(-450.000000, -1198.000000)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Project-Detail-Copy-2",
                    transform: "translate(49.000000, 247.000000)",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Evidence-Prompt-Steps-Tab---Large",
                        transform: "translate(0.000000, 675.000000)",
                      },
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "Add-response",
                            transform: "translate(21.000000, 206.000000)",
                          },
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "BUTTON-ICON---DEFAULT",
                                transform: "translate(0.000000, 63.000000)",
                              },
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "button---new-file-ON",
                                    transform:
                                      "translate(292.000000, 0.000000)",
                                  },
                                },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        id: "file27",
                                        transform:
                                          "translate(88.378588, 7.000000)",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          id: "Shape",
                                          d: "M13.9185743,4.20002361 L10.4702667,0.798685557 C10.1016966,0.435269579 9.61537936,0.19896891 9.01117943,0.090051161 L9.01117943,5.63896891 L14.6368587,5.63896891 C14.5263012,5.04286501 14.2867374,4.56330579 13.9185743,4.20002361 L13.9185743,4.20002361 Z",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          id: "Shape",
                                          d: "M7.90601175,6.72908304 C7.69968303,6.52569854 7.5965865,6.27855962 7.5965865,5.98780008 L7.5965865,0.0574025974 L1.58429525,0.0574025974 C1.28965621,0.0574025974 1.03910451,0.159094845 0.832775795,0.362613144 C0.62644708,0.565997639 0.523350549,0.81313656 0.523350549,1.1038961 L0.523350549,15.755608 C0.523350549,16.0463676 0.62644708,16.2935065 0.832775795,16.496891 C1.03910451,16.7002755 1.28965621,16.8021015 1.58429525,16.8021015 L13.6091491,16.8021015 C13.9037881,16.8021015 14.1542041,16.7002755 14.3603972,16.496891 C14.5667259,16.2933727 14.6698225,16.0463676 14.6698225,15.755608 L14.6698225,7.03429359 L8.65766685,7.03429359 C8.36275651,7.03429359 8.11220481,6.93260134 7.90601175,6.72908304 Z",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }