<template>
  <select
    class="filter--form--criteria"
    v-bind="$attrs"
    :value="value"
    @change="updateInput"
  >
    <option :value="null">
      Review Status
    </option>
    <option
      v-for="status in statuses"
      :key="status.id"
      :value="status.id"
    >
      {{ status.title }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'StatusSelect',
  inheritAttrs: false,
  props: {
    statuses: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: String,
      default() {
        return null;
      },
    },
  },
  methods: {
    updateInput(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>
