var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "full-wrap" }, [
    _c("div", { staticClass: "page-wrap" }, [
      _c("main", [
        _c("section", { staticClass: "primary-content" }, [
          _c("div", { staticClass: "content-box" }, [
            _vm.currentUser.isInActiveGroup
              ? _c(
                  "div",
                  { staticClass: "back_link" },
                  [
                    _c("router-link", { attrs: { to: "/join/group" } }, [
                      _c("i", {
                        staticClass: "fa fa-chevron-left",
                        attrs: { "aria-hidden": "true" },
                      }),
                      _vm._v(" Back to Add Group\n            "),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.currentUser.hasAnyLicensedSites && !_vm.currentUser.isNewUser
              ? _c("div", [
                  _c("h1", { staticClass: "page-title" }, [
                    _vm._v(
                      "\n              Time to renew your site’s Mouse membership!\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _vm._m(2),
                  _vm._v(" "),
                  _vm._m(3),
                  _vm._v(" "),
                  _vm._m(4),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n              If you are now at a new Mouse site and want to use this account, enter your new site’s Educator Code or Group Code below.\n            "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("h1", { staticClass: "page-title" }, [
              _vm._v("\n            Educator Code\n          "),
            ]),
            _vm._v(" "),
            _c("form", { staticClass: "simple-join-group" }, [
              _vm._m(5),
              _vm._v(" "),
              _c("div", { staticClass: "form-row short-row" }, [
                _c(
                  "label",
                  {
                    staticClass: "visuallyhidden",
                    attrs: { for: "educator_code" },
                  },
                  [_vm._v("Educator Code")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.code,
                      expression: "code",
                    },
                  ],
                  attrs: {
                    id: "educator_code",
                    type: "text",
                    name: "code",
                    placeholder: "Enter Educator Code",
                    value: "",
                    autocomplete: "off",
                  },
                  domProps: { value: _vm.code },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.code = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "submit-button-centered" }, [
                _c(
                  "button",
                  {
                    staticClass: "button",
                    attrs: { type: "submit" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.joinGroup($event)
                      },
                    },
                  },
                  [_vm._v("\n                Submit\n              ")]
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("strong", [
        _vm._v("Unfortunately, your site’s membership has expired."),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "Don’t worry, all of your own work is still available in your Profile."
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "You can export your work anytime in the My Work area of your Profile."
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "We will reactivate your groups after your site’s membership is renewed."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("strong", [_vm._v("Ready to Renew?")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("a", { attrs: { href: "https://www.mouse.org/renew" } }, [
        _vm._v("Click here"),
      ]),
      _vm._v(
        " to learn more about renewing your membership or to let us know if you think this message is an error.  Students: Talk to your teacher about renewing your site’s membership.\n            "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("strong", [_vm._v("At a new site?")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "instructional" }, [
      _vm._v(
        "\n              Please confirm your Mouse Create membership by entering your site’s Educator Code below. If you don’t have your site’s code or want to learn more about membership, email "
      ),
      _c("a", { attrs: { href: "mailto:membership@mouse.org" } }, [
        _vm._v("membership@mouse.org"),
      ]),
      _vm._v(".\n            "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }