var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "modal",
    {
      attrs: { tabs: true, "is-visible": _vm.visible },
      on: {
        "update:isVisible": function ($event) {
          _vm.visible = $event
        },
        "update:is-visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "tabs" }, slot: "tabs" },
        _vm._l(_vm.tabs, function (tab) {
          return _c("div", { key: tab.component }, [
            tab.visible
              ? _c(
                  "a",
                  {
                    class: { active: tab.active },
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.activateTab(tab)
                      },
                    },
                  },
                  [
                    _vm._v("\n        " + _vm._s(tab.title) + "\n        "),
                    tab.count !== false
                      ? _c("span", { staticClass: "tab-count" }, [
                          _vm._v(_vm._s(tab.count)),
                        ])
                      : _vm._e(),
                  ]
                )
              : _vm._e(),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm.evidenceId
        ? _c(
            "div",
            { staticClass: "tab-content" },
            [
              _c("evidence-review", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeTab.title === "Project Work",
                    expression: "activeTab.title === 'Project Work'",
                  },
                ],
                attrs: {
                  "is-editing": _vm.isEditing,
                  "is-exportable": _vm.isExportable,
                  evidence: _vm.evidence,
                  "group-id": _vm.groupId,
                },
              }),
              _vm._v(" "),
              _c("comments", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.activeTab.title === "Discussion",
                    expression: "activeTab.title === 'Discussion'",
                  },
                ],
                attrs: {
                  "commentable-type": "evidence",
                  creators: _vm.allCreators,
                  "cursor-id": _vm.commentId,
                  "commentable-id": _vm.evidenceId,
                  evidence: _vm.evidence,
                },
              }),
              _vm._v(" "),
              _c("work-manage", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.activeTab.title === "Manage" && _vm.manageIsVisible,
                    expression:
                      "activeTab.title === 'Manage' && manageIsVisible",
                  },
                ],
                attrs: { evidence: _vm.evidenceId, wrapper: _vm.wrapper },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }