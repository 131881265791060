var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "360px",
        height: "40px",
        viewBox: "0 0 360 40",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("title", [_vm._v("Mouse Logo")]),
      _vm._v(" "),
      _c("defs"),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Page-1",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
            "font-family": "Basetica-Regular, Basetica",
            "font-size": "57.6",
            "font-weight": "normal",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Create-HOME-final",
                transform: "translate(-459.000000, -58.000000)",
              },
            },
            [
              _c("text", { attrs: { id: "mouse-create" } }, [
                _c("tspan", { attrs: { x: "456", y: "97", fill: "#FFFFFF" } }, [
                  _vm._v("mouse"),
                ]),
                _vm._v(" "),
                _c(
                  "tspan",
                  { attrs: { x: "648.4416", y: "97", fill: "#FF3333" } },
                  [_vm._v("create")]
                ),
              ]),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }