<template>
  <span>
    <a
      style="color:green"
      href="#"
      title="Masquerade as user"
      @click.stop.prevent="masquerade"
    >
      <i
        class="fa fa-user-secret"
        title="Masquerade as user"
      />
      <span v-if="showText">Masquerade</span>
    </a>
  </span>
</template>

<script>
export default {
  name: 'Masquerade',
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    showText: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentUser() {
      return (this.$store.state.users.current) ? this.$store.state.users.current : null;
    },
  },
  methods: {
    masquerade() {
      axios.get(`/api/masquerade/${this.id}`)
        .then(() => {
          // let adminUser = response.data.current.original
          // let masqueradeAs = response.data
          // this.$store.dispatch('loginUser', masqueradeAs)
          // this.$store.dispatch('masquerade', masqueradeAs)
          window.location.replace('/');
        });
    },
  },
};
</script>
