var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-wrap" }, [
    _c("main", [
      _c("section", { staticClass: "primary-content" }, [
        _c("div", { staticClass: "content-box" }, [
          _c("h1", { staticClass: "page-title" }, [
            _vm._v("\n          Mouse Competency Badges\n        "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { id: "badge" } },
            [
              _c("div", { staticClass: "badge_details" }, [
                _c("p", [
                  _vm._v(
                    "Mouse competency badges are awarded to learners who have demonstrated their grasp of skills and knowledge through project evidence reviewed by local educators."
                  ),
                ]),
                _vm._v(" "),
                _c("img", {
                  staticStyle: { float: "left" },
                  attrs: {
                    src: _vm.badgeUrl,
                    width: "125",
                    alt: _vm.badgeTitle,
                  },
                }),
                _vm._v(" "),
                _c("h2", { staticClass: "sub-section" }, [
                  _vm._v(
                    "\n              Badge: " +
                      _vm._s(_vm.badgeTitle) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _c("span", {
                  domProps: { innerHTML: _vm._s(_vm.badgeDescription) },
                }),
                _vm._v(" "),
                _c("hr"),
              ]),
              _vm._v(" "),
              _vm._l(_vm.evidences, function (evidence) {
                return _c(
                  "div",
                  { key: evidence.id, staticClass: "project-block" },
                  [
                    _c("h3", { staticClass: "sub-section" }, [
                      _vm._v(
                        "\n              Project: " +
                          _vm._s(evidence.project.title) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(evidence.project.teaser))]),
                    _vm._v(" "),
                    _c("div", { staticClass: "evidence-info" }, [
                      _c("p", [
                        _vm._v(
                          "Evidence Date: " +
                            _vm._s(
                              _vm.formatDate(evidence.created_at, "M/D/YYYY")
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        _vm._l(evidence.assets, function (asset) {
                          return _c("li", { key: asset.id }, [
                            _c("h3", [
                              _c("strong", [
                                _vm._v(
                                  "Work Submitted: " +
                                    _vm._s(
                                      _vm.formatDate(
                                        asset.created_at,
                                        "M/D/YYYY H:m a"
                                      )
                                    )
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            asset.upload
                              ? _c("div", [
                                  asset.upload.file.type === "image"
                                    ? _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            asset.upload.file.img
                                          ),
                                        },
                                      })
                                    : _c(
                                        "a",
                                        { attrs: { href: asset.upload.url } },
                                        [_vm._v("Download File")]
                                      ),
                                ])
                              : _c("p", {
                                  domProps: {
                                    innerHTML: _vm._s(asset.content),
                                  },
                                }),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]
                )
              }),
              _vm._v(" "),
              _c("h3", { staticClass: "sub-section" }, [
                _vm._v("\n            Earned By:\n          "),
              ]),
              _vm._v(" "),
              _c(
                "ul",
                _vm._l(_vm.users, function (user) {
                  return _c("li", { key: user.id }, [
                    _vm._v(
                      "\n              " + _vm._s(user.name) + "\n            "
                    ),
                  ])
                }),
                0
              ),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }