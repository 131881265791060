var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "project-tab" }, [
    _c("h1", [_vm._v(_vm._s(_vm.project.title))]),
    _vm._v(" "),
    !_vm.isLoading && _vm.project.hasPackAccess
      ? _c(
          "div",
          { staticClass: "project__plan" },
          _vm._l(_vm.plans, function (step, index) {
            return _c("evidence-step", { key: index, attrs: { step: step } })
          }),
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading && !_vm.project.hasPackAccess,
            expression: "!isLoading && !project.hasPackAccess",
          },
        ],
      },
      [_c("p", [_vm._v("You do not have access to this project")])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }