var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "comment-thread",
      class: _vm.commentClass,
      attrs: { id: _vm.commentElId },
    },
    [
      _c("div", { staticClass: "comment-thread__user-avatar" }, [
        _c("a", {
          attrs: {
            tabindex: "-1",
            href: _vm.comment.user.links.profile,
            target: "_blank",
          },
          domProps: { innerHTML: _vm._s(_vm.comment.user.avatar) },
        }),
        _vm._v(" "),
        _vm.comment.type.id
          ? _c("div", { staticClass: "comment-thread__type" }, [
              _vm.commentType === "love"
                ? _c(
                    "svg",
                    {
                      attrs: {
                        width: "40px",
                        height: "40px",
                        viewBox: "0 0 40 40",
                        xmlns: "http://www.w3.org/2000/svg",
                        "aria-labelledby": "navHeartOnTitle navHeartOnDesc",
                      },
                    },
                    [
                      _c("title", { attrs: { id: "navHeartOnTitle" } }, [
                        _vm._v("Heart Selected"),
                      ]),
                      _c("desc", { attrs: { id: "navHeartOnDesc" } }, [
                        _vm._v("A Heart"),
                      ]),
                      _vm._v(" "),
                      _c("desc", [_vm._v("Created with Sketch.")]),
                      _vm._v(" "),
                      _c("defs"),
                      _vm._v(" "),
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "Symbols",
                            stroke: "none",
                            "stroke-width": "1",
                            fill: "none",
                            "fill-rule": "evenodd",
                          },
                        },
                        [
                          _c("g", { attrs: { id: "comment-icons-ON" } }, [
                            _c("g", { attrs: { id: "Star--SELECTED-Copy" } }, [
                              _c("g", [
                                _c("circle", {
                                  attrs: {
                                    id: "star-Oval-5",
                                    fill: "#2C328C",
                                    cx: "20",
                                    cy: "20",
                                    r: "20",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    id: "Fill-1",
                                    d: "M23.2111061,29.7771814 L27.27883,31.9081167 C27.8534854,32.2092536 28.5249588,31.7226445 28.4150493,31.0854182 L27.0329259,23.0525698 C26.9887667,22.7994318 27.073568,22.5414258 27.2577274,22.3618925 L33.114093,16.6731996 C33.5793274,16.2215429 33.3228721,15.4347705 32.6805125,15.341791 L24.58727,14.1699566 C24.3322801,14.1334463 24.1114842,13.9733852 23.9975692,13.7433217 L20.3785689,6.43512966 C20.0909481,5.85495678 19.2608144,5.85495678 18.9737798,6.43512966 L15.354291,13.7433217 C15.2402783,13.973872 15.0194824,14.1334463 14.7645902,14.1704434 L6.6713477,15.3422778 C6.0288904,15.4353547 5.77243505,16.2220298 6.23766949,16.6736864 L12.0935466,22.3623793 C12.2782922,22.5414258 12.3626049,22.800016 12.3189343,23.0530566 L10.9362247,31.0854182 C10.8263152,31.7231313 11.4983748,32.2097404 12.0730302,31.9086035 L16.1411449,29.777084 L17.5961504,29.0148465 L19.3115192,28.1160118 C19.5393493,27.9965501 19.8119247,27.9965501 20.0402432,28.1160118 L21.7557097,29.0147491 L23.2111061,29.7771814 Z",
                                    fill: "#FFFFFF",
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.commentType === "question"
                ? _c(
                    "svg",
                    {
                      attrs: {
                        width: "41px",
                        height: "40px",
                        viewBox: "0 0 41 40",
                        xmlns: "http://www.w3.org/2000/svg",
                        "aria-labelledby":
                          "navQuestionOnTitle navQuestionOnDesc",
                      },
                    },
                    [
                      _c("title", { attrs: { id: "navQuestionOnTitle" } }, [
                        _vm._v("Question Mark Selected"),
                      ]),
                      _c("desc", { attrs: { id: "navQuestionOnDesc" } }, [
                        _vm._v("A Question Mark"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "Symbols",
                            stroke: "none",
                            "stroke-width": "1",
                            fill: "none",
                            "fill-rule": "evenodd",
                          },
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "comment-icons-ON",
                                transform: "translate(-48.000000, 0.000000)",
                              },
                            },
                            [
                              _c("g", { attrs: { id: "Q-SELECTED-Copy" } }, [
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      transform:
                                        "translate(48.000000, 0.000000)",
                                    },
                                  },
                                  [
                                    _c("circle", {
                                      attrs: {
                                        id: "question-Oval-5",
                                        fill: "#017362",
                                        cx: "20.5",
                                        cy: "20",
                                        r: "20",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        id: "question",
                                        d: "M21.6934245,20.567581 C21.6614006,20.567581 21.5813408,20.6213826 21.5813408,20.6687919 L21.5813408,21.6329584 C21.5370411,22.3057442 20.9547396,22.8144619 20.2811701,22.7697161 C19.6695134,22.7297644 19.1827499,22.2434196 19.1421863,21.6329584 L19.1421863,20.6741187 C19.1544621,19.5757147 19.8675279,18.6067539 20.914176,18.2668985 C21.6293767,18.0266559 22.1027968,17.3469452 22.0777115,16.5937234 C22.0296756,15.6743027 21.2760462,14.9498461 20.3537576,14.9375942 C19.7010035,14.941323 19.1074937,15.3184666 18.8272845,15.9065549 C18.5385356,16.5159508 17.8099916,16.7759029 17.1994024,16.4871856 C16.5893469,16.199001 16.3288857,15.471881 16.6176346,14.8630178 C16.6213708,14.8555602 16.6245732,14.8486352 16.6283093,14.8411776 L16.6336466,14.8683447 C17.3205595,13.4316833 18.7749788,12.5191875 20.3697695,12.5239817 C22.5991675,12.5394297 24.4255979,14.296237 24.5222033,16.5191469 C24.5819813,18.3452038 23.4307217,19.9928099 21.6934245,20.567581 M20.3751069,26.4543237 L20.3537576,26.4543237 C19.6759181,26.4543237 19.1261743,25.9056544 19.1261743,25.2286071 C19.1261743,24.5520924 19.6759181,24.0034231 20.3537576,24.0034231 C21.031597,24.0034231 21.5813408,24.5520924 21.5813408,25.2286071 C21.5813408,25.8971314 21.0449403,26.4420719 20.3751069,26.4543237 M20.502135,7 C13.5983136,6.99893462 8.00106768,12.5836429 8.00000022,19.4734384 C7.99946649,22.3259864 8.9783307,25.0922388 10.7732709,27.3114199 C10.4071309,28.5467249 9.80935118,29.7021267 9.0119558,30.7158333 C8.74295495,31.0658098 8.81020516,31.5670698 9.16140072,31.8355449 C9.31084564,31.950073 9.49605059,32.0081361 9.68445793,31.9990804 C11.5824084,31.9591287 13.4536722,31.5409681 15.1872333,30.7685695 C16.8514092,31.5505565 18.6687661,31.9543345 20.5085398,31.9511384 C27.407024,31.9463442 32.9989325,26.3642994 33,19.4776999 C33.0010675,12.5879044 27.4054228,7.00106538 20.502135,7",
                                        fill: "#FFFFFF",
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.commentType === "intrigued"
                ? _c(
                    "svg",
                    {
                      attrs: {
                        width: "41px",
                        height: "40px",
                        viewBox: "0 0 41 40",
                        xmlns: "http://www.w3.org/2000/svg",
                        "aria-labelledby": "navBulbOnTitle navBulbOnDesc",
                      },
                    },
                    [
                      _c("title", { attrs: { id: "navBulbOnTitle" } }, [
                        _vm._v("Light Bulb Selected"),
                      ]),
                      _c("desc", { attrs: { id: "navBulbOnDesc" } }, [
                        _vm._v("A Light Bulb"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "Symbols",
                            stroke: "none",
                            "stroke-width": "1",
                            fill: "none",
                            "fill-rule": "evenodd",
                          },
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "comment-icons-ON",
                                transform: "translate(-96.000000, 0.000000)",
                              },
                            },
                            [
                              _c(
                                "g",
                                { attrs: { id: "lighbulb-SELECTED-Copy-2" } },
                                [
                                  _c(
                                    "g",
                                    {
                                      attrs: {
                                        transform:
                                          "translate(96.000000, 0.000000)",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          id: "bulb-Oval-5",
                                          d: "M20.5,40 C31.545695,40 40.5,31.045695 40.5,20 C40.5,8.954305 31.545695,0 20.5,0 C9.454305,0 0.5,8.954305 0.5,20 C0.5,31.045695 9.454305,40 20.5,40 Z",
                                          fill: "#FF3333",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          id: "bulb",
                                          d: "M27.0911502,22.7444984 L27.0875716,22.7444984 C27.0947289,22.7369461 27.1042719,22.7322985 27.1114292,22.7247461 C27.113815,22.7235842 27.1150079,22.7212604 27.1167972,22.7200985 C28.7182413,21.1590872 29.7083333,19.0049265 29.7083333,16.6241954 C29.7083333,11.8615714 25.74379,8 20.8541667,8 C15.9639469,8 12,11.8615714 12,16.6241954 C12,19.0182883 13.0032138,21.1817442 14.6207618,22.7444984 L14.6177796,22.7444984 C14.6177796,22.7444984 15.6478332,24.3699951 15.9752793,25.2420003 C16.4112776,26.4027374 16.5585986,27.5448842 16.4762897,28.5295936 L16.5120762,28.6417168 L16.5120762,30.307299 C16.5120762,30.860363 16.9725286,31.308275 17.539744,31.308275 L17.9023802,31.308275 C17.9023802,32.3226129 19.2908948,33 20.5058451,33 C21.7225848,33 23.1099065,32.3226129 23.1099065,31.308275 L23.4725427,31.308275 C24.0403545,31.308275 24.4984212,30.860363 24.4984212,30.307299 L24.4984212,28.6417168 L24.5067714,28.6237074 C24.5043856,27.7557688 24.6695998,26.7925546 25.0352181,25.8188832 C25.5242997,24.5175563 26.2710438,23.451514 27.0911502,22.7444984",
                                          fill: "#FFFFFF",
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.isDeleting
        ? _c("div", { staticClass: "comment-thread__delete-confirmation" }, [
            _c("div", [
              _vm._v("Are you sure you want to delete this comment?"),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "a",
                {
                  staticClass: "confirm",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.deleteComment($event)
                    },
                  },
                },
                [_vm._v("Yes")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.toggleDelete($event)
                    },
                  },
                },
                [_vm._v("No")]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "comment-thread__body" },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.commentSaved
              ? _c(
                  "div",
                  { staticClass: "comment-thread__saved-notice animated" },
                  [
                    _c("i", { staticClass: "fa fa-check" }),
                    _vm._v(" Updated!\n      "),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          !_vm.isEditing
            ? _c("div", { staticClass: "comment-thread__content" }, [
                _c(
                  "a",
                  {
                    staticClass: "comment-thread__user-link",
                    attrs: {
                      href: _vm.comment.user.links.profile,
                      target: "_blank",
                    },
                  },
                  [_vm._v(_vm._s(_vm.comment.user.username))]
                ),
                _vm._v(" "),
                _vm.isCreator(_vm.comment.user)
                  ? _c(
                      "span",
                      { staticClass: "comment-thread__creator-label" },
                      [_vm._v("Creator")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("span", {
                  staticClass: "comment-thread__text",
                  domProps: { innerHTML: _vm._s(_vm.currentComment) },
                }),
                _vm._v(" "),
                _vm.comment.manageable && _vm.currentUser.isInActiveGroup
                  ? _c(
                      "a",
                      {
                        staticClass: "comment-thread__manage-action",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.toggleEdit($event)
                          },
                        },
                      },
                      [_vm._v("\n        Edit\n      ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.comment.manageable && !_vm.isDeleting
                  ? _c(
                      "a",
                      {
                        staticClass: "comment-thread__manage-action",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.toggleDelete($event)
                          },
                        },
                      },
                      [_vm._v("\n        Delete\n      ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { staticClass: "comment-thread__time" }, [
                  _vm._v(_vm._s(_vm._f("timeago")(_vm.comment.createdAt))),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isEditing
            ? _c(
                "div",
                { staticClass: "comment-thread__content" },
                [
                  _c(
                    "comment-form",
                    {
                      attrs: {
                        "on-submit": _vm.updateComment,
                        comment: _vm.comment,
                        owner: _vm.currentUser,
                        "with-actions": false,
                      },
                    },
                    [_vm._v('\n        :replaceHolder="placeholder"\n      ')]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.repliesEnabled
            ? _c("div", { staticClass: "comment-thread__actions" }, [
                _c("span", [
                  !_vm.moreRepliesAvailable
                    ? _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.toggleReplies($event)
                            },
                          },
                        },
                        [
                          !_vm.comment.replyCount &&
                          !_vm.repliesVisible &&
                          _vm.currentUser.isInActiveGroup
                            ? _c("span", [_vm._v("Reply")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.comment.replyCount && !_vm.repliesVisible
                            ? _c("span", [
                                _vm._v(
                                  "View " +
                                    _vm._s(_vm.comment.replyCount) +
                                    " " +
                                    _vm._s(
                                      _vm.comment.replyCount > 1
                                        ? "replies"
                                        : "reply"
                                    )
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.repliesVisible
                            ? _c("span", [_vm._v("Hide Replies")])
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.moreRepliesAvailable
                    ? _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.loadNextPage($event)
                            },
                          },
                        },
                        [_c("span", [_vm._v("Load More")])]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _vm.replies && _vm.comment.replyCount && _vm.repliesVisible
                  ? _c("span", [
                      _vm._v(
                        _vm._s(_vm.replies.data.length) +
                          " of " +
                          _vm._s(_vm.comment.replyCount)
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.repliesVisible && _vm.repliesEnabled
            ? _c(
                "div",
                { staticClass: "comment-thread__replies" },
                [
                  _vm._l(_vm.replies.data, function (reply) {
                    return _c("CommentThread", {
                      key: reply.id,
                      attrs: {
                        "on-delete": _vm.onReplyDeleted,
                        "cursor-id": _vm.cursorId,
                        comment: reply,
                        creators: _vm.creators,
                        "replies-enabled": false,
                        "current-user": _vm.currentUser,
                      },
                    })
                  }),
                  _vm._v(" "),
                  _vm.hasPreviousReplies
                    ? _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.loadPrevious($event)
                            },
                          },
                        },
                        [_c("span", [_vm._v("Load More")])]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.currentUser.isInActiveGroup
                    ? _c(
                        "div",
                        { staticClass: "comment-thread__reply-form" },
                        [
                          _vm.currentUser
                            ? _c(
                                "div",
                                { staticClass: "comment-thread__user-avatar" },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(_vm.currentUser.avatar),
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("comment-form", {
                            attrs: {
                              "on-submit": _vm.addReply,
                              "replace-holder": "Add a reply...",
                              owner: _vm.currentUser,
                              "with-actions": false,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }