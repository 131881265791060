// noinspection JSUnusedLocalSymbols
// eslint-disable-next-line no-unused-vars
const learner = 'learner';
const creator = 'creator';
const facilitator = 'facilitator';

const managers = [creator, facilitator];

// eslint-disable-next-line import/prefer-default-export
export const groupRolesHelper = (role) => ({
  can() {
    return managers.includes(role);
  },
  canOther(otherRole) {
    return managers.includes(otherRole);
  },
});
