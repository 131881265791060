export const SET_ACTIVE_GROUP = 'SET_ACTIVE_GROUP';

// initial state
const state = {
  active: {},
};

// getters
const getters = {
  active: (state) => state.active,
};

// actions
const actions = {
  setActiveGroup: ({ commit }, id) => new Promise((resolve, reject) => {
    axios.get(`/api/groups/${id}/set`).then((response) => {
      commit(SET_ACTIVE_GROUP, response.data);
      resolve(response);
    }).catch((error) => {
      // console.error(`error: ${error}`);
      reject(error);
    });
  }),
  setGroupByResponse: ({ commit }, group) => {
    commit(SET_ACTIVE_GROUP, group);
  },
  clearGroup: ({ commit }) => {
    commit(SET_ACTIVE_GROUP, {});
  },
};

// mutations
const mutations = {

  SET_ACTIVE_GROUP: (state, payload) => {
    state.active = payload;
  },
};

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
