<template>
  <span
    class="sort"
    :class="{
      'sort__active': field === sortBy,
      'sort__descend': field === sortBy && sortDir === 'desc'
    }"
    @click="handleSort"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: 'GroupWorkTableSort',
  props: {
    sortDir: {
      type: String,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    modifySort: {
      type: Function,
      required: true,
    },
  },
  methods: {
    handleSort() {
      let sortDir = null;
      if (this.sortBy === this.field) {
        sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
      } else {
        sortDir = 'desc';
      }
      this.modifySort(this.field, sortDir);
    },
  },
};
</script>

<style scoped>

</style>
