<template>
  <main>
    <section class="group-tab primary-content">
      <group-header :group="group" />

      <div class="header__intro">
        <div class="header__intro__title">
          Group Work
        </div>
        <div class="header__intro__summary">
          This is all of the work submitted by members of your group. <a
            href="https://tools.mouse.org/group-work"
            target="_blank"
          >Learn more ></a>
        </div>
      </div>

      <slot />
    </section>
  </main>
</template>

<script>
import GroupHeader from '../GroupHeader.vue';

export default {
  name: 'GroupWorkLayout',
  components: { GroupHeader },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
