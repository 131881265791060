// messages methods use in various files
export default {
  methods: {
    callNotification(message, type) {
      this.$store.dispatch('loadMessage', {
        content: message,
        type,
        icon: '',
        autoclose: true,
      });
    },
  },
};
