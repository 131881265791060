<template>
  <nav
    v-if="isLoggedIn && isRegistered"
    :class="navCls"
    class="menu-actions"
  >
    <ul role="menu">
      <template v-if="hasDefaultGroup && ! navMinified && currentUser && currentUser.hasAnyLicensedSites">
        <li class="primary group">
          <router-link
            :to="'/group/' + currentUser.defaultGroupId"
            aria-label="Group"
          >
            <NavGroupIcon />
          </router-link>
        </li>

        <li class="primary projects">
          <router-link
            aria-label="projects"
            to="/projects"
          >
            <PaperAirplaneIcon />
          </router-link>
        </li>
      </template>

      <li
        v-if="isRegistered"
        class="primary profile"
      >
        <ProfileImage />
      </li>

      <li
        v-if="currentUser.hasAnyLicensedSites && hasDefaultGroup && ! navMinified"
        id="notification-menu"
        class="primary menu-notifications"
      >
        <router-link
          aria-label="Inbox"
          to="/notifications"
        >
          <NavNotificationIcon />
          <span
            v-if="notificationCount"
            class="notification-count"
          >
            {{ notificationCount }}
          </span>
        </router-link>
      </li>

      <li class="primary main-dropdown no-group">
        <div class="dropdown">
          <div class="dropdown-container">
            <div
              id="menu-dropdown"
              class="dropdown-button"
              tabindex="0"
              @click="toggleMenu"
            >
              <MenuIcon />
            </div>
            <ul
              v-show="isToggled"
              class="dropdown-menu dropdown-select"
            >
              <template v-if="canSeeMenu">
                <li>
                  <a
                    href="https://tools.mouse.org"
                    tabindex="0"
                    target="_blank"
                  >Help</a>
                </li>
                <li>
                  <a
                    href="https://mouse.org/news/wire"
                    target="_blank"
                  >The Wire</a>
                </li>
                <li>
                  <router-link to="/group/switch">
                    My Groups
                  </router-link>
                </li>
              </template>
              <li>
                <a
                  href="#"
                  @click.stop.prevent="logoutUser"
                >Logout</a>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </nav>
</template>

<script>
import _ from 'lodash';
import NavGroupIcon from '../icons/NavGroupIcon.vue';
import PaperAirplaneIcon from '../icons/PaperAirplaneIcon.vue';
import ProfileImage from '../profile/ProfileImage.vue';
import NavNotificationIcon from '../icons/NavNotificationIcon.vue';
import MenuIcon from '../icons/MenuIcon.vue';

export default {
  name: 'AppNav',
  components: {
    MenuIcon, NavNotificationIcon, ProfileImage, PaperAirplaneIcon, NavGroupIcon,
  },
  props: {
    navMinified: {
      type: Boolean,
      required: true,
    },
    notificationCount: {
      type: Number,
      required: true,
    },
    toggleMenu: {
      type: Function,
      required: true,
    },
    isToggled: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    hasDefaultGroup() {
      return !!(this.currentUser && this.currentUser.defaultGroupId);
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    isRegistered() {
      return this.currentUser && !_.isEmpty(this.currentUser.profile);
    },
    currentUser() {
      return this.$store.state.users.current ? this.$store.state.users.current : null;
    },
    navCls() {
      return { 'no-license': this.currentUser && !this.currentUser.hasAnyLicensedSites };
    },
    canSeeMenu() {
      return !this.currentUser.isNewUser;
    },
  },
  methods: {
    logoutUser() {
      this.$store.dispatch('logoutUser');
    },
  },
};
</script>
