export default {

  custom: {
    content_text: {
      required: 'Please add some text to add into evidence',
      min: 'Must have at least 10 characters',
    },
    content_image: {
      required: 'Please select a file to add to your work',
      image: 'Must be one of the following formats: jpeg, jpg, bmp, png, gif, heic',
      ext: 'The file you have added is missing an extension (jpeg,jpg,bmp,png,gif,heic). Please correct with the proper extension and upload again',
    },
    content_file: {
      required: 'Please select a file to add to your work',
      ext: 'Must be one of the following formats: pdf, docx, xlsx, doc, ppt, xls, zip, txt, sb3, webm',
    },
  },

};
