var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "comment-form__actions" },
    _vm._l(_vm.commentTypes, function (type) {
      return _c(
        "li",
        {
          key: type.id,
          staticClass: "comment-form__action",
          class: _vm.getTypeClass(type),
          attrs: { tabindex: "0", transition: "bounce" },
          on: {
            click: function ($event) {
              return _vm.onSelect(type)
            },
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.onSelect(type)
            },
          },
        },
        [
          _vm.getType(type) === "love" && !_vm.isTypeActive(type)
            ? _c(
                "svg",
                {
                  attrs: {
                    width: "33px",
                    height: "31px",
                    viewBox: "0 0 33 31",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Symbols",
                        stroke: "none",
                        "stroke-width": "1",
                        fill: "none",
                        "fill-rule": "evenodd",
                      },
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "comment-icons-OFF",
                            transform: "translate(-2.000000, -1.000000)",
                            fill: "#A2DBCF",
                          },
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "Fill-1",
                                transform: "translate(2.000000, 1.000000)",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M20.5209342,28.3497163 L25.3709127,30.8904469 C26.0560787,31.2494947 26.8566816,30.6693068 26.7256357,29.909537 L25.0777194,20.3319102 C25.025068,20.0300918 25.1261772,19.7224692 25.3457518,19.5084103 L32.3283416,12.725738 C32.8830442,12.1872243 32.5772706,11.2491494 31.8113803,11.1382892 L22.1617449,9.74110215 C21.8577185,9.69757065 21.5944619,9.50672854 21.4586402,9.23242204 L17.1436783,0.518808436 C16.8007458,-0.172936145 15.810971,-0.172936145 15.4687374,0.518808436 L11.1531931,9.23242204 C11.0172549,9.50730896 10.7539983,9.69757065 10.4500883,9.74168257 L0.800453032,11.1388696 C0.0344462505,11.249846 -0.271327442,12.1878047 0.283375157,12.7263184 L7.26538253,19.5089907 C7.48565607,19.7224692 7.58618282,20.0307883 7.53411392,20.3324906 L5.88549866,29.909537 C5.75445279,30.6698873 6.55575459,31.2500751 7.24092064,30.8910273 L12.091365,28.3496002 L13.8261794,27.4407785 L15.8714268,26.369091 C16.1430703,26.2266559 16.4680641,26.2266559 16.74029,26.369091 L18.7856539,27.4406624 L20.5209342,28.3497163 Z",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.getType(type) === "love" && _vm.isTypeActive(type)
            ? _c(
                "svg",
                {
                  attrs: {
                    width: "40px",
                    height: "40px",
                    viewBox: "0 0 40 40",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Symbols",
                        stroke: "none",
                        "stroke-width": "1",
                        fill: "none",
                        "fill-rule": "evenodd",
                      },
                    },
                    [
                      _c("g", { attrs: { id: "comment-icons-ON" } }, [
                        _c("g", { attrs: { id: "Star--SELECTED-Copy" } }, [
                          _c("g", [
                            _c("circle", {
                              attrs: {
                                id: "Oval-5",
                                fill: "#2C328C",
                                cx: "20",
                                cy: "20",
                                r: "20",
                              },
                            }),
                            _vm._v(" "),
                            _c("path", {
                              attrs: {
                                id: "Fill-1",
                                d: "M23.2111061,29.7771814 L27.27883,31.9081167 C27.8534854,32.2092536 28.5249588,31.7226445 28.4150493,31.0854182 L27.0329259,23.0525698 C26.9887667,22.7994318 27.073568,22.5414258 27.2577274,22.3618925 L33.114093,16.6731996 C33.5793274,16.2215429 33.3228721,15.4347705 32.6805125,15.341791 L24.58727,14.1699566 C24.3322801,14.1334463 24.1114842,13.9733852 23.9975692,13.7433217 L20.3785689,6.43512966 C20.0909481,5.85495678 19.2608144,5.85495678 18.9737798,6.43512966 L15.354291,13.7433217 C15.2402783,13.973872 15.0194824,14.1334463 14.7645902,14.1704434 L6.6713477,15.3422778 C6.0288904,15.4353547 5.77243505,16.2220298 6.23766949,16.6736864 L12.0935466,22.3623793 C12.2782922,22.5414258 12.3626049,22.800016 12.3189343,23.0530566 L10.9362247,31.0854182 C10.8263152,31.7231313 11.4983748,32.2097404 12.0730302,31.9086035 L16.1411449,29.777084 L17.5961504,29.0148465 L19.3115192,28.1160118 C19.5393493,27.9965501 19.8119247,27.9965501 20.0402432,28.1160118 L21.7557097,29.0147491 L23.2111061,29.7771814 Z",
                                fill: "#FFFFFF",
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.getType(type) === "question" && !_vm.isTypeActive(type)
            ? _c(
                "svg",
                {
                  attrs: {
                    width: "30px",
                    height: "30px",
                    viewBox: "0 0 30 30",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Symbols",
                        stroke: "none",
                        "stroke-width": "1",
                        fill: "none",
                        "fill-rule": "evenodd",
                      },
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "comment-icons-OFF",
                            transform: "translate(-49.000000, -1.000000)",
                            fill: "#A2DBCF",
                          },
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "question",
                                transform: "translate(2.000000, 1.000000)",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M63.4144291,16.2652812 C63.3764748,16.2652812 63.2815891,16.329046 63.2815891,16.3852348 L63.2815891,17.5279507 C63.2290857,18.3253265 62.5389507,18.9282512 61.740646,18.875219 C61.0157195,18.8278689 60.4388147,18.2514603 60.3907393,17.5279507 L60.3907393,16.3915481 C60.4052884,15.0897359 61.2504034,13.941338 62.4908753,13.5385464 C63.3385205,13.2538144 63.8996111,12.4482313 63.8698802,11.555524 C63.8129488,10.4658402 62.9197585,9.60722495 61.8266757,9.59270425 C61.0530412,9.59712359 60.3496222,10.0441086 60.0175224,10.7411022 C59.6753014,11.4633491 58.8118419,11.7714405 58.0881806,11.429257 C57.3651518,11.0877049 57.0564571,10.225933 57.3986781,9.50431739 C57.4031061,9.4954787 57.4069015,9.48727135 57.4113295,9.47843266 L57.4176552,9.51063073 C58.2317742,7.80792092 59.9555304,6.7264445 61.8456528,6.73212651 C64.4879022,6.75043522 66.6525604,8.83257721 66.7670558,11.4671371 C66.8379037,13.6313526 65.4734479,15.584071 63.4144291,16.2652812 M61.8519785,23.2421615 L61.8266757,23.2421615 C61.0233104,23.2421615 60.3717622,22.5918867 60.3717622,21.7894602 C60.3717622,20.9876651 61.0233104,20.3373903 61.8266757,20.3373903 C62.6300409,20.3373903 63.2815891,20.9876651 63.2815891,21.7894602 C63.2815891,22.5817853 62.6458552,23.2276408 61.8519785,23.2421615 M62.0025304,0.185185185 C53.8202235,0.183922516 47.1864506,6.802836 47.1851854,14.9685196 C47.1845529,18.3493172 48.3446882,21.6278385 50.4720248,24.2579791 C50.038081,25.7220444 49.3296014,27.0914094 48.3845402,28.2928395 C48.0657244,28.7076264 48.1454283,29.3017124 48.5616601,29.6199051 C48.73878,29.7556421 48.9582822,29.8244576 49.1815798,29.8137249 C51.4310025,29.7663748 53.6487967,29.270777 55.7033876,28.3553416 C57.6757442,29.282141 59.8296488,29.7606927 62.0101213,29.7569047 C70.1861025,29.7512227 76.8135497,23.1354659 76.8148148,14.9735703 C76.81608,6.80788668 70.1842048,0.186447855 62.0025304,0.185185185",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.getType(type) === "question" && _vm.isTypeActive(type)
            ? _c(
                "svg",
                {
                  attrs: {
                    width: "41px",
                    height: "40px",
                    viewBox: "0 0 41 40",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Symbols",
                        stroke: "none",
                        "stroke-width": "1",
                        fill: "none",
                        "fill-rule": "evenodd",
                      },
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "comment-icons-ON",
                            transform: "translate(-48.000000, 0.000000)",
                          },
                        },
                        [
                          _c("g", { attrs: { id: "Q-SELECTED-Copy" } }, [
                            _c(
                              "g",
                              {
                                attrs: {
                                  transform: "translate(48.000000, 0.000000)",
                                },
                              },
                              [
                                _c("circle", {
                                  attrs: {
                                    id: "Oval-5",
                                    fill: "#017362",
                                    cx: "20.5",
                                    cy: "20",
                                    r: "20",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    id: "question",
                                    d: "M21.6934245,20.567581 C21.6614006,20.567581 21.5813408,20.6213826 21.5813408,20.6687919 L21.5813408,21.6329584 C21.5370411,22.3057442 20.9547396,22.8144619 20.2811701,22.7697161 C19.6695134,22.7297644 19.1827499,22.2434196 19.1421863,21.6329584 L19.1421863,20.6741187 C19.1544621,19.5757147 19.8675279,18.6067539 20.914176,18.2668985 C21.6293767,18.0266559 22.1027968,17.3469452 22.0777115,16.5937234 C22.0296756,15.6743027 21.2760462,14.9498461 20.3537576,14.9375942 C19.7010035,14.941323 19.1074937,15.3184666 18.8272845,15.9065549 C18.5385356,16.5159508 17.8099916,16.7759029 17.1994024,16.4871856 C16.5893469,16.199001 16.3288857,15.471881 16.6176346,14.8630178 C16.6213708,14.8555602 16.6245732,14.8486352 16.6283093,14.8411776 L16.6336466,14.8683447 C17.3205595,13.4316833 18.7749788,12.5191875 20.3697695,12.5239817 C22.5991675,12.5394297 24.4255979,14.296237 24.5222033,16.5191469 C24.5819813,18.3452038 23.4307217,19.9928099 21.6934245,20.567581 M20.3751069,26.4543237 L20.3537576,26.4543237 C19.6759181,26.4543237 19.1261743,25.9056544 19.1261743,25.2286071 C19.1261743,24.5520924 19.6759181,24.0034231 20.3537576,24.0034231 C21.031597,24.0034231 21.5813408,24.5520924 21.5813408,25.2286071 C21.5813408,25.8971314 21.0449403,26.4420719 20.3751069,26.4543237 M20.502135,7 C13.5983136,6.99893462 8.00106768,12.5836429 8.00000022,19.4734384 C7.99946649,22.3259864 8.9783307,25.0922388 10.7732709,27.3114199 C10.4071309,28.5467249 9.80935118,29.7021267 9.0119558,30.7158333 C8.74295495,31.0658098 8.81020516,31.5670698 9.16140072,31.8355449 C9.31084564,31.950073 9.49605059,32.0081361 9.68445793,31.9990804 C11.5824084,31.9591287 13.4536722,31.5409681 15.1872333,30.7685695 C16.8514092,31.5505565 18.6687661,31.9543345 20.5085398,31.9511384 C27.407024,31.9463442 32.9989325,26.3642994 33,19.4776999 C33.0010675,12.5879044 27.4054228,7.00106538 20.502135,7",
                                    fill: "#FFFFFF",
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.getType(type) === "intrigued" && !_vm.isTypeActive(type)
            ? _c(
                "svg",
                {
                  attrs: {
                    width: "22px",
                    height: "30px",
                    viewBox: "0 0 22 30",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Symbols",
                        stroke: "none",
                        "stroke-width": "1",
                        fill: "none",
                        "fill-rule": "evenodd",
                      },
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "comment-icons-OFF",
                            transform: "translate(-97.000000, -1.000000)",
                            fill: "#A2DBCF",
                          },
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "bulb",
                                transform: "translate(2.000000, 1.000000)",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  d: "M113.219141,17.6601463 L113.2149,17.6601463 C113.223382,17.6511953 113.234693,17.6456871 113.243175,17.6367361 C113.246003,17.6353591 113.247417,17.632605 113.249537,17.6312279 C115.147545,15.7811404 116.320988,13.228061 116.320988,10.4064538 C116.320988,4.76186234 111.62227,0.185185185 105.82716,0.185185185 C100.031344,0.185185185 95.3333333,4.76186234 95.3333333,10.4064538 C95.3333333,13.2438972 96.5223274,15.8079932 98.4394213,17.6601463 L98.4358869,17.6601463 C98.4358869,17.6601463 99.6566912,19.5866609 100.044775,20.6201485 C100.561514,21.995837 100.736117,23.3494923 100.638566,24.5165553 L100.680979,24.6494422 L100.680979,26.6234655 C100.680979,27.2789487 101.226701,27.8098075 101.898956,27.8098075 L102.328747,27.8098075 C102.328747,29.0119856 103.974394,29.8148148 105.414335,29.8148148 C106.856397,29.8148148 108.50063,29.0119856 108.50063,27.8098075 L108.930421,27.8098075 C109.603383,27.8098075 110.146277,27.2789487 110.146277,26.6234655 L110.146277,24.6494422 L110.156173,24.6280976 C110.153346,23.5994297 110.349155,22.4578424 110.782481,21.3038615 C111.362133,19.7615482 112.247163,18.4980906 113.219141,17.6601463",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.getType(type) === "intrigued" && _vm.isTypeActive(type)
            ? _c(
                "svg",
                {
                  attrs: {
                    width: "41px",
                    height: "40px",
                    viewBox: "0 0 41 40",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Symbols",
                        stroke: "none",
                        "stroke-width": "1",
                        fill: "none",
                        "fill-rule": "evenodd",
                      },
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "comment-icons-ON",
                            transform: "translate(-96.000000, 0.000000)",
                          },
                        },
                        [
                          _c(
                            "g",
                            { attrs: { id: "lighbulb-SELECTED-Copy-2" } },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    transform: "translate(96.000000, 0.000000)",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      id: "Oval-5",
                                      d: "M20.5,40 C31.545695,40 40.5,31.045695 40.5,20 C40.5,8.954305 31.545695,0 20.5,0 C9.454305,0 0.5,8.954305 0.5,20 C0.5,31.045695 9.454305,40 20.5,40 Z",
                                      fill: "#FF3333",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    attrs: {
                                      id: "bulb",
                                      d: "M27.0911502,22.7444984 L27.0875716,22.7444984 C27.0947289,22.7369461 27.1042719,22.7322985 27.1114292,22.7247461 C27.113815,22.7235842 27.1150079,22.7212604 27.1167972,22.7200985 C28.7182413,21.1590872 29.7083333,19.0049265 29.7083333,16.6241954 C29.7083333,11.8615714 25.74379,8 20.8541667,8 C15.9639469,8 12,11.8615714 12,16.6241954 C12,19.0182883 13.0032138,21.1817442 14.6207618,22.7444984 L14.6177796,22.7444984 C14.6177796,22.7444984 15.6478332,24.3699951 15.9752793,25.2420003 C16.4112776,26.4027374 16.5585986,27.5448842 16.4762897,28.5295936 L16.5120762,28.6417168 L16.5120762,30.307299 C16.5120762,30.860363 16.9725286,31.308275 17.539744,31.308275 L17.9023802,31.308275 C17.9023802,32.3226129 19.2908948,33 20.5058451,33 C21.7225848,33 23.1099065,32.3226129 23.1099065,31.308275 L23.4725427,31.308275 C24.0403545,31.308275 24.4984212,30.860363 24.4984212,30.307299 L24.4984212,28.6417168 L24.5067714,28.6237074 C24.5043856,27.7557688 24.6695998,26.7925546 25.0352181,25.8188832 C25.5242997,24.5175563 26.2710438,23.451514 27.0911502,22.7444984",
                                      fill: "#FFFFFF",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }