var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal modal__global" }, [
    _c("input", {
      staticClass: "modal-state",
      attrs: { id: "verify-collaborators", type: "checkbox" },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "modal-fade-screen" }, [
      _c("div", { staticClass: "modal-inner" }, [
        _c("div", { staticClass: "modal__header" }, [
          _c(
            "button",
            {
              staticClass: "modal__close",
              attrs: {
                id: "close-button",
                for: "verify-collaborators",
                "aria-label": "Close this dialog window",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.closeCollaboratorModal($event)
                },
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "21px",
                    height: "21px",
                    viewBox: "0 0 21 21",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("title", [_vm._v("Close X")]),
                  _vm._v(" "),
                  _c("defs"),
                  _vm._v(" "),
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Designs",
                        stroke: "none",
                        "stroke-width": "1",
                        fill: "none",
                        "fill-rule": "evenodd",
                        "stroke-linecap": "square",
                      },
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "Work-Tab----Edit-Collaborators-Modal-",
                            transform: "translate(-1032.000000, -61.000000)",
                            stroke: "#FFFFFF",
                            "stroke-width": "4",
                          },
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "Edit-Collaborators-Modal-Copy",
                                transform: "translate(210.000000, 49.000000)",
                              },
                            },
                            [
                              _c("g", { attrs: { id: "Green-top-area" } }, [
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      id: "close-X",
                                      transform:
                                        "translate(825.000000, 15.000000)",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        id: "Line",
                                        d: "M0.5,0.50472973 L14.6421356,14.7806423",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("path", {
                                      attrs: {
                                        id: "Line",
                                        d: "M0.5,0.50472973 L14.6421356,14.7806423",
                                        transform:
                                          "translate(7.500000, 7.570946) scale(-1, 1) translate(-7.500000, -7.570946) ",
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("h2", [_vm._v("Who is working on this?")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal__content" }, [
          _c("div", { staticClass: "modal__instructions" }, [
            _vm._v(
              "\n          You are active in " +
                _vm._s(_vm.groupName) +
                ". Select the group members who are collaborating with you on this work, or just click close if you are working alone.\n        "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal__all-collaborators" },
            [
              _vm.currentUser
                ? _c(
                    "span",
                    {
                      staticClass: "modal-collaborator active",
                      attrs: { href: "#" },
                    },
                    [
                      _c("div", {
                        staticClass: "modal-collaborator__pic",
                        domProps: { innerHTML: _vm._s(_vm.currentUser.avatar) },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "modal-collaborator__name" }, [
                        _vm._v(
                          "\n              " + _vm._s(_vm.currentUser.username)
                        ),
                        _c("br"),
                        _vm._v("(You!)\n            "),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.allCollaborators, function (user) {
                return _c(
                  "a",
                  {
                    key: user.id,
                    staticClass: "modal-collaborator",
                    class: { active: _vm.collaborators.indexOf(user.id) > -1 },
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.selectUser(user.id)
                      },
                    },
                  },
                  [
                    _c("div", {
                      staticClass: "modal-collaborator__pic",
                      domProps: { innerHTML: _vm._s(user.avatar) },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "modal-collaborator__name" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(user.username) +
                          "\n              "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      user.shortName && user.shortName.length > 1
                        ? _c("span", [
                            _vm._v("(" + _vm._s(user.shortName) + ".)"),
                          ])
                        : _vm._e(),
                    ]),
                  ]
                )
              }),
              _vm._v(" "),
              _c("div", { staticClass: "clearifx" }),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm.availableCollaborators.length > _vm.limit && _vm.isViewMore
                ? _c("div", { staticClass: "modal-collaborator__pagination" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.addMoreCollaborators($event)
                          },
                        },
                      },
                      [
                        _vm._v("View all Group Members "),
                        _c("span", { staticClass: "pagination__sign" }, [
                          _vm._v("+"),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              !_vm.isViewMore
                ? _c("div", { staticClass: "modal-collaborator__pagination" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.removeMoreCollaborators($event)
                          },
                        },
                      },
                      [
                        _vm._v("View Fewer Group Members "),
                        _c("span", { staticClass: "pagination__sign" }, [
                          _vm._v("-"),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "modal__actions" }, [
            _c("span", { staticClass: "action__proceed" }, [
              _c(
                "button",
                {
                  staticClass: "button modal__button",
                  attrs: { type: "button", for: "verify-collaborators" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.closeCollaboratorModal($event)
                    },
                  },
                },
                [_vm._v("Close")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "clearfix" }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }