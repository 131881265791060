var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c(
      "a",
      {
        staticStyle: { color: "green" },
        attrs: { href: "#", title: "Masquerade as user" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.masquerade($event)
          },
        },
      },
      [
        _c("i", {
          staticClass: "fa fa-user-secret",
          attrs: { title: "Masquerade as user" },
        }),
        _vm._v(" "),
        _vm.showText ? _c("span", [_vm._v("Masquerade")]) : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }