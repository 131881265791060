<template>
  <div class="page-wrap">
    <main>
      <section class="primary-content">
        <div class="content-box">
          <h1 class="page-title">
            Mouse Competency Badges
          </h1>

          <div id="badge">
            <div class="badge_details">
              <p>Mouse competency badges are awarded to learners who have demonstrated their grasp of skills and knowledge through project evidence reviewed by local educators.</p>

              <img
                :src="badgeUrl"
                width="125"
                style="float: left"
                :alt="badgeTitle"
              >

              <h2 class="sub-section">
                Badge: {{ badgeTitle }}
              </h2>
              <span v-html="badgeDescription" />
              <hr>
            </div>

            <div
              v-for="evidence in evidences"
              :key="evidence.id"
              class="project-block"
            >
              <h3 class="sub-section">
                Project: {{ evidence.project.title }}
              </h3>
              <p>{{ evidence.project.teaser }}</p>

              <div class="evidence-info">
                <p>Evidence Date: {{ formatDate( evidence.created_at, 'M/D/YYYY' ) }}</p> <!-- |date("m/d/Y") -->

                <ul>
                  <li
                    v-for="asset in evidence.assets"
                    :key="asset.id"
                  >
                    <h3><strong>Work Submitted: {{ formatDate( asset.created_at, 'M/D/YYYY H:m a' ) }}</strong></h3>  <!-- date("m/d/Y H:i a") -->
                    <div v-if="asset.upload">
                      <span
                        v-if="asset.upload.file.type === 'image'"
                        v-html="asset.upload.file.img"
                      />
                      <a
                        v-else
                        :href="asset.upload.url"
                      >Download File</a>
                    </div>
                    <p
                      v-else
                      v-html="asset.content"
                    />
                  </li>
                </ul>
              </div>
            </div>

            <h3 class="sub-section">
              Earned By:
            </h3>
            <ul>
              <li
                v-for="user in users"
                :key="user.id"
              >
                {{ user.name }}
              </li>
            </ul>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>

import moment from 'moment';

export default {
  name: 'BadgeEvidence',
  components: {},
  props: {},
  data: () => ({
    badge: {},
    evidences: [],
    users: [],
  }),
  computed: {
    badgeUrl() {
      return this.badge ? this.badge.image : '';
    },
    badgeTitle() {
      return this.badge ? this.badge.title : '';
    },
    badgeDescription() {
      return this.badge ? this.badge.description : '';
    },
  },
  watch: {},
  mounted() {
    this.getBadgeInfo();

    document.body.className = 'no-js language-markup single-column';
  },
  methods: {
    getBadgeInfo() {
      const code = this.$route.params.id;

      axios.get(`/api/badge-evidence/${code}`)
        .then((response) => {
          this.badge = response.data.badge.badge;
          this.users = response.data.badge.users;
          this.evidences = response.data.evidences;
        });
    },
    formatDate(date, format) {
      return moment(date).format(format);
    },
  },
};
</script>
