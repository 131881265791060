export const SET_USER = 'SET_USER';
export const SET_USER_LOGGED_IN = 'SET_USER_LOGGED_IN';
export const SET_IS_MASQUERADE = 'SET_IS_MASQUERADE';
export const SET_INITIAL_LOAD_FINISHED = 'SET_INITIAL_LOAD_FINISHED';

// initial state
const state = {
  current: {},
  isMasquerading: false,
  isLoggedIn: localStorage.getItem('isLoggedIn') === 'true',
  initialLoadFinished: false,
};

// getters
const getters = {
  current: (state) => state.current,
  isLoggedIn: (state) => state.isLoggedIn,
  initFinished: (state) => state.initialLoadFinished,
};

// actions
const actions = {
  getMe: ({ commit, dispatch }) => axios.get('/api/me').then((response) => {
    localStorage.setItem('isLoggedIn', 'true');
    commit(SET_USER_LOGGED_IN, true);
    commit(SET_USER, response.data);
  }).catch(() => {
    dispatch('logoutUser');
    commit(SET_USER_LOGGED_IN, false);
    commit(SET_USER, null);
  }).finally(() => {
    commit(SET_INITIAL_LOAD_FINISHED, true);
  }),

  updateUser: ({ commit }, user) => {
    commit(SET_USER, user);
  },

  masquerade: ({ commit, dispatch }, user) => {
    commit(SET_IS_MASQUERADE, user);
    dispatch('loginUser', user);
  },

  loginUser: ({ commit }, user) => {
    localStorage.setItem('isLoggedIn', 'true');
    commit(SET_USER_LOGGED_IN, true);
    commit(SET_USER, user);
  },

  logoutUser: ({ dispatch }) => {
    axios.post('/api/logout').then(() => {
      dispatch('logoutClient');
    }).catch(() => {});
  },

  logoutClient: ({ commit }) => {
    localStorage.setItem('isLoggedIn', 'false');
    commit(SET_USER_LOGGED_IN, false);
    commit(SET_USER, {});
    window.location.href = '/';
  },
};

// mutations
const mutations = {

  [SET_USER]: (state, payload) => {
    state.current = payload;
  },

  [SET_USER_LOGGED_IN]: (state, payload) => {
    state.isLoggedIn = payload;
  },

  [SET_IS_MASQUERADE]: (state) => {
    state.isMasquerading = true;
  },
  [SET_INITIAL_LOAD_FINISHED]: (state, payload = true) => {
    state.initialLoadFinished = !!payload;
  },
};

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
