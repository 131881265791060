import popper from 'popper.js';
import jQuery from 'jquery';

window._ = require('lodash');

try {
  window.Popper = popper;
  window.$ = jQuery;
  window.jQuery = jQuery;
} catch (e) {
  // do nothing here
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

// let token = document.head.querySelector('meta[name="csrf-token"]');

// if (token) {
//     window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
// } else {
//     console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
// }

window.axios.defaults.withCredentials = true;

/**
 * Load other vendor librareis
 */
window.matchHeight = require('jquery-match-height');
window.autosize = require('autosize');
window.dragula = require('dragula');

// Moment JS
// ---------------------------------------
require('vue-moment');
window.moment = require('moment-timezone');

// Browser Detection
// ---------------------------------------
window.detection = require('detect-browser');

// fixes bug in moment.js
window.moment.createFromInputFallback = (config) => {
  // unreliable string magic, or
  // eslint-disable-next-line no-param-reassign,no-underscore-dangle
  config._d = new Date(config._i);
};

// Laroute
// ---------------------------------------
window.laroute = require('./laroute');

// Timezone
// ---------------------------------------
window.userTimeZone = window.moment.tz.guess();
