var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-wrap work" },
    [
      _c("div", { staticClass: "main-menu mobile-project-filter" }, [
        _c("div", { staticClass: "mobile-dropdown mobile-filter" }, [
          _c("div", { staticClass: "dropdown" }, [
            _c("div", { staticClass: "dropdown-container" }, [
              _c(
                "div",
                {
                  staticClass: "dropdown-button-delay",
                  attrs: { tabindex: "0" },
                  on: { click: _vm.dropDownToggle },
                },
                [_vm._v("\n            Categories\n          ")]
              ),
              _vm._v(" "),
              _vm.dropDownMenu
                ? _c(
                    "ul",
                    {
                      staticClass: "dropdown-menu",
                      attrs: { "aria-hidden": "true" },
                    },
                    [
                      _c("li", { staticClass: "button-holder" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.addCategoryModal()
                              },
                            },
                          },
                          [
                            _vm._v("\n                Add New Category "),
                            _c("i", { staticClass: "fa fa-plus" }),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("div", { staticClass: "project-filter-list" }, [
                          _c("div", { staticClass: "filter-block" }, [
                            _c("h3", [_vm._v("Choose a Category")]),
                            _vm._v(" "),
                            _c(
                              "ul",
                              _vm._l(_vm.categories, function (cat) {
                                return _c("li", { key: cat.name }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "filter tag",
                                      class: { active: cat.active },
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.filterWork(cat, $event)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(cat.name) +
                                          " (\n                        "
                                      ),
                                      _c("span", {
                                        staticClass: "count",
                                        domProps: {
                                          textContent: _vm._s(cat.total),
                                        },
                                      }),
                                      _vm._v("\n                        )"),
                                    ]
                                  ),
                                ])
                              }),
                              0
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("main", [
        _c("aside", { staticClass: "sidebar finder left" }, [
          _c("div", { staticClass: "loading" }, [
            _c("h1", [_vm._v("My Work")]),
            _vm._v(" "),
            _c("div", { staticClass: "project-filter-list" }, [
              _c(
                "div",
                { staticClass: "filter-block" },
                [
                  _c("h3", [_vm._v("Categories")]),
                  _vm._v(" "),
                  _vm._l(_vm.categories, function (_category) {
                    return _c(
                      "ul",
                      { key: _category.name, staticClass: "who-categories" },
                      [
                        _c("li", [
                          _c(
                            "a",
                            {
                              staticClass: "filter tag",
                              class: { active: _category.active },
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  return _vm.filterWork(_category, $event)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_category.name) +
                                  " (\n                  "
                              ),
                              _c("span", { staticClass: "count" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_category.total) +
                                    "\n                  "
                                ),
                              ]),
                              _vm._v(" )\n                "),
                            ]
                          ),
                        ]),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.addCategoryModal($event)
                        },
                      },
                    },
                    [
                      _vm._v("\n              Add New Category "),
                      _c("i", { staticClass: "fa fa-plus" }),
                    ]
                  ),
                ],
                2
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("a", { attrs: { id: "maincontent" } }),
        _vm._v(" "),
        _c("section", { staticClass: "primary-content" }, [
          _c("div", { staticClass: "loading" }, [
            _vm.category.id != null
              ? _c("div", { staticClass: "mywork-category" }, [
                  _c("div", { staticClass: "mywork-category--name" }, [
                    _vm._v("\n            Evidence: "),
                    _c("span", {
                      domProps: { textContent: _vm._s(_vm.category.name) },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mywork-category--actions" }, [
                    _c(
                      "a",
                      {
                        staticClass: "actions",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.editCategoryModal($event)
                          },
                        },
                      },
                      [
                        _vm._v("Edit "),
                        _c("i", {
                          staticClass: "fa fa-pencil",
                          attrs: { "aria-hidden": "true" },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "actions",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.confirmDeleteCategory(_vm.category)
                          },
                        },
                      },
                      [
                        _vm._v("Delete "),
                        _c("i", {
                          staticClass: "fa fa-trash",
                          attrs: { "aria-hidden": "true" },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.isExporting
                      ? _c(
                          "a",
                          {
                            staticClass: "actions",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                $event.preventDefault()
                                return _vm.exportWorkByCategory($event)
                              },
                            },
                          },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(_vm.exportingText),
                              },
                            }),
                            _vm._v(" "),
                            _c("i", {
                              staticClass: "fa fa-arrow-up fa-rotate-45",
                              attrs: { "aria-hidden": "true" },
                            }),
                          ]
                        )
                      : _c(
                          "a",
                          { staticClass: "actions", attrs: { href: "#" } },
                          [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(_vm.exportingText),
                              },
                            }),
                          ]
                        ),
                  ]),
                ])
              : _c("p", { staticClass: "mywork-category" }, [
                  _vm._v("\n          Evidence: All\n        "),
                ]),
            _vm._v(" "),
            _c("div", { staticClass: "list" }, [
              _c(
                "ul",
                _vm._l(_vm.paginatedEvidences, function (evidence) {
                  return _c("card", {
                    key: evidence.id,
                    attrs: { evidence: evidence },
                  })
                }),
                1
              ),
            ]),
            _vm._v(" "),
            _vm.evidences.length > _vm.cardLimit
              ? _c("div", { staticClass: "mywork--load-more" }, [
                  _c(
                    "a",
                    {
                      staticClass: "button button-evidence",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          _vm.cardLimit += 12
                        },
                      },
                    },
                    [_vm._v("Load More")]
                  ),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("work-category-modal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }