<template>
  <li
    :id="slide.id"
    class="home-slider__item"
  >
    <div class="slider__image">
      <img
        :src="imageSource"
        :srcset="imageSrcset"
        :alt="altTag"
        sizes="(min-width: 768px) 50vw, 100vw"
      >
    </div>
    <div class="slider__content">
      <!--  eslint-disable  -->
      <h2 v-html="title" />
      <span v-html="description" />
      <div class="slider__cta">
        <span
          class="cta__content"
          v-html="slide.slideCtaHeader"
        />
        <!--  eslint-enable  -->
        <a
          :href="slide.slideCtaUrl"
          class="button home__button home_slider-button"
          target="_blank"
        >
          <span v-text="slide.slideCtaButtonText" />
        </a>
      </div>
    </div>
    <div class="clearfix" />
  </li>
</template>

<script>
export default {
  name: 'HomeSlide',
  props: {
    slide: {
      type: Object,
      required: true,
    },
  },
  computed: {
    title() {
      return this.slide.slideTitle;
    },
    description() {
      return this.slide.slideDescription;
    },
    imageSource() {
      return this.slide.slideImage[0].ioSlideshow.src;
    },
    imageSrcset() {
      return this.slide.slideImage[0].ioSlideshow.srcset;
    },
    altTag() {
      return this.slide.slideImage[0].altTag;
    },
  },
  methods: {
  },
};
</script>
