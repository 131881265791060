var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vue-modal",
      class: _vm.modalClasses,
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.close($event)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "vue-modal__container",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.captureClick($event)
            },
          },
        },
        [
          _vm.tabs && !_vm.header
            ? _c(
                "div",
                { staticClass: "vue-modal__tabs" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "vue-modal__close",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.close($event)
                        },
                      },
                    },
                    [_c("project-review-close-modal-icon")],
                    1
                  ),
                  _vm._v(" "),
                  _vm._t("tabs"),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.header && !_vm.tabs
            ? _c(
                "div",
                { staticClass: "vue-modal__header" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "vue-modal__close",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.close($event)
                        },
                      },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "21px",
                            height: "21px",
                            viewBox: "-3 -3 21 21",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "close-X",
                                stroke: "none",
                                "stroke-width": "1",
                                fill: "none",
                                "fill-rule": "evenodd",
                                "stroke-linecap": "square",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  id: "Line-3",
                                  d: "M0.5,0.5 L14.6421356,14.6421356",
                                  stroke: "#028E6D",
                                  "stroke-width": "4",
                                },
                              }),
                              _vm._v(" "),
                              _c("path", {
                                attrs: {
                                  id: "Line-4",
                                  d: "M0.5,0.5 L14.6421356,14.6421356",
                                  stroke: "#028E6D",
                                  "stroke-width": "4",
                                  transform:
                                    "translate(7.500000, 7.500000) scale(-1, 1) translate(-7.500000, -7.500000) ",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._t("header"),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "vue-modal__wrap" }, [
            _c(
              "div",
              { staticClass: "vue-modal__content" },
              [
                !_vm.tabs && !_vm.header
                  ? _c(
                      "div",
                      {
                        staticClass: "vue-modal__close",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.close($event)
                          },
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "21px",
                              height: "21px",
                              viewBox: "-3 -3 21 21",
                              xmlns: "http://www.w3.org/2000/svg",
                            },
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  id: "close-X",
                                  stroke: "none",
                                  "stroke-width": "1",
                                  fill: "none",
                                  "fill-rule": "evenodd",
                                  "stroke-linecap": "square",
                                },
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    id: "Line-5",
                                    d: "M0.5,0.5 L14.6421356,14.6421356",
                                    stroke: "#028E6D",
                                    "stroke-width": "4",
                                  },
                                }),
                                _vm._v(" "),
                                _c("path", {
                                  attrs: {
                                    id: "Line=6",
                                    d: "M0.5,0.5 L14.6421356,14.6421356",
                                    stroke: "#028E6D",
                                    "stroke-width": "4",
                                    transform:
                                      "translate(7.500000, 7.500000) scale(-1, 1) translate(-7.500000, -7.500000) ",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._t("default"),
              ],
              2
            ),
            _vm._v(" "),
            _vm.footer
              ? _c(
                  "div",
                  { staticClass: "vue-modal__footer" },
                  [_vm._t("footer")],
                  2
                )
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }