<template>
  <div class="full-wrap">
    <div class="page-wrap">
      <main>
        <section class="primary-content">
          <div class="content-box">
            <div v-if="currentUser.isInActiveGroup && !currentUser.isNewUser && ! currentUser.hasAnyLicensedSites">
              <h1 class="page-title">
                Time to renew your site’s Mouse membership!
              </h1>

              <p><strong>Unfortunately, your site’s membership has expired.</strong></p>

              <p />

              <ul>
                <li>Don’t worry, all of your own work is still available in your Profile.</li>
                <li>You can export your work anytime in the My Work area of your Profile.</li>
                <li>We will reactivate your groups after your site’s membership is renewed.</li>
              </ul>

              <p />

              <p>
                <strong>Ready to Renew?</strong><br>
                <a href="https://www.mouse.org/renew">Click here</a> to learn more about renewing your membership or to
                let us know if you think this message is an error. Students: Talk to your teacher about renewing your
                site’s membership.
              </p>

              <p>
                <strong>At a new site?</strong><br>
                If you are now at a new Mouse site and want to use this account, enter your new site’s Educator Code or
                Group Code below.
              </p>
            </div>

            <div class="back_link">
              <router-link :to="{ name: 'group-switch' }">
                <i
                  class="fa fa-chevron-left"
                  aria-hidden="true"
                /> Back to
                My Groups
              </router-link>
            </div>

            <div class="group-title--area">
              <h1 v-if="currentUser.isEducator">Join an Existing Group</h1>
              <h1 v-else>Join a Group</h1>

              <div class="group-switch-buttons" v-if="currentUser.isEducator">
                <router-link
                  to="/create-group"
                  class="button__secondary"
                  v-if="currentUser.isEducator"
                >
                  Create New Group <i class="fa fa-plus"></i>
                </router-link>
              </div>

              <div class="clearfix"></div>
            </div>

            <div v-if="currentUser.isEducator">
              <p class="instructional">
                To join an existing group, enter the Group Code below. You can get the Group Code from the educator who manages that group. Can’t find your code? <a
                  href="https://tools.mouse.org/help/group-members"
                  target="_blank"
                >Click here for help</a>.
              </p>
            </div>

            <div v-else>
              <p class="instructional">
                Enter your group’s Group Code below. You can get the Group Code from the educator who manages your group. Can’t find the code? <a
                  href="https://tools.mouse.org/help/group-members"
                  target="_blank"
                >Click here for help</a>.
              </p>
            </div>

            <form
              class="simple-join-group"
              method="POST"
            >
              <div class="form-row short-row">
                <label
                  for="groupCode"
                  class="visuallyhidden"
                >Group Code</label>
                <input
                  id="groupCode"
                  v-model="code"
                  type="text"
                  name="code"
                  placeholder="Enter Group Code"
                  value=""
                  autocomplete="off"
                >
              </div>
              <div class="form-row">
                <div class="submit-button">
                  <button
                    type="submit"
                    class="button"
                    @click.stop.prevent="saveGroup"
                  >
                    Join Group
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>

import messages from '../../mixins/messages';

export default {
  name: 'JoinGroup',
  components: {},
  mixins: [messages],
  props: {},
  data: () => ({
    code: '',
  }),
  computed: {
    currentUser() {
      return this.$store.state.users.current;
    },
    getJoinGroupRoute() {
      return laroute.route('api.join.group');
    },
  },
  mounted() {
    document.body.className = 'no-js language-markup single-column';
    window.scrollTo(0, 0);
  },
  methods: {
    saveGroup() {
      const data = {
        code: this.code,
      };

      axios.post('/api/join/group', data).then((response) => {
        if (response.data.error) {
          this.callNotification(response.data.message, 'error');
        } else {
          const { group } = response.data;
          this.$store.dispatch('setGroupByResponse', group);
          this.$store.dispatch('getMe');
          this.$router.push(response.data.route).catch(() => {});
          this.callNotification(response.data.message, 'success');
        }
      },
      function joinGroupErrorCollection(error) {
        this.callNotification(error.data.message, 'error');
      });
    },

  },
};
</script>
