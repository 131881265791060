<template>
  <div
    class="vue-modal"
    :class="modalClasses"
    @click.stop="close"
  >
    <div
      class="vue-modal__container"
      @click.stop="captureClick"
    >
      <div
        v-if="tabs && !header"
        class="vue-modal__tabs"
      >
        <div
          class="vue-modal__close"
          @click.stop="close"
        >
          <project-review-close-modal-icon />
        </div>
        <slot name="tabs" />
      </div>

      <div
        v-if="header && !tabs"
        class="vue-modal__header"
      >
        <div
          class="vue-modal__close"
          @click.stop="close"
        >
          <svg
            width="21px"
            height="21px"
            viewBox="-3 -3 21 21"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="close-X"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="square"
            >
              <path
                id="Line-3"
                d="M0.5,0.5 L14.6421356,14.6421356"
                stroke="#028E6D"
                stroke-width="4"
              />
              <path
                id="Line-4"
                d="M0.5,0.5 L14.6421356,14.6421356"
                stroke="#028E6D"
                stroke-width="4"
                transform="translate(7.500000, 7.500000) scale(-1, 1) translate(-7.500000, -7.500000) "
              />
            </g>
          </svg>
        </div>

        <slot name="header" />
      </div>

      <div class="vue-modal__wrap">
        <div class="vue-modal__content">
          <div
            v-if="!tabs && !header"
            class="vue-modal__close"
            @click.stop="close"
          >
            <svg
              width="21px"
              height="21px"
              viewBox="-3 -3 21 21"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="close-X"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                stroke-linecap="square"
              >
                <path
                  id="Line-5"
                  d="M0.5,0.5 L14.6421356,14.6421356"
                  stroke="#028E6D"
                  stroke-width="4"
                />
                <path
                  id="Line=6"
                  d="M0.5,0.5 L14.6421356,14.6421356"
                  stroke="#028E6D"
                  stroke-width="4"
                  transform="translate(7.500000, 7.500000) scale(-1, 1) translate(-7.500000, -7.500000) "
                />
              </g>
            </svg>
          </div>

          <slot />
        </div>

        <div
          v-if="footer"
          class="vue-modal__footer"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectReviewCloseModalIcon from '../icons/ProjectReviewCloseModalIcon.vue';

export default {
  name: 'Modal',
  components: { ProjectReviewCloseModalIcon },
  props: {
    header: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Boolean,
      default: false,
    },
    footer: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
    },
  },
  data() {
    return {
      bodyScrollPosition: 0,
      modalVisible: false,
    };
  },
  computed: {
    modalClasses() {
      return {
        'vue-modal--visible': this.isVisible && this.modalVisible,
      };
    },
    isModalVisible() {
      return this.isVisible && this.modalVisible;
    },
  },
  watch: {
    isVisible() {
      if (!this.isVisible && this.modalVisible) {
        this.close();
      }
    },
  },
  created() {
    bus.$on('modal-open', this.open);
    bus.$on('modal-force-close', this.close);
  },
  methods: {
    captureClick() {
      // empty function that just captures click through modal container.
    },
    close() {
      const $body = $('body');
      bus.$emit('modal-clear');
      this.modalVisible = false;
      $body.removeClass('vue-modal-active');

      // Scroll back to original scroll position
      $body.scrollTop(this.bodyScrollPosition);
    },
    open() {
      const $body = $('body');
      this.modalVisible = true;

      // Store current scroll position
      this.bodyScrollPosition = $body.scrollTop();
      $body.addClass('vue-modal-active');
    },
  },
};
</script>
