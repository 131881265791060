<template>
  <div class="header__admin">
    <div class="header__admin__text">
      <h1 class="header__admin__text__title">
        {{ groupName }}
      </h1>

      <h2
        v-if="$groupUser().can('manage_group')"
        class="header__admin__text__subtitle"
      >
        Group Code: {{ groupCode }}
      </h2>
    </div>

    <div class="header__admin__actions">
      <span v-if="$groupUser().can('manage_group')">
        <a
          href="#"
          class="header__admin__actions--notification"
          @click.stop.prevent="loadNotifications"
        >Send Notification</a>
        <a
          href="#"
          class="header__admin__actions--edit"
          @click.stop.prevent="loadEdit"
        >Edit Group</a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GroupHeader',
  props: {
    group: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.users.current;
    },
    groupName() {
      return this.group.name;
    },
    groupCode() {
      return this.group.code;
    },
  },
  methods: {
    loadEdit() {
      this.$router.push({ name: 'group-edit', params: { id: this.group.id } });
    },
    loadNotifications() {
      this.$router.push({ name: 'group-notifications', params: { id: this.group.id } });
      // bus.$emit('set-active-tab', 'group-notifications');
    },
  },
};
</script>
