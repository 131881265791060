import VueRouter from 'vue-router';
import ApolloClient from 'apollo-boost';
import VueApollo from 'vue-apollo';
import VeeValidate from 'vee-validate';
import Vue from 'vue';
import laroute from './laroute';
import routes from './routes';
import store from './store';
import dictionary from './dictionary';
import 'js-cookie';

import ProjectReviewModal from './components/modals/ProjectReviewModal.vue';
import AlertMessage from './components/alerts/AlertMessage.vue';
import Notification from './components/notifications/Notification.vue';
import NotificationForm from './components/notifications/NotificationForm.vue';
import Masquerade from './components/admin/Masquerade.vue';
import CourseDescription from './components/admin/CourseDescription.vue';
import LicenseExpired from './components/global/LicenseExpired.vue';
import AppRoot from './components/AppRoot.vue';
import GroupUserRolePlugin from './plugins/GroupUserRolePlugin';

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./bootstrap');

window.Vue = Vue;

// Laroute
// ---------------------------------------
window.laroute = laroute;

// Filters
// ---------------------------------------
require('./filters');
require('./directives');

// Event Bus
// ---------------------------------------
window.bus = new Vue();

// Apollo setup
// ---------------------------------------
const apolloClient = new ApolloClient({
  // You should use an absolute URL here
  uri: process.env.MIX_CRAFT_URL,
  request: (operation) => {
    operation.setContext({
      headers: {
        authorization: `Bearer ${process.env.MIX_CRAFT_TOKEN}`, // Your Auth token extraction logic
      },
    });
  },
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});
/**
 * Use loaded libraries
 */
// ---------------------------------------
Vue.use(VueRouter);
Vue.use(VueApollo);
Vue.use(VeeValidate);
// noinspection JSCheckFunctionSignatures
Vue.use(require('vue-script2'));

Vue.use(GroupUserRolePlugin);

/**
 * Loaded library configs
 */
const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

router.beforeEach((to, from, next) => {
  const { isLoggedIn } = store.getters;

  if (to.path === '/logging-in') {
    // if there's a groupId then set that
    // @TODO can we refactor this so that the Login component and here use the same code?
    if (to.query.groupId) {
      store.dispatch('setActiveGroup', to.query.groupId).then();
    }

    // properly set the user
    localStorage.setItem('isLoggedIn', 'true');
    store.dispatch('getMe').then(() => next(to.query.redirect));

    // If no matches route to login if not auth
    // If logged in and no match result 404
  } else if ((to.name === 'page-not-found' || to.matched.length < 1) && !isLoggedIn) {
    next({ path: '/login' });
  } else if (to.matched.some((record) => record.meta.requiresAuth) && !isLoggedIn) {
    next({ path: '/login' });
  } else {
    next();
  }
});

Vue.component('AppRoot', AppRoot);

const app = new Vue({
  el: '#vue-app',
  store,
  router,
  apolloProvider,
  components: {
    ProjectReviewModal,
    AlertMessage,
    Notification,
    NotificationForm,
    Masquerade,
    CourseDescription,
    LicenseExpired,
  },
  computed: {
  },

  mounted() {
    this.loadSettings();
    this.getUser();
    this.axiosInterceptor();

    this.$store.dispatch('getMe').then();
  },
  methods: {
    globalEvent(event, params) {
      window.bus.$emit(event, params);
    },
    openAdminModal(env) {
      window.bus.$emit('open-project-review-modal', {
        evidenceId: env.id,
        groupId: env.group_id,
        isEditing: false,
      });
    },
    axiosInterceptor() {
      const { name } = this.$route;

      const dontRedirectFor = ['login', 'home', 'register', 'badge-evidence'];

      if (!dontRedirectFor.includes(name)) {
        window.axios.interceptors.response.use(
          (response) => response,
          (error) => {
            if (error.response.status === 402) {
              this.$store.dispatch('logoutClient').then();
              return Promise.reject(error);
            }

            return Promise.reject(error);
          },
        );
      }
    },
    getUser() {
      if (this.isLoggedIn) {
        this.$store.dispatch('getMe').then();
      }
    },
    loadSettings() {
      $('body').on('vue.global.event', ($e, params) => {
        bus.$emit(params._event, params);
      });

      // Setup validation language
      this.$validator.localize('en', dictionary);

      // close callout
      $('.close-button').on('click', function closeCallout() {
        $(this).closest('.callout').fadeOut();
      });
    },
  },
});

window.app = app;
