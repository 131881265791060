<template>
  <div
    v-if="hasAccess"
    :class="{ 'profile-view': !isAdminEdit }"
  >
    <div
      id="profile-header"
      class="profile-header"
    >
      <h1
        v-if="isAdminEdit"
        class="page-title"
      >
        Edit Profile
      </h1>
      <h1
        v-else
        class="page-title"
      >
        Your Profile
      </h1>
      <p
        v-show="isAdminEdit"
        class="instructional"
      >
        You are about to update this users information.
      </p>
    </div>

    <div v-show="formErrors">
      <p
        v-for="error in formErrors"
        :key="error[0]"
        class="form-error"
        v-text="error[0]"
      />
    </div>

    <div id="profileHandler">
      <form
        id="profile_form"
        class="simple-edit"
        method="post"
        enctype="multipart/form-data"
      >
        <input
          type="hidden"
          name="_method"
          value="POST"
        >

        <div class="form-row">
          <label for="username">Username</label>
          <input
            id="username"
            v-model="user.username"
            type="text"
            name="username"
            autocomplete="off"
            @keyup="checkUsernameUpdate"
          >
        </div>

        <div class="form-row">
          <label for="password">Password</label>
          <input
            id="password"
            v-model="user.password"
            type="password"
            name="password"
            autocomplete="off"
          >
        </div>

        <div class="form-row">
          <label for="password_confirmation">Confirm Password</label>
          <input
            id="password_confirmation"
            v-model="user.password_confirmation"
            type="password"
            name="password_confirmation"
            autocomplete="off"
          >
        </div>

        <div class="form-row">
          <label for="email">Email Address</label>
          <input
            id="email"
            v-model="emailValue"
            type="text"
            name="email"
          >
        </div>

        <div
          v-if="showBadge"
          class="form-row"
        >
          <label for="can_award">Earn Badges on Mouse Create & Credly</label>
          <div style="margin-bottom: 0.8rem">
            Mouse badges are shared with Credly.com, a service that lets you share your badges publicly on college or
            career applications, and on social media. If you earn a badge, Credly will contact you with the email
            address associated with your Mouse account. In that email, you will be able to choose whether or not to
            accept and share your badge on Credly.
          </div>
          <input
            id="can_award"
            v-model="user.canAward"
            type="checkbox"
            name="can_award"
          >
          &nbsp;Yes, I would like to earn badges on Mouse Create that will be shared with Credly (<a
            href="https://credly.com"
          >Credly.com</a>)
        </div>
        <div
          v-else
          class="form-row"
        >
          <label
            for="can_award"
            style="margin-bottom: 0.8rem"
          >Earn Badges on Mouse Create & Credly</label>
          First make sure you have entered a valid email address above.
          <template v-if="!user.of_age">
            If you are under 13, you will not be able to earn badges until your 13th birthday.
          </template>
        </div>

        <div class="form-row">
          <label for="first_name">First Name</label>
          <input
            id="first_name"
            v-model="user.first_name"
            type="text"
            name="first_name"
            autocomplete="off"
          >
        </div>

        <div class="form-row">
          <label for="last_name">Last Name</label>
          <input
            id="last_name"
            v-model="user.last_name"
            type="text"
            name="last_name"
            autocomplete="off"
          >
        </div>
        <div class="form-row">
          <label for="profileimage">Profile Image</label>

          <div v-show="user.avatar">
            <div>Current Image</div>
            <!--  eslint-disable    -->
            <div v-html="user.avatar" />
            <!--  eslint-enable    -->
          </div>

          <div v-if="image">
            <span class="warning label">New Image</span>
            <img
              :src="image"
              :alt="user.username"
            >
            <br>
            <button
              class="button"
              @click="removeImage"
            >
              Remove image
            </button>
          </div>

          <div>
            <input
              id="profileimage"
              type="file"
              name="profileimage"
              @change="onFileChange"
            >
          </div>
        </div>

        <div class="form-row">
          <label for="bio">
            Brief Bio (140 characters)
          </label>

          <input
            id="bio"
            v-model="user.profile.bio"
            type="text"
            name="bio"
          >
        </div>

        <div class="form-row align-right">
          <button
            type="button"
            class="button"
            @click.prevent="saveProfile"
          >
            Update Profile
          </button>

          <span class="cancel">or <a href="javascript:history.go(-1)">Cancel</a></span>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProfileEdit',
  components: {},
  props: {
    options: { type: Object, default() { return {}; } },
  },
  data() {
    return {
      user: {
        of_age: false,
        canAward: '',
        isStudent: true,
        username: null,
        email: null,
        first_name: null,
        last_name: null,
        password: null,
        password_confirmation: null,
        avatar: null,
        profile: {
          bio: null,
        },
      },
      image: '',
      profile_image: null,
      file: null,
      editable: false,
      owner: false,
      formErrors: [],
      isEditing: false,
      emailValue: '',
    };
  },
  computed: {
    isAdminEdit() {
      const routeName = this.$route.name;

      return routeName === 'profile-educator-edit';
    },
    currentUser() {
      return this.$store.state.users.current;
    },
    hasAccess() {
      // Don't let students edit anyone but themselves
      if (this.currentUser.isStudent) {
        const { id } = this.$route.params;
        const { token } = this.currentUser;

        if (token === id) {
          return true;
        }
      }

      if (this.currentUser.isEducator && this.user.isStudent) {
        return true;
      }

      if (this.currentUser.id === this.user.id) {
        return true;
      }

      if (this.currentUser.isEducator && !this.user.editable) {
        return false;
      }

      return false;
    },
    showBadge() {
      if (!this.user) {
        return false;
      }

      if (!this.user.isStudent) {
        return true;
      }

      if (!this.user.of_age) {
        return false;
      }

      return this.emailValue.length > 0;
    },
  },
  watch: {
    currentUser(user) {
      const { id } = this.$route.params;
      const { name } = this.$route;
      const { token } = user;

      if (user.isStudent || name === 'profile-settings') {
        if (token !== id) {
          this.$router.push({ name: 'home' });
        }
      }
    },
  },
  mounted() {
    this.loadProfile();

    if (this.$route.name === 'profile-educator-edit') {
      document.body.className = 'no-js language-markup single-column';
    }
  },
  methods: {
    loadProfile() {
      const token = this.$route.params.id;

      const url = laroute.route('api.user', { token });

      axios
        .get(url)
        .then((response) => {
          this.emailValue = !response.data.email ? '' : response.data.email;
          this.user = response.data;
          this.owner = response.data.owner;
        });
    },

    checkUsernameUpdate() {

    },

    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      this.createImage(files[0]);
    },

    createImage(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.image = e.target.result;
        this.file = e.target.result;
      };

      reader.readAsDataURL(file);
      this.file = file;
    },

    removeImage() {
      this.image = '';
    },

    saveProfile() {
      const token = this.$route.params.id;
      const url = laroute.route('api.user.update', { token });

      const form = document.getElementById('profile_form');
      const formData = new FormData(form);
      formData.append('token', token);

      this.formErrors = [];

      axios
        .post(url, formData)
        .then((response) => {
          this.$store.dispatch('loadMessage', {
            content: response.data.message,
            type: 'success',
            icon: '',
            autoclose: true,
          });

          if (!this.isAdminEdit) {
            this.$store.dispatch('updateUser', response.data.user.original);
          }

          this.$router.push({ name: 'profile-view', params: { id: token } });
        })
        .catch((error) => {
          this.formErrors = _.each(error.response.data.errors, (item) => item);
        });

      $('html, body').animate({ scrollTop: 0 }, 100);
    },
  },
};
</script>
