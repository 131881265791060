<template>
  <div>
    <div class="evidence-creators">
      <div class="step-heading">
        <h2>Creators</h2>
        <span>Add all group members who collaborated with you on this work.
          <a
            href="#"
            class="creators__edit"
            @click.stop.prevent="addCreator"
          >
            <span class="creators__edit-text">Edit</span>
            <span class="creators__edit-icon">
              <svg
                width="13px"
                height="13px"
                viewBox="0 0 13 13"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs />
                <g
                  id="Symbols"
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="Creators-block"
                    transform="translate(-643.000000, -25.000000)"
                    fill="#00A886"
                  >
                    <g id="Creators">
                      <g
                        id="edit-on-white"
                        transform="translate(611.000000, 0.000000)"
                      >
                        <g
                          id="pencil30"
                          transform="translate(32.000000, 25.000000)"
                        >
                          <g id="Group">
                            <path
                              id="Shape"
                              d="M0.0287110698,9.20044633 L0.0287110698,12.6720597 L3.45513462,12.6720597 L10.3079817,5.72883296 L6.88155817,2.2572196 L0.0287110698,9.20044633 L0.0287110698,9.20044633 Z M3.01860505,11.6038316 L3.01860505,11.6038316 L2.13725608,11.603934 L2.13725608,10.5357059 L1.08303412,10.5357059 L1.08303412,9.6426307 L1.8325548,8.88322488 L3.76812572,10.8443233 L3.01860505,11.6038316 L3.01860505,11.6038316 Z M7.14521476,3.67595847 C7.26592257,3.67595847 7.32627647,3.73721079 7.32627647,3.85951057 C7.32627647,3.91523174 7.30716946,3.96245133 7.26865214,4.00147664 L2.80438407,8.52462227 C2.76596785,8.56344271 2.71926182,8.58290415 2.66436706,8.58290415 C2.54365925,8.58290415 2.48320425,8.52154941 2.48320425,8.39935206 C2.48320425,8.34363089 2.50241236,8.29641129 2.54082858,8.25738598 L7.00509665,3.73434279 C7.04351287,3.69541991 7.09021891,3.67595847 7.14521476,3.67595847 L7.14521476,3.67595847 Z"
                            />
                            <path
                              id="Shape"
                              d="M12.2023058,2.29880562 L10.2667348,0.346106322 C10.0581752,0.13479607 9.80826758,0.0289873007 9.51721416,0.0289873007 C9.2206005,0.0289873007 8.97362465,0.13479607 8.7759833,0.346106322 L7.40877025,1.72305432 L10.8351938,5.19466769 L12.202508,3.80942297 C12.4057095,3.60354143 12.5072092,3.35320588 12.5072092,3.05831387 C12.5071081,2.76905543 12.4056084,2.51585187 12.2023058,2.29880562 L12.2023058,2.29880562 Z"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </span>
          </a>
        </span>
      </div>

      <div class="clearfix" />

      <div class="creators__all">
        <div
          v-if="currentUser"
          class="evidence-creator"
        >
          <!--     eslint-disable     -->
          <div
            class="evidence-creator__profile"
            v-html="currentUser.avatar"
          />
          <!--     eslint-enable     -->
          <div class="evidence-creator__name">
            You!
          </div>
        </div>

        <div
          v-for="user in evidenceCollaborators"
          :key="user.id"
          class="evidence-creator"
        >
          <!--     eslint-disable     -->
          <div
            class="evidence-creator__profile"
            v-html="user.avatar"
          />
          <!--     eslint-enable     -->
          <div class="evidence-creator__name">
            {{ user.username }}
          </div>
        </div>

        <div class="evidence-creator">
          <a
            href="#"
            class="evidence__creator--add"
            @click.stop.prevent="addCreator"
          >
            <svg
              width="17px"
              height="18px"
              viewBox="0 0 17 18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs />
              <g
                id="Symbols"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
                font-family="Basetica-Medium, Basetica"
                font-size="36"
                font-weight="400"
              >
                <g
                  id="Creators-block"
                  transform="translate(-174.000000, -76.000000)"
                  fill="#FFFFFF"
                >
                  <g id="Creators">
                    <g
                      id="Green-top-area"
                      transform="translate(0.000000, 51.000000)"
                    >
                      <g id="creators---images">
                        <g id="row-1">
                          <g
                            id="Add-collaborator----ICON-Copy"
                            transform="translate(141.000000, 0.000000)"
                          >
                            <g
                              id="Group-5"
                              transform="translate(19.000000, 12.000000)"
                            >
                              <text id="+">
                                <tspan
                                  x="12.376699"
                                  y="35"
                                >+</tspan>
                              </text>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </a>
          <div class="evidence-creator__name evidence-add__more">
            Add More Creators
          </div>
        </div>
      </div>

      <hr>
    </div>

    <collaborator-modal
      :evidence-collaborators="evidenceCollaborators"
      :group-name="groupName"
    />
  </div>
</template>

<script>

import modalMixin from '../../mixins/modal';
import CollaboratorModal from '../modals/CollaboratorModal.vue';

export default {
  name: 'ProjectCollaborators',
  components: {
    CollaboratorModal,
  },
  mixins: [modalMixin],
  props: {
    evidenceId: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      availableCollaborators: [],
      evidenceCollaborators: [],
      collaborators: [],
      creator: null,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.users.current;
    },
    activeGroup() {
      return this.$store.state.groups.active;
    },
    groupName() {
      return this.activeGroup.name;
    },
    getEvidenceCollaboratorsRoute() {
      return laroute.route('api.collaborators.evidence', {
        evidence: this.evidenceId,
      });
    },
    getEvidenceRoute() {
      return laroute.route('api.evidence.get', {
        id: this.evidenceId,
      });
    },
    postCollaboratorsRoute() {
      return laroute.route('group.project.evidence.collaborators', {
        group: this.currentUser.defaultGroupId,
        project: this.$route.params.id,
      });
    },
  },
  watch: {
    evidenceId(newId) {
      if (newId) {
        this.getEvidenceCollaborators();
      }
    },
  },
  mounted() {
    bus.$on('update:collaborators', this.getEvidenceCollaborators);

    this.getEvidenceCollaborators({ id: this.evidenceId });
  },
  methods: {
    getEvidenceCollaborators(params) {
      let route;
      if (params) {
        route = laroute.route('api.collaborators.evidence', {
          evidence: params.id,
        });
      } else {
        route = this.getEvidenceCollaboratorsRoute;
      }

      axios.get(route).then((response) => {
        this.evidenceCollaborators = response.data.data;
      });
    },

    saveCollaborators() {
      axios.post(this.postCollaboratorsRoute).then((response) => {
        if (response.data.status === 'success') {
          // this.getEvidenceCreator(response.data.id);this.g
        }
      });
    },

    addCreator() {
      const ids = _.mapValues(this.evidenceCollaborators, (c) => c.id);
      this.collaborators = _.values(ids);
      this.openModal('#verify-collaborators');
    },
  },
};
</script>
