<template>
  <div class="full-wrap">
    <div class="page-wrap">
      <div
        v-if="loaded"
        class="main-menu mobile-project-filter"
      >
        <div class="mobile-dropdown mobile-filter project-mobile-filter">
          <div class="dropdown">
            <div class="dropdown-container">
              <div
                class="dropdown-button-delay"
                tabindex="0"
                @click="dropDownToggle"
              >
                Filter Projects
              </div>
              <ul
                v-show="dropDownMenu"
                class="dropdown-menu"
                aria-hidden="true"
              >
                <li>
                  <project-finder-filters
                    :courses="courses"
                    :categories="categories"
                    :category-counts="categoryCounts"
                    :active-categories="activeCategories"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <main>
        <div
          v-if="!loaded"
          class="response-loading"
        >
          <h2>Projects loading now...</h2>
          <p>
            <img
              alt="loader"
              src="/img/gear-animation-115.gif"
            >
          </p>
        </div>
        <aside class="sidebar finder left">
          <div v-if="loaded">
            <h1>Find Projects</h1>
            <h2>Project Finder</h2>

            <project-finder-filters
              :courses="courses"
              :categories="categories"
              :category-counts="categoryCounts"
              :active-categories="activeCategories"
            />
          </div>
        </aside>

        <section class="primary-content playlist-content">
          <div v-if="loaded">
            <p class="filter-results">
              There <span id="project-count">are {{ (projects) ? projects.length : '' }} projects</span> that match your filter settings.
            </p>

            <div id="project-list">
              <project-card
                v-for="project in projects"
                :key="project.id"
                :project="project"
                :is-in-current-group="checkProject(project.id)"
              />
            </div>
          </div>
        </section>
      </main>
    </div>
  </div>
</template>

<script>

import ProjectCard from './ProjectCard.vue';
import ProjectFinderFilters from './ProjectFinderFilters.vue';

export default {
  name: 'Projectfinder',
  components: { ProjectCard, ProjectFinderFilters },
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      dropDownMenu: false,
      responseProjects: [],
      responseCategories: [],
      filteredProjects: [],
      activeCategories: [],
      groupProjectIds: [],
      courses: [],
      loaded: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.users.current;
    },

    packProjectsUrl() {
      return laroute.route('api.packs.projects');
    },

    currentGroupId() {
      if (this.options.groupId) {
        return this.options.groupId;
      }

      if (this.$route.params.id) {
        return this.$route.params.id;
      }
      return this.$store.state.groups.active.id || false;
    },

    getGroupProjectsRoute() {
      return laroute.route('api.group.projects', { group: this.currentGroupId });
    },
    getCoursesRoute() {
      return laroute.route('api.pack.courses');
    },
    projects() {
      const projects = !_.isEmpty(this.filteredProjects) ? this.filteredProjects : this.responseProjects;

      return _.orderBy(projects, (project) => project.title.toString().toLowerCase());
    },
    categories() {
      let allCategories = _.flatten(this.responseCategories);

      // Get all distinct categories across all projects, sorted
      allCategories = _.orderBy(
        _.uniqWith(allCategories, _.isEqual),
        (category) => category.categoryName.toString().toLowerCase(),
      );

      // Group categories by group name
      return _.groupBy(allCategories, (category) => category.categoryGroupName.toLowerCase());
    },
    categoryCounts() {
      const allCategories = _.flatMap(this.projects, (project) => project.categories);

      // The number of times each category exists
      return _.countBy(allCategories, 'categoryId');
    },
  },
  watch: {
    currentGroupId() {
      if (this.currentGroupId) {
        this.getGroupProjects();
      }
    },
  },
  created() {
    bus.$on('filter-by-category', this.filterByCategory);
  },
  mounted() {
    this.getPackProjects();
    this.getCourses();

    if (this.$route.name !== 'project-finder') {
      const id = (this.$route.params.id) ? this.$route.params.id : this.options.groupId;
      this.$router.push({ name: 'project-finder', params: { id } });
    }

    if (this.currentGroupId) {
      this.getGroupProjects();
    }
  },
  methods: {
    async getPackProjects() {
      const response = await axios.get(this.packProjectsUrl).catch((r) => r.response);
      const projects = response.data.data;

      this.responseProjects = this.responseProjects.concat(projects);
      this.loadCategories(projects);
      this.loaded = true;
    },

    getGroupProjects() {
      axios.get(this.getGroupProjectsRoute).then((response) => {
        const projects = response.data.data;
        this.groupProjectIds = _.map(projects, 'id');
      });
    },

    getCourses() {
      axios.get(this.getCoursesRoute).then((response) => {
        this.courses = _.orderBy(response.data.data, (course) => course.title.toString().toLowerCase());
      });
    },

    checkProject(id) {
      return this.groupProjectIds.indexOf(id) > -1;
    },

    loadCategories(projects) {
      const allCategories = _.flatMap(projects, (project) => project.categories);

      this.responseCategories.push(allCategories);
    },

    dropDownToggle() {
      this.dropDownMenu = !this.dropDownMenu;
    },

    loadAnalytics(id, label) {
      if (window.ga) {
        window.ga('send', {
          hitType: 'event',
          eventCategory: 'Navigation',
          eventAction: 'Project Tag Click',
          eventLabel: label,
        });
      }
    },

    filterByCategory(params) {
      const { category } = params;
      const { categoryId } = category;
      let { projects } = this;

      // If not in filtered categories add and analytics
      // Else remove
      if (this.activeCategories.indexOf(categoryId) === -1) {
        this.activeCategories.push(categoryId);
        this.filterAddCategory(projects, categoryId);
        this.loadAnalytics(categoryId, category.categoryName);
      } else {
        this.activeCategories = _.without(this.activeCategories, categoryId);
        projects = this.responseProjects;
        this.filterRemoveCategory(projects);
      }
    },

    filterAddCategory(projects, categoryId) {
      this.filteredProjects = _.filter(projects, (project) => _.find(project.categories, (c) => parseInt(c.categoryId, 10) === parseInt(categoryId, 10)));
    },
    filterRemoveCategory(projects) {
      const self = this;
      this.filteredProjects = _.filter(projects, (project) => _.find(project.categories, (c) => self.activeCategories.indexOf(c.categoryId) > -1));
    },
  },
};
</script>
