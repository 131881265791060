var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "pagination__arrow",
      attrs: {
        height: "15px",
        viewBox: "0 0 10 15",
        width: "10px",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("defs"),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Designs",
            fill: "none",
            "fill-rule": "evenodd",
            stroke: "none",
            "stroke-width": "1",
          },
        },
        [
          _c(
            "g",
            {
              staticClass: "pagination__arrow",
              attrs: {
                id: "Steps-Tab---1-Prompt_no-work-yet",
                transform: "translate(-77.000000, -260.000000)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Project-Detail-Copy-2",
                    transform: "translate(49.000000, 251.000000)",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "prev/next-step-top",
                        transform: "translate(18.000000, 0.000000)",
                      },
                    },
                    [
                      _c("g", { attrs: { id: "left-arrow" } }, [
                        _c("path", {
                          attrs: {
                            id: "Shape-Copy",
                            d: "M15.8054327,12.0950972 C15.5563791,11.8539213 15.2655616,11.7333333 14.9333333,11.7333333 C14.5946346,11.7333333 14.3071112,11.8539213 14.0706456,12.0950972 L7.83089431,18.4592607 C7.58807588,18.706796 7.46666667,19.0034063 7.46666667,19.3488518 C7.46666667,19.7007766 7.58819353,19.9940273 7.83089431,20.2286038 L8.54970274,20.9617305 C8.77981555,21.2093857 9.06733892,21.3333333 9.4123905,21.3333333 C9.75744207,21.3333333 10.0449654,21.2092657 10.2750783,20.9617305 L14.9333333,16.2204046 L19.5915884,20.9616105 C19.8215836,21.2091458 20.109107,21.3332133 20.4543938,21.3332133 C20.7928573,21.3332133 21.0837924,21.2091458 21.3264931,20.9616105 L22.0451839,20.2284838 C22.2816495,19.9873079 22.4,19.6940573 22.4,19.3487318 C22.4,19.0032863 22.2785908,18.706676 22.0356547,18.4592607 L15.8054327,12.0950972 Z",
                            transform:
                              "translate(14.933333, 16.533333) rotate(-90.000000) translate(-14.933333, -16.533333) ",
                          },
                        }),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }