<template>
  <svg
    width="21px"
    height="17px"
    viewBox="0 0 21 17"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs />
    <g
      id="Designs"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Steps-Tab-with-Prompt-Large"
        transform="translate(-156.000000, -1199.000000)"
        class="step__icon-state"
      >
        <g
          id="Project-Detail-Copy-2"
          transform="translate(49.000000, 247.000000)"
        >
          <g
            id="Evidence-Prompt-Steps-Tab---Large"
            transform="translate(0.000000, 675.000000)"
          >
            <g
              id="Add-response"
              transform="translate(21.000000, 206.000000)"
            >
              <g
                id="BUTTON-ICON---DEFAULT"
                transform="translate(0.000000, 63.000000)"
              >
                <g id="button--text-ON">
                  <g
                    id="align16"
                    transform="translate(86.522251, 8.000000)"
                  >
                    <g id="Group">
                      <path
                        id="Shape"
                        d="M0.72552301,7.30288595 L18.1391884,7.30288595 C18.3356669,7.30288595 18.505707,7.25032521 18.6493088,7.14540704 C18.7927714,7.04059055 18.8645723,6.91625439 18.8645723,6.77270357 L18.8645723,5.71244046 C18.8645723,5.56878798 18.7927714,5.44465515 18.6493088,5.33973699 C18.505707,5.23481882 18.3356669,5.18235974 18.1391884,5.18235974 L0.72552301,5.18235974 C0.52890544,5.18235974 0.358865332,5.23481882 0.21526354,5.33973699 C0.0716617473,5.44465515 -0.000139149024,5.56888964 -0.000139149024,5.71244046 L-0.000139149024,6.77270357 C-0.000139149024,6.91625439 0.0716617473,7.04059055 0.21526354,7.14540704 C0.359004481,7.25032521 0.529044589,7.30288595 0.72552301,7.30288595 L0.72552301,7.30288595 Z"
                      />
                      <path
                        id="Shape"
                        d="M0.72552301,2.78461559 L13.7854938,2.78461559 C13.9819722,2.78461559 14.1521515,2.73215651 14.2958924,2.62723835 C14.4393551,2.52232019 14.5111559,2.39808569 14.5111559,2.25453487 L14.5111559,1.19427177 C14.5111559,1.05072095 14.4393551,0.926486455 14.2958924,0.821466626 C14.1521515,0.716650128 13.9819722,0.664089381 13.7854938,0.664089381 L0.72552301,0.664089381 C0.52890544,0.664089381 0.358865332,0.716650128 0.21526354,0.821466626 C0.0716617473,0.92638479 -0.000139149024,1.05072095 -0.000139149024,1.19427177 L-0.000139149024,2.25453487 C-0.000139149024,2.39808569 0.0716617473,2.52232019 0.21526354,2.62723835 C0.359004481,2.73215651 0.529044589,2.78461559 0.72552301,2.78461559 L0.72552301,2.78461559 Z"
                      />
                      <path
                        id="Shape"
                        d="M20.1003548,14.3758445 C19.956753,14.2709263 19.7865737,14.2184672 19.5900953,14.2184672 L0.72552301,14.2184672 C0.52890544,14.2184672 0.358865332,14.2709263 0.21526354,14.3758445 C0.0716617473,14.4807626 -0.000139149024,14.6049971 -0.000139149024,14.7485479 L-0.000139149024,15.808811 C-0.000139149024,15.9523619 0.0716617473,16.076698 0.21526354,16.1817179 C0.359004481,16.2864327 0.529044589,16.3389934 0.72552301,16.3389934 L19.5900953,16.3389934 C19.7865737,16.3389934 19.956753,16.2864327 20.1003548,16.1817179 C20.2439566,16.076698 20.3157575,15.9523619 20.3157575,15.808811 L20.3157575,14.7485479 C20.3157575,14.6049971 20.2439566,14.4807626 20.1003548,14.3758445 L20.1003548,14.3758445 Z"
                      />
                      <path
                        id="Shape"
                        d="M0.72552301,11.8208675 L15.2368181,11.8208675 C15.4332965,11.8208675 15.6034758,11.7683067 15.7469384,11.6633886 C15.8905402,11.5585721 15.9624803,11.4342359 15.9624803,11.2906851 L15.9624803,10.2305237 C15.9624803,10.0870745 15.8905402,9.96263668 15.7469384,9.85782018 C15.6034758,9.75290202 15.4332965,9.70034127 15.2368181,9.70034127 L0.72552301,9.70034127 C0.52890544,9.70034127 0.358865332,9.75290202 0.21526354,9.85782018 C0.0716617473,9.96263668 -0.000139149024,10.0870745 -0.000139149024,10.2305237 L-0.000139149024,11.2907868 C-0.000139149024,11.4343376 0.0716617473,11.5586737 0.21526354,11.6634902 C0.359004481,11.7684084 0.529044589,11.8208675 0.72552301,11.8208675 L0.72552301,11.8208675 Z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TextIcon',
};
</script>

<style scoped>

</style>
