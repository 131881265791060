var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", [
    _c(
      "section",
      { staticClass: "group-tab primary-content", attrs: { id: "group" } },
      [
        _c("div", { staticClass: "wrap-12" }, [
          _c("div", { staticClass: "back_link tab__back-button" }, [
            _c(
              "a",
              {
                staticClass: "gallery__back-to",
                attrs: { href: "#" },
                on: { click: _vm.backToPlaylist },
              },
              [
                _c("span", { staticClass: "gallery__back-to-icon" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "9px",
                        height: "17px",
                        viewBox: "0 0 9 17",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("title", [_vm._v("Shape")]),
                      _vm._v(" "),
                      _c("desc", [_vm._v("Created with Sketch.")]),
                      _vm._v(" "),
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "Page-1",
                            stroke: "none",
                            "stroke-width": "1",
                            fill: "none",
                            "fill-rule": "evenodd",
                          },
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "Project-Gallery-Browser---med/tablet",
                                transform: "translate(-23.000000, -204.000000)",
                                fill: "#00A886",
                              },
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "back-to-profile-copy",
                                    transform:
                                      "translate(23.000000, 203.000000)",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      id: "Shape",
                                      d: "M12.4801154,11.237282 L5.5086642,5.27180751 C5.22997992,5.04574032 4.90456303,4.93270673 4.53280847,4.93270673 C4.15381364,4.93270673 3.83208271,5.04574032 3.56748402,5.27180751 L-3.41463012,11.237282 C-3.68633743,11.4693101 -3.82219108,11.747339 -3.82219108,12.0711437 C-3.82219108,12.4010219 -3.68620578,12.6759016 -3.41463012,12.8957828 L-2.61030279,13.5829821 C-2.35281273,13.8151227 -2.0310818,13.9313055 -1.64497835,13.9313055 C-1.2588749,13.9313055 -0.937143967,13.8150103 -0.679653907,13.5829821 L4.53280847,9.13868114 L9.74527084,13.5828696 C10.0026293,13.8148978 10.3243602,13.9311931 10.7107269,13.9311931 C11.0894585,13.9311931 11.415007,13.8148978 11.6865827,13.5828696 L12.4907783,12.8956704 C12.755377,12.6696032 12.887808,12.3947235 12.887808,12.0710313 C12.887808,11.7472266 12.7519544,11.4691977 12.4801154,11.237282 L12.4801154,11.237282 Z",
                                      transform:
                                        "translate(4.532808, 9.432006) rotate(-90.000000) translate(-4.532808, -9.432006) ",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "gallery__back-to-text" }, [
                  _vm._v("Back to Playlist"),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.$groupUser().can("manage_group")
            ? _c("div", [
                _vm._m(0),
                _vm._v(" "),
                _c("div", {}, [
                  _c("p", [
                    _vm._v(
                      'Select "Clear Playlist" to remove all projects at once, or you can change the playlist order and remove individual projects below.'
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "button",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "preven",
                              undefined,
                              $event.key,
                              undefined
                            )
                          ) {
                            return null
                          }
                          $event.stopPropagation()
                          return _vm.clearPlaylist($event)
                        },
                      },
                    },
                    [_vm._v("Clear Playlist")]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { attrs: { id: "playlist_update_message" } }),
                _vm._v(" "),
                _c("form", [
                  _c("input", {
                    attrs: { type: "hidden", name: "_method", value: "PUT" },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "items-container",
                      attrs: { id: "playlist_card_management" },
                    },
                    [
                      _vm.projects && _vm.projects.length > 0
                        ? _vm._l(_vm.projects, function (project, index) {
                            return _c(
                              "div",
                              {
                                key: project.id,
                                staticClass: "card playlist-card",
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-arrows",
                                  attrs: { "aria-hidden": "true" },
                                }),
                                _vm._v(" "),
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(project.imgix),
                                  },
                                }),
                                _vm._v(" "),
                                _c("h2", { staticClass: "small-card" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(project.title) +
                                      "\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  staticClass: "project_order",
                                  attrs: {
                                    type: "hidden",
                                    name: "projects[" + project.id + "]",
                                  },
                                  domProps: { value: index },
                                }),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "remove-from-group",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return _vm.removeFromGroup(project.id)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v("Remove from Group "),
                                    _c("i", {
                                      staticClass: "fa fa-minus-square",
                                      attrs: { "aria-hidden": "true" },
                                    }),
                                  ]
                                ),
                              ]
                            )
                          })
                        : _c("span", [
                            _vm._v(
                              "\n              There are no projects in this playlist.\n            "
                            ),
                          ]),
                    ],
                    2
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header__intro" }, [
      _c("h1", { staticClass: "header__admin__text__title title--no-margin" }, [
        _vm._v("\n            Manage Playlist\n          "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }