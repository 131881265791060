var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", [
    _c(
      "a",
      {
        staticClass: "filter tag",
        class: _vm.filterClass,
        attrs: { href: "#" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            $event.preventDefault()
            return _vm.filterCategory($event)
          },
        },
      },
      [
        _vm._v("\n    " + _vm._s(_vm.category.categoryName) + " ("),
        _c("span", { staticClass: "count" }, [
          _vm._v(_vm._s(_vm.currentCount)),
        ]),
        _vm._v(")\n  "),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }