<template>
  <span>
    <slot v-bind="{ handleUpload, upload, handleFileChange, file, isValid }" />
  </span>
</template>

<script>
import { AssetTypes } from '../../common/AssetTypes';

export default {
  name: 'UploadManager',
  props: {
    type: {
      type: [String, Number],
      default: 'none',
    },
    errorClearFn: {
      type: Function,
      default() {
        return () => {};
      },
    },
  },
  data() {
    return {
      file: null,
      upload: {
        active: false,
        percent: 0,
        direct_upload_id: 0,
      },
    };
  },
  computed: {
    getUploadCredentials() {
      return laroute.route('direct.upload.credentials');
    },
    isValid() {
      if (this.type === AssetTypes.Text.name || this.type === AssetTypes.Text.id) {
        return true;
      }
      return !!this.file || this.upload.percent === 100;
    },
  },
  methods: {
    handleFileChange(e) {
      this.errorClearFn();
      const files = e.target.files || e.transfer.files;

      if (!files.length) {
        return;
      }

      this.readFile(files[0]);
    },
    readFile(file) {
      this.file = file;
    },
    async handleUpload() {
      if (!this.file || this.upload.active) {
        return { upload: this.upload, errors: null };
      }

      this.upload.active = true;
      this.upload.percent = 0;
      this.upload.direct_upload_id = 0;

      const query = {
        file_size: this.file.size,
        file_type: this.file.type,
        file_name: this.file.name,
        file_ext: this.file.name.split('.').reverse()[0],
        type: this.type,
      };

      try {
        const { data: { url, direct_upload_id } } = await axios.post(this.getUploadCredentials, query);

        await axios.put(url, this.file, {
          headers: {
            'Content-Type': this.file.type,
          },
          onUploadProgress: (progressEvent) => {
            this.upload.percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          },
          withCredentials: false,
        });

        this.upload.active = false;
        this.upload.direct_upload_id = direct_upload_id;

        this.file = null;

        return { upload: this.upload };
      } catch (e) {
        this.upload.active = false;

        if (e.response && e.response.data) {
          return { errors: e.response.data.errors, upload: this.upload };
        }

        return { errors: e, upload: this.upload };
      }
    },
  },
};
</script>

<style scoped>

</style>
