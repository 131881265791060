// asset methods use in various files
export default {

  data() {
    return {
      modal: '',
    };
  },

  methods: {
    // function to close modal by id
    closeModal(modal) {
      this.modal = modal;
      $('body').removeClass('modal__global--active');
      $(modal).prop('checked', false).change();
      $(modal).parent().attr('aria-hidden', 'true');
      bus.$emit('close:modal');
      return false;
    },

    // opens modal correlating to evidence type (text,video,image)
    openModal(modal) {
      this.modal = modal;
      this.setupModal();
      $('body').addClass('modal__global--active');
      $(modal).prop('checked', true).change();
      $(modal).parent().attr('aria-hidden', 'false');
      // noinspection JSDeprecatedSymbols
      $('.modal-close').focus();
      return false;
    },

    setupModal() {
      $(this.modal).on('change', function setupModalOnChange() {
        if ($(this).is(':checked')) {
          // console.log('we are open');
        } else {
          bus.$emit('close:modal');
        }
      });
    },

  },
};
