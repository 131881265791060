<template>
  <div
    v-if="badges.length > 0"
    class="modal modal__global"
  >
    <input
      id="badge-award-modal"
      class="modal-state"
      type="checkbox"
    >
    <div class="modal-fade-screen">
      <div class="modal-inner">
        <div class="modal__header">
          <button
            id="close-button"
            class="modal-close"
            for="badge-award-modal"
            aria-label="Close this dialog window"
            @click="closeAwardModal"
          />
          <h2>Award Badge</h2>
        </div>

        <div class="modal__content">
          <template v-if="!award.user.of_age">
            <h3>This user is under 13 and is not able to earn badges yet.</h3>
            <p>
              Mouse badges are shared via <a
                href="https://credly.com"
                target="_blank"
              ><span class="sr-only">(opens in a new tab)</span>credly.com</a>, a service that gives users the option to display their
              badges publicly. COPPA regulations require that users be at least 13 to share information publicly.
              <a
                href="https://tools.mouse.org/help/badges"
                target="_blank"
              ><span class="sr-only">(opens in a new tab)</span>Learn more about Badges here</a>.
            </p>
          </template>
          <template v-else-if="!award.user.can_award">
            <h3>This badge is pending. The user must opt-in to earn badges first.</h3>
            <p>
              Users who are 13 or older must go to their Profile page and select "Edit Account Settings" to opt-in
              to earn badges. <a href="https://tools.mouse.org/badge-directory/">Learn more about Badges here</a>.
            </p>
          </template>
          <template v-else>
            <div v-if="awardComplete">
              <h3>Badge <strong>{{ award.badge.title }}</strong> has been awarded to {{ award.user.profile.short_name }}</h3>
            </div>

            <div v-if="awardError">
              <h3 v-html="awardErrorText" />
            </div>

            <div v-if="!awardComplete || !awardError">
              <p><strong>User:</strong> {{ award.user.profile.short_name }}</p>
              <p><strong>Badge:</strong> {{ award.badge.title }}</p>

              <label class="terms__label">Terms</label>
              <div class="modal__terms small full">
                By awarding a badge to this student, the badge information will be added to the student's account
                on <a
                  href="https://credly.com"
                  target="_blank"
                ><span class="sr-only">(opens in a new tab)</span>Credly</a>.
                Mouse has no control of that website, and your use of <a
                  href="https://credly.com"
                  target="_blank"
                ><span class="sr-only">(opens in a new tab)</span>Credly's</a> website is
                subject to the terms of use and privacy policy posted on <a
                  href="https://credly.com"
                  target="_blank"
                ><span class="sr-only">(opens in a new tab)</span>Credly</a>.
                You represent that those students to be awarded badges through <a
                  href="https://credly.com"
                  target="_blank"
                ><span class="sr-only">(opens in a new tab)</span>Credly</a>
                have satisfactorily achieved the requirements for the badge to be awarded, and that the appropriate
                permissions and consents have been obtained from the student and his or her parent or guardian to
                participate in the badge program and use the <a
                  href="https://credly.com"
                  target="_blank"
                ><span class="sr-only">(opens in a new tab)</span>Credly</a> website, including any
                requirements of the Children's Online Protection Act (COPPA) and the Family Educational Rights and Privacy Act (FERPA).
              </div>
              <p>
                <input
                  id="badge-award-terms-check"
                  v-model="agreeToTerms"
                  type="checkbox"
                  value="Y"
                > <label for="badge-award-terms-check"> <strong>Agree To Terms</strong></label>
              </p>
            </div>

            <div class="form-row short-row">
              <div v-if="!awardComplete">
                <button
                  :disabled="!agreeToTerms"
                  type="button"
                  class="button"
                  @click.stop.prevent="awardBadge"
                >
                  Award Badge
                </button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import modalMixin from '../../mixins/modal';

export default {
  name: 'BadgeAwardModal',
  components: {},
  mixins: [modalMixin],
  props: {
    badges: {
      type: Array,
      required: true,
    },
    award: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    awardComplete: false,
    awardError: false,
    awardErrorText: null,
    agreeToTerms: false,
  }),
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    awardBadge() {
      const url = laroute.route('badgeaward.save', {
        award: this.award.id,
      });

      axios.post(url, { agreetoterms: this.agreeToTerms }).then(() => {
        this.closeModal('#badge-award-modal');
        bus.$emit('get-badge-evidence');
      });
    },
    closeAwardModal() {
      this.closeModal('#badge-award-modal');
    },
  },
};
</script>
