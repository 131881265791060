var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "course__row course__bottom-row" }, [
    _c("div", { staticClass: "course__wrapper" }, [
      _c("h2", { staticClass: "row__title" }, [
        _vm._v("\n      Related Projects\n    "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "related_course-description" }, [
        _c("p", [_vm._v(_vm._s(_vm.description))]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "clearfix" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card__list course__project-cards" },
        _vm._l(_vm.projects, function (project) {
          return _c("project-card", {
            key: project.id,
            attrs: {
              project: project,
              "is-in-current-group": _vm.checkProject(project.id),
            },
          })
        }),
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }