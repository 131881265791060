import { render, staticRenderFns } from "./ProjectCard.vue?vue&type=template&id=1f949e5f"
import script from "./ProjectCard.vue?vue&type=script&lang=js"
export * from "./ProjectCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/hotfix-2o9b5/hotfix-create.mouse.org/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1f949e5f')) {
      api.createRecord('1f949e5f', component.options)
    } else {
      api.reload('1f949e5f', component.options)
    }
    module.hot.accept("./ProjectCard.vue?vue&type=template&id=1f949e5f", function () {
      api.rerender('1f949e5f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/projects/ProjectCard.vue"
export default component.exports