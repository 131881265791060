<template>
  <svg
    width="59px"
    height="52px"
    viewBox="0 0 59 52"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Announce</title>
    <defs />
    <g
      id="Project-Work-Tab----Submit-Work-Modal"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      transform="translate(-235.000000, -403.000000)"
    >
      <g
        id="Modal-with-work-added---Gallery-YES"
        transform="translate(210.000000, 297.000000)"
        fill="#00A886"
      >
        <g
          id="Page-1"
          transform="translate(25.000000, 106.000000)"
        >
          <path
            id="Fill-1"
            d="M54.8892892,9.2597318 C54.5525543,8.24531706 53.5700599,7.73913934 52.7061244,8.13452633 L48.0728621,10.2556128 C47.2089265,10.6509998 46.7778356,11.8046237 47.1145706,12.8190384 C47.4513055,13.8330413 48.4032832,14.2473739 49.2672188,13.8519869 L53.9008319,11.7309005 C54.7644167,11.3355135 55.2263749,10.2741465 54.8892892,9.2597318"
          />
          <path
            id="Fill-3"
            d="M58.9903319,17.8865559 C58.9219385,16.7540076 58.0617474,15.9153512 57.2110838,16.0068246 L52.4228681,16.7390639 C51.495985,16.8694813 50.9124297,17.8734236 51.0107664,19.1069548 C51.0788195,20.2395032 51.8631314,21.1175564 52.7896743,20.987139 L57.5778899,20.2544468 C58.4288939,20.1634263 59.0883283,19.12054 58.9903319,17.8865559"
          />
          <path
            id="Fill-5"
            d="M47.5341524,6.64373877 L51.3392664,3.29274734 C52.0306488,2.77588491 52.2335435,1.57183038 51.6871338,0.710392997 C51.1698526,-0.0637259634 50.1701104,-0.214085943 49.4790629,0.303168051 L45.6736141,3.65415948 C44.9370324,4.2931894 44.7793369,5.37507644 45.3257466,6.23651383 C45.8430278,7.01063279 46.7972359,7.28276869 47.5341524,6.64373877"
          />
          <path
            id="Fill-7"
            d="M4.83986045,18 L0.762907562,19.5511506 C0.151579884,19.7840359 -0.156236517,20.468513 0.0791102886,21.0728805 L6.38324758,37.2461781 C6.61859439,37.8505455 7.31171943,38.1543244 7.92340587,37.9217936 L12,36.3706429 L4.83986045,18 Z"
          />
          <path
            id="Fill-10"
            d="M38.9440995,1.18288069 C38.5937091,0.247865419 37.5329986,-0.233962924 36.5867611,0.11227189 L8.03930126,16.358842 C7.37998031,16.7014513 6.75001143,18.1204703 7.10003491,19.0554855 L13.4514567,35.1795139 C13.7420423,35.9557325 14.5231743,36.4114573 15.3215507,36.3483737 L15.3230183,48.8878747 L11.1862104,48.8882373 L11.1865773,52 L18.4721289,51.9992749 L18.4721289,50.4432123 L18.4728627,50.4432123 L18.4713951,35.8092625 L27.4733092,34.3195464 L27.4747768,48.8864245 L27.4751437,51.9985498 L34.7610621,51.9978247 L34.7606952,48.8856994 L30.6242542,48.886062 L30.6227866,33.7982002 L47.8029222,30.0512506 C48.7491597,29.7050158 49.236771,28.6568851 48.8863806,27.7218698 L38.9440995,1.18288069 Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'AnnounceIcon',
};
</script>

<style scoped>

</style>
