<template>
  <div class="checkbox">
    <div class="check-label on">
      Yes
    </div>
    <div class="check-label off">
      No
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckboxLabel',
};
</script>

<style scoped>

</style>
