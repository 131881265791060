<template>
  <select
    class="filter--form--criteria"
    v-bind="$attrs"
    :value="value"
    @change="updateInput"
  >
    <option :value="null">
      Project Name
    </option>
    <option
      v-for="project in projects"
      :key="project.id"
      :value="project.id"
    >
      {{ project.title }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'ProjectSelect',
  inheritAttrs: false,
  props: {
    projects: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: String,
      default() {
        return null;
      },
    },
  },
  methods: {
    updateInput(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>
