<template>
  <div class="card">
    <router-link
      tabindex="-1"
      class="project-image"
      :to="projectUrl"
      v-html="project.imgix"
    />
    <h2>
      <span
        v-if="sortable"
        class="course__project-sort"
      >
        {{ project.sort }}
      </span>
      <router-link :to="projectUrl">
        {{ project.title }}
      </router-link>
    </h2>
    <span
      v-if="project && project.teaser.length > 0"
      class="teaser"
    >{{ project.teaser }}</span>

    <h3 v-if="project && project.curriculum.length > 0">
      Course: {{ project.curriculum }}
    </h3>

    <span
      v-if="project && project.categories.length < 0"
      class="teaser"
    >There are no tags for this project.</span>
    <ul
      v-else
      class="card-tags"
    >
      <li
        v-for="category in categories"
        :key="category.categoryId"
      >
        {{ category.categoryName }}
      </li>
    </ul>

    <span v-if="$groupUser().can('manage_group')">
      <a
        v-if="!addedToCurrentGroup"
        href="#"
        role="button"
        tabindex="0"
        class="add-to-group"
        @click.stop.prevent="addToGroup"
      >
        Add this Project to Playlist <i
          class="fa fa-plus"
          aria-hidden="true"
        />
      </a>
      <span
        v-else
        class="inactive-group-button"
      >
        In Playlist
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'ProjectCard',
  components: {},
  props: {
    project: {
      type: Object,
      required: true,
    },
    isInCurrentGroup: Boolean,
    sortable: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      hasBeenAdded: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.users.current;
    },
    projectUrl() {
      return `/project/${this.project.id}/step/1`;
    },
    addToGroupUrl() {
      return laroute.route('api.group.add.project', {
        id: this.project.id,
      });
    },
    defaultGroupId() {
      return this.currentUser.defaultGroupId;
    },
    categories() {
      return _.orderBy(this.project.categories, (category) => category.categoryName.toString().toLowerCase());
    },
    addedToCurrentGroup() {
      if (this.hasBeenAdded) {
        return true;
      }

      return this.isInCurrentGroup;
    },
  },
  watch: {},
  methods: {
    addToGroup() {
      axios.post(this.addToGroupUrl, {
        group: this.defaultGroupId,
      }).then((response) => {
        if (response.status === 200) {
          this.hasBeenAdded = true;

          this.$store.dispatch('loadMessage', {
            content: `This project has been added to your current group: <strong>${response.data.group.name}</strong>`,
            type: 'success',
            icon: 'plane',
            autoclose: true,
          });
        }
      }).catch(() => {
        this.$store.dispatch('loadMessage', {
          content: 'Unable to add this project to that group',
          type: 'error',
          icon: 'warning',
          autoclose: true,
        });
      });
    },
  },
};
</script>
