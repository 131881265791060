export const AssetTypes = {
  Text: {
    name: 'text',
    id: 1,
  },
  Image: {
    name: 'image',
    id: 2,
  },
  Video: {
    name: 'video',
    id: 3,
  },
  File: {
    name: 'file',
    id: 4,
  },
};
