var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "GroupWorkLayout",
    { attrs: { group: _vm.group } },
    [
      _c(
        "GroupWorkFilterLayout",
        {
          attrs: {
            "clear-filter": _vm.clearFilters,
            "is-filter-selected": _vm.isFilterSelected,
          },
        },
        [
          _c("div", { staticClass: "sr-only" }, [
            _vm._v("\n      Project Name\n    "),
          ]),
          _vm._v(" "),
          _c("ProjectSelect", {
            attrs: { projects: _vm.searchFilters.projects },
            model: {
              value: _vm.form.projectId,
              callback: function ($$v) {
                _vm.$set(_vm.form, "projectId", $$v)
              },
              expression: "form.projectId",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "sr-only" }, [
            _vm._v("\n      Member Name\n    "),
          ]),
          _vm._v(" "),
          _c("UserSelect", {
            attrs: { users: _vm.searchFilters.users },
            model: {
              value: _vm.form.userId,
              callback: function ($$v) {
                _vm.$set(_vm.form, "userId", $$v)
              },
              expression: "form.userId",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "sr-only" }, [
            _vm._v("\n      Review Status\n    "),
          ]),
          _vm._v(" "),
          _c("StatusSelect", {
            attrs: { statuses: _vm.searchFilters.statuses },
            model: {
              value: _vm.form.statusId,
              callback: function ($$v) {
                _vm.$set(_vm.form, "statusId", $$v)
              },
              expression: "form.statusId",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "group-work-table" }, [
        _c("table", { staticClass: "table__paginated" }, [
          _c("thead", [
            _c("tr", [
              _c(
                "th",
                [
                  _c(
                    "GroupWorkTableSort",
                    _vm._b(
                      { attrs: { field: "date" } },
                      "GroupWorkTableSort",
                      {
                        modifySort: _vm.modifySort,
                        sortDir: _vm.form.sortDir,
                        sortBy: _vm.form.sortBy,
                      },
                      false
                    ),
                    [_vm._v("\n              Date\n            ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "th",
                [
                  _c(
                    "GroupWorkTableSort",
                    _vm._b(
                      { attrs: { field: "project_name" } },
                      "GroupWorkTableSort",
                      {
                        modifySort: _vm.modifySort,
                        sortDir: _vm.form.sortDir,
                        sortBy: _vm.form.sortBy,
                      },
                      false
                    ),
                    [_vm._v("\n              Project Name\n            ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "th",
                [
                  _c(
                    "GroupWorkTableSort",
                    _vm._b(
                      { attrs: { field: "submitted_by" } },
                      "GroupWorkTableSort",
                      {
                        modifySort: _vm.modifySort,
                        sortDir: _vm.form.sortDir,
                        sortBy: _vm.form.sortBy,
                      },
                      false
                    ),
                    [_vm._v("\n              Submitted By\n            ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("th", [_vm._v("Collaborators")]),
              _vm._v(" "),
              _c(
                "th",
                [
                  _c(
                    "GroupWorkTableSort",
                    _vm._b(
                      { attrs: { field: "comments" } },
                      "GroupWorkTableSort",
                      {
                        modifySort: _vm.modifySort,
                        sortDir: _vm.form.sortDir,
                        sortBy: _vm.form.sortBy,
                      },
                      false
                    ),
                    [_vm._v("\n              Comments\n            ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "th",
                [
                  _c(
                    "GroupWorkTableSort",
                    _vm._b(
                      { attrs: { field: "reviewed" } },
                      "GroupWorkTableSort",
                      {
                        modifySort: _vm.modifySort,
                        sortDir: _vm.form.sortDir,
                        sortBy: _vm.form.sortBy,
                      },
                      false
                    ),
                    [_vm._v("\n              Reviewed\n            ")]
                  ),
                ],
                1
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            [
              _vm._l(_vm.results.evidence, function (evidence) {
                return _c("GroupWorkTableRow", {
                  key: evidence.id,
                  attrs: {
                    "reload-fn": _vm.fetchGroupWork,
                    evidence: evidence,
                  },
                })
              }),
              _vm._v(" "),
              _vm.results.evidence.length === 0 && !_vm.results.loading
                ? _c("tr", [
                    _c("td", { attrs: { colspan: "6" } }, [
                      _c("div", { staticStyle: { "text-align": "center" } }, [
                        _vm._v(
                          "\n              No results found for this group with these filters\n            "
                        ),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.results.loading && _vm.results.evidence.length < 1
                ? _c("tr", [
                    _c("td", { attrs: { colspan: "6" } }, [
                      _c("div", { staticStyle: { "text-align": "center" } }, [
                        _c("h2", [_vm._v("Group work loading now...")]),
                        _vm._v(" "),
                        _c("p", [
                          _c("img", {
                            attrs: {
                              src: "/img/gear-animation-115.gif",
                              alt: "Group work loading",
                            },
                          }),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("GroupWorkPagination", {
        attrs: { "last-page": _vm.paginator.lastPage },
        model: {
          value: _vm.paginator.page,
          callback: function ($$v) {
            _vm.$set(_vm.paginator, "page", $$v)
          },
          expression: "paginator.page",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }