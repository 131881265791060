var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal modal__global" }, [
    _c("input", {
      staticClass: "modal-state",
      attrs: { id: "badge-student-modal", type: "checkbox" },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "modal-fade-screen" }, [
      _c("div", { staticClass: "modal-inner" }, [
        _c("div", { staticClass: "modal__header" }, [
          _c("button", {
            staticClass: "modal-close",
            attrs: {
              id: "close-button",
              for: "badge-student-modal",
              "aria-label": "Close this dialog window",
            },
            on: {
              click: function ($event) {
                return _vm.closeModal("#badge-student-modal")
              },
            },
          }),
          _vm._v(" "),
          _c("h2", [_vm._v("Badge Details")]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modal__content" }, [
          !_vm.award.user.of_age
            ? _c("h3", [
                _vm._v(
                  "\n            This badge has not yet been awarded\n          "
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }