var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("footer", { staticClass: "footer" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "footer-content" }, [
          _c("div", { staticClass: "branding" }, [
            _c("div", { staticClass: "top-row" }, [
              _c("div", { staticClass: "logo" }, [
                _c("img", {
                  attrs: {
                    alt: "Mouse Create",
                    src: "/img/mouse-create-logo.png",
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "legal" }, [
              _c(
                "a",
                {
                  attrs: { href: "https://tools.mouse.org", target: "_blank" },
                },
                [_vm._v("Help   |  ")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://mouse.org/privacy-policy",
                    target: "_blank",
                  },
                },
                [_vm._v("Privacy Policy")]
              ),
              _vm._v("\n            |  \n          "),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://mouse.org/terms-of-use",
                    target: "_blank",
                  },
                },
                [_vm._v("Terms of Use")]
              ),
              _vm._v("\n            |  \n          "),
              _c(
                "a",
                {
                  attrs: {
                    href: "https://mouse.org/mouse-create-code-of-conduct",
                    target: "_blank",
                  },
                },
                [_vm._v("Code of Conduct")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "affiliate" }, [
            _c("a", { attrs: { href: "https://mouse.org" } }, [
              _c("img", {
                attrs: { alt: "Visit mouse.org", src: "/img/gear.svg" },
              }),
              _vm._v("Visit mouse.org"),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }