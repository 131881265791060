<template>
  <tabs
    :nav-tabs="tabs"
    :options="tabOptions"
  />
</template>

<script>

import Tabs from '../tabs/Tabs.vue';

export default {
  name: 'ProjectShowTabs',
  components: { Tabs },
  props: {},
  data() {
    const pathname = window.location.pathname.replace('/project', '');
    const paths = pathname.split('/');
    const last = _.last(paths);

    return {
      tabs: [
        {
          title: 'Plan',
          component: 'project-plan',
          active: (last === 'plan'),
          visible: false,
          checkOwnership: true,
        },
        {
          title: 'Steps',
          component: 'project-steps',
          active: !!((paths.indexOf('step') !== -1 || !Number.isNaN(parseInt(`${last}`, 10)))),
          visible: false,
          checkOwnership: true,
        },
        {
          title: 'Submit Work',
          component: 'project-add-work',
          active: (last === 'submit'),
          visible: false,
          checkOwnership: true,
        },
      ],
      tabOptions: {
        groupId: this.groupId,
      },
      isLoading: true,
      owner: false,
    };
  },
  computed: {},
  watch: {
    $route(to) {
      if (to.name === 'project-show-tabs') {
        this.$router.push({
          name: 'project-steps',
          params: {
            id: this.$route.params.id,
            step: '1',
          },
        });
      }
      if (to.name === 'project-add-work') {
        bus.$emit('set-active-tab', 'project-add-work');
      }
      if (to.name === 'project-steps') {
        if (!to.params.step) {
          this.$router.push({
            name: 'project-steps',
            params: {
              id: this.$route.params.id,
              step: '1',
            },
          });
        }
        bus.$emit('set-active-tab', 'project-steps');
      }
      if (to.name === 'project-plan') {
        bus.$emit('set-active-tab', 'project-plan');
      }
    },
  },
  created() {
    bus.$on('disable:tabs', this.removeTabs);
    bus.$on('enable:tabs', this.activateAllTabs);
  },
  mounted() {},
  methods: {
    removeTabs() {
      this.tabs.forEach((tab) => {
        tab.visible = false;
      });
    },
    activateAllTabs() {
      this.tabs.forEach((tab) => {
        tab.visible = true;
      });
    },
  },
};
</script>
