<template>
  <div class="modal__callout">
    Accepted file types: jpeg,jpg,bmp,png,gif,heic
  </div>
</template>

<script>
export default {
  name: 'ImageLabel',
};
</script>

<style scoped>

</style>
