var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "news__card" }, [
    _c("div", { staticClass: "news__card-image" }, [
      _vm.isFeatured
        ? _c("div", {
            staticClass: "news__featured-label",
            domProps: { textContent: _vm._s(_vm.news.articleFeatured) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isFeatured
        ? _c("div", { staticClass: "news__featured-star" }, [
            _c(
              "svg",
              {
                attrs: {
                  width: "69px",
                  height: "62px",
                  viewBox: "0 0 69 62",
                  xmlns: "http://www.w3.org/2000/svg",
                },
              },
              [
                _c("defs"),
                _vm._v(" "),
                _c(
                  "g",
                  {
                    attrs: {
                      id: "Page-1",
                      stroke: "none",
                      "stroke-width": "1",
                      fill: "none",
                      "fill-rule": "evenodd",
                    },
                  },
                  [
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "Create-HOME-final",
                          transform: "translate(-1122.000000, -2097.000000)",
                          fill: "#00A886",
                        },
                      },
                      [
                        _c(
                          "g",
                          {
                            attrs: {
                              id: "Feat-News",
                              transform: "translate(-3.000000, 1764.000000)",
                            },
                          },
                          [
                            _c(
                              "g",
                              {
                                attrs: {
                                  id: "News-block-3---featured-example",
                                  transform:
                                    "translate(864.000000, 103.000000)",
                                },
                              },
                              [
                                _c(
                                  "g",
                                  {
                                    attrs: {
                                      id: "featured-label-and-icon",
                                      transform:
                                        "translate(13.000000, 16.000000)",
                                    },
                                  },
                                  [
                                    _c("path", {
                                      attrs: {
                                        id: "Fill-1-Copy-3",
                                        d: "M308.53744,233.327801 L288.729469,238.855166 L289.402122,219.949781 C289.618499,215.280731 286.725623,214 282.501553,214 C278.277484,214 275.389311,215.280731 275.610393,219.949781 L276.278341,238.855166 L256.475074,233.327801 C252.246301,232.051564 249.569802,233.759206 248.45969,237.794631 C247.354282,242.041265 248.022231,245.011662 252.909545,246.081185 L272.491731,249.253802 L259.819522,265.835896 C257.364105,269.021995 258.032053,271.992392 261.588174,274.32467 C265.148999,276.665936 268.262957,276.881638 270.934752,272.841719 L282.501553,255.423779 L294.068355,272.841719 C296.740149,276.881638 299.849403,276.665936 303.414932,274.32467 C306.975757,271.992392 307.639002,269.021995 305.183585,265.835896 L292.501968,249.253802 L312.088857,246.081185 C316.98558,245.011662 317.648825,242.041265 316.534009,237.794631 C315.428601,233.759206 312.76151,232.051564 308.53744,233.327801 Z",
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "image__block" }, [
        _c("img", {
          attrs: {
            src: _vm.imageSource,
            srcset: _vm.imageSrcset,
            alt: _vm.altTag,
            sizes: "(min-width: 768px) 50vw, (min-width: 1024px) 33vw, 100vw",
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "news__card-content" }, [
      _c("div", {
        staticClass: "news__card-date",
        domProps: { textContent: _vm._s(_vm.date) },
      }),
      _vm._v(" "),
      _c("h2", {
        staticClass: "news__card-title",
        domProps: { textContent: _vm._s(_vm.news.articleTitle) },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "news__card-text" }, [
        _c("span", { domProps: { innerHTML: _vm._s(_vm.summary) } }),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "news__card-action",
            attrs: { href: _vm.news.articleUrl, target: "_blank" },
          },
          [_vm._v("Read more >")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }