<template>
  <!-- Modal award start -->
  <div class="modal modal__global">
    <input
      id="badge-student-modal"
      class="modal-state"
      type="checkbox"
    >
    <div class="modal-fade-screen">
      <div class="modal-inner">
        <div class="modal__header">
          <button
            id="close-button"
            class="modal-close"
            for="badge-student-modal"
            aria-label="Close this dialog window"
            @click="closeModal('#badge-student-modal')"
          />
          <h2>Badge Details</h2>
        </div>

        <div class="modal__content">
          <h3 v-if="!award.user.of_age">
            This badge has not yet been awarded
          </h3>
        </div>
      </div>
    </div>
  </div>
<!-- Modal award end -->
</template>

<script>

import modalMixin from '../../mixins/modal';

export default {
  name: 'BadgeStudentModal',
  mixins: [modalMixin],
  props: {
    award: {
      type: Object,
      required: true,
    },
  },
};
</script>
