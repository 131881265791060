<template>
  <main>
    <group-delete-modal
      :group-name="groupName"
      :group="group"
    />

    <section class="group-tab primary-content">
      <div class="group-fixed-container">
        <div class="header__admin header__admin--no-rule">
          <div class="header__admin__text">
            <h1 class="header__admin__text__title">
              Edit Group: {{ groupName }}
            </h1>
          </div>
        </div>

        <div v-if="editMode">
          <form
            class="simple-edit"
            method="post"
          >
            <input
              type="hidden"
              name="_method"
              value="PUT"
            >
            <div class="form-row">
              <label for="name">
                Change your Group's name <span>* required</span></label>
              <input
                id="name"
                v-model="groupName"
                type="text"
                name="name"
                placeholder="Group Name"
              >
              <div
                v-if="errors.first('name')"
                class="form-error form-row"
              >
                <span><strong>{{ errors.first('name') }}</strong></span>
              </div>
            </div>

            <div class="form-row align-right">
              <button
                type="submit"
                class="button"
                @click.stop.prevent="editGroup"
              >
                Save Changes
              </button>
              <span class="cancel">or <router-link :to="{ name: 'group-playlist', params: {id: group.id} }">cancel</router-link></span>
            </div>
          </form>
        </div>

        <div class="header__intro">
          <h2 class="header__intro__title">
            Delete this Group
          </h2>
        </div>

        <div>
          <p>
            <strong>Warning: </strong>Before deleting a group it is a good idea to review any work and award any pending badges. Deleting a group is permanent. Student connections to that group will also be deleted, and any students who are only in that group will no longer have full access to Mouse Create until they join another group.
          </p>

          <p>
            Student work that was submitted to a deleted group is still available on their profile and in the Project Gallery (if they added it), however, unreviewed work is no longer available to be reviewed. Pending badges in a deleted group are not awardable.
          </p>
          <p>
            <button @click.stop.prevent="openDeleteModal">
              Delete
            </button>
          </p>
        </div>
      </div>
    </section>
  </main>
</template>

<script>

import modalMixin from '../../mixins/modal';
import GroupDeleteModal from './GroupDeleteModal.vue';

export default {
  name: 'GroupEdit',
  components: {
    GroupDeleteModal,
  },
  mixins: [modalMixin],
  data() {
    return {
      groupName: '',
      previousName: '',
      deleteModal: '#delete-group-modal',
      editMode: true,
    };
  },
  computed: {
    submitEditRoute() {
      return laroute.route('api.groups.update', { group: this.group.id });
    },
    group() {
      return this.$store.state.groups.active;
    },
  },
  watch: {
    group() {
      this.setGroup();
    },
  },
  mounted() {
    this.setGroup();
  },
  methods: {
    editGroup() {
      axios.post(this.submitEditRoute, {
        _method: 'PUT',
        name: this.groupName,
      }).then((response) => {
        const group = response.data;

        this.$store.dispatch('setGroupByResponse', group);
        this.editMode = false;
        this.$router.push({ name: 'group-playlist', params: { id: group.id } });
        bus.$emit('set-active-tab', 'playlist');
      }).catch((error) => {
        const message = error.data.errors.name[0];

        this.errors.clear();

        this.errors.add({
          field: 'name',
          msg: message,
        });

        this.groupName = this.previousName;
      });
    },

    openDeleteModal() {
      this.openModal(this.deleteModal);
    },

    setGroup() {
      this.groupName = this.group.name;
      this.previousName = this.group.name;
    },
  },
};
</script>
