var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isExpired
    ? _c("div", { staticClass: "no-active-group" }, [_vm._m(0)])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("\n    Your site’s membership has expired! "),
      _c(
        "a",
        { attrs: { href: "https://mouse.org/renew", target: "_blank" } },
        [_vm._v("Click here for help renewing your membership")]
      ),
      _vm._v(".\n  "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }