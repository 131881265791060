var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        "aria-labelledby": "navGroupTitle navGroupDesc",
        focusable: "false",
        height: "57",
        viewBox: "0 0 46 57",
        width: "46",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("title", { attrs: { id: "navGroupTitle" } }, [_vm._v("Group")]),
      _vm._v(" "),
      _c("desc", { attrs: { id: "navGroupDesc" } }, [
        _vm._v("A group of people"),
      ]),
      _vm._v(" "),
      _c("g", { attrs: { fill: "none" } }, [
        _c("path", {
          staticClass: "icon",
          attrs: {
            d: "M5.7 43.8C2.8 43.8 0.6 45.9 0.6 49 0.6 52 2.8 54.2 5.7 54.2 7.2 54.2 8.3 53.5 9 52.5L9 54 10.3 54 10.3 48.8 5.1 48.8 5.1 50 9 50C8.8 51.7 7.6 52.8 5.8 52.8 3.6 52.8 2 51.2 2 49 2 46.7 3.6 45.1 5.7 45.1 7 45.1 8 45.7 8.7 46.8L9.9 46.1C9 44.7 7.8 43.8 5.7 43.8ZM16 46.3C15.8 46.3 15.7 46.3 15.5 46.3 14.5 46.3 13.7 46.9 13.3 47.9L13.3 46.4 12 46.4 12 54 13.3 54 13.3 50.6C13.3 49.9 13.4 49.4 13.4 49.2 13.7 48.2 14.3 47.6 15.4 47.6 15.6 47.6 15.8 47.6 16 47.7L16 46.3ZM16.7 50.2C16.7 47.9 18.4 46.3 20.6 46.3 22.8 46.3 24.5 47.9 24.5 50.2 24.5 52.4 22.8 54.2 20.6 54.2 18.4 54.2 16.7 52.5 16.7 50.2ZM18.1 50.2C18.1 51.8 19.2 52.9 20.6 52.9 22.1 52.9 23.2 51.7 23.2 50.2 23.2 48.7 22.1 47.5 20.6 47.5 19.2 47.5 18.1 48.7 18.1 50.2ZM28.5 54.2C29.5 54.2 30.3 53.6 30.7 52.9L30.7 54 32 54 32 46.4 30.7 46.4 30.7 50.5C30.7 52.1 30.2 52.9 28.8 52.9 27.6 52.9 27.3 52.2 27.3 50.7L27.3 46.4 26 46.4 26 50.7C26 51.5 26 51.9 26.1 52.3 26.3 53.4 27.2 54.2 28.5 54.2ZM40.1 50.2C40.1 48.7 39.1 47.5 37.7 47.5 36.2 47.5 35.2 48.5 35.2 50.2 35.2 51.9 36.3 53 37.7 53 39.1 53 40.1 51.8 40.1 50.2ZM41.4 50.2C41.4 52.6 39.9 54.2 37.9 54.2 36.7 54.2 35.8 53.6 35.3 52.8L35.3 56.4 34 56.4 34 46.4 35.3 46.4 35.3 47.7C35.8 46.9 36.7 46.3 37.9 46.3 39.8 46.3 41.4 47.9 41.4 50.2Z",
            fill: "#E7E7E7",
          },
        }),
        _vm._v(" "),
        _c("g", { attrs: { fill: "#636366" } }, [
          _c("path", {
            staticClass: "icon",
            attrs: {
              d: "M15.5 4.7C15.8 4.3 16.2 4 16.6 3.8L15.1 1.2C14.9 0.9 14.6 0.7 14.2 0.7L7.9 0.7C7.5 0.7 7.2 0.9 7 1.2L3.8 6.7C3.6 7 3.6 7.4 3.8 7.8L7 13.2C7.2 13.6 7.5 13.8 7.9 13.8L12.7 13.8C12.6 13.1 12.5 12.5 12.5 11.9 12.5 9.1 13.7 6.5 15.5 4.7",
            },
          }),
          _vm._v(" "),
          _c("path", {
            staticClass: "icon",
            attrs: {
              d: "M10.9 23.1C12.4 21.6 14.4 20.5 16.6 20 16.2 19.7 15.8 19.4 15.5 19.1 14.5 18.1 13.8 16.9 13.2 15.6L8.3 15.6C3.7 15.6 0 19.3 0 23.7L0 27.6 8 27.6C8.1 27.3 8.2 27 8.3 26.7 8.9 25.4 9.8 24.1 10.9 23.1",
            },
          }),
          _vm._v(" "),
          _c("path", {
            staticClass: "icon",
            attrs: {
              d: "M45.5 23.7C45.5 19.3 41.7 15.6 37.1 15.6L32.2 15.6C31.7 16.9 30.9 18.1 30 19.1 29.6 19.4 29.3 19.7 28.9 20 30.4 20.3 31.7 20.9 32.9 21.7 34.8 22.9 36.3 24.7 37.2 26.7 37.4 27 37.5 27.3 37.6 27.6L45.5 27.6 45.5 23.7Z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            staticClass: "icon",
            attrs: {
              d: "M31.7 31.3L13.9 31.3 13.9 31.2C13.9 28.4 16.3 26.2 19.2 26.2L26.4 26.2C29.3 26.2 31.7 28.4 31.7 31.2L31.7 31.3ZM26.4 21.7L19.2 21.7C13.8 21.7 9.4 26 9.4 31.2L9.4 35.7 36.1 35.7 36.1 31.2C36.1 26 31.7 21.7 26.4 21.7L26.4 21.7Z",
            },
          }),
          _vm._v(" "),
          _c("path", {
            staticClass: "icon",
            attrs: {
              d: "M22.7 19.6C27 19.6 30.5 16.1 30.5 11.9 30.5 7.6 27 4.1 22.7 4.1 18.4 4.1 15 7.6 15 11.9 15 16.1 18.4 19.6 22.7 19.6",
            },
          }),
          _vm._v(" "),
          _c("path", {
            staticClass: "text",
            attrs: {
              d: "M38.3 0.7L30.2 0.7C28.8 0.7 27.8 1.8 27.8 3.1L27.8 3.2C30.9 5 33 8.3 33 12 33 12.6 32.9 13.1 32.8 13.6L38.3 13.6C39.6 13.6 40.7 12.5 40.7 11.2L40.7 3.1C40.7 1.8 39.6 0.7 38.3 0.7",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }