<template>
  <li>
    <a
      href="#"
      class="filter tag"
      :class="filterClass"
      @click.stop.prevent="filterCategory"
    >
      {{ category.categoryName }} (<span class="count">{{ currentCount }}</span>)
    </a>
  </li>
</template>

<script>
export default {
  name: 'ProjectFilterLink',
  components: {},
  props: {
    category: {
      type: Object,
      default() {
        return {};
      },
    },
    categoryCounts: {
      type: [Object, Array],
      default() {
        return {};
      },
    },
    activeCategories: {
      type: [Object, Array],
      default() {
        return {};
      },
    },
  },
  computed: {
    currentCount() {
      return (this.categoryCounts[this.category.categoryId])
        ? this.categoryCounts[this.category.categoryId] : 0;
    },
    filterClass() {
      return {
        active: this.activeCategories.indexOf(this.category.categoryId) > -1,
        empty: !this.currentCount,
      };
    },
  },
  watch: {},
  mounted() {},
  methods: {
    filterCategory() {
      // If not in current grouping
      if (!this.currentCount) {
        return;
      }

      bus.$emit('filter-by-category', {
        category: this.category,
      });
    },
  },
};
</script>
