var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "home-slider__item", attrs: { id: _vm.slide.id } },
    [
      _c("div", { staticClass: "slider__image" }, [
        _c("img", {
          attrs: {
            src: _vm.imageSource,
            srcset: _vm.imageSrcset,
            alt: _vm.altTag,
            sizes: "(min-width: 768px) 50vw, 100vw",
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "slider__content" }, [
        _c("h2", { domProps: { innerHTML: _vm._s(_vm.title) } }),
        _vm._v(" "),
        _c("span", { domProps: { innerHTML: _vm._s(_vm.description) } }),
        _vm._v(" "),
        _c("div", { staticClass: "slider__cta" }, [
          _c("span", {
            staticClass: "cta__content",
            domProps: { innerHTML: _vm._s(_vm.slide.slideCtaHeader) },
          }),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "button home__button home_slider-button",
              attrs: { href: _vm.slide.slideCtaUrl, target: "_blank" },
            },
            [
              _c("span", {
                domProps: { textContent: _vm._s(_vm.slide.slideCtaButtonText) },
              }),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "clearfix" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }