var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", [
    !_vm.loaded
      ? _c("div", { staticClass: "response-loading" }, [
          _c("h2", [_vm._v("Members loading now...")]),
          _vm._v(" "),
          _vm._m(0),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.loaded
      ? _c(
          "section",
          { staticClass: "group-tab primary-content" },
          [
            _c("group-header", { attrs: { group: _vm.group } }),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("table", { staticClass: "table__paginated" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", [
                    _c(
                      "span",
                      {
                        staticClass: "sort",
                        class: _vm.sortClasses("first_name"),
                        on: {
                          click: function ($event) {
                            return _vm.sortMe("first_name")
                          },
                        },
                      },
                      [_vm._v("Name (Username)")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Type")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Last Login")]),
                  _vm._v(" "),
                  _c("th", [_vm._v("Actions")]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.filteredMembers, function (member) {
                  return _c("tr", { key: member.id }, [
                    _c(
                      "td",
                      {
                        staticClass: "table__cell__valign-top",
                        attrs: { "data-label": "Name/Email" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "icon__group" },
                          [
                            _c(
                              "router-link",
                              { attrs: { to: _vm.profileView(member.token) } },
                              [
                                _c(
                                  "div",
                                  { staticClass: "icon__group__icon" },
                                  [
                                    _c("span", {
                                      staticClass:
                                        "profile__pic-member profile__pic-small-left",
                                      domProps: {
                                        innerHTML: _vm._s(member.avatar),
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "icon__group__text" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(member.full_name) +
                                        " "
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "table__text__block" },
                                      [
                                        _vm._v(
                                          "(" + _vm._s(member.username) + ")"
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { attrs: { "data-label": "Type" } },
                      [
                        _vm
                          .$groupUser()
                          .canOther(member.groupRole, "manage_group")
                          ? [
                              _c("span", { staticClass: "group-type-badge" }, [
                                _vm._v("Group Manager"),
                              ]),
                            ]
                          : [
                              member.isStudent
                                ? _c("span", [_vm._v("Student")])
                                : _vm._e(),
                              _vm._v(" "),
                              member.isEducator
                                ? _c("span", [_vm._v("Educator")])
                                : _vm._e(),
                              _vm._v(" "),
                              !member.isEducator && !member.isStudent
                                ? _c("span", [_vm._v("General")])
                                : _vm._e(),
                            ],
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("td", { attrs: { "data-label": "Last Login" } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("formatDate")(member.lastLogin, "M/D/YY")
                          ) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "table__actions",
                        attrs: { "data-label": "Actions" },
                      },
                      [
                        _vm.$groupUser().can("manage_group") &&
                        !member.isStudent &&
                        member.isEducator &&
                        member.groupRole !== "creator"
                          ? _c(
                              "span",
                              [
                                _vm
                                  .$groupUser()
                                  .canOther(member.groupRole, "manage_group")
                                  ? [
                                      _vm.currentUser.id !== member.id
                                        ? _c(
                                            "a",
                                            {
                                              attrs: { href: "#" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeGroupManager(
                                                    member
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "Remove as Group Manager "
                                              ),
                                              _c("WrenchIconLight"),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  : [
                                      _c(
                                        "a",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.promoteGroupManager(
                                                member
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v("Make Group Manager "),
                                          _c("WrenchIcon"),
                                        ],
                                        1
                                      ),
                                    ],
                                _vm._v(" "),
                                member.id !== _vm.currentUser.id
                                  ? _c("br")
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.currentUser.isStudent ||
                        !_vm.$groupUser().can("manage_group")
                          ? _c("span", { staticClass: "no__actions" }, [
                              _vm._v("None"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        member.isInActiveGroup
                          ? _c("span", [
                              !_vm.currentUser.isStudent &&
                              !member.isEducator &&
                              _vm.$groupUser().can("manage_group")
                                ? _c("span", [
                                    !_vm
                                      .$groupUser()
                                      .canOther(
                                        member.groupRole,
                                        "manage_group"
                                      )
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "admin__actions--edit",
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.editAccount(
                                                      member.token
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Edit Account")]
                                            ),
                                            _vm._v(" "),
                                            _c("br"),
                                          ]
                                        )
                                      : _vm._e(),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$groupUser().can("manage_group") &&
                        _vm.currentUser.id !== member.id &&
                        member.groupRole !== "creator"
                          ? _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.removeMember(member.id)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Archive user from Group "
                                ),
                                _c("CircleXIcon"),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _vm.pagination.totalPages > 1
              ? _c(
                  "nav",
                  {
                    staticClass: "table__pagination",
                    attrs: {
                      role: "navigation",
                      "aria-label": "Pagination Navigation",
                    },
                  },
                  [
                    _c("div", { staticClass: "table__pagination__prev" }, [
                      _c(
                        "a",
                        {
                          staticClass: "table__pagination__link",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.previous($event)
                            },
                          },
                        },
                        [
                          _vm.pagination.currentPage === 1 ||
                          _vm.pagination.totalPages === 1
                            ? _c("span", [_vm._v(" ")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.pagination.currentPage !== 1
                            ? _c("span", [_vm._v("< previous")])
                            : _vm._e(),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "table__pagination__numbers" }, [
                      _vm.pagination.totalPages > 1
                        ? _c(
                            "ul",
                            { staticClass: "table__pagination__row" },
                            _vm._l(_vm.pagination.totalPages, function (page) {
                              return _c(
                                "li",
                                {
                                  key: page,
                                  staticClass: "table__pagination__list",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "table__pagination__link",
                                      class: {
                                        "table__pagination__link--active":
                                          _vm.pagination.currentPage === page,
                                      },
                                      attrs: {
                                        href: "#",
                                        "aria-label":
                                          "Current Page, Page " + page,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.pageSelected(page)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(page))]
                                  ),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "table__pagination__next" }, [
                      _c(
                        "a",
                        {
                          staticClass: "table__pagination__link",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.next($event)
                            },
                          },
                        },
                        [
                          _vm.pagination.currentPage ===
                            _vm.pagination.totalPages ||
                          _vm.pagination.totalPages === 1
                            ? _c("span", [_vm._v(" ")])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.pagination.currentPage !==
                          _vm.pagination.totalPages
                            ? _c("span", [_vm._v("next >")])
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.$groupUser().can("manage_group")
              ? _c("p", { staticClass: "manage-users" }, [
                  _c(
                    "small",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "group-members-archived",
                              params: { id: _vm.group.id },
                            },
                          },
                        },
                        [
                          _vm._v("Manage archived users "),
                          _c("i", { staticClass: "fa fa-cog" }),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("img", {
        attrs: {
          src: "/img/gear-animation-115.gif",
          alt: "Members loading icon",
        },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header__intro" }, [
      _c("div", { staticClass: "header__intro__title" }, [
        _vm._v("\n        Members\n      "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "header__intro__summary" }, [
        _vm._v(
          "\n        This is everyone in your group. Only Group Managers are able to change the group’s settings. "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "https://tools.mouse.org/group-members",
              target: "_blank",
            },
          },
          [_vm._v("Learn more >")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }