<template>
  <svg
    width="21px"
    height="21px"
    viewBox="0 0 21 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Close X</title>
    <defs />
    <g
      id="Designs"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="square"
    >
      <g
        id="Work-Tab----Edit-Collaborators-Modal-"
        transform="translate(-1032.000000, -61.000000)"
        stroke="#FFFFFF"
        stroke-width="4"
      >
        <g
          id="Edit-Collaborators-Modal-Copy"
          transform="translate(210.000000, 49.000000)"
        >
          <g id="Green-top-area">
            <g
              id="close-X"
              transform="translate(825.000000, 15.000000)"
            >
              <path
                id="Line"
                d="M0.5,0.50472973 L14.6421356,14.7806423"
              />
              <path
                id="Line"
                d="M0.5,0.50472973 L14.6421356,14.7806423"
                transform="translate(7.500000, 7.570946) scale(-1, 1) translate(-7.500000, -7.570946) "
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CloseModalIcon',
};
</script>

<style scoped>

</style>
