var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "21px",
        height: "21px",
        viewBox: "0 0 21 21",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("title", [_vm._v("Close X")]),
      _vm._v(" "),
      _c("defs"),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Designs",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
            "stroke-linecap": "square",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Work-Tab----Edit-Collaborators-Modal-",
                transform: "translate(-1032.000000, -61.000000)",
                stroke: "#FFFFFF",
                "stroke-width": "4",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Edit-Collaborators-Modal-Copy",
                    transform: "translate(210.000000, 49.000000)",
                  },
                },
                [
                  _c("g", { attrs: { id: "Green-top-area" } }, [
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "close-X",
                          transform: "translate(825.000000, 15.000000)",
                        },
                      },
                      [
                        _c("path", {
                          attrs: {
                            id: "Line",
                            d: "M0.5,0.50472973 L14.6421356,14.7806423",
                          },
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            id: "Line",
                            d: "M0.5,0.50472973 L14.6421356,14.7806423",
                            transform:
                              "translate(7.500000, 7.570946) scale(-1, 1) translate(-7.500000, -7.570946) ",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }