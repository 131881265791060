<template>
  <div class="evidence__asset-wrapper">
    <div
      v-for="evidenceAsset in assets"
      v-show="scopedStep ? evidenceAsset.step_id === parseInt(`${scopedStep}`, 10) : true"
      :key="evidenceAsset.id"
      class="evidence__asset-block"
    >
      <div
        v-if="isSubmitWork"
        class="controls"
      >
        <a
          href="#"
          class="control__action control__edit"
          @click.stop.prevent="editEvidence( evidenceAsset )"
        >
          <span class="control__edit-text">Edit</span>
          <span class="control__icon control__edit-icon">
            <edit-icon />
          </span>
        </a>
        <a
          href="#"
          class="control__action control__delete"
          @click.stop.prevent="deleteEvidence( evidenceAsset )"
        >
          <span class="control__delete-text">Delete</span>
          <span class="control__icon control__delete-icon">
            <delete-icon />
          </span>
        </a>
      </div>
      <div
        v-if="evidenceAsset.upload"
        v-show="isImage( evidenceAsset )"
        class="asset-image"
      >
        <img
          :src="evidenceAsset.upload.path_url"
          alt=""
        >
      </div>
      <div
        v-if="evidenceAsset.upload"
        v-show="isFile( evidenceAsset )"
      >
        <a :href="evidenceAsset.upload.url">Download File</a>
      </div>
      <!--   eslint-disable   -->
      <div
        v-show="isText( evidenceAsset )"
        class="asset-text"
        v-html="$options.filters.htmlMarkUp(evidenceAsset.content)"
      />
      <!--   eslint-enable   -->
    </div>

    <div class="modal modal__global">
      <label for="evidenceModalEdit" />
      <input
        id="evidenceModalEdit"
        class="modal-state"
        type="checkbox"
      >
      <div class="modal-fade-screen">
        <div class="modal-inner">
          <div class="modal__header">
            <button
              id="close-button"
              class="modal__close"
              for="evidenceModalEdit"
              aria-label="Close this dialog window"
              @click="closeEvidenceModal"
            >
              <close-modal-icon />
            </button>
            <h2 v-if="asset.type_id === 1">
              Edit Text
            </h2>
            <h2 v-if="asset.type_id === 2">
              Upload Image
            </h2>
            <h2 v-if="asset.type_id === 4">
              Upload File
            </h2>
          </div>

          <div
            class="modal__content"
            :class="{ 'modal__content-text': asset.type_id === 1 }"
          >
            <upload-manager
              :type="asset.type_id"
              :error-clear-fn="() => errors.clear()"
            >
              <template #default="umProps">
                <form>
                  <div v-if="asset.type_id === 1">
                    <label for="textarea-content" />
                    <textarea
                      id="textarea-content"
                      v-model="asset.content"
                      v-validate="'required|min:10'"
                      type="text"
                      name="content_text"
                      placeholder="Your text here"
                      :class="{ 'input-error' : errors.has('content_text') }"
                    />
                    <div
                      v-show="errors.has('content_text')"
                      class="form-error"
                    >
                      {{ errors.first('content_text') }}
                    </div>
                  </div>
                  <div
                    v-if="asset.type_id === 2"
                    class="modal__file-input"
                  >
                    <label>
                      <image-label />
                      <input
                        ref="fileInputRef"
                        v-validate="'ext:jpeg,jpg,bmp,png,gif,heic'"
                        type="file"
                        name="content_image"
                        :class="{ 'input-error' : errors.has('content_image') || errors.has('filesize') }"
                        @change="umProps.handleFileChange"
                      >
                    </label>
                    <div
                      v-show="errors.has('filesize')"
                      class="form-error"
                    >
                      {{ errors.first('filesize') }}
                    </div>
                    <div
                      v-show="errors.has('content_image')"
                      class="form-error"
                    >
                      {{ errors.first('content_image') }}
                    </div>
                    <div
                      v-if="umProps.upload.active && !errors.has('filesize')"
                      class="asset-processing"
                    >
                      Uploading file, please wait...{{ umProps.upload.percent }}%
                    </div>
                    <hr>
                  </div>
                  <div
                    v-if="asset.type_id === 4"
                    class="modal__file-input"
                  >
                    <label>
                      <file-label />
                      <input
                        ref="fileInputRef"
                        v-validate="'required|ext:pdf,docx,xlsx,pptx,doc,xls,ppt,txt,zip,webm'"
                        type="file"
                        name="content_file"
                        :class="{ 'input-error' : errors.has('content_file') || errors.has('filesize') }"
                        @change="umProps.handleFileChange"
                      >
                    </label>

                    <div
                      v-show="errors.has('filesize')"
                      class="form-error"
                    >
                      {{ errors.first('filesize') }}
                    </div>

                    <div
                      v-show="errors.has('content_file')"
                      class="form-error"
                    >
                      {{ errors.first('content_file') }}
                    </div>

                    <div
                      v-if="umProps.upload.active && !errors.has('filesize')"
                      class="asset-processing"
                    >
                      Uploading file, please wait...{{ umProps.upload.percent }}%
                    </div>
                    <hr>
                  </div>
                  <div class="clearfix" />
                  <div class="modal__actions">
                    <span class="action__proceed">
                      <button
                        type="button"
                        class="button modal__button"
                        for="evidenceModal"
                        :disabled="!umProps.isValid ? true : umProps.upload.active || errors.items.length > 0"
                        @click.stop.prevent="() => sendEvidence(umProps.handleUpload)"
                      >
                        Save
                      </button>
                    </span>
                  </div>
                </form>
              </template>
            </upload-manager>
            <div class="clearfix" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import assetMixin from '../../mixins/asset';
import modalMixin from '../../mixins/modal';
import ImageLabel from './labels/ImageLabel.vue';
import EditIcon from './icons/EditIcon.vue';
import DeleteIcon from './icons/DeleteIcon.vue';
import CloseModalIcon from './icons/CloseModalIcon.vue';
import FileLabel from './labels/FileLabel.vue';
import UploadManager from '../upload/UploadManager.vue';
import { AssetTypes } from '../../common/AssetTypes';

export default {
  name: 'ProjectEvidences',
  components: {
    UploadManager, FileLabel, CloseModalIcon, DeleteIcon, EditIcon, ImageLabel,
  },
  mixins: [assetMixin, modalMixin],
  props: {
    assets: {
      type: [Object, Array],
      default() {
        return {};
      },
    },
    evidenceId: {
      type: [String, Number],
      default() {
        return 0;
      },
    },
    scopedStep: {
      type: [Number, String, Boolean],
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      asset: {
        content: '',
      },
      isEdit: true,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.users.current;
    },
    editEvidenceRoute() {
      return laroute.route('group.project.evidence.asset.update', {
        group: this.currentUser.defaultGroupId,
        project: this.$route.params.id,
        evidence: this.evidenceId,
        asset: this.asset.id,
      });
    },
    deleteEvidenceRoute() {
      return laroute.route('group.project.evidence.asset.delete', {
        group: this.currentUser.defaultGroupId,
        project: this.$route.params.id,
        evidence: this.evidenceId,
        asset: this.asset.id,
      });
    },
    isSubmitWork() {
      return this.$route.name === 'project-add-work';
    },
  },
  mounted() {
    bus.$on('close:modal', this.modalIsClosed);
  },
  methods: {
    editEvidence(asset) {
      this.errors.clear();
      this.asset.content = '';
      this.asset = asset;
      this.openModal('#evidenceModalEdit');
    },

    async sendEvidence(uploadFn) {
      const umData = { status: null };

      const query = {
        type: this.asset.type_id,
        content: this.asset.content,
      };

      if ([AssetTypes.Image.id, AssetTypes.File.id].includes(this.asset.type_id)) {
        umData.status = await uploadFn();
        if (!umData.status.upload.direct_upload_id) {
          this.errors.add({
            field: 'filesize',
            msg: umData.status.errors.file_size[0],
          });
          return;
        }
        query.direct_upload_id = umData.status.upload.direct_upload_id;
      }

      axios.put(this.editEvidenceRoute, query)
        .then((response) => {
          if (response.data.status) {
            this.closeEvidenceModal();
            this.asset = {};
            bus.$emit('update-project-evidence');
          }
        })
        .catch((error) => {
          this.errors.add('filesize', error.data.status);
        });
    },

    deleteEvidence(asset) {
      this.asset = asset;
      const assetName = asset.type.name;

      if (window.confirm(`Are you sure? This will remove this ${assetName} asset`)) {
        axios.get(this.deleteEvidenceRoute)
          .then(() => {
            bus.$emit('update-project-evidence');

            this.$store.dispatch('loadMessage', {
              content: `${assetName} deleted`,
              type: 'success',
              icon: '',
              autoclose: true,
            });
          });
      }

      return false;
    },

    modalIsClosed() {
      this.errors.clear();
      this.asset.content = '';
    },

    closeEvidenceModal() {
      if (this.$refs.fileInputRef && this.$refs.fileInputRef.value) {
        this.$refs.fileInputRef.value = '';
      }
      this.modalIsClosed();
      this.closeModal('#evidenceModalEdit');
    },
  },
};
</script>
