var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "full-wrap" }, [
    _c("div", { staticClass: "page-wrap" }, [
      _c("main", [
        _c("section", { staticClass: "primary-content" }, [
          _c(
            "div",
            { staticClass: "content-box" },
            [
              _c("div", { staticClass: "group-title--area" }, [
                _vm.currentUser.isNewEducator
                  ? _c("h1", [_vm._v("Getting Started")])
                  : _c("h1", [_vm._v("My Groups")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "group-switch-buttons" },
                  [
                    _vm.currentUser.isEducator
                      ? _c(
                          "router-link",
                          {
                            staticClass: "button__secondary",
                            attrs: { to: "/create-group" },
                          },
                          [
                            _vm._v("\n                Create New Group "),
                            _c("i", { staticClass: "fa fa-plus" }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "button__secondary",
                        attrs: { to: "/join/group" },
                      },
                      [
                        _vm._v(
                          "\n                Join a Group\n                "
                        ),
                        _c(
                          "svg",
                          {
                            staticStyle: {
                              "enable-background": "new 0 0 45.5 35",
                            },
                            attrs: {
                              version: "1.1",
                              id: "Layer_1",
                              focusable: "false",
                              xmlns: "http://www.w3.org/2000/svg",
                              "xmlns:xlink": "http://www.w3.org/1999/xlink",
                              x: "0px",
                              y: "0px",
                              viewBox: "0 0 45.5 35",
                              "xml:space": "preserve",
                            },
                          },
                          [
                            _c(
                              "desc",
                              {
                                attrs: {
                                  "data-v-908c7996": "",
                                  id: "navGroupDesc",
                                },
                              },
                              [_vm._v("A group of people")]
                            ),
                            _c("g", [
                              _c("path", {
                                staticClass: "st0",
                                attrs: {
                                  d: "M15.5,4c0.3-0.4,0.7-0.7,1.1-0.9l-1.5-2.6C14.9,0.2,14.6,0,14.2,0H7.9C7.5,0,7.2,0.2,7,0.5L3.8,6\n                    c-0.2,0.3-0.2,0.7,0,1.1L7,12.5c0.2,0.4,0.5,0.6,0.9,0.6h4.8c-0.1-0.7-0.2-1.3-0.2-1.9C12.5,8.4,13.7,5.8,15.5,4",
                                },
                              }),
                              _c("path", {
                                staticClass: "st0",
                                attrs: {
                                  d: "M10.9,22.4c1.5-1.5,3.5-2.6,5.7-3.1c-0.4-0.3-0.8-0.6-1.1-0.9c-1-1-1.7-2.2-2.3-3.5H8.3C3.7,14.9,0,18.6,0,23\n                    v3.9h8c0.1-0.3,0.2-0.6,0.3-0.9C8.9,24.7,9.8,23.4,10.9,22.4",
                                },
                              }),
                              _c("path", {
                                staticClass: "st0",
                                attrs: {
                                  d: "M45.5,23c0-4.4-3.8-8.1-8.4-8.1h-4.9c-0.5,1.3-1.3,2.5-2.2,3.5c-0.4,0.3-0.7,0.6-1.1,0.9\n                    c1.5,0.3,2.8,0.9,4,1.7c1.9,1.2,3.4,3,4.3,5c0.2,0.3,0.3,0.6,0.4,0.9h7.9V23z",
                                },
                              }),
                              _c("path", {
                                staticClass: "st0",
                                attrs: {
                                  d: "M31.7,30.6H13.9v-0.1c0-2.8,2.4-5,5.3-5h7.2C29.3,25.5,31.7,27.7,31.7,30.6L31.7,30.6z M26.4,21h-7.2\n                    c-5.4,0-9.8,4.3-9.8,9.5V35h26.7v-4.5C36.1,25.3,31.7,21,26.4,21L26.4,21z",
                                },
                              }),
                              _c("path", {
                                staticClass: "st0",
                                attrs: {
                                  d: "M22.7,18.9c4.3,0,7.8-3.5,7.8-7.7c0-4.3-3.5-7.8-7.8-7.8S15,6.9,15,11.2C15,15.4,18.4,18.9,22.7,18.9",
                                },
                              }),
                              _c("path", {
                                staticClass: "st0",
                                attrs: {
                                  d: "M38.3,0h-8.1c-1.4,0-2.4,1.1-2.4,2.4v0.1c3.1,1.8,5.2,5.1,5.2,8.8c0,0.6-0.1,1.1-0.2,1.6h5.5\n                    c1.3,0,2.4-1.1,2.4-2.4V2.4C40.7,1.1,39.6,0,38.3,0",
                                },
                              }),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "clearfix" }),
              _vm._v(" "),
              _vm.currentUser.isNewEducator
                ? _c("div", [
                    _c("p", { staticClass: "instructional" }, [
                      _vm._v(
                        "\n              Your educator account is now confirmed – Welcome to Mouse Create!\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "instructional" }, [
                      _vm._v(
                        "Your next step is to join an existing group or create your own group. Select one of these options above to get started.\n            "
                      ),
                    ]),
                  ])
                : _c("div", [
                    _vm.hasGroups
                      ? _c("p", { staticClass: "instructional" }, [
                          _vm._v(
                            "\n              Select a group to get started. You can switch your active group anytime by going to “More > My Groups” in the top menu.\n            "
                          ),
                        ])
                      : _c("p", { staticClass: "instructional" }, [
                          _vm._v(
                            "\n              Looks like you aren’t in a group yet! Join a group to get started using Mouse Create.\n            "
                          ),
                        ]),
                  ]),
              _vm._v(" "),
              _vm._l(_vm.activeGroups, function (group) {
                return _c(
                  "div",
                  { key: group.id, staticClass: "group-selector" },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "button",
                        attrs: { href: "" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.selectGroup(group.id)
                          },
                        },
                      },
                      [_vm._v(_vm._s(group.name))]
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }