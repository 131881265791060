var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "full-wrap" }, [
    _c("div", { staticClass: "page-wrap" }, [
      _c("main", [
        _c("section", { staticClass: "primary-content" }, [
          _c("div", { staticClass: "content-box" }, [
            _vm.currentUser.isInActiveGroup &&
            !_vm.currentUser.isNewUser &&
            !_vm.currentUser.hasAnyLicensedSites
              ? _c("div", [
                  _c("h1", { staticClass: "page-title" }, [
                    _vm._v(
                      "\n              Time to renew your site’s Mouse membership!\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(0),
                  _vm._v(" "),
                  _c("p"),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _c("p"),
                  _vm._v(" "),
                  _vm._m(2),
                  _vm._v(" "),
                  _vm._m(3),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "back_link" },
              [
                _c("router-link", { attrs: { to: { name: "group-switch" } } }, [
                  _c("i", {
                    staticClass: "fa fa-chevron-left",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" Back to\n              My Groups\n            "),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "group-title--area" }, [
              _vm.currentUser.isEducator
                ? _c("h1", [_vm._v("Join an Existing Group")])
                : _c("h1", [_vm._v("Join a Group")]),
              _vm._v(" "),
              _vm.currentUser.isEducator
                ? _c(
                    "div",
                    { staticClass: "group-switch-buttons" },
                    [
                      _vm.currentUser.isEducator
                        ? _c(
                            "router-link",
                            {
                              staticClass: "button__secondary",
                              attrs: { to: "/create-group" },
                            },
                            [
                              _vm._v("\n                Create New Group "),
                              _c("i", { staticClass: "fa fa-plus" }),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "clearfix" }),
            ]),
            _vm._v(" "),
            _vm.currentUser.isEducator
              ? _c("div", [_vm._m(4)])
              : _c("div", [_vm._m(5)]),
            _vm._v(" "),
            _c(
              "form",
              { staticClass: "simple-join-group", attrs: { method: "POST" } },
              [
                _c("div", { staticClass: "form-row short-row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "visuallyhidden",
                      attrs: { for: "groupCode" },
                    },
                    [_vm._v("Group Code")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.code,
                        expression: "code",
                      },
                    ],
                    attrs: {
                      id: "groupCode",
                      type: "text",
                      name: "code",
                      placeholder: "Enter Group Code",
                      value: "",
                      autocomplete: "off",
                    },
                    domProps: { value: _vm.code },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.code = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c("div", { staticClass: "submit-button" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button",
                        attrs: { type: "submit" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.saveGroup($event)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                  Join Group\n                "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [
        _vm._v("Unfortunately, your site’s membership has expired."),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _vm._v(
          "Don’t worry, all of your own work is still available in your Profile."
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "You can export your work anytime in the My Work area of your Profile."
        ),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v(
          "We will reactivate your groups after your site’s membership is renewed."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("Ready to Renew?")]),
      _c("br"),
      _vm._v(" "),
      _c("a", { attrs: { href: "https://www.mouse.org/renew" } }, [
        _vm._v("Click here"),
      ]),
      _vm._v(
        " to learn more about renewing your membership or to\n              let us know if you think this message is an error. Students: Talk to your teacher about renewing your\n              site’s membership.\n            "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("strong", [_vm._v("At a new site?")]),
      _c("br"),
      _vm._v(
        "\n              If you are now at a new Mouse site and want to use this account, enter your new site’s Educator Code or\n              Group Code below.\n            "
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "instructional" }, [
      _vm._v(
        "\n              To join an existing group, enter the Group Code below. You can get the Group Code from the educator who manages that group. Can’t find your code? "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://tools.mouse.org/help/group-members",
            target: "_blank",
          },
        },
        [_vm._v("Click here for help")]
      ),
      _vm._v(".\n            "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "instructional" }, [
      _vm._v(
        "\n              Enter your group’s Group Code below. You can get the Group Code from the educator who manages your group. Can’t find the code? "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://tools.mouse.org/help/group-members",
            target: "_blank",
          },
        },
        [_vm._v("Click here for help")]
      ),
      _vm._v(".\n            "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }