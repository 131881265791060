<template>
  <div class="profile-view">
    <div class="profile-header">
      <!--  eslint-disable    -->
      <div
        class="profile-pic"
        v-html="user.avatar"
      />
      <!--  eslint-enable    -->
      <div class="username">
        <h1 class="page-title">
          {{ user.username }}
        </h1>
      </div>
      <div
        v-show="editable"
        class="profile-edit"
      >
        <a
          href="#"
          @click.prevent="loadEditComponent"
        >Edit Account Settings <i
          class="fa fa-pencil"
          aria-hidden="true"
        /></a>
      </div>
      <div class="bio">
        <p
          class="bio"
          v-text="user.profile.bio"
        />
      </div>
    </div>

    <template v-if="showGoogleConnect">
      <hr>

      <div class="login-grid-google profile">
        <p>Connect your Google account to log in to Mouse Create using the "Login with Google" option instead of your username and password.</p>
        <button
          type="submit"
          class="button"
          @click.stop.prevent="connectGoogle"
        >
          <span class="text">Log In With Google</span>
          <span class="icon"><img
            src="/img/icon-google.png"
            alt="Google"
          ></span>
        </button>
      </div>
    </template>

    <hr>

    <h2 class="section-heading">
      Groups
    </h2>

    <ul
      v-show="groups"
      class="profile-group-list"
    >
      <li
        v-for="group in groups"
        :key="group.id"
      >
        {{ group.name }}&nbsp;
        <span
          v-if="group.status === 'archived'"
          class="archived"
        >(archived)</span>
      </li>
    </ul>

    <hr v-show="groups">

    <h2 class="section-heading">
      Badges
    </h2>
    <div
      v-show="badges"
      class="badge-block"
    >
      <div
        v-for="badge in badges"
        :key="badge.id"
        class="badge"
      >
        <a
          :href="badge.url"
          target="new"
        ><img
          :src="badge.image"
          :alt="badge.title"
        ></a>
      </div>
    </div>
    <div
      v-show="!badges.length"
      class="clearfix"
    >
      <p>
        If you are 13 or older, you can sign up to earn badges on Mouse Create and Credly. Go to "Edit Account Settings" and check the badges option. Then, start working on projects.
      </p>
      <p>
        For more badges help, go to <a
          href="https://tools.mouse.org"
          target="_blank"
        ><span class="sr-only">(opens in a new tab) </span>Tools.mouse.org</a>.
      </p>
    </div>
    <hr>

    <div
      v-show="evidence"
      id="profileEvidenceHandler"
    >
      <table>
        <caption class="large-table-caption">
          Recent Work Log
        </caption>
        <thead>
          <tr>
            <th scope="col">
              Project Title
            </th>
            <th scope="col">
              Group
            </th>
            <th scope="col">
              Date
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="e in evidence"
            :key="e.id"
          >
            <td data-label="Project Title">
              <a
                href="#"
                @click.stop.prevent="showProjectReviewModal(e, e.group.id)"
                v-text="e.title"
              />
            </td>
            <td
              data-label="Group"
              v-text="e.group.name"
            />
            <td data-label="Date">
              {{ e.submitted|formatDate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProfileView',
  components: {},
  props: {
    token: {
      type: String,
      default() {
        return '';
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      user: {
        username: null,
        bio: null,
        of_age: false,
        avatar: null,
        profile: {
          bio: null,
          birthdate: null,
        },
      },
      editable: false,
      owner: false,
      userToken: null,
      email: null,

      groups: [],
      evidence: [],
      badges: [],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.users.current;
    },
    isEducatorEdit() {
      return this.currentUser.id !== this.user.id;
    },
    showGoogleConnect() {
      if (!this.user) {
        return false;
      }
      if (this.user.id !== this.currentUser.id) {
        return false;
      }
      return !this.user.oauth_provider_id;
    },
  },
  watch: {
    currentUser() {
      this.loadProfile();
    },
  },
  mounted() {
    this.loadProfile();

    if (this.$route.query.googleconnected) {
      const message = 'Your account is now connected to Google! You can use the Login with Google feature moving forward.';

      this.$store.dispatch('loadMessage', {
        content: message,
        type: 'success',
        autoclose: true,
      });
    }

    if (this.$route.query.unmatchedemail) {
      const message = 'The email address you have in your profile does not match this Google account. Go to "Edit Account Settings" to update your email address and try again.';

      this.$store.dispatch('loadMessage', {
        content: message,
        type: 'error',
        autoclose: true,
      });
    }

    if (this.$route.name !== 'profile-view') {
      this.$router.push({ name: 'profile-view', params: { id: this.currentUser.token } }).catch();
    }
  },
  methods: {
    loadProfile() {
      const token = this.$route.params.id;

      const url = laroute.route('api.user', { token });

      axios.get(url)
        .then((response) => {
          this.user = response.data;
          this.editable = response.data.editable;
          this.owner = response.data.owner;
          this.loadBadges();
          this.loadEvidence();
          this.loadGroups();
        });
    },
    loadBadges() {
      const url = laroute.route('api.user.badges', { token: this.user.token });

      axios
        .get(url)
        .then((response) => {
          this.badges = response.data.data;
        });
    },
    loadEvidence() {
      const url = laroute.route('api.user.evidence', { token: this.user.token });
      axios.get(url)
        .then((response) => {
          this.evidence = response.data.data;
        });
    },
    loadGroups() {
      const url = laroute.route('api.user.groups', { token: this.user.token });

      axios.get(url)
        .then((response) => {
          this.groups = response.data.data;
        });
    },

    loadEditComponent() {
      if (this.isEducatorEdit) {
        this.$router.push({ name: 'profile-educator-edit', params: { id: this.user.token } });
      } else {
        this.$router.push({ name: 'profile-settings', params: { id: this.user.token } });
      }
    },

    showProjectReviewModal(evidence, groupId) {
      bus.$emit('open-project-review-modal', {
        evidenceId: evidence.id,
        groupId,
        isEditing: false,
      });
    },

    popState() {
      if (document.location.href.indexOf('my-work') !== -1) {
        $('body').trigger('vue.global.event', {
          _event: 'setActiveTabComponent',
          component: 'works',
        });
      } else if (document.location.href.indexOf('/settings') !== -1) {
        $('body').trigger('vue.global.event', {
          _event: 'setActiveTabComponent',
          component: 'profile-edit',
        });
      } else if (document.location.href.indexOf('profile') !== -1) {
        $('body').trigger('vue.global.event', {
          _event: 'setActiveTabComponent',
          component: 'profile-view',
        });
      }
    },

    connectGoogle() {
      axios.get(laroute.route('oauth.google.connect.url')).then((response) => {
        window.location = response.data;
      });
    },
  },
};
</script>
