var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "full-wrap" }, [
    _c("div", { staticClass: "page-wrap" }, [
      _vm.loaded
        ? _c("div", { staticClass: "main-menu mobile-project-filter" }, [
            _c(
              "div",
              {
                staticClass:
                  "mobile-dropdown mobile-filter project-mobile-filter",
              },
              [
                _c("div", { staticClass: "dropdown" }, [
                  _c("div", { staticClass: "dropdown-container" }, [
                    _c(
                      "div",
                      {
                        staticClass: "dropdown-button-delay",
                        attrs: { tabindex: "0" },
                        on: { click: _vm.dropDownToggle },
                      },
                      [_vm._v("\n              Filter Projects\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "ul",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.dropDownMenu,
                            expression: "dropDownMenu",
                          },
                        ],
                        staticClass: "dropdown-menu",
                        attrs: { "aria-hidden": "true" },
                      },
                      [
                        _c(
                          "li",
                          [
                            _c("project-finder-filters", {
                              attrs: {
                                courses: _vm.courses,
                                categories: _vm.categories,
                                "category-counts": _vm.categoryCounts,
                                "active-categories": _vm.activeCategories,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("main", [
        !_vm.loaded
          ? _c("div", { staticClass: "response-loading" }, [
              _c("h2", [_vm._v("Projects loading now...")]),
              _vm._v(" "),
              _vm._m(0),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("aside", { staticClass: "sidebar finder left" }, [
          _vm.loaded
            ? _c(
                "div",
                [
                  _c("h1", [_vm._v("Find Projects")]),
                  _vm._v(" "),
                  _c("h2", [_vm._v("Project Finder")]),
                  _vm._v(" "),
                  _c("project-finder-filters", {
                    attrs: {
                      courses: _vm.courses,
                      categories: _vm.categories,
                      "category-counts": _vm.categoryCounts,
                      "active-categories": _vm.activeCategories,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("section", { staticClass: "primary-content playlist-content" }, [
          _vm.loaded
            ? _c("div", [
                _c("p", { staticClass: "filter-results" }, [
                  _vm._v("\n            There "),
                  _c("span", { attrs: { id: "project-count" } }, [
                    _vm._v(
                      "are " +
                        _vm._s(_vm.projects ? _vm.projects.length : "") +
                        " projects"
                    ),
                  ]),
                  _vm._v(" that match your filter settings.\n          "),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { attrs: { id: "project-list" } },
                  _vm._l(_vm.projects, function (project) {
                    return _c("project-card", {
                      key: project.id,
                      attrs: {
                        project: project,
                        "is-in-current-group": _vm.checkProject(project.id),
                      },
                    })
                  }),
                  1
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _c("img", {
        attrs: { alt: "loader", src: "/img/gear-animation-115.gif" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }