var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-tab" },
    [
      _c("h1", [_vm._v(_vm._s(_vm.project.title))]),
      _vm._v(" "),
      _c("project-evidence-manager", {
        attrs: {
          prompts: _vm.prompts,
          "get-project": _vm.getProject,
          "is-loading": _vm.isLoading,
          project: _vm.project,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }