var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        "aria-labelledby": "navNotificationTitle navNotificationDesc",
        focusable: "false",
        height: "44px",
        viewBox: "0 13 32 44",
        width: "32px",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("title", { attrs: { id: "navNotificationTitle" } }, [
        _vm._v("Notifications"),
      ]),
      _vm._v(" "),
      _c("desc", { attrs: { id: "navNotificationDesc" } }, [
        _vm._v("Notifications"),
      ]),
      _vm._v(" "),
      _c("path", {
        staticClass: "icon",
        attrs: {
          id: "Fill-1-1",
          d: "M30.9191535,28.3816081 L20.3462467,28.3816081 C19.6048402,28.3816081 19.0849569,27.6419224 19.3271497,26.9314048 L23.0081209,14.4592922 C23.4021897,13.3053278 21.9193766,12.4640094 21.1564019,13.406049 L0.247389029,38.9195087 C-0.330908084,39.6345838 0.169204338,40.7069685 1.08001106,40.7069685 L11.6538165,40.7069685 C12.3943244,40.7069685 12.9164544,41.4480215 12.6724642,42.158539 L8.99329035,55.5403329 C8.59922153,56.6952088 10.079788,57.5360714 10.8436614,56.5935761 L31.7531236,30.1690679 C32.330522,29.4567273 31.8308589,28.3816081 30.9191535,28.3816081",
          fill: "#00A886",
          "fill-rule": "evenodd",
          stroke: "none",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }