window.Vue.filter('timeago', (value) => window.moment.utc(value).tz(window.userTimeZone).calendar(null, {
  sameDay: 'h:mma',
  nextDay: '[Tomorrow]',
  nextWeek: 'dddd',
  lastDay: '[Yesterday] h:mma',
  lastWeek: '[Last] dddd',
  sameElse: 'MMM D, Y',
}));

window.Vue.filter('fileSize', (bytes) => {
  const si = true;
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`;
  }
  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  do {
    // eslint-disable-next-line no-param-reassign
    bytes /= thresh;
    u += 1;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return `${bytes.toFixed(1)} ${units[u]}`;
});

window.Vue.filter('script2Tag', (value) => value.replace(/<script/g, '<script2').replace(/<\/script/g, '</script2'));

window.Vue.filter('formatDate', (value, format) => {
  if (value === '0000-00-00 00:00:00') {
    return '';
  }

  let dateFormat = format;

  if (value) {
    if (!dateFormat) {
      dateFormat = 'MM/DD/YYYY';
    }
    return window.moment(String(value)).format(dateFormat);
  }

  return '';
});

window.Vue.filter('numberUpperLimit', (value, limit) => ((value >= limit) ? `${limit}+` : value));

window.Vue.filter('htmlMarkUp', (value) => {
  if (!value) return ''; // If there is no value then just return

  // URLs starting with http://, https://, or ftp://
  const protocolPattern = /(\b(https?):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
  let newContent = value.replace(protocolPattern, '<a href="$1" target="_blank">$1</a>');

  const dubPattern = /(^|[^/])(www\.[\S]+(\b|$))/gim;
  // noinspection HttpUrlsUsage
  newContent = newContent.replace(dubPattern, '$1<a href="http://$2" target="_blank">$2</a>');

  const newLinePattern = /\r?\n/gim;
  newContent = newContent.replace(newLinePattern, '<br>');

  return newContent;
});
