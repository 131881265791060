<template>
  <main>
    <div
      v-if="!loaded"
      class="response-loading"
    >
      <h2>Members loading now...</h2>
      <p>
        <img
          src="/img/gear-animation-115.gif"
          alt="Members loading icon"
        >
      </p>
    </div>

    <section
      v-if="loaded"
      class="group-tab primary-content"
    >
      <group-header :group="group" />

      <div class="header__intro">
        <div class="header__intro__title">
          Members
        </div>
        <div class="header__intro__summary">
          This is everyone in your group. Only Group Managers are able to change the group’s settings. <a
            href="https://tools.mouse.org/group-members"
            target="_blank"
          >Learn more ></a>
        </div>
      </div>

      <table class="table__paginated">
        <thead>
          <tr>
            <th>
              <span
                class="sort"
                :class="sortClasses('first_name')"
                @click="sortMe('first_name')"
              >Name (Username)</span>
            </th>
            <th>Type</th>
            <th>Last Login</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="member in filteredMembers"
            :key="member.id"
          >
            <td
              class="table__cell__valign-top"
              data-label="Name/Email"
            >
              <div class="icon__group">
                <router-link :to="profileView(member.token)">
                  <div class="icon__group__icon">
                    <span
                      class="profile__pic-member profile__pic-small-left"
                      v-html="member.avatar"
                    />
                  </div>
                  <div class="icon__group__text">
                    {{ member.full_name }} <span class="table__text__block">({{ member.username }})</span>
                  </div>
                </router-link>
              </div>
            </td>
            <td data-label="Type">
              <template v-if="$groupUser().canOther(member.groupRole, 'manage_group')">
                <span class="group-type-badge">Group Manager</span>
              </template>
              <template v-else>
                <span v-if="member.isStudent">Student</span>
                <span v-if="member.isEducator">Educator</span>
                <span v-if="!member.isEducator && !member.isStudent">General</span>
              </template>
            </td>
            <td data-label="Last Login">
              {{ member.lastLogin | formatDate('M/D/YY') }}
            </td>
            <td
              class="table__actions"
              data-label="Actions"
            >
              <span v-if="$groupUser().can('manage_group') && !member.isStudent && member.isEducator && member.groupRole !== 'creator'">
                <template v-if="$groupUser().canOther(member.groupRole, 'manage_group')">
                  <a
                    href="#"
                    @click="removeGroupManager(member)"
                    v-if="currentUser.id !== member.id"
                  >Remove as Group Manager <WrenchIconLight /></a>
                </template>
                <template v-else>
                  <a
                    href="#"
                    @click="promoteGroupManager(member)"
                  >Make Group Manager <WrenchIcon /></a>
                </template>
                <br v-if="member.id !== currentUser.id">
              </span>
              <span
                v-if="currentUser.isStudent || !$groupUser().can('manage_group')"
                class="no__actions"
              >None</span>
              <span v-if="member.isInActiveGroup">
                <span v-if="!currentUser.isStudent && !member.isEducator && $groupUser().can('manage_group')">
                  <span
                    v-if="!$groupUser().canOther(member.groupRole, 'manage_group')"
                    class="admin__actions--edit"
                  >
                    <a
                      href="#"
                      @click.stop.prevent="editAccount(member.token)"
                    >Edit Account</a>
                    <br>
                  </span>
                </span>
              </span>
              <a
                v-if="$groupUser().can('manage_group') && currentUser.id !== member.id && member.groupRole !== 'creator'"
                href="#"
                @click.stop.prevent="removeMember(member.id)"
              >
                Archive user from Group <CircleXIcon />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <nav
        v-if="pagination.totalPages > 1"
        class="table__pagination"
        role="navigation"
        aria-label="Pagination Navigation"
      >
        <div class="table__pagination__prev">
          <a
            href="#"
            class="table__pagination__link"
            @click.stop.prevent="previous"
          >
            <span v-if="pagination.currentPage === 1 || pagination.totalPages === 1">&nbsp;</span>
            <span v-if="pagination.currentPage !== 1">&lt; previous</span>
          </a>
        </div>
        <div class="table__pagination__numbers">
          <ul
            v-if="pagination.totalPages > 1"
            class="table__pagination__row"
          >
            <li
              v-for="page in pagination.totalPages"
              :key="page"
              class="table__pagination__list"
            >
              <a
                href="#"
                class="table__pagination__link"
                :aria-label="'Current Page, Page ' + page"
                :class="{ 'table__pagination__link--active': pagination.currentPage === page }"
                @click.stop.prevent="pageSelected(page)"
              >{{ page }}</a>
            </li>
          </ul>
        </div>
        <div class="table__pagination__next">
          <a
            href="#"
            class="table__pagination__link"
            @click.stop.prevent="next"
          >
            <span v-if="pagination.currentPage === pagination.totalPages || pagination.totalPages === 1">&nbsp;</span>
            <span v-if="pagination.currentPage !== pagination.totalPages">next &gt;</span>
          </a>
        </div>
      </nav>

      <p
        v-if="$groupUser().can('manage_group')"
        class="manage-users"
      >
        <small>
          <router-link :to="{name: 'group-members-archived', params: { id: group.id }}">Manage archived users <i class="fa fa-cog" /></router-link>
        </small>
      </p>
    </section>
  </main>
</template>

<script>
import GroupHeader from './GroupHeader.vue';
import paginationMixin from '../../mixins/pagination';
import sortingMixin from '../../mixins/sorting';
import WrenchIconLight from '../icons/WrenchIconLight.vue';
import WrenchIcon from '../icons/WrenchIcon.vue';
import CircleXIcon from '../icons/CircleXIcon.vue';

export default {
  name: 'Members',
  components: {
    CircleXIcon, WrenchIcon, WrenchIconLight, GroupHeader,
  },
  mixins: [paginationMixin, sortingMixin],
  data() {
    return {
      pagination: {
        limit: 15,
      },
      loaded: false,
      members: [],
      field: 'first_name',
      order: 'asc',
      groupName: '',
      groupCode: '',
    };
  },
  computed: {
    getMembersRoute() {
      const id = (this.$route.params.id) ? this.$route.params.id : this.group.id;

      return laroute.route('api.group.members', { group: id });
    },

    group() {
      return this.$store.state.groups.active;
    },

    filteredMembers() {
      const start = (this.pagination.currentPage - 1) * this.pagination.limit;
      const end = this.pagination.currentPage * this.pagination.limit;

      // Order Evidences
      const members = _.orderBy(this.members, (member) => {
        const byField = _.get(member, this.field);

        if (byField == null || byField === false) {
          return '';
        }

        return byField.toString().toLowerCase();
      }, [this.order]);

      return members.slice(start, end);
    },

    currentUser() {
      return this.$store.state.users.current;
    },

    showGroupRoute() {
      return laroute.route('api.group.show', { group: this.group.id });
    },

    deleteGroupRoute() {
      return laroute.route('group.delete', { group: this.group.id });
    },
  },
  mounted() {
    this.getMembers();

    if (this.$route.name !== 'group-members') {
      this.$router.push({ name: 'group-members', params: { id: this.group.id } }).catch(() => {});
    }
  },
  methods: {
    getMembers() {
      axios.get(this.getMembersRoute)
        .then((response) => {
          this.members = response.data.data;

          this.setupPagination(this.members);

          this.pagination.count = this.members.length;
          this.pagination.totalPages = Math.ceil(this.pagination.count / this.pagination.limit);
          this.loaded = true;
        });
    },

    // http://mouse.test/group/166/manage-members/37/remove
    removeMember(memberId) {
      axios.get(`/api/group/${this.group.id}/manage-members/${memberId}/remove`)
        .then(() => {
          this.getMembers();
        });
    },

    editAccount(memberToken) {
      this.$router.push({ name: 'profile-educator-edit', params: { id: memberToken } });
      bus.$emit('set-active-tab', 'profile-edit');
    },

    async removeGroupManager(member) {
      await axios.post('/api/user/group/role/change', { groupId: this.group.id, userId: member.id, role: 'learner' });

      if (this.currentUser.id === member.id) {
        window.location.reload();
      }

      this.members = this.members.reduce((acc, m) => {
        if (member.id === m.id) {
          // eslint-disable-next-line no-param-reassign
          m.groupRole = 'learner';
        }
        acc.push(m);
        return acc;
      }, []);
    },
    async promoteGroupManager(member) {
      await axios.post('/api/user/group/role/change', { groupId: this.group.id, userId: member.id, role: 'facilitator' });

      this.members = this.members.reduce((acc, m) => {
        if (member.id === m.id) {
          // eslint-disable-next-line no-param-reassign
          m.groupRole = 'facilitator';
        }
        acc.push(m);
        return acc;
      }, []);
    },

    profileView(memberToken) {
      return laroute.url('profile', [memberToken]);
    },

    // confirmDelete: function() {
    //     var confirm = window.confirm('Are you sure? \nThis will delete the group ' + this.group.name + '. Any group relationships will be nullified');

    //     if(confirm) {
    //         window.location.href = this.deleteGroupRoute;
    //     }
    // }
  },
};
</script>
