<template>
  <header class="home__header">
    <div class="header__brand">
      <MouseHeaderLogoIcon />
    </div>

    <div
      class="header__description"
      v-html="home.tagline"
    />

    <div class="header__actions">
      <router-link
        v-if="isLoggedIn && !isRegistered"
        to="/register"
        class="button home__button"
      >
        <span>Continue Registration</span>
      </router-link>

      <router-link
        v-else
        to="/login"
        class="button home__button"
      >
        <span v-if="isLoggedIn">
          Back to Group
        </span>
        <span v-else>
          Login or Create an Account
        </span>
      </router-link>

      <a
        v-if="isLoggedIn"
        href="#"
        class="button home__button"
        @click.stop.prevent="logoutUser"
      >
        <span>Logout</span>
      </a>
    </div>

    <div class="header__video">
      <div
        v-if="video"
        class="video__player"
      >
        <video
          ref="videoPlayer"
          class="video-js vjs-default-skin vjs-big-play-centered"
          height="240"
          width="600"
        >
          <p class="vjs-no-js">
            To view this video please enable JavaScript, and consider upgrading to a web browser that <a
              href="https://videojs.com/html5-video-support/"
              target="_blank"
            >supports HTML5 video</a>
          </p>
        </video>
      </div>

      <div
        class="video__content"
        v-html="videoContent"
      />
    </div>
  </header>
</template>

<script>
import _ from 'lodash';
import videojs from 'video.js';
import MouseHeaderLogoIcon from '../icons/MouseHeaderLogoIcon.vue';
import 'video.js/dist/video-js.css';

export default {
  name: 'HomeHeader',
  components: { MouseHeaderLogoIcon },
  props: {
    home: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      player: null,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.users.current;
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    isRegistered() {
      return Boolean(this.currentUser && !_.isEmpty(this.currentUser.profile));
    },
    videoContent() {
      return this.home.description ? this.home.description : '';
    },
    video() {
      return this.home.video ? this.home.video[0] : false;
    },
  },
  watch: {
    video(video) {
      if (!video) {
        return;
      }
      this.$nextTick(() => {
        this.videoCall();
      });
    },
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    registerWithGoogle() {
      axios.get(laroute.route('oauth.google.register.url')).then((response) => {
        window.location = response.data;
      });
    },
    videoUrl(source) {
      return `${process.env.MIX_S3_URL}content/${source}`;
    },
    videoCall() {
      const videoOptions = {
        autoplay: false,
        controls: true,
        preload: 'auto',
        height: 240,
        width: 600,
        fluid: true,
        sources: [
          {
            src: this.videoUrl(this.video.filename),
            type: this.video.mimeType,
          },
        ],
      };

      this.player = videojs(this.$refs.videoPlayer, videoOptions, function videoPlayerCallback() {
        const trackGaEvent = function trackGaEventCb() {
          // noinspection JSUnresolvedFunction
          // eslint-disable-next-line no-restricted-globals
          ga('send', 'event', 'Video', event.type, 'Create Homepage Video', '0');
        };

        // Attach GA tracking to event listeners
        this.on('play', trackGaEvent);
        this.on('pause', trackGaEvent);
        this.on('load', trackGaEvent);
        this.on('ended', trackGaEvent);
        this.on('volumechange', trackGaEvent);
      });
    },
    logoutUser() {
      this.$store.dispatch('logoutUser');
    },
  },
};
</script>

<style>

</style>
