var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "evidence__asset-wrapper" },
    [
      _vm._l(_vm.assets, function (evidenceAsset) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.scopedStep
                  ? evidenceAsset.step_id === parseInt("" + _vm.scopedStep, 10)
                  : true,
                expression:
                  "scopedStep ? evidenceAsset.step_id === parseInt(`${scopedStep}`, 10) : true",
              },
            ],
            key: evidenceAsset.id,
            staticClass: "evidence__asset-block",
          },
          [
            _vm.isSubmitWork
              ? _c("div", { staticClass: "controls" }, [
                  _c(
                    "a",
                    {
                      staticClass: "control__action control__edit",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.editEvidence(evidenceAsset)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "control__edit-text" }, [
                        _vm._v("Edit"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "control__icon control__edit-icon" },
                        [_c("edit-icon")],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "control__action control__delete",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.deleteEvidence(evidenceAsset)
                        },
                      },
                    },
                    [
                      _c("span", { staticClass: "control__delete-text" }, [
                        _vm._v("Delete"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "control__icon control__delete-icon" },
                        [_c("delete-icon")],
                        1
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            evidenceAsset.upload
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isImage(evidenceAsset),
                        expression: "isImage( evidenceAsset )",
                      },
                    ],
                    staticClass: "asset-image",
                  },
                  [
                    _c("img", {
                      attrs: { src: evidenceAsset.upload.path_url, alt: "" },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            evidenceAsset.upload
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isFile(evidenceAsset),
                        expression: "isFile( evidenceAsset )",
                      },
                    ],
                  },
                  [
                    _c("a", { attrs: { href: evidenceAsset.upload.url } }, [
                      _vm._v("Download File"),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isText(evidenceAsset),
                  expression: "isText( evidenceAsset )",
                },
              ],
              staticClass: "asset-text",
              domProps: {
                innerHTML: _vm._s(
                  _vm.$options.filters.htmlMarkUp(evidenceAsset.content)
                ),
              },
            }),
          ]
        )
      }),
      _vm._v(" "),
      _c("div", { staticClass: "modal modal__global" }, [
        _c("label", { attrs: { for: "evidenceModalEdit" } }),
        _vm._v(" "),
        _c("input", {
          staticClass: "modal-state",
          attrs: { id: "evidenceModalEdit", type: "checkbox" },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "modal-fade-screen" }, [
          _c("div", { staticClass: "modal-inner" }, [
            _c("div", { staticClass: "modal__header" }, [
              _c(
                "button",
                {
                  staticClass: "modal__close",
                  attrs: {
                    id: "close-button",
                    for: "evidenceModalEdit",
                    "aria-label": "Close this dialog window",
                  },
                  on: { click: _vm.closeEvidenceModal },
                },
                [_c("close-modal-icon")],
                1
              ),
              _vm._v(" "),
              _vm.asset.type_id === 1
                ? _c("h2", [_vm._v("\n            Edit Text\n          ")])
                : _vm._e(),
              _vm._v(" "),
              _vm.asset.type_id === 2
                ? _c("h2", [_vm._v("\n            Upload Image\n          ")])
                : _vm._e(),
              _vm._v(" "),
              _vm.asset.type_id === 4
                ? _c("h2", [_vm._v("\n            Upload File\n          ")])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "modal__content",
                class: { "modal__content-text": _vm.asset.type_id === 1 },
              },
              [
                _c("upload-manager", {
                  attrs: {
                    type: _vm.asset.type_id,
                    "error-clear-fn": function () {
                      return _vm.errors.clear()
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (umProps) {
                        return [
                          _c("form", [
                            _vm.asset.type_id === 1
                              ? _c("div", [
                                  _c("label", {
                                    attrs: { for: "textarea-content" },
                                  }),
                                  _vm._v(" "),
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.asset.content,
                                        expression: "asset.content",
                                      },
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|min:10",
                                        expression: "'required|min:10'",
                                      },
                                    ],
                                    class: {
                                      "input-error":
                                        _vm.errors.has("content_text"),
                                    },
                                    attrs: {
                                      id: "textarea-content",
                                      type: "text",
                                      name: "content_text",
                                      placeholder: "Your text here",
                                    },
                                    domProps: { value: _vm.asset.content },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.asset,
                                          "content",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has("content_text"),
                                          expression:
                                            "errors.has('content_text')",
                                        },
                                      ],
                                      staticClass: "form-error",
                                    },
                                    [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(
                                            _vm.errors.first("content_text")
                                          ) +
                                          "\n                  "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.asset.type_id === 2
                              ? _c(
                                  "div",
                                  { staticClass: "modal__file-input" },
                                  [
                                    _c(
                                      "label",
                                      [
                                        _c("image-label"),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value:
                                                "ext:jpeg,jpg,bmp,png,gif,heic",
                                              expression:
                                                "'ext:jpeg,jpg,bmp,png,gif,heic'",
                                            },
                                          ],
                                          ref: "fileInputRef",
                                          class: {
                                            "input-error":
                                              _vm.errors.has("content_image") ||
                                              _vm.errors.has("filesize"),
                                          },
                                          attrs: {
                                            type: "file",
                                            name: "content_image",
                                          },
                                          on: {
                                            change: umProps.handleFileChange,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.errors.has("filesize"),
                                            expression:
                                              "errors.has('filesize')",
                                          },
                                        ],
                                        staticClass: "form-error",
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.errors.first("filesize")
                                            ) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.errors.has("content_image"),
                                            expression:
                                              "errors.has('content_image')",
                                          },
                                        ],
                                        staticClass: "form-error",
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.errors.first("content_image")
                                            ) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    umProps.upload.active &&
                                    !_vm.errors.has("filesize")
                                      ? _c(
                                          "div",
                                          { staticClass: "asset-processing" },
                                          [
                                            _vm._v(
                                              "\n                    Uploading file, please wait..." +
                                                _vm._s(umProps.upload.percent) +
                                                "%\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("hr"),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.asset.type_id === 4
                              ? _c(
                                  "div",
                                  { staticClass: "modal__file-input" },
                                  [
                                    _c(
                                      "label",
                                      [
                                        _c("file-label"),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value:
                                                "required|ext:pdf,docx,xlsx,pptx,doc,xls,ppt,txt,zip,webm",
                                              expression:
                                                "'required|ext:pdf,docx,xlsx,pptx,doc,xls,ppt,txt,zip,webm'",
                                            },
                                          ],
                                          ref: "fileInputRef",
                                          class: {
                                            "input-error":
                                              _vm.errors.has("content_file") ||
                                              _vm.errors.has("filesize"),
                                          },
                                          attrs: {
                                            type: "file",
                                            name: "content_file",
                                          },
                                          on: {
                                            change: umProps.handleFileChange,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.errors.has("filesize"),
                                            expression:
                                              "errors.has('filesize')",
                                          },
                                        ],
                                        staticClass: "form-error",
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.errors.first("filesize")
                                            ) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.errors.has("content_file"),
                                            expression:
                                              "errors.has('content_file')",
                                          },
                                        ],
                                        staticClass: "form-error",
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.errors.first("content_file")
                                            ) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    umProps.upload.active &&
                                    !_vm.errors.has("filesize")
                                      ? _c(
                                          "div",
                                          { staticClass: "asset-processing" },
                                          [
                                            _vm._v(
                                              "\n                    Uploading file, please wait..." +
                                                _vm._s(umProps.upload.percent) +
                                                "%\n                  "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("hr"),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "clearfix" }),
                            _vm._v(" "),
                            _c("div", { staticClass: "modal__actions" }, [
                              _c("span", { staticClass: "action__proceed" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "button modal__button",
                                    attrs: {
                                      type: "button",
                                      for: "evidenceModal",
                                      disabled: !umProps.isValid
                                        ? true
                                        : umProps.upload.active ||
                                          _vm.errors.items.length > 0,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        $event.preventDefault()
                                        return (function () {
                                          return _vm.sendEvidence(
                                            umProps.handleUpload
                                          )
                                        })($event)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                      Save\n                    "
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("div", { staticClass: "clearfix" }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }