var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isLoggedIn
    ? _c("div", { staticClass: "page-wrap" }, [
        _c(
          "main",
          [
            _c(
              "router-link",
              { staticClass: "back-to__button", attrs: { to: "/" } },
              [
                _c("span", { staticClass: "back-to__icon" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "9px",
                        height: "17px",
                        viewBox: "0 0 9 17",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("title", [_vm._v("Shape")]),
                      _vm._v(" "),
                      _c("desc", [_vm._v("Created with Sketch.")]),
                      _vm._v(" "),
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "Page-1",
                            stroke: "none",
                            "stroke-width": "1",
                            fill: "none",
                            "fill-rule": "evenodd",
                          },
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "Project-Gallery-Browser---med/tablet",
                                transform: "translate(-23.000000, -204.000000)",
                                fill: "#00A886",
                              },
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    id: "back-to-profile-copy",
                                    transform:
                                      "translate(23.000000, 203.000000)",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      id: "Shape",
                                      d: "M12.4801154,11.237282 L5.5086642,5.27180751 C5.22997992,5.04574032 4.90456303,4.93270673 4.53280847,4.93270673 C4.15381364,4.93270673 3.83208271,5.04574032 3.56748402,5.27180751 L-3.41463012,11.237282 C-3.68633743,11.4693101 -3.82219108,11.747339 -3.82219108,12.0711437 C-3.82219108,12.4010219 -3.68620578,12.6759016 -3.41463012,12.8957828 L-2.61030279,13.5829821 C-2.35281273,13.8151227 -2.0310818,13.9313055 -1.64497835,13.9313055 C-1.2588749,13.9313055 -0.937143967,13.8150103 -0.679653907,13.5829821 L4.53280847,9.13868114 L9.74527084,13.5828696 C10.0026293,13.8148978 10.3243602,13.9311931 10.7107269,13.9311931 C11.0894585,13.9311931 11.415007,13.8148978 11.6865827,13.5828696 L12.4907783,12.8956704 C12.755377,12.6696032 12.887808,12.3947235 12.887808,12.0710313 C12.887808,11.7472266 12.7519544,11.4691977 12.4801154,11.237282 L12.4801154,11.237282 Z",
                                      transform:
                                        "translate(4.532808, 9.432006) rotate(-90.000000) translate(-4.532808, -9.432006) ",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "back-to__text" }, [
                  _vm._v("Back to Home"),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("section", { staticClass: "primary-content" }, [
              _c("div", { staticClass: "content-box" }, [
                _c("h1", { staticClass: "page-title" }, [
                  _vm._v("\n          Login\n        "),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "login-welcome" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "login-buttons" },
                    [
                      !_vm.isLoggedIn
                        ? _c(
                            "router-link",
                            {
                              staticClass: "button__secondary",
                              attrs: { to: { name: "register" } },
                            },
                            [
                              _vm._v("\n              Create "),
                              _c("span", { staticClass: "sm-hidden" }, [
                                _vm._v("a "),
                              ]),
                              _vm._v("new account\n            "),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("span", { staticClass: "spacing" }, [_vm._v("or")]),
                      _vm._v(" "),
                      !_vm.isLoggedIn
                        ? _c(
                            "button",
                            {
                              staticClass: "button__secondary",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.registerWithGoogle($event)
                                },
                              },
                            },
                            [
                              _vm._v("\n              Join with "),
                              _c("span", { staticClass: "sm-hidden" }, [
                                _vm._v("a "),
                              ]),
                              _vm._v("Google"),
                              _c("span", { staticClass: "sm-hidden" }, [
                                _vm._v(" account"),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "login-grid" }, [
                  _c("div", { staticClass: "login-grid-login" }, [
                    _c(
                      "form",
                      {
                        staticClass: "form-horizontal",
                        attrs: { method: "POST" },
                      },
                      [
                        _c("div", { staticClass: "log-in-form" }, [
                          _c("div", { staticClass: "form-row short-row" }, [
                            _c("p", [_vm._v("Login")]),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "visuallyhidden",
                                attrs: { for: "username" },
                              },
                              [_vm._v("Username or Email")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.username,
                                  expression: "form.username",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                id: "username",
                                type: "text",
                                name: "username",
                                placeholder: "Username or Email",
                              },
                              domProps: { value: _vm.form.username },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.form,
                                    "username",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.errors.has("username")
                              ? _c("div", { staticClass: "form-error" }, [
                                  _c("span", [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(_vm.errors.first("username"))
                                      ),
                                    ]),
                                  ]),
                                ])
                              : _c("div", { staticClass: "form-error" }, [
                                  _c("span", [
                                    _c("strong", [
                                      _vm._v(_vm._s(_vm.errors.first("email"))),
                                    ]),
                                  ]),
                                ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row short-row" }, [
                            _c(
                              "label",
                              {
                                staticClass: "visuallyhidden",
                                attrs: { for: "password" },
                              },
                              [_vm._v("Username")]
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.form.password,
                                  expression: "form.password",
                                },
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              attrs: {
                                id: "password",
                                type: "password",
                                name: "password",
                                placeholder: "Password",
                              },
                              domProps: { value: _vm.form.password },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.form,
                                    "password",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.errors.has("password"),
                                    expression: "errors.has('password')",
                                  },
                                ],
                                staticClass: "form-error",
                              },
                              [
                                _c("span", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.errors.first("password"))
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "form-row short-row" }, [
                            _c(
                              "button",
                              {
                                staticClass: "button expanded",
                                attrs: { type: "submit" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    $event.preventDefault()
                                    return _vm.login($event)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    Log In\n                  "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "login-grid-google" }, [
                    _c("p", [
                      _vm._v("Or, Log in with your Google or G Suite Account."),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button",
                        attrs: { type: "submit" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.loginWithGoogle($event)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "text" }, [
                          _vm._v("Log In With Google"),
                        ]),
                        _vm._v(" "),
                        _vm._m(1),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm._m(2),
              ]),
            ]),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "instructional" }, [
      _vm._v("\n            Welcome to Mouse Create! "),
      _c("span", { staticClass: "sm-block" }, [
        _vm._v("Login below or Sign up here:"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("img", { attrs: { src: "img/icon-google.png", alt: "Google" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-center" }, [
      _c("a", { attrs: { href: "/password/reset" } }, [
        _vm._v("Forgot your username or password?"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }