<template>
  <tabs
    :nav-tabs="tabs"
    :options="tabOptions"
  />
</template>

<script>

import Tabs from '../tabs/Tabs.vue';

export default {
  name: 'GroupTabs',
  components: { Tabs },
  data() {
    const route = this.$route;

    return {
      tabs: [
        {
          title: 'Playlist',
          component: 'playlist',
          active: true,
          visible: true,
          checkOwnership: true,
        },
        {
          title: 'Group Work',
          component: 'group-work',
          active: route.name === 'group-work',
          visible: true,
        },
        {
          title: 'Group Edit',
          component: 'group-edit',
          active: route.name === 'group-edit',
          visible: false,
        },
        {
          title: 'Manage Playlist',
          component: 'manage-playlist',
          active: route.name === 'manage-playlist',
          visible: false,
        },
        {
          title: 'Notifications',
          component: 'group-notifications',
          active: route.name === 'group-notifications',
          visible: false,
        },
        {
          title: 'Badges',
          component: 'badges',
          active: route.name === 'group-badges',
          visible: true,
          checkOwnership: true,
        },
        {
          title: 'Members',
          component: 'members',
          active: route.name === 'group-members',
          visible: true,
          checkOwnership: true,
        },
        {
          title: 'Members Archived',
          component: 'members-archived',
          active: route.name === 'group-members-archived',
          visible: false,
        },
      ],
      owner: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.users.current;
    },
    activeGroup() {
      return this.$store.state.groups.active;
    },
    tabOptions() {
      return {
        groupId: this.activeGroup.id,
      };
    },
  },
  methods: {
    processNav() {
      _.each(this.tabs, (tab) => {
        if (tab.checkOwnership) {
          tab.visible = this.owner;
        }
      });
      this.updateTabs();
    },

    updateTabs() {
      $('body').trigger('vue.global.event', {
        _event: 'updateNavTabs',
        tabs: this.tabs,
      });
    },
  },
};
</script>
