<template>
  <li class="evidence-card-wrapper">
    <div class="evidence-card">
      <div class="evidence-card__content">
        <div
          v-if="isFile"
          class="evidence-card__asset"
        >
          <div
            class="evidence-card__file"
            href="#"
          >
            <svg
              width="130px"
              height="153px"
              viewBox="0 0 130 153"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs />
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="Project-Gallery---Large"
                  transform="translate(-497.000000, -305.000000)"
                >
                  <g
                    id="Work-Names-Overlay"
                    transform="translate(30.000000, 200.000000)"
                  >
                    <g id="Row-1">
                      <g
                        id="Cards--2---file"
                        transform="translate(385.000000, 77.000000)"
                      >
                        <g
                          id="file-icon"
                          transform="translate(3.000000, 28.000000)"
                        >
                          <g
                            id="Group-2"
                            transform="translate(79.000000, 0.000000)"
                          >
                            <path
                              id="Shape"
                              d="M120.721488,38.2300073 L89.1796677,6.59155225 C85.808341,3.21114139 81.3599764,1.01312755 75.8333333,-1.0658141e-14 L75.8333333,51.6147416 L127.291667,51.6147416 C126.280393,46.0699219 124.089092,41.6091736 120.721488,38.2300073 L120.721488,38.2300073 Z"
                              fill="#13C3A0"
                            />
                            <path
                              id="Shape"
                              d="M67.8434851,60.6130415 C65.9474128,58.7652682 65,56.5199805 65,53.8783941 L65,0 L9.74962602,0 C7.04201987,0 4.73955736,0.923886635 2.8434851,2.77287555 C0.947412834,4.62064882 -1.42108547e-14,6.86593647 -1.42108547e-14,9.50752286 L-1.42108547e-14,142.620137 C-1.42108547e-14,145.261723 0.947412834,147.507011 2.8434851,149.354784 C4.73955736,151.202557 7.04201987,152.12766 9.74962602,152.12766 L120.252867,152.12766 C122.960473,152.12766 125.261689,151.202557 127.156515,149.354784 C129.052587,147.505795 130,145.261723 130,142.620137 L130,63.385917 L74.7508726,63.385917 C72.0407733,63.385917 69.7383108,62.4620304 67.8434851,60.6130415 Z"
                              fill="#13C3A0"
                            />
                            <path
                              id="Line"
                              d="M13,129 C13,129 13.4573964,129 13.9988691,129 L115.591423,129"
                              stroke="#00A886"
                              stroke-width="5"
                              stroke-linecap="square"
                            />
                            <path
                              id="Line-Copy"
                              d="M13,108.6 C13,108.6 13.4573964,108.6 13.9988691,108.6 L115.591423,108.6"
                              stroke="#00A886"
                              stroke-width="5"
                              stroke-linecap="square"
                            />
                            <path
                              id="Line-Copy-2"
                              d="M13,88.2 C13,88.2 13.4573964,88.2 13.9988691,88.2 L115.591423,88.2"
                              stroke="#00A886"
                              stroke-width="5"
                              stroke-linecap="square"
                            />
                            <path
                              id="Line-Copy-3"
                              d="M13,68.5 C13,68.5 13.4514852,68.5 14.0043171,68.5 L54.460022,68.5"
                              stroke="#00A886"
                              stroke-width="5"
                              stroke-linecap="square"
                            />
                            <path
                              id="Line-Copy-4"
                              d="M13,48.5 C13,48.5 13.4514852,48.5 14.0043171,48.5 L54.460022,48.5"
                              stroke="#00A886"
                              stroke-width="5"
                              stroke-linecap="square"
                            />
                            <path
                              id="Line-Copy-5"
                              d="M13,28.5 C13,28.5 13.4514852,28.5 14.0043171,28.5 L54.460022,28.5"
                              stroke="#00A886"
                              stroke-width="5"
                              stroke-linecap="square"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <div class="evidence-card__file-info">
              {{ fileExtension }} <span>{{ fileSize }}</span>
            </div>
          </div>
        </div>

        <div
          v-if="isImage"
          class="evidence-card__asset"
          :style="{ 'background-image': 'url(' + currentImages[0] + ')' }"
        />

        <div
          v-if="isText"
          class="evidence-card__asset evidence-card__text"
          v-text="currentContent"
        />

        <div
          v-if="isBlank( evidence )"
          class="evidence-card__asset evidence-card__text"
        />

        <div class="evidence-card__title">
          {{ evidence.title }}
        </div>

        <a
          href="#"
          class="evidence-card__overlay"
          @click.stop.prevent="viewEvidenceModal($event)"
        />
      </div>

      <div class="evidence-card__actions">
        <div class="evidence-card__action-group">
          <div
            class="evidence-card__creator-image"
            v-html="evidence.user.avatar"
          />
          <div class="evidence-card__collaborator-count">
            <span v-if="collaboratorCount > 0">+{{ collaboratorCount }}</span>
          </div>
        </div>

        <div class="evidence-card__action-group">
          <div class="evidence-card__reactions">
            <div class="evidence-card__question">
              <svg
                width="25px"
                height="24px"
                viewBox="0 0 25 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>question</title>
                <desc>Created with Sketch.</desc>
                <defs />
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="icons-on-light-green-250w-avatar-icons-original"
                    transform="translate(-99.000000, -14.000000)"
                    fill="#A2DACF"
                  >
                    <g
                      id="comment-icon"
                      transform="translate(99.000000, 14.000000)"
                    >
                      <path
                        id="question"
                        d="M12.3097945,0 C5.51218568,-0.00102276229 0.00105125597,5.36029716 2.14793685e-07,11.9745009 C-0.000525305796,14.7129469 0.963279455,17.3685492 2.7306052,19.4989631 C2.37009807,20.6848559 1.78151501,21.7940416 0.996387252,22.7672 C0.731524875,23.1031774 0.797740469,23.584387 1.14353302,23.8421231 C1.29067878,23.9520701 1.47303443,24.0078106 1.65854319,23.9991171 C3.52729441,23.9607636 5.3697696,23.5593294 7.07666047,22.8178267 C8.71523367,23.5685342 10.5046313,23.9561611 12.3161007,23.9530928 C19.1084544,23.9484904 24.6143336,18.5897274 24.6153846,11.9785919 C24.6164357,5.36438821 19.1068778,0.00102276229 12.3097945,0"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>

            <div class="evidence-card__reaction-count">
              {{ commentCount | numberUpperLimit('99') }}
            </div>
          </div>
        </div>

        <div class="evidence-card__action-group">
          <div class="evidence-card__views">
            <div class="evidence-card__views-icon">
              <svg
                width="34px"
                height="23px"
                viewBox="0 0 34 23"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Views</title>
                <desc>Views icon.</desc>
                <defs />
                <g
                  stroke="none"
                  stroke-width="1"
                  fill="none"
                  fill-rule="evenodd"
                >
                  <g
                    id="icons-on-light-green"
                    transform="translate(-219.000000, -19.000000)"
                    fill="#A2DBCF"
                  >
                    <g id="icons-on-white---green">
                      <g
                        id="eye"
                        transform="translate(219.000000, 19.000000)"
                      >
                        <path
                          id="Fill-1"
                          d="M25.8352962,15.2260697 C24.5963821,16.3272818 23.1511004,17.3936455 21.6143363,18.163797 C20.0754446,18.9367364 18.4627996,19.4085848 16.8380988,19.4078879 C15.1616287,19.4092818 13.4972146,18.9053727 11.9136454,18.0885242 C9.54219208,16.8695242 7.40830958,14.9486758 5.90700375,13.3317061 C5.21840292,12.5929182 4.66808958,11.9287061 4.26811958,11.4199182 C4.99714292,10.4957364 6.24527625,9.03140303 7.84161042,7.61585758 C9.08052458,6.51464545 10.5258062,5.44828182 12.0625704,4.6781303 C13.6014621,3.90519091 15.2133979,3.43334242 16.8380988,3.43403939 C18.5145687,3.43264545 20.1796921,3.93655455 21.7625521,4.75340303 C24.1354238,5.97240303 26.2685971,7.89325152 27.7706121,9.51022121 C28.4585037,10.2490091 29.0095262,10.9139182 29.4087871,11.4213121 C28.6804729,12.3461909 27.4323396,13.8105242 25.8352962,15.2260697 L25.8352962,15.2260697 Z M33.0283737,10.4671606 C32.9801504,10.3988576 31.2717679,7.88279697 28.4464479,5.30888788 C27.0309512,4.02228182 25.3332062,2.71825152 23.3872529,1.71322121 C21.4434271,0.710281818 19.2308271,0.000766666667 16.8380987,6.96969697e-05 C14.4453704,0.000766666667 12.2327704,0.710281818 10.2896537,1.71322121 C7.37285125,3.22355455 5.00636208,5.39670606 3.32989208,7.19070606 C1.65696792,8.98749394 0.68399125,10.4162818 0.648532917,10.4671606 L-0.000354583333,11.4206152 L0.648532917,12.3747667 C0.697465417,12.4430697 2.40513875,14.9591303 5.23045875,17.5330394 C6.64595542,18.8196455 8.34370042,20.1229788 10.2896537,21.1287061 C12.2327704,22.1316455 14.4453704,22.8411606 16.8380987,22.8418576 C19.2308271,22.8411606 21.4434271,22.1316455 23.3872529,21.1287061 C26.3047646,19.6190697 28.6712537,17.4459182 30.3477237,15.6512212 C32.0206479,13.8544333 32.9929154,12.4256455 33.0283737,12.3747667 L33.6772612,11.4206152 L33.0283737,10.4671606 Z"
                        />
                        <path
                          id="Fill-4"
                          d="M16.8380987,6.63870606 C14.1510662,6.63940303 11.9746337,8.7791 11.9739246,11.4213121 C11.9746337,14.0628273 14.1510662,16.2025242 16.8380987,16.2032212 C19.5258404,16.2025242 21.7036912,14.0628273 21.7036912,11.4213121 C21.7036912,8.7791 19.5258404,6.63940303 16.8380987,6.63870606"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div class="evidence-card__views-count">
              {{ totalViews | numberUpperLimit('500') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'Card',
  props: {
    evidence: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      totalViews: 0,
      isImage: false,
      isFile: false,
      isText: false,
      currentImages: [],
      currentContent: '',
    };
  },
  computed: {
    viewedUrl() {
      return laroute.route('api.evidence.viewed');
    },
    commentCount() {
      return this.evidence.totals.comments;
    },
    collaboratorCount() {
      return this.evidence.collaborators.data.length;
    },
  },
  watch: {},
  mounted() {
    this.totalViews = this.evidence.totals.views;
    this.hasImages();
    this.hasFiles();
    this.hasText();
  },
  methods: {
    hasImages() {
      const $this = this;

      _.forEach(this.evidence.assets.data, (asset) => {
        if (asset.type.name === 'Image') {
          if (asset.upload) {
            $this.currentImages.push(asset.upload.path_url);
            $this.isImage = true;
          }
        }
      });
    },

    hasFiles() {
      if (!this.isImage) {
        const $this = this;

        _.forEach(this.evidence.assets.data, (asset) => {
          if (asset.type.name === 'File') {
            $this.fileSize = asset.upload.size;
            $this.fileExtension = asset.upload.ext;
            $this.isFile = true;
          }
        });
      }
    },

    hasText() {
      if (!this.isImage && !this.isFile) {
        const $this = this;

        _.forEach(this.evidence.assets.data, (asset) => {
          if (asset.type.name === 'Text') {
            $this.currentContent = asset.content;
            $this.isText = true;
          }
        });
      }
    },

    isBlank(evidence) {
      return evidence.assets.data.length < 1;
    },

    evidenceContent() {
      return this.evidence.assets.data[0].content;
    },

    userViewed() {
      this.totalViews += 1;

      axios.post(this.viewedUrl, { id: this.evidence.id }).then(() => {});
    },

    viewEvidenceModal(event) {
      const wrapper = $(event.target).closest('.slider__wrapper');

      this.userViewed();

      bus.$emit('open-project-review-modal', {
        isEditing: false,
        groupId: this.evidence.group.id,
        evidenceId: this.evidence.id,
        wrapper,
      });
    },
  },
};
</script>
