<template>
  <section
    class="course__row course__bottom-border"
    @keydown.esc="closeBadgeModal"
  >
    <h2 class="row__title">
      Badges
    </h2>

    <div class="badge__items">
      <div
        v-if="badgeVisible"
        class="badge__modal"
      >
        <a
          class="badge__exit"
          href="#"
          @click.stop.prevent="closeBadgeModal"
        >
          <svg
            width="20px"
            height="19px"
            viewBox="0 0 20 19"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>X</title>
            <desc>Created with Sketch.</desc>
            <g
              id="Page-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
              stroke-linecap="square"
            >
              <g
                id="Courses-Page---Open-v2"
                transform="translate(-926.000000, -1288.000000)"
                fill-rule="nonzero"
                stroke="#00A886"
                stroke-width="3.2"
              >
                <g
                  id="Badge-info-Modal-/-drawer?"
                  transform="translate(103.000000, 1267.000000)"
                >
                  <g
                    id="X"
                    transform="translate(833.142136, 30.142136) rotate(-315.000000) translate(-833.142136, -30.142136) translate(823.142136, 20.142136)"
                  >
                    <path
                      id="Line-2"
                      d="M0.434782609,10 L20,10"
                    />
                    <path
                      id="Line-2-Copy"
                      d="M0.217391304,10.2173913 L19.7826087,10.2173913"
                      transform="translate(10.000000, 10.217391) rotate(-270.000000) translate(-10.000000, -10.217391) "
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </a>
        <div class="badge__modal-body">
          <div class="badge__modal-image">
            <img
              :src="activeBadge.image"
              :alt="activeBadge.label"
            >
          </div>
          <div class="badge__modal-content">
            <h2>
              {{ activeBadge.label }}
              <span
                v-if="activeBadge.shortDescription"
                class="sub-heading"
              >{{ activeBadge.shortDescription }}</span>
            </h2>
            <div v-html="activeBadge.description" />
          </div>
        </div>
      </div>

      <badge-item
        v-for="badge in badges"
        :key="badge.id"
        :badge="badge"
      />
    </div>

    <div class="clearfix" />
  </section>
</template>

<script>

import BadgeItem from '../badges/BadgeItem.vue';

export default {
  name: 'CourseBadges',
  components: {
    BadgeItem,
  },
  props: {
    badges: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      activeBadge: {
        image: '',
        label: '',
      },
      badgeVisible: false,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    bus.$on('open:badge-modal', this.activateBadgeModal);
  },
  methods: {
    activateBadgeModal(data) {
      this.badgeVisible = true;
      this.activeBadge = data.badge;
    },
    closeBadgeModal() {
      this.badgeVisible = false;
    },
  },
};
</script>
