export default {
  data() {
    return {
      field: '',
      order: '',
    };
  },

  methods: {
    sortMe(newField) {
      if (this.field === newField) {
        // reverse sort
        this.order = (this.order === 'desc') ? 'asc' : 'desc';
      }

      this.field = newField;
      this.clicked = newField;
    },

    sortClasses(field) {
      return {
        sort__active: this.field === field,
        sort__descend: this.field === field && this.order === 'desc',
      };
    },
  },
};
