var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm._t("default", null, null, {
        handleUpload: _vm.handleUpload,
        upload: _vm.upload,
        handleFileChange: _vm.handleFileChange,
        file: _vm.file,
        isValid: _vm.isValid,
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }