var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasAccess
    ? _c("div", { class: { "profile-view": !_vm.isAdminEdit } }, [
        _c(
          "div",
          { staticClass: "profile-header", attrs: { id: "profile-header" } },
          [
            _vm.isAdminEdit
              ? _c("h1", { staticClass: "page-title" }, [
                  _vm._v("\n      Edit Profile\n    "),
                ])
              : _c("h1", { staticClass: "page-title" }, [
                  _vm._v("\n      Your Profile\n    "),
                ]),
            _vm._v(" "),
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isAdminEdit,
                    expression: "isAdminEdit",
                  },
                ],
                staticClass: "instructional",
              },
              [
                _vm._v(
                  "\n      You are about to update this users information.\n    "
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.formErrors,
                expression: "formErrors",
              },
            ],
          },
          _vm._l(_vm.formErrors, function (error) {
            return _c("p", {
              key: error[0],
              staticClass: "form-error",
              domProps: { textContent: _vm._s(error[0]) },
            })
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { attrs: { id: "profileHandler" } }, [
          _c(
            "form",
            {
              staticClass: "simple-edit",
              attrs: {
                id: "profile_form",
                method: "post",
                enctype: "multipart/form-data",
              },
            },
            [
              _c("input", {
                attrs: { type: "hidden", name: "_method", value: "POST" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("label", { attrs: { for: "username" } }, [
                  _vm._v("Username"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.username,
                      expression: "user.username",
                    },
                  ],
                  attrs: {
                    id: "username",
                    type: "text",
                    name: "username",
                    autocomplete: "off",
                  },
                  domProps: { value: _vm.user.username },
                  on: {
                    keyup: _vm.checkUsernameUpdate,
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "username", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("label", { attrs: { for: "password" } }, [
                  _vm._v("Password"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.password,
                      expression: "user.password",
                    },
                  ],
                  attrs: {
                    id: "password",
                    type: "password",
                    name: "password",
                    autocomplete: "off",
                  },
                  domProps: { value: _vm.user.password },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "password", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("label", { attrs: { for: "password_confirmation" } }, [
                  _vm._v("Confirm Password"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.password_confirmation,
                      expression: "user.password_confirmation",
                    },
                  ],
                  attrs: {
                    id: "password_confirmation",
                    type: "password",
                    name: "password_confirmation",
                    autocomplete: "off",
                  },
                  domProps: { value: _vm.user.password_confirmation },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.user,
                        "password_confirmation",
                        $event.target.value
                      )
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("label", { attrs: { for: "email" } }, [
                  _vm._v("Email Address"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.emailValue,
                      expression: "emailValue",
                    },
                  ],
                  attrs: { id: "email", type: "text", name: "email" },
                  domProps: { value: _vm.emailValue },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.emailValue = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _vm.showBadge
                ? _c("div", { staticClass: "form-row" }, [
                    _c("label", { attrs: { for: "can_award" } }, [
                      _vm._v("Earn Badges on Mouse Create & Credly"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticStyle: { "margin-bottom": "0.8rem" } }, [
                      _vm._v(
                        "\n          Mouse badges are shared with Credly.com, a service that lets you share your badges publicly on college or\n          career applications, and on social media. If you earn a badge, Credly will contact you with the email\n          address associated with your Mouse account. In that email, you will be able to choose whether or not to\n          accept and share your badge on Credly.\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.canAward,
                          expression: "user.canAward",
                        },
                      ],
                      attrs: {
                        id: "can_award",
                        type: "checkbox",
                        name: "can_award",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.user.canAward)
                          ? _vm._i(_vm.user.canAward, null) > -1
                          : _vm.user.canAward,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.user.canAward,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.user,
                                  "canAward",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.user,
                                  "canAward",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.user, "canAward", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(
                      "\n         Yes, I would like to earn badges on Mouse Create that will be shared with Credly ("
                    ),
                    _c("a", { attrs: { href: "https://credly.com" } }, [
                      _vm._v("Credly.com"),
                    ]),
                    _vm._v(")\n      "),
                  ])
                : _c(
                    "div",
                    { staticClass: "form-row" },
                    [
                      _c(
                        "label",
                        {
                          staticStyle: { "margin-bottom": "0.8rem" },
                          attrs: { for: "can_award" },
                        },
                        [_vm._v("Earn Badges on Mouse Create & Credly")]
                      ),
                      _vm._v(
                        "\n        First make sure you have entered a valid email address above.\n        "
                      ),
                      !_vm.user.of_age
                        ? [
                            _vm._v(
                              "\n          If you are under 13, you will not be able to earn badges until your 13th birthday.\n        "
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("label", { attrs: { for: "first_name" } }, [
                  _vm._v("First Name"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.first_name,
                      expression: "user.first_name",
                    },
                  ],
                  attrs: {
                    id: "first_name",
                    type: "text",
                    name: "first_name",
                    autocomplete: "off",
                  },
                  domProps: { value: _vm.user.first_name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "first_name", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("label", { attrs: { for: "last_name" } }, [
                  _vm._v("Last Name"),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.last_name,
                      expression: "user.last_name",
                    },
                  ],
                  attrs: {
                    id: "last_name",
                    type: "text",
                    name: "last_name",
                    autocomplete: "off",
                  },
                  domProps: { value: _vm.user.last_name },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user, "last_name", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("label", { attrs: { for: "profileimage" } }, [
                  _vm._v("Profile Image"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.user.avatar,
                        expression: "user.avatar",
                      },
                    ],
                  },
                  [
                    _c("div", [_vm._v("Current Image")]),
                    _vm._v(" "),
                    _c("div", {
                      domProps: { innerHTML: _vm._s(_vm.user.avatar) },
                    }),
                  ]
                ),
                _vm._v(" "),
                _vm.image
                  ? _c("div", [
                      _c("span", { staticClass: "warning label" }, [
                        _vm._v("New Image"),
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        attrs: { src: _vm.image, alt: _vm.user.username },
                      }),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button",
                          on: { click: _vm.removeImage },
                        },
                        [_vm._v("\n            Remove image\n          ")]
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _c("input", {
                    attrs: {
                      id: "profileimage",
                      type: "file",
                      name: "profileimage",
                    },
                    on: { change: _vm.onFileChange },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row" }, [
                _c("label", { attrs: { for: "bio" } }, [
                  _vm._v("\n          Brief Bio (140 characters)\n        "),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.user.profile.bio,
                      expression: "user.profile.bio",
                    },
                  ],
                  attrs: { id: "bio", type: "text", name: "bio" },
                  domProps: { value: _vm.user.profile.bio },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.user.profile, "bio", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-row align-right" }, [
                _c(
                  "button",
                  {
                    staticClass: "button",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.saveProfile($event)
                      },
                    },
                  },
                  [_vm._v("\n          Update Profile\n        ")]
                ),
                _vm._v(" "),
                _vm._m(0),
              ]),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "cancel" }, [
      _vm._v("or "),
      _c("a", { attrs: { href: "javascript:history.go(-1)" } }, [
        _vm._v("Cancel"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }