<template>
  <section class="supporting__slider">
    <h1>{{ sliderHeader }}</h1>

    <h4 v-if="loading">
      Loading...
    </h4>

    <ul>
      <li class="home__arrows home__prev" />
      <li class="home__arrows home__next" />
    </ul>

    <ul class="supporting__slider-wrapper">
      <slick
        ref="slick"
        :options="slickOptions"
      >
        <home-slide
          v-for="(slide, index) in slides"
          :key="index"
          :slide="slide"
        />
      </slick>
    </ul>
  </section>
</template>

<script>
import Slick from 'vue-slick';
import HomeSlide from './HomeSlide.vue';

export default {
  name: 'HomeSlider',
  components: { HomeSlide, Slick },
  props: {
    sliderHeader: {
      type: String,
      default() {
        return '';
      },
    },
    slides: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      loading: 0,
      // slideWrapper: '.supporting__slider-wrapper',
      slickOptions: {
        prevArrow: '.home__prev',
        nextArrow: '.home__next',
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        responsive: [
          {
            breakpoint: 768,
            dots: false,
          },
        ],
      },
    };
  },
  watch: {
    slides() {
      const currIndex = this.$refs.slick.currentSlide();

      this.$refs.slick.destroy();

      this.$nextTick(() => {
        this.$refs.slick.create();
        this.$refs.slick.goTo(currIndex, true);
      });
    },
  },
};
</script>
