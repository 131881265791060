var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "tab-content--padding main--overflow-visible" },
    [
      _c("section", { staticClass: "group-tab" }, [
        _c("div", { staticClass: "wrap-12" }, [
          _c("h1", { staticClass: "page-title table-title" }, [
            _vm._v("\n        Manage Archvied Members\n      "),
          ]),
          _vm._v(" "),
          _c("table", [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.members, function (member) {
                return _c("tr", { key: member.id }, [
                  _c(
                    "td",
                    { attrs: { "data-label": "Name/Email" } },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: _vm.profileView(member.token) } },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(member.short_name) +
                              " (" +
                              _vm._s(member.username) +
                              ")\n              "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", { attrs: { "data-label": "Role" } }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(member.roleName) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { attrs: { "data-label": "Last Login" } }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm._f("formatDate")(member.lastLogin, "MMM DD, YYYY")
                        ) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticClass: "text-right",
                      attrs: { "data-label": "Actions" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "member-change-status modal-trigger",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.changeStatus(member.id)
                            },
                          },
                        },
                        [_vm._v("Change Status")]
                      ),
                    ]
                  ),
                ])
              }),
              0
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "modal",
            class: { "active-modal": _vm.isModalVisible },
            attrs: {
              id: "update-status",
              role: "dialog",
              "aria-hidden": !_vm.isModalVisible,
            },
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.isModalVisible,
                  expression: "isModalVisible",
                },
              ],
              staticClass: "modal-state",
              attrs: { id: "modal-1", type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.isModalVisible)
                  ? _vm._i(_vm.isModalVisible, null) > -1
                  : _vm.isModalVisible,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.isModalVisible,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.isModalVisible = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.isModalVisible = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.isModalVisible = $$c
                  }
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "modal-fade-screen" }, [
              _c("div", { staticClass: "modal-inner" }, [
                _c("button", {
                  staticClass: "modal-close",
                  attrs: {
                    id: "close-button",
                    "data-close-modal": "",
                    for: "modal-1",
                    "aria-label": "Close this dialog window",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      _vm.isModalVisible = false
                    },
                  },
                }),
                _vm._v(" "),
                _vm._m(1),
                _vm._v(" "),
                _c("div", { staticClass: "form-row" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.status,
                        expression: "status",
                      },
                    ],
                    attrs: {
                      id: "status-active",
                      type: "radio",
                      name: "status",
                      value: "active",
                    },
                    domProps: { checked: _vm._q(_vm.status, "active") },
                    on: {
                      change: function ($event) {
                        _vm.status = "active"
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "status-active" } }, [
                    _vm._v("Active"),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.status,
                        expression: "status",
                      },
                    ],
                    attrs: {
                      id: "status-archived",
                      type: "radio",
                      name: "status",
                      value: "archived",
                    },
                    domProps: { checked: _vm._q(_vm.status, "archived") },
                    on: {
                      change: function ($event) {
                        _vm.status = "archived"
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "status-archived" } }, [
                    _vm._v("Archived"),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-row short-row align-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "do-update button float-none",
                      attrs: { "data-close": "" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.handleStatus($event)
                        },
                      },
                    },
                    [_vm._v("\n              Update Status\n            ")]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "cancel" }, [
                    _vm._v("or "),
                    _c(
                      "a",
                      {
                        staticClass: "modal-close-text",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            _vm.isModalVisible = true
                          },
                        },
                      },
                      [_vm._v("Cancel")]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name/Email")]),
        _vm._v(" "),
        _c("th", [_vm._v("Role")]),
        _vm._v(" "),
        _c("th", [_vm._v("Last Login")]),
        _vm._v(" "),
        _c("th", [_vm._v("Actions")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row-row" }, [
      _c("h3", { attrs: { id: "name-placeholder" } }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "Archiving a user will prevent them from accessing your group. The work they submitted and collaborated on will still be available on your group page and on their profile"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }