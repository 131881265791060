var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "course-description" },
    [
      _c("vue-editor", {
        attrs: { id: "course-editor", "editor-toolbar": _vm.customToolbar },
        model: {
          value: _vm.description,
          callback: function ($$v) {
            _vm.description = $$v
          },
          expression: "description",
        },
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.description,
            expression: "description",
          },
        ],
        attrs: { type: "hidden", name: "description" },
        domProps: { value: _vm.description },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.description = $event.target.value
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }