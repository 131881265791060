var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "26",
        height: "31",
        viewBox: "0 0 26 31",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("title", [_vm._v("click to view")]),
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            d: "M2.61 7.1l4.484-.007V2.61L2.61 7.1zM1 25.1H.993c-.37-.004-.664-.305-.66-.673V7.76c0-.176.07-.345.196-.47L7.28.54c.126-.132.3-.205.48-.207h11.466c.37 0 .667.3.667.667v14.636H18.56V1.666H8.426v6.1c0 .37-.298.667-.666.667H1.667v15.334h9.568V25.1H1z",
            fill: "#00A886",
          },
        }),
        _c("path", {
          attrs: {
            d: "M2.61 7.1l4.484-4.49v4.483L2.61 7.1zM1 25.1h10.234v-1.333H1.666V8.433H7.76c.37 0 .667-.298.667-.666v-6.1H18.56v13.97h1.333V1c0-.368-.298-.667-.667-.667H7.76c-.18.002-.354.075-.48.207L.53 7.29c-.125.125-.196.294-.197.47v16.667c-.003.368.292.67.66.673H1z",
            stroke: "#00A886",
            "stroke-width": ".625",
          },
        }),
        _c("path", {
          attrs: { fill: "#00A886", d: "M4.597 12.376H15.63v-1.333H4.598" },
        }),
        _c("path", {
          attrs: {
            stroke: "#00A886",
            "stroke-width": ".625",
            d: "M4.597 12.376H15.63v-1.333H4.598z",
          },
        }),
        _c("path", {
          attrs: { fill: "#00A886", d: "M4.597 16.376H15.63v-1.333H4.598" },
        }),
        _c("path", {
          attrs: {
            stroke: "#00A886",
            "stroke-width": ".625",
            d: "M4.597 16.376H15.63v-1.333H4.598z",
          },
        }),
        _c("path", {
          attrs: {
            d: "M18.41 27.224c-2.21 0-4-1.79-4-4s1.79-4 4-4c2.208 0 4 1.79 4 4s-1.792 4-4 4zm4.21-.733c1.814-2.334 1.39-5.697-.945-7.51-2.334-1.815-5.697-1.392-7.512.943-1.813 2.334-1.39 5.697.944 7.512 1.934 1.5 4.636 1.5 6.568 0l2.904 2.902.942-.942-2.903-2.904z",
            fill: "#00A886",
          },
        }),
        _c("path", {
          attrs: {
            d: "M18.41 27.224c-2.21 0-4-1.79-4-4s1.79-4 4-4c2.208 0 4 1.79 4 4s-1.792 4-4 4zm4.21-.733c1.814-2.334 1.39-5.697-.945-7.51-2.334-1.815-5.697-1.392-7.512.943-1.813 2.334-1.39 5.697.944 7.512 1.934 1.5 4.636 1.5 6.568 0l2.904 2.902.942-.942-2.903-2.904z",
            stroke: "#00A886",
            "stroke-width": ".625",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }