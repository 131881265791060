var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select",
    _vm._b(
      {
        staticClass: "filter--form--criteria",
        domProps: { value: _vm.value },
        on: { change: _vm.updateInput },
      },
      "select",
      _vm.$attrs,
      false
    ),
    [
      _c("option", { domProps: { value: null } }, [
        _vm._v("\n    Project Name\n  "),
      ]),
      _vm._v(" "),
      _vm._l(_vm.projects, function (project) {
        return _c(
          "option",
          { key: project.id, domProps: { value: project.id } },
          [_vm._v("\n    " + _vm._s(project.title) + "\n  ")]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }