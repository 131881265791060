<template>
  <div>
    <router-link
      :class="{'router-link-active': currentRoute}"
      aria-label="profile"
      :to="'/profile/' + currentUser.token"
    >
      <!--  eslint-disable    -->
      <span
        class="profile-pic"
        v-html="currentUser.avatar"
      />
      <!--  eslint-enable    -->

      <svg
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="12"
        viewBox="0 0 42 12"
        aria-labelledby="navProfileTitle navProfileDesc"
      ><title id="navProfileTitle">Profile</title><desc id="navProfileDesc">Profile</desc><g fill="none"><g fill="#E7E7E7"><path
        class="text"
        d="M4.7 2.2L2.4 2.2 2.4 5.6 4.6 5.6C5.9 5.6 6.6 4.9 6.6 3.9 6.6 2.9 5.9 2.2 4.7 2.2ZM4.6 6.9L2.4 6.9 2.4 11 1 11 1 0.9 4.7 0.9C6.9 0.9 8 2.2 8 3.9 8 5.7 6.9 6.9 4.6 6.9ZM13.5 3.3C13.3 3.3 13.1 3.3 13 3.3 12 3.3 11.2 3.9 10.8 4.9L10.8 3.4 9.5 3.4 9.5 11 10.8 11 10.8 7.6C10.8 6.9 10.9 6.4 10.9 6.2 11.2 5.2 11.8 4.6 12.9 4.6 13.1 4.6 13.3 4.6 13.5 4.7L13.5 3.3ZM14.2 7.2C14.2 4.9 15.9 3.3 18.1 3.3 20.3 3.3 22 4.9 22 7.2 22 9.4 20.3 11.2 18.1 11.2 15.9 11.2 14.2 9.5 14.2 7.2ZM15.6 7.2C15.6 8.8 16.7 9.9 18.1 9.9 19.6 9.9 20.7 8.7 20.7 7.2 20.7 5.7 19.6 4.5 18.1 4.5 16.7 4.5 15.6 5.7 15.6 7.2ZM29.4 3.4L29.4 11 28.1 11 28.1 4.6 25.4 4.6 25.4 11 24.1 11 24.1 4.6 22.7 4.6 22.7 3.4 24.1 3.4 24.1 2.9C24.1 1.7 24.8 0.8 26.2 0.8 26.5 0.8 26.8 0.8 27 0.9L26.9 2.1C26.7 2 26.6 2 26.3 2 25.7 2 25.4 2.3 25.4 2.9L25.4 3.4 29.4 3.4ZM28 1.6C28 1.1 28.3 0.8 28.8 0.8 29.2 0.8 29.6 1.1 29.6 1.6 29.6 2.1 29.2 2.4 28.8 2.4 28.3 2.4 28 2.1 28 1.6ZM32.7 0.9L31.3 0.9 31.3 11 32.7 11 32.7 0.9ZM35.6 6.5L40.1 6.5C40 5.3 39.2 4.5 37.9 4.5 36.7 4.5 35.8 5.4 35.6 6.5ZM41.4 7.6L35.5 7.6C35.6 9 36.6 9.9 38 9.9 39.1 9.9 39.7 9.4 40 8.7L41.3 8.9C40.9 10.2 39.7 11.2 38 11.2 35.8 11.2 34.2 9.6 34.2 7.2 34.2 4.9 35.9 3.3 37.9 3.3 40.1 3.3 41.5 5 41.5 7 41.5 7.2 41.5 7.4 41.4 7.6Z"
      /></g></g></svg>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ProfileImage',
  computed: {
    currentUser() {
      return this.$store.state.users.current;
    },
    currentRoute() {
      return this.$route.name === 'profile-work' || this.$route.name === 'profile-settings';
    },
  },
};
</script>
