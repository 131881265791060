<template>
  <tabs
    :nav-tabs="tabs"
    :options="tabOptions"
  />
</template>

<script>

import Tabs from '../tabs/Tabs.vue';

export default {
  name: 'ProjectTabs',
  components: { Tabs },
  props: {
    tabOptions: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    const route = this.$route;

    return {
      tabs: [
        {
          title: 'Project Finder',
          component: 'project-finder',
          active: true,
          visible: true,
          checkOwnership: true,
        },
        {
          title: 'Courses',
          component: 'course-module',
          active: route.name === 'course-module',
          visible: false,
        },
      ],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.users.current;
    },
    owner() {
      return this.currentUser.owner;
    },
  },
  watch: {
    currentUser(user) {
      // TODO: Fix this or figure out whats happening here
      // eslint-disable-next-line vue/no-mutating-props
      this.tabOptions.groupId = user.defaultGroupId;
    },
    // $route(to, from) {
    //     let tab = _.find(this.tabs, function(t) {
    //         return t.component === to.name;
    //     });

    //     if( ! tab.visible ) {
    //         bus.$emit('set-active-tab', to.name);
    //     }
    // }
  },
  mounted() {
    document.body.className = 'no-js language-markup body--tab-height project-cards projects-page';
  },
  methods: {

    processNav() {
      const $this = this;
      _.each(this.tabs, (tab) => {
        if (tab.checkOwnership) {
          tab.visible = $this.owner;
        }
      });
      this.updateTabs();
    },

    updateTabs() {
      $('body').trigger('vue.global.event', {
        _event: 'updateNavTabs',
        tabs: this.tabs,
      });
    },

  },
};
</script>
