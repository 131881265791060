var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "full-wrap" }, [
      _c("div", { staticClass: "page-wrap" }, [
        _c("main", [
          _c("section", { staticClass: "primary-content" }, [
            _c("div", { staticClass: "content-box" }, [
              _c("img", { attrs: { src: "/img/oops.gif", alt: "" } }),
              _vm._v(" "),
              _c("p", [
                _vm._v("Oh no! Looks like something went wrong. Email "),
                _c("a", { attrs: { href: "mailto:help@mouse.org" } }, [
                  _vm._v("help@mouse.org"),
                ]),
                _vm._v(
                  " to let us know you found a bug. Tell us your username, what browser you are using and how you got to this error."
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _c("a", { attrs: { href: "/" } }, [
                  _vm._v("Take me back to the home page"),
                ]),
                _vm._v("."),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }