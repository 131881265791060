export const SET_FACILITATOR_NOTE = 'SET_FACILITATOR_NOTE';

// initial state
const state = {
  facilitationNotes: true,
};

// actions
const actions = {
  setFacilitationNotes: ({ commit }, facilitationNotes) => {
    commit(SET_FACILITATOR_NOTE, facilitationNotes);
  },
};

// mutations
const mutations = {
  SET_FACILITATOR_NOTE: (state, payload) => {
    state.facilitationNotes = payload;
  },
};

export default {
  // namespaced: true,
  state,
  actions,
  mutations,
};
