<template>
  <div
    class="current-step__content content__has-lists"
    :class="{ 'has-heading': step.type === 'header'}"
  >
    <figure
      v-if="step.type === 'image'"
      class="step__item-image"
      :class="stepClassSize + ' ' + step.position"
    >
      <div
        class="step__item-image-wrap"
        :class="{ 'ie__image': ieClass }"
      >
        <a
          v-if="step.url && step.url.length > 0"
          :href="step.url"
          :target="hrefTarget"
        >
          <img
            :src="step.imgix_url"
            :alt="step.alt"
          >
        </a>
        <figure v-else>
          <img
            :src="step.imgix_url"
            :alt="step.alt"
          >
          <figcaption
            v-if="step.caption"
            class="caption"
          >
            {{ step.caption }}
          </figcaption>
        </figure>
      </div>
    </figure>

    <!--  eslint-disable  -->
    <span
      v-if="step.type === 'text'"
      class="step__item-text"
      v-html="step.text"
    />
    <!--  eslint-enable  -->

    <!--  eslint-disable  -->
    <span
      v-if="step.type === 'header'"
      class="step__item-header"
      v-html="stepHeader(step)"
    />
    <!--  eslint-enable  -->

    <div
      v-if="step.type === 'code'"
      class="cbCode"
    >
      <pre v-if="step.syntax === 'html'">
        <code>{{ step.text }}</code>
      </pre>
      <pre
        v-else
        :class="'language-' + step.syntax"
      >
        <code>{{ step.text }}</code>
      </pre>
    </div>

    <div
      v-if="step.type === 'facilitator_note'"
      class="step__item-note"
    >
      <div
        v-if="showNote"
        class="facilitator_note"
      >
        <div class="flash-success">
          <h3>Facilitation Notes</h3>
          <!--  eslint-disable  -->
          <span v-html="step.text" />
          <!--  eslint-enable  -->
        </div>
      </div>
    </div>

    <div
      v-if="step.type === 'video'"
      class="step__item-video"
    >
      <video
        :id="randomVideoString"
        class="video-js vjs-default-skin vjs-big-play-centered"
        controls
        preload="auto"
        height="240"
        width="600"
        data-setup="{&quot;fluid&quot;:true}"
      >
        <source
          v-for="video in step.videos"
          :key="video.path"
          :src="videoURL(video.path)"
          :type="video.mime"
        >
        <p class="vjs-no-js">
          To view this video please enable JavaScript, and consider upgrading to a web browser that&nbsp;
          <a
            href="https://videojs.com/html5-video-support/"
            target="_blank"
          >supports HTML5 video</a></p>
      </video>
    </div>
    <!--  eslint-disable  -->
    <div
      v-if="step.type === 'embed'"
      :id="'embed-' + index"
      class="step__item-embed"
      v-html="$options.filters.script2Tag(step.code)"
    />
    <!--  eslint-enable  -->
  </div>
</template>

<script>
export default {
  name: 'EvidenceStep',
  components: {},
  props: {
    step: {
      type: Object,
      default() {
        return {};
      },
    },
    showNote: Boolean,
    index: {
      type: Number,
      default() {
        return 0;
      },
    },
    ieClass: Boolean,
  },
  computed: {
    hrefTarget() {
      return (this.step.url) ? '_blank' : '_self';
    },
    stepClassSize() {
      return (this.step.size_preference) ? this.step.size_preference : 'automatic';
    },
    randomVideoString() {
      return `video${Math.random().toString(36).slice(2)}`;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    sourceSet(source) {
      const widths = [300];
      const sources = [];
      const $this = this;

      _.each(widths, (value) => {
        const url = `${$this.imageSource(source)}&w=${value}`;
        sources.push(url);
      });
      return sources.join(', ');
    },
    imageSource(source) {
      // return source + '&fit=max&auto=format';
      return `${source}&auto=format`;
    },
    stepHeader(content) {
      return `<${content.level}>${content.text}</${content.level}>`;
    },
    videoURL(source) {
      return process.env.MIX_S3_URL + source;
    },
  },
};
</script>
