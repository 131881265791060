var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.initialLoadFinished,
          expression: "initialLoadFinished",
        },
      ],
    },
    [
      _vm.showHeader
        ? _c("header", [
            _c(
              "div",
              {
                staticClass: "container",
                class: {
                  "has-group": _vm.hasDefaultGroup && !_vm.navMinified,
                  "has-no-active-groups":
                    _vm.currentUser &&
                    !_vm.currentUser.hasAnyLicensedSites &&
                    !_vm.navMinified,
                },
              },
              [
                _vm._m(0),
                _vm._v(" "),
                !_vm.navMinified
                  ? _c(
                      "div",
                      { staticClass: "grouping" },
                      [
                        _vm.isLoggedIn && _vm.hasDefaultGroup
                          ? _c("group-name", {
                              attrs: {
                                "group-id": _vm.currentUser.defaultGroupId,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "AppNav",
                  _vm._b(
                    {},
                    "AppNav",
                    {
                      isLoggedIn: _vm.isLoggedIn,
                      navMinified: _vm.navMinified,
                      toggleMenu: _vm.toggleMenu,
                      notificationCount: _vm.notificationCount,
                      isToggled: _vm.isToggled,
                    },
                    false
                  )
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("router-view", {
        key: _vm.$route.fullPath,
        attrs: { "tab-options": _vm.tabOptions },
      }),
      _vm._v(" "),
      !_vm.isHome ? _c("footer-nav") : _vm._e(),
      _vm._v(" "),
      _c("project-review-modal"),
      _vm._v(" "),
      _c("alert-message"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "branding" }, [
      _c("div", { staticClass: "logo" }, [
        _c("img", {
          attrs: { alt: "Mouse Create", src: "/img/mouse-create-logo.png" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }