<template>
  <form class="comments-form">
    <!--  eslint-disable  -->
    <label
      v-if="prompt"
      for="comment-input"
      class="comments-form__prompt"
    >${prompt}</label>

    <textarea
      id="comment-input"
      v-model="comment.body"
      class="comment-form__input"
      :placeholder="placeholder"
      rows="1"
      :maxlength="charLimit"
      :disabled="!owner.isInActiveGroup"
      @focus="inputFocus"
      @blur="inputBlur"
      @keyup.enter.prevent="submit($event)"
      @keydown.enter.prevent=""
    />
    <button
      class="comment-form__submit"
      :class="{ 'comment-form__submit': !comment.body.length }"
      @click.stop.prevent="submit($event)"
    >
      <span v-if="!submitInProgress">Submit <i class="fa fa-chevron-right" /></span>
      <span v-if="submitInProgress">
        Posting
        <i class="fa fa-spinner fa-pulse fa-fw fa-spin" />
      </span>
    </button>
    <comment-type-selection
      v-if="withActions"
      :visible.sync="typesVisible"
      :prompt.sync="prompt"
      :comment-type-id.sync="comment.comment_type_id"
    />
  </form>
</template>

<script>
/* eslint-disable */
// TODO: Fix this very broken file
import CommentTypeSelection from './CommentTypeSelection.vue';

export default {
  name: 'CommentForm',
  components: {
    CommentTypeSelection,
  },
  props: {
    replaceHolder: {
      type: String,
    },
    withActions: {
      type: Boolean,
      default: true,
    },
    comment: {
      type: Object,
      default() {
        return {
          body: '',
          comment_type_id: null,
        };
      },
    },
    onSubmit: {
      type: Function,
      required: true,
    },
    onError: {
      type: Function,
      default(response) {
      },
    },
    owner: {
      type: Object,
    },
  },
  data() {
    return {
      typesVisible: false,
      prompt: '',
      charLimit: 2000,
      submitInProgress: false,
      placeholder: 'Add a comment...',
      commentId: null,
    };
  },
  computed: {},
  watch: {
    'comment.body': function (newValue) {
      if (newValue.length > this.charLimit) {
        this.comment.body = newValue.substring(0, this.charLimit);
      }
    },
    prompt(newValue) {
      const $this = this;
      if (!newValue) {
        return;
      }
      this.$nextTick(() => {
        $($this.$el).find('.comment-form__input').focus();
      });
    },
  },
  created() {
    bus.$on('comment-type', this.commentType);
    bus.$on('clear-comment-type', this.clearCommenttype);
  },
  mounted() {
    autosize(this.getTextArea());
    if (this.replaceHolder) {
      this.placeholder = this.replaceHolder;
    }
  },
  methods: {
    submit($event) {
      const $this = this;
      if (!this.comment.body.length || this.submitInProgress) {
        return;
      }

      const { comment } = this;
      comment.comment_type_id = this.commentId;

      this.submitInProgress = true;
      this.onSubmit(comment).then(() => {
        $this.resetComment();
        $this.typesVisible = false;
        $this.submitInProgress = false;
      }, () => {
      });

      bus.$emit('comment-added', comment);
    },

    commentType(params) {
      this.placeholder = params.prompt;
      this.commentId = params.commentTypeId;
    },

    clearCommenttype() {
      this.placeholder = 'Add a comment...';
      this.commentId = null;
    },

    checkCount(e) {
      if (this.comment.body.length >= 10 && e.keyCode !== 8) {
        e.preventDefault();
      }
    },
    resetComment() {
      this.comment.body = '';
      this.comment.comment_type_id = null;
      const $this = this;
      this.$nextTick(() => {
        $this.updateTextArea();
      });
    },
    getTextArea() {
      return $(this.$el).find('.comment-form__input');
    },
    toggleActions() {
      this.typesVisible = !this.typesVisible;
    },
    inputFocus() {
      this.typesVisible = true;
    },
    inputBlur() {
      const $this = this;
      setTimeout(() => {
        $this.toggleActions();
      }, 250);
    },
    updateTextArea() {
      autosize.update(this.getTextArea());
    },
  },
};
</script>
