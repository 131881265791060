<template>
  <div class="page-wrap work">
    <div class="main-menu mobile-project-filter">
      <div class="mobile-dropdown mobile-filter">
        <div class="dropdown">
          <div class="dropdown-container">
            <div
              class="dropdown-button-delay"
              tabindex="0"
              @click="dropDownToggle"
            >
              Categories
            </div>
            <ul
              v-if="dropDownMenu"
              class="dropdown-menu"
              aria-hidden="true"
            >
              <li class="button-holder">
                <button
                  type="button"
                  class="btn"
                  @click="addCategoryModal()"
                >
                  Add New Category <i class="fa fa-plus" />
                </button>
              </li>
              <li>
                <div class="project-filter-list">
                  <div class="filter-block">
                    <h3>Choose a Category</h3>

                    <ul>
                      <li
                        v-for="cat in categories"
                        :key="cat.name"
                      >
                        <a
                          href="#"
                          class="filter tag"
                          :class="{ active: cat.active }"
                          @click="filterWork( cat , $event )"
                        >{{ cat.name }} (
                          <span
                            class="count"
                            v-text="cat.total"
                          />
                          )</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <main>
      <aside class="sidebar finder left">
        <div class="loading">
          <h1>My Work</h1>

          <div class="project-filter-list">
            <div class="filter-block">
              <h3>Categories</h3>
              <ul
                v-for="_category in categories"
                :key="_category.name"
                class="who-categories"
              >
                <li>
                  <a
                    href="#"
                    class="filter tag"
                    :class="{ active: _category.active }"
                    @click="filterWork( _category , $event )"
                  >
                    {{ _category.name }} (
                    <span class="count">
                      {{ _category.total }}
                    </span> )
                  </a>
                </li>
              </ul>

              <button
                class="btn"
                @click.stop.prevent="addCategoryModal"
              >
                Add New Category <i class="fa fa-plus" />
              </button>
            </div>
          </div>
        </div>
      </aside>

      <a id="maincontent" />
      <section class="primary-content">
        <div class="loading">
          <div
            v-if="category.id!=null"
            class="mywork-category"
          >
            <div class="mywork-category--name">
              Evidence: <span v-text="category.name" />
            </div>

            <div class="mywork-category--actions">
              <a
                href="#"
                class="actions"
                @click.stop.prevent="editCategoryModal"
              >Edit <i
                class="fa fa-pencil"
                aria-hidden="true"
              /></a>
              <a
                href="#"
                class="actions"
                @click.stop.prevent="confirmDeleteCategory( category )"
              >Delete <i
                class="fa fa-trash"
                aria-hidden="true"
              /></a>
              <a
                v-if="!isExporting"
                href="#"
                class="actions"
                @click.stop.prevent="exportWorkByCategory"
              >
                <span v-text="exportingText" /> <i
                  class="fa fa-arrow-up fa-rotate-45"
                  aria-hidden="true"
                />
              </a>
              <a
                v-else
                href="#"
                class="actions"
              >
                <span v-text="exportingText" />
              </a>
            </div>
          </div>
          <p
            v-else
            class="mywork-category"
          >
            Evidence: All
          </p>

          <div class="list">
            <ul>
              <card
                v-for="evidence in paginatedEvidences"
                :key="evidence.id"
                :evidence="evidence"
              />
            </ul>
          </div>

          <div
            v-if="evidences.length > cardLimit"
            class="mywork--load-more"
          >
            <a
              class="button button-evidence"
              href="#"
              @click.stop.prevent="cardLimit+=12"
            >Load More</a>
          </div>
        </div>
      </section>
    </main>
    <work-category-modal />
  </div>
</template>

<script>

import Card from '../card/Card.vue';
import modalMixin from '../../mixins/modal';
import WorkCategoryModal from '../modals/WorkCategoryModal.vue';

export default {
  name: 'Works',
  components: {
    Card,
    WorkCategoryModal,
  },
  mixins: [modalMixin],
  props: {},
  data() {
    return {
      categories: [],
      category: {
        id: null,
        name: null,
        hash: null,
        active: false,
        asset_type: null,
        asset: null,
        total: 0,
      },
      dropDownMenu: false,
      evidences: [],
      cardLimit: 12,
      isExporting: false,
      exportingText: 'Export',
    };
  },
  computed: {
    categoryUrl() {
      return laroute.route('api.work.category.index');
    },
    categoryShowUrl() {
      return laroute.route('api.work.category.show', {
        category: this.category.id,
        include: 'user,assets,collaborators',
      });
    },
    workUrl() {
      return laroute.route('api.evidences', {
        include: 'user,assets,collaborators',
      });
    },
    currentUser() {
      return this.$store.state.users.current;
    },
    categoryDeleteUrl() {
      return laroute.route('api.work.category.deleteCategory', { category: this.category.id });
    },
    isActive(category) {
      return (category.id === this.category.id);
    },
    paginatedEvidences() {
      return this.evidences.slice(0, this.cardLimit);
    },
  },
  created() {
    bus.$on('refresh-work-categories', this.refreshWorkCategories);
    bus.$on('set-active-category', this.filterWork);
  },
  mounted() {
    this.getCategories();
    this.getWork();

    if (this.$route.name !== 'profile-work') {
      const { id } = this.$route.params;
      this.$router.push({ name: 'profile-work', params: { id } });
    }

    document.body.className = 'no-js language-markup body--tab-height project-cards profile-page';
  },
  methods: {
    // get work|evidence
    getWork() {
      axios
        .get(this.workUrl)
        .then((response) => {
          this.evidences = response.data.data;
        });
    },

    // get work|evidence
    getWorkByCategory() {
      axios
        .get(this.categoryShowUrl)
        .then((response) => {
          this.evidences = response.data.data;
        });
    },

    // get list of categories with (counts)
    getCategories() {
      axios
        .get(this.categoryUrl)
        .then((response) => {
          this.categories = (response.data.data || []).map((cat) => {
            if (cat.id === this.category.id) {
              cat.active = true;
            }
            return cat;
          });
          this.getWorkByCategory();
        });
    },

    // filter list based on category selection
    filterWork(c, e) {
      if (e) {
        e.preventDefault();
      }

      // reset all active classes
      _.each(this.categories, (cat) => {
        cat.active = false;
      });

      // set current category to the one we clicked
      // eslint-disable-next-line
      c.active = true;

      const t = this.category;

      // deselecting if the current category is the one we clicked
      if (t.id === c.id) {
        // eslint-disable-next-line
        c.active = false;
        this.category = {
          id: null,
          name: null,
          active: false,
        };
        this.getWork();
        return true;
      }

      // set active category
      this.category = c;

      this.getWorkByCategory();
      return true;
    },

    addCategoryModal() {
      bus.$emit('open-work-category-modal', {
        category: {},
        mode: 'add',
        isVisible: true,
      });
    },

    editCategoryModal() {
      bus.$emit('open-work-category-modal', {
        category: this.category,
        mode: 'edit',
        isVisible: true,
      });
    },

    confirmDeleteCategory() {
      if (window.confirm(`Are you sure? This will remove the "${this.category.name}" category.`)) {
        this.deleteCategory();
      }
    },

    deleteCategory() {
      axios
        .post(this.categoryDeleteUrl)
        .then(() => {
          // this.filterWork()

          // reset all active classes
          _.each(this.categories, (cat) => {
            cat.active = false;
          });

          this.category = {
            id: null,
            name: null,
            active: false,
          };

          this.getWork();
          this.getCategories();
          // this.$router.push({ name: 'profile-work', params: { id: this.userToken }})
          // window.location.pathname = '/profile/my-work';
        },
        () => {
          // console.log(error);
        });
    },

    dropDownToggle() {
      this.dropDownMenu = !this.dropDownMenu;
    },

    // export functionality
    exportWorkByCategory() {
      this.isExporting = true;
      this.exportingText = 'Processing';

      const url = laroute.route('work.category.export', {
        user: this.currentUser.id,
        category: this.category.id,
      });

      window.open(url);

      this.isExporting = false;
      this.exportingText = 'Export';
    },

    refreshWorkCategories(params) {
      if (params) {
        this.category.name = params.updatedCategoryName;
        this.category.id = params.updatedCategoryId;
        this.category.active = true;
      }

      this.getCategories();
    },
  },
  events: {
    refreshWorkCategories(event) {
      this.category.name = event.updatedCategoryName;
      this.getCategories();
    },
  },
};
</script>
