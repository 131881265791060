var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "sort",
      class: {
        sort__active: _vm.field === _vm.sortBy,
        sort__descend: _vm.field === _vm.sortBy && _vm.sortDir === "desc",
      },
      on: { click: _vm.handleSort },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }