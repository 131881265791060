<template>
  <form class="notification-form">
    <div v-show="modes.entry">
      <div
        v-if="error"
        class="form-row"
      >
        <div v-text="error" />
      </div>

      <div class="form-row">
        <label>Notification Copy <span>* required</span></label>
        <span
          v-if="formErrors.text"
          class="help-block error"
        >
          <strong>{{ formErrors.text[0] }}</strong>
        </span>

        <textarea
          v-model.trim="notification.text"
          v-validate="'required'"
          type="text"
          name="email"
          rows="4"
          @keyup.enter.prevent="submit($event)"
        />

        <div
          v-if="formErrors.text"
          class="form-error"
        >
          <span v-text="formErrors.text[0]" />
        </div>
      </div>

      <div
        v-show="isAdmin"
        class="form-row"
      >
        <div class="form-1-2">
          <label>Url</label>

          <input
            v-model="notification.url"
            type="text"
            name="url"
            :placeholder="placeholder"
            @keyup.enter.prevent="submit($event)"
          >

          <div
            v-if="formErrors.url"
            class="form-error"
          >
            <span v-text="formErrors.url[0]" />
          </div>
        </div>
      </div>

      <div
        v-show="isAdmin"
        v-if="scope.networks.length"
        class="form-row"
      >
        <div class="form-1-2">
          <label>By Network</label>

          <select
            v-model="selected.networks"
            multiple
            :disabled="networkNotSelected"
            @keyup.enter.prevent="submit($event)"
          >
            <option />
            <option
              v-for="network in scope.networks"
              :key="network.id"
              :value="network.id"
            >
              {{ network.name }}
            </option>
          </select>
        </div>
      </div>

      <div
        v-show="isAdmin"
        v-if="scope.licenses.length"
        class="form-row"
      >
        <div class="form-1-2">
          <label>By License</label>

          <select
            v-model="selected.licenses"
            multiple
            :disabled="licenseNotSelected"
            @keyup.enter.prevent="submit($event)"
          >
            <option />
            <option
              v-for="license in scope.licenses"
              :key="license.id"
              :value="license.id"
            >
              {{ license.name }}
            </option>
          </select>
        </div>
      </div>

      <div
        v-show="isAdmin"
        v-if="scope.packs.length"
        class="form-row"
      >
        <div class="form-1-2">
          <label>By Content Pack</label>

          <select
            v-model="selected.packs"
            multiple
            :disabled="packNotSelected"
            @keyup.enter.prevent="submit($event)"
          >
            <option />
            <option
              v-for="pack in scope.packs"
              :key="pack.id"
              :value="pack.id"
            >
              {{ pack.name }}
            </option>
          </select>
        </div>
      </div>

      <div
        v-show="isAdmin"
        class="form-row"
      >
        <div class="form-1-2">
          <label>By Role</label>

          <select v-model="selected.role">
            <option>All Users</option>
            <option
              v-for="role in scope.roles"
              :key="role.id"
              :value="role.id"
            >
              {{ role.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="form-row">
        <label>{{ projectedText }}: {{ projected }}</label>
      </div>

      <div class="form-row">
        <div class="register align-right">
          <button
            type="button"
            class="button"
            @click="validateNotification"
          >
            Preview
          </button>
          <span class="cancel">or <a
            href="#"
            @click.prevent.stop="$router.go(-1)"
          >cancel</a></span>
        </div>
      </div>
    </div>

    <div v-show="modes.preview">
      <h2>Preview Notification</h2>

      <div
        v-if="error"
        class="form-row"
      >
        <div
          class="form-1-2"
          v-text="error"
        />
      </div>

      <div class="form-row">
        <notification :notification="formNotification" />
      </div>

      <h2>To be sent to:</h2>

      <div class="form-row">
        <label>{{ projectedText }}: {{ projected }}</label>

        <div class="register align-right preview__actions">
          <button
            type="button"
            class="button button-edit"
            @click="editNotification"
          >
            Edit
          </button><button
            type="button"
            class="button"
            @click="approveNotification"
          >
            Approve &amp; Send
          </button>
        </div>
      </div>
    </div>

    <div v-if="modes.send">
      <h2>Notification Sent</h2>

      <div
        v-if="error"
        class="form-row"
      >
        <div
          class="form-1-2"
          v-text="error"
        />
      </div>

      <div class="form-row">
        <notification :notification="notificationSubmitted" />
      </div>

      <div class="form-row">
        <a
          href="#"
          @click.stop.prevent="editNotification()"
        >Send another notification</a>
      </div>
    </div>
  </form>
</template>

<script>
import _ from 'lodash';
import Notification from './Notification.vue';

export default {
  name: 'NotificationForm',
  components: { Notification },
  props: {
    placeholder: {
      type: String,
      default: 'https://www.sample.com',
    },
    context: {
      type: String,
      default: 'group',
    },
    contextid: {
      type: [Number, String],
      default: 0,
    },
    approved: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      admin: false,
      group: false,
      notification: {
        text: null,
        user: false,
        created: {
          date: null,
        },
      },
      notificationSubmitted: false,
      scope: {
        networks: [],
        licenses: [],
        packs: [],
        roles: [],
      },
      selected: {
        networks: [],
        role: null,
        licenses: [],
        packs: [],
        group() {

        },
      },
      projected: 0,
      projectedText: null,
      modes: {
        entry: true, // initial form
        preview: false, // preview form for review
        send: false, // final send off
      },
      error: null,
      formErrors: '',
      formNotification: {},
    };
  },
  computed: {
    currentUser() {
      try {
        return this.$store.state.users.current;
      } catch (e) {
        return null;
      }
    },
    isAdmin() {
      return this.context === 'admin';
    },
    isGroup() {
      return this.context === 'group';
    },
    networkNotSelected() {
      return this.selected.licenses.length > 0 ? true : (this.selected.packs.length > 0);
    },
    licenseNotSelected() {
      return this.selected.networks.length > 0 ? true : (this.selected.packs.length > 0);
    },
    packNotSelected() {
      return this.selected.networks.length > 0 ? true : (this.selected.licenses.length > 0);
    },
  },
  watch: {
    'selected.networks': function watchSelectedNetworks() {
      // noinspection JSValidateTypes
      this.getRecipientCount();
    },
    'selected.role': function watchSelectedRoles() {
      // noinspection JSValidateTypes
      this.getRecipientCount();
    },
    'selected.licenses': function watchSelectedLicenses() {
      // noinspection JSValidateTypes
      this.getRecipientCount();
    },
    'selected.packs': function watchSelectedPacks() {
      // noinspection JSValidateTypes
      this.getRecipientCount();
    },
  },
  created() {
    this.startFormNotification();
  },
  mounted() {
    this.getFormOptions();
    // noinspection JSValidateTypes
    this.getRecipientCount();
  },
  methods: {
    getFormOptions() {
      if (!this.isAdmin) {
        return;
      }

      axios.get(laroute.route('api.notification.options'))
        .then((response) => {
          this.scope = response.data;
        });
    },

    getRecipientCount: _.debounce(function getRecipientCountMethod() {
      let tmpData = null;

      if (this.isAdmin) {
        tmpData = this.selected;
      } else if (this.isGroup) {
        tmpData = {
          group_id: this.contextid,
        };
      }

      axios
        .post(laroute.route('api.notification.recipients'), tmpData)
        .then((response) => {
          // console.log(response.data);
          this.projected = response.data.total;
          this.projectedText = response.data.text;
        });
    }, 500),

    // },

    validateNotification() {
      axios
        .post(laroute.route('api.notification.validate'), this.notification)
        .then((response) => {
          if (response.data.status) {
            this.previewNotification();
          } else {
            this.formErrors = response.data.errors;
          }
        });
    },

    editNotification() {
      this.modes.entry = true;
      this.modes.preview = false;
      this.modes.send = false;
    },
    previewNotification() {
      this.formNotification.text = this.notification.text;
      this.formNotification.user = this.currentUser ? this.currentUser : window.adminStore.currentUser;
      this.modes.entry = false;
      this.modes.preview = true;
      this.modes.send = false;
    },

    approveNotification() {
      this.modes.entry = false;
      this.modes.preview = false;

      // post new notification with scope
      axios.post(laroute.route('api.notification.send'), {
        context: this.context,
        context_id: this.contextid,
        networks: this.selected.networks,
        licenses: this.selected.licenses,
        packs: this.selected.packs,
        role: this.selected.role,
        group: this.selected.group,
        notification: this.notification,
      }).then((response) => {
        this.modes.send = true;
        this.notification.text = null;
        this.notificationSubmitted = response.data;
      }, (response) => {
        this.error = response.data.error;
      });
    },
    startFormNotification() {
      this.formNotification = _.extend({}, this.notification, {
        created: window.moment.now(),
      });
    },

  },
};
</script>
